
import React, { Component } from 'react'
import config from '../../config'
import { UIStore } from '../../stores'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'


class ProductDetailFullImage extends Component {
  render () {
    return (
      <ReactCSSTransitionGroup transitionName={ this.props.transitionName }
                               transitionEnterTimeout={ 500 }
                               transitionLeaveTimeout={ 300 }>
      {(this.props.isOpen) ? this.renderContent() : null }
      </ReactCSSTransitionGroup>
    )
  }


  renderContent () {
    return (
      <div className="product-detail-fullimage-wrapper" onClick={ () => UIStore.hideProductDetailFullImage() }>
        <div className="img-wrapper">
          <img src={ `https://${ config.S3_IMAGES }/images/100/${ this.props.src }` } alt=""/>
        </div>
      </div>
    )
  }
}

export default ProductDetailFullImage
