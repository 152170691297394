import React, { Component } from "react";
import { translate } from "react-i18next";
import { AppStore, OrderStore } from "../../stores";
import { Button } from "../";

@translate(["common"], { wait: false })
class OrderDetailHeader extends Component {
  render() {
    var button = null;
    if (
      AppStore.idprovider == OrderStore.idprovider ||
      AppStore.idprovider == null
    ) {
      button = (
        <Button
          type="normal"
          color="green"
          placeholder="Repetir carrito"
          onClick={() =>
            OrderStore.repeatOrder(
              OrderStore.idorder,
              OrderStore.postalcode,
              OrderStore.idzone,
              OrderStore.idprovider,
              OrderStore.providername
            )
          }
        />
      );
    }
    return (
      <div className="shopping-cart_herader">
        <div className="left box-md-6">
          <p className="p regular white s14">{this.props.units} ARTÍCULOS:</p>
          <p className="p regular white s14 total opacity">
            Total (Sin envío):
          </p>
          <h1 className="h1 regular white s38">
            {this.props.price.toFixed(2)} €
          </h1>
        </div>
        <div className="right box-md-6">{button}</div>
      </div>
    );
  }
}

export default OrderDetailHeader;
