import React from "react";
import { browserHistory } from "react-router";
import config from "../../../config";
import { OrderStore, UIStore } from "../../../stores";
import { IconButton, LinkButton, Button, Icon } from "../../";
import IframeModal from "../../Dialogs/Modal/modals/IframeModal";

const OrderButtonApartments = (props) => {
  const openIframe = () => {
    UIStore.toggleModal({
      content: () => (
        <IframeModal
          url={props.detailCart}
          classIframe="iframe-modal-details-cart"
        />
      ),
    });
  };

  return (
    <div className="order-button" id={props.id} to={props.onClick}>
      <div className="container-order-id">
        <p className="p bold black s17">{props.id}</p>
      </div>
      <div className="container-status">
        <div className="state_cart">
          <p className="p regular black normal s17">{props.status}</p>
        </div>
      </div>
      {props.detailCart !== null && props.detailCart !== undefined ? (
        <div className="container-details">
          <button className="btn flat grey detail" onClick={openIframe}>
            <Icon type="order_chat" size="60px" viewport={"0 0 57 30"} />
          </button>
        </div>
      ) : (
        <div className="container-tracking">
          <div className="not-found-tracking">
            <p className="p regular not-found-text black normal s17">-</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderButtonApartments;
