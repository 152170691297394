// Component for deals type 1 and 3
import React, { Component } from 'react'

class ProductDeals_2 extends Component {

  render () {

  	var text = null
  	var text2 = null
  	if (this.props.type == 2){
  		text = <p className="p bold white s18">{ this.props.deal }<span className="p regular white s12">%</span></p>
  		text2 = <p className="p regular white s9">2ª unidad </p>
  	}
  	const class_top = this.props.class_top
  	const class_down = this.props.class_down

		return (
			<div className={class_top}>
				{text}
				<div className={class_down}>
				{text2}
				</div>
			</div>
		)
	}
}

export default ProductDeals_2
