
/*****************************************************************************
 * @class: UIStore
 *
 * @description:Este store es el encargado de manejar la lógica de datos de la
 * aplicación. El estado de los datos de la aplicación se ha de encargar el store
 * y los componentes no necesitan conocer el estado de los datos que consumen,
 * ellos tan solo saben que necesitan ciertos datos y los piden.
 *
 *****************************************************************************/

import { observable, computed, action, reaction } from 'mobx'
import { AppStore, SearchStore } from './'
import config from '../config'
import { capitalize, debounce, truncate } from '../utils'

class UIStore {

  // Controla los titulos para las paginas
  title = 'Ilusia®. The Smart Shopping Experience'

  // Controla la visualización de la toolbar
  @observable toolbarType = 'simple'

  // Controla la visualización del menu de categorias
  @observable isCategoriesOpen = false

  // Controla la visualización del tutorial
  @observable isTutorialOpen = false
  @observable isTutorialuserOpen = false

  // Controla la visualización del aviso de cookies
  @observable isCookiesOpen = false

  // Controla la visualización de las ventanas modales
  @observable isModalOpen = false
  modalConfig = {}

  // Controla la visualización de las notificaciones
  @observable isNotificationOpen = false
  notificationConfig = {}

  // Controla la visualización de las alertas
  @observable isAlertOpen = false
  alertConfig = {}

  // Controla la visualización del loading
  @observable isLoadingOpen = false

  // Controla los fondos para las diferentes vistas de la aplicación
  @observable backgroundSource = ''
  @observable backgroundType = ''

  // Array de productos que caen al carro
  @observable animatedProducts = new Map()
  @observable animatedQuantityBadges = new Map()

  // Control del tooltip para las descripciones de productos
  @observable isProductTooltipVisible = false
  @observable productTooltipDescription = ''
  @observable productTooltipBrand = ''


  @observable productDetailFullImage = null
  @observable isProductDetailFullImageVisible = false


  product_url
  layouts_url
  background_url


  constructor () {
    window.addEventListener('resize', (event) => {
      if (AppStore.categories) this.resizeWindowHandler()
    }, false)
  }


  @action setTitle (newTitle) {
    if (newTitle) this.title = `Ilusia® | ${ newTitle }`
    else this.title = 'Ilusia®. The Smart Shopping Experience'
    if (newTitle === 'Shop') this.title = `Ilusia® | ${ AppStore.providername }`
    if (newTitle === 'Cart') this.title = `Ilusia® | ${ AppStore.providername } - Carrito`
    if (newTitle === 'Bot') this.title = `Ilusia® | ${ AppStore.providername } - Buscador`
    if (newTitle === 'Detail') this.title = `Ilusia® | ${ AppStore.providername } - ${ capitalize(AppStore.productname.replace(/\-/g, ' ')) }`
    document.title = this.title
  }


  // Control del tooltip para las descripciones de productos
  @action showProductTooltip = (product) => {
    this.productTooltipDescription = truncate(capitalize(product.description), 80)
    this.productTooltipBrand = truncate(capitalize(product.brand), 40)
    this.isProductTooltipVisible = true
    return
  }

  // Control del tooltip para las descripciones de productos
  @action hideProductTooltip = () => {
    this.productTooltipDescription = ''
    this.productTooltipBrand = ''
    this.isProductTooltipVisible = false
  }


  /**
   * Añade un producto al Map
   */
  @action addAnimatedProduct = (product) => {
    const key = Math.round(Math.random() * 100) + 1
    this.animatedProducts.set(key, product)
    this.animatedQuantityBadges.set(key, product)
  }


  @action showProductDetailFullImage = (src) => {
    this.productDetailFullImage = src
    this.isProductDetailFullImageVisible = true
  }
  @action hideProductDetailFullImage = () => this.isProductDetailFullImageVisible = false


  /**
   *
   */
  @action toggleCategories = () => this.isCategoriesOpen = !this.isCategoriesOpen

  /**
   *
   */
  @action removeAnimatedProduct = (key) => this.animatedProducts.delete(key)


  /**
   *
   */
  @action removeAnimatedQuantityBadge = (key) => this.animatedQuantityBadges.delete(key)

  /**
   * Maneja la visualización del tutorial
   */
  @action toggleTutorial = () => { this.isTutorialOpen = !this.isTutorialOpen }
  @action toggleTutorialuser = () => { this.isTutorialuserOpen = !this.isTutorialuserOpen }

  /**
   * Maneja la visualización del aviso de cookies
   */
  @action showCookiesDialog = () => { this.isCookiesOpen = true }
  @action hideCookiesDialog = () => { this.isCookiesOpen = false }

  /**
   * Maneja la visualización de las ventanas modales
   */
  @action toggleToolbar = (state) => { this.toolbarType = state }

  /**
   * Maneja la visualización de las ventanas modales
   */
  @action toggleModal = (config) => {
    if (config) this.modalConfig = config
    this.isNotificationOpen = false
    this.isModalOpen = !this.isModalOpen
  }

  @action hideModal = () => {
    this.isNotificationOpen = false
    this.isModalOpen = false
  }

  /**
   * Maneja la visualización de las notificaciones
   */
  @action showNotification = (config) => {
    if (config) this.notificationConfig = config
    this.isNotificationOpen = true
  }

  /**
   * Maneja la visualización de las notificaciones
   */
  @action hideNotification = () => this.isNotificationOpen = false


  /**
   * Maneja la visualización de las alertas
   */
  @action toggleAlert = (config) => {
    if (config) this.alertConfig = config
    this.isNotificationOpen = false
    this.isAlertOpen = !this.isAlertOpen
  }

  @action hideAlert = () => {
    this.isNotificationOpen = false
    this.isAlertOpen = false
  }


  /**
   * Maneja la visualización del Loading
   */
  @action showLoading = () => this.isLoadingOpen = true
  @action hideLoading = () => this.isLoadingOpen = false


  /**
   * Maneja el cambio de fondo del componente BackgroundTransitionManager
   */
  @action setBackground = (source, type) => {
    if (type === 'custom') return
    this.backgroundSource = source || this.backgroundSource
    this.backgroundType = type || this.backgroundType
  }


  /**
   * Controla el reescalado del navegador.
   */
  resizeWindowHandler () {
    const height = window.innerHeight
    if (height > 920) config.MAX_SELVES = 3
    if (height <= 920) config.MAX_SELVES = 2
    if (height < 600) config.MAX_SELVES = 1

    if (height <= 820) config.MAX_SELVES_SHOP_BOT = 1
    if (height > 820) config.MAX_SELVES_SHOP_BOT = 2

    debounce(AppStore.resizeHandler(), 300)
    if (SearchStore.matches > 0) debounce(SearchStore.resizeHandler(SearchStore.models), 300, true)
  }
}


export default new UIStore()
