
import React from 'react'

const filters = () => {
  return (
    <g>
      <path d="M189.914,19.703H98.216C94.272,8.254,83.467,0,70.789,0C58.106,0,47.311,8.254,43.361,19.703H10.08
       c-5.246,0-9.498,4.295-9.498,9.586c0,5.297,4.252,9.59,9.498,9.59h33.281c3.949,11.451,14.745,19.699,27.428,19.699
       c12.678,0,23.483-8.248,27.427-19.699h91.698c5.248,0,9.503-4.293,9.503-9.59C199.417,23.998,195.162,19.703,189.914,19.703z
        M70.789,39.408c-5.529,0-10.027-4.537-10.027-10.119c0-5.576,4.498-10.119,10.027-10.119c5.533,0,10.031,4.543,10.031,10.119
       C80.82,34.871,76.322,39.408,70.789,39.408z M189.914,89.834h-31.87c-3.942-11.451-14.747-19.703-27.429-19.703
       c-12.68,0-23.484,8.252-27.428,19.703H10.08c-5.246,0-9.498,4.293-9.498,9.584c0,5.297,4.252,9.59,9.498,9.59h93.107
       c3.943,11.451,14.748,19.699,27.428,19.699c12.682,0,23.486-8.248,27.429-19.699h31.87c5.248,0,9.503-4.293,9.503-9.59
       C199.417,94.127,195.162,89.834,189.914,89.834z M130.615,109.539c-5.529,0-10.032-4.537-10.032-10.121
       c0-5.576,4.503-10.117,10.032-10.117c5.536,0,10.031,4.541,10.031,10.117C140.646,105.002,136.151,109.539,130.615,109.539z
        M189.914,159.963H88.362c-3.942-11.449-14.748-19.701-27.431-19.701c-12.678,0-23.479,8.252-27.428,19.701H10.08
       c-5.246,0-9.498,4.293-9.498,9.586c0,5.297,4.252,9.59,9.498,9.59h23.424c3.949,11.449,14.75,19.697,27.428,19.697
       c12.683,0,23.488-8.248,27.431-19.697h101.552c5.248,0,9.503-4.293,9.503-9.59C199.417,164.256,195.162,159.963,189.914,159.963z
        M60.932,179.666c-5.527,0-10.023-4.541-10.023-10.117c0-5.578,4.496-10.119,10.023-10.119c5.537,0,10.033,4.541,10.033,10.119
       C70.965,175.125,66.469,179.666,60.932,179.666z"/>
    </g>
  )
}

export default filters
