import React from 'react';

const three = () => {
  return (
    <g>
      <path
        d="m363.395 231.083c33.926-25.107 51.079-56.792 51.079-94.526 0-66.636-60.732-136.557-162.378-136.557-158.476 0-167.63 144.392-171.108 151.117l118.74 19.087c1.679-4.734-1.149-65.142 49.658-65.142 45.748 0 47.476 48.183 27.715 67.441-13.91 13.568-22.534 11.392-65.405 14.78l-15.601 109.058c14.685-3.471 39.459-12.041 61.758-12.041 25.591 0 51.416 18.164 51.416 58.755 0 43.682-27.524 63.237-54.8 63.237-57.998 0-55.866-65.857-57.524-70.195l-121.787 13.975c1.564 3.002 3.976 161.928 179.985 161.928 101.895 0 181.699-73.085 181.699-165.092 0-52.207-27.934-94.936-73.447-115.825z" />
    </g>
  )
}

export default three;