
import React, { Component } from 'react'
import { ShopBotLine } from '../components'

class ShopBot extends Component {

  render () {
    return (
      <div className="section shopbot">
        <ShopBotLine />
      </div>
    )
  }
}

export default ShopBot
