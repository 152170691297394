
import React from 'react'

const cart_eroski = () => {
  return (
  	<g transform="scale(0.95) translate(5, 5)">
      <path  fill="#FFFFFF" d="M158.637,15.75H41.362c-14.194,0-25.737,11.531-25.737,25.706v117.081
        c0,14.562,11.625,21.456,11.625,21.456c4.169,2.769,9.025,4.256,14.112,4.256h117.275c6.025,0,11.731-2.087,16.262-5.8
        c0,0,9.475-5.7,9.475-19.912V41.463C184.375,27.281,172.825,15.75,158.637,15.75"/>
      <path  fill="#E1151E" d="M41.362,20.519h63.187c-34.069,11.306-59.256,44.562-59.256,82.25
        c0,4.938,0.425,9.744,1.25,14.394C31.087,129,25.919,148.325,23.725,169.825c-2.113-3.256-3.331-7.144-3.331-11.287V41.463
        C20.394,29.938,29.825,20.519,41.362,20.519 M172.712,161.762l3.556,8.069c-3.744,5.794-10.256,9.65-17.637,9.65h-26.194
        C147.4,177.331,161.219,171.012,172.712,161.762 M109.9,179.488H41.362c-7.237,0-13.638-3.7-17.413-9.319
        c6.612-13.6,16.269-24.612,28.962-33.025C63.631,159.694,84.669,175.788,109.9,179.488"/>
      <path  fill="#E1151E" d="M106.819,20.519h51.819c11.537,0,20.969,9.419,20.969,20.938v117.081
        c0,4.138-1.219,8.006-3.312,11.269l-16.481-37.275c-8.863,8.925-21.006,14.612-34.363,14.95
        c-16.15,0.413-30.519-7.087-39.494-18.969c38.469-5.175,49.537-23.35,50.513-55.031c-13.2,19.244-32.619,30.181-59.756,32.431
        c-0.269-2.087-0.419-4.206-0.419-6.369c0-25.719,19.9-47.425,45.075-50.15L106.819,20.519z"/>
</g>
  )
}

export default cart_eroski
