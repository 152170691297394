
import React from 'react'

const support = () => {
  return (
    <g>
      <path d="M170.541,79.305h-4.007C160.841,47.744,133.177,23.732,100,23.732c-33.164,0-60.83,24.012-66.521,55.572
       H29.47c-15.921,0-28.889,12.961-28.889,28.889v26.199c0,15.926,12.968,28.883,28.889,28.883h10.584
       c4.223,0,7.652-3.426,7.652-7.645V91.332c0-28.84,23.463-52.303,52.293-52.303c28.848,0,52.311,23.463,52.311,52.303v61.381
       c-0.132,1.084-0.372,1.992-1.926,1.992h-27.627c-2.353-3.539-6.371-5.885-10.944-5.885c-7.261,0-13.14,5.885-13.14,13.139
       c0,7.26,5.879,13.139,13.14,13.139c4.208,0,7.919-2.018,10.319-5.098h26.627c0.367,0.033,1.51-0.027,2.076-0.027
       c2.758,0,6.922-0.496,10.966-3.996c0.8-0.695,1.628-1.602,2.427-2.701h6.312c15.922,0,28.878-12.957,28.878-28.883v-26.199
       C199.418,92.266,186.462,79.305,170.541,79.305z M32.406,147.982H29.47c-7.49,0-13.59-6.1-13.59-13.59v-26.199
       c0-7.492,6.1-13.592,13.59-13.592h2.936V147.982z M184.125,134.393c0,7.49-6.095,13.59-13.585,13.59h-2.94V94.602h2.94
       c7.49,0,13.585,6.1,13.585,13.592V134.393z"/>
    </g>
  )
}

export default support
