import React from 'react'

const welfare_1 = () => {
  return (
    <g>
    <path d="M161.428,53.97H38.571c-4.333,0-7.857,3.524-7.857,7.857V172.07C48.689,189.353,73.093,200,99.999,200
        c26.907,0,51.312-10.647,69.287-27.932V61.827C169.286,57.494,165.761,53.97,161.428,53.97z M100,141.467
        c-5.569,0-10.099-4.531-10.099-10.1c0-3.909,2.235-7.43,5.735-9.104V76.437h8.727v45.826c3.499,1.674,5.734,5.195,5.734,9.104
        C110.098,136.936,105.568,141.467,100,141.467z M145.848,84.559l-15.911,15.91c-1.317,1.317-3.069,2.043-4.932,2.043
        c-1.417,0-2.783-0.424-3.953-1.228c-2.517-1.735-5.299-3.17-8.267-4.26l-0.468-0.173v-8.083l0.926,0.284
        c4.146,1.283,8.071,3.156,11.675,5.57l14.921-14.922C128.307,70.876,114.548,66.213,100,66.213s-28.307,4.663-39.841,13.486
        L75.08,94.62c3.607-2.416,7.532-4.289,11.676-5.57l0.926-0.284v8.083l-0.468,0.173c-2.968,1.09-5.75,2.525-8.266,4.258
        c-1.17,0.807-2.537,1.23-3.954,1.23c-1.864,0-3.615-0.726-4.932-2.043l-15.911-15.91c-1.41-1.409-2.149-3.356-2.029-5.346
        c0.121-1.992,1.09-3.84,2.658-5.07C67.81,63.931,83.446,58.532,100,58.532c16.554,0,32.19,5.399,45.219,15.61
        c1.571,1.234,2.538,3.081,2.657,5.07C147.997,81.202,147.258,83.15,145.848,84.559z"/>
    <path d="M23.571,61.827c0-8.273,6.729-15,15-15h122.857c8.272,0,15,6.727,15,15v102.631
        C191.126,147.051,200,124.568,200,100C200,44.771,155.229,0,99.999,0S0,44.771,0,100c0,24.568,8.875,47.051,23.572,64.459V61.827z"
        />
</g>
  )
}

export default welfare_1