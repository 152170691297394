
import React, { Component } from 'react'
import { browserHistory } from 'react-router'
import { UIStore, AuthStore } from '../../../../stores'
import SignupModal from './SignupModal'
import { Icon, Button, IconButton } from '../../../'

/**
 *
 */

class LoginModal extends Component {

  componentDidMount () {
    window.setTimeout(() => {
      this.refs.email.focus()
      window.clearTimeout(this)
    })
  }

  render () {
    return (
      <div className="login-modal">
        <div className="logotype">
          <div className="symbol">
            <Icon className="icon-symbol" type="symbol" width="55" height="55" fill="blue" viewport="0 0 200 200"></Icon>
          </div>
          <div className="logo">
            <Icon className="icon-logo" type="logo" width="110" height="36" fill="grey" viewport="0 0 200 70"></Icon>
          </div>
        </div>
        <h2 className="h2 light grey s32">Iniciar sesión</h2>
        <form onSubmit={ this.send }>
          <input ref="email" className="input" id="email" type="text" placeholder="Correo electrónico" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" />
          <input className="input" id="password" type="password" placeholder="Contraseña" readOnly={ false } />
          <button type="submit"></button>
          <Button onClick={ this.recoverPass } type="flat" placeholder="He olvidado mi contraseña" color="blue" />
          <Button onClick={ this.send } type="outline" placeholder="Iniciar sesión" color="blue" />
        </form>
        <p className="p light grey s14">
          <span>¿Primera vez en Ilusia? </span><Button onClick={ this.openSignupModal } type="flat" placeholder="Registrate" color="blue" />
        </p>
      </div>
    )
  }


  openSignupModal = (event) => {
    event.preventDefault()

    UIStore.toggleModal()
    window.setTimeout(() => {
      UIStore.toggleModal({ content: () => <SignupModal onSubmit={ AuthStore.singUp } /> })
      window.clearTimeout(this)
    }, 400)
  }



  recoverPass = (event) => {
    event.preventDefault()
    UIStore.toggleModal()
    browserHistory.push('/recuperar-clave')
  }


  /**
   * Envia los datos del formulario.
   *
   * TODO: cambiar las referecias DOM a React.refs
   */
  send = (event) => {
    event.preventDefault()
    const email = document.querySelector('form input#email').value
    const password = document.querySelector('form input#password').value
    this.props.onSubmit(email, password)
  }
}


export default LoginModal
