
import React from 'react'

const logo = () => {
 return (
    <g>
      <path d="M193.02,126.987c0-0.573,0.143-1.101,0.418-1.593c0.279-0.487,0.674-0.88,1.172-1.167
      	c0.508-0.292,1.047-0.435,1.619-0.435c0.594,0,1.139,0.14,1.627,0.431c0.494,0.288,0.879,0.679,1.168,1.171
      	c0.279,0.495,0.42,1.028,0.42,1.593c0,0.587-0.146,1.128-0.436,1.622c-0.291,0.492-0.682,0.876-1.174,1.162
      	c-0.498,0.279-1.029,0.417-1.605,0.417c-0.572,0-1.111-0.143-1.619-0.438c-0.498-0.289-0.893-0.684-1.172-1.168
      	C193.162,128.1,193.02,127.563,193.02,126.987z M193.604,126.987c0,0.474,0.127,0.913,0.363,1.319s0.555,0.719,0.957,0.945
      	c0.395,0.233,0.834,0.348,1.297,0.348c0.48,0,0.918-0.119,1.328-0.352c0.406-0.238,0.732-0.553,0.965-0.952
      	c0.23-0.406,0.348-0.837,0.348-1.309c0-0.468-0.117-0.903-0.35-1.307c-0.242-0.4-0.564-0.718-0.969-0.955
      	c-0.404-0.231-0.842-0.351-1.322-0.351c-0.463,0-0.902,0.119-1.303,0.346c-0.4,0.238-0.725,0.551-0.959,0.96
      	S193.604,126.525,193.604,126.987z M196.537,125.305c0.314,0,0.566,0.09,0.771,0.262c0.195,0.169,0.291,0.391,0.291,0.669
      	c0,0.269-0.092,0.491-0.258,0.672c-0.174,0.177-0.404,0.278-0.689,0.302v0.02c0.125,0.037,0.24,0.109,0.342,0.206
      	c0.105,0.102,0.244,0.306,0.422,0.611l0.41,0.679h-0.781l-0.158-0.3c-0.139-0.281-0.266-0.507-0.389-0.681
      	c-0.121-0.176-0.229-0.285-0.318-0.352c-0.092-0.057-0.219-0.09-0.375-0.09h-0.291v1.422h-0.627v-3.42H196.537z M196.291,126.791
      	c0.207,0,0.361-0.048,0.471-0.127c0.113-0.093,0.17-0.211,0.17-0.365c0-0.157-0.064-0.279-0.176-0.359
      	c-0.119-0.085-0.271-0.125-0.465-0.125h-0.777v0.977H196.291z M12.058,77.715c-1.355,2.241-3.226,3.367-5.612,3.367
      	c-1.027,0-1.992-0.223-2.895-0.673c-0.964-0.575-1.703-1.324-2.224-2.259c-0.511-0.929-0.771-1.94-0.771-3.03
      	c0-1.222,0.29-2.312,0.873-3.273c0.577-1.088,1.381-1.937,2.414-2.55c1.028-0.609,2.09-0.908,3.185-0.908
      	c0.969,0,1.901,0.256,2.801,0.764c1.03,0.52,1.803,1.253,2.317,2.213c0.522,0.962,0.778,1.989,0.778,3.08
      	C12.924,75.602,12.633,76.688,12.058,77.715z M19.584,120.477c-0.323,0.451-0.866,0.822-1.636,1.107
      	c-0.776,0.281-1.55,0.369-2.32,0.238c-0.781-0.128-1.456-0.523-2.032-1.201c-0.576-0.674-0.867-1.783-0.867-3.318V94.31
      	c0-1.919,0.017-3.479,0.043-4.663c0.031-1.186,0.084-2.132,0.152-2.839c0.06-0.771,0.129-1.374,0.188-1.829
      	c-3.995,0-7.017,0.836-9.076,2.506c-2.064,1.667-3.09,4.426-3.09,8.269l-0.1,22.704c0,1.604,0.246,3.14,0.728,4.614
      	c0.481,1.473,1.222,2.753,2.219,3.85c0.998,1.09,2.241,1.938,3.718,2.548c1.481,0.608,3.221,0.817,5.219,0.621
      	c2.06-0.126,3.573-0.718,4.54-1.778c0.96-1.059,1.609-2.164,1.931-3.311C19.584,123.65,19.715,122.141,19.584,120.477z
      	 M42.278,120.477c0.133,1.664,0,3.174-0.38,4.525c-0.325,1.146-0.965,2.252-1.935,3.311c-0.963,1.061-2.479,1.652-4.538,1.778
      	c-1.994,0.196-3.733-0.013-5.216-0.621c-1.48-0.61-2.723-1.458-3.718-2.548c-0.998-1.097-1.736-2.377-2.221-3.85
      	c-0.483-1.475-0.727-3.01-0.727-4.614V78.486c-0.061-1.925-0.11-3.482-0.145-4.666c-0.037-1.188-0.083-2.132-0.147-2.84
      	c-0.062-0.768-0.124-1.374-0.188-1.829c3.989,0,7.049,0.838,9.175,2.503c2.12,1.672,3.186,4.394,3.186,8.178v37.47
      	c0,1.535,0.288,2.645,0.874,3.318c0.574,0.678,1.246,1.073,2.022,1.201c0.772,0.131,1.551,0.043,2.319-0.238
      	C41.413,121.299,41.958,120.928,42.278,120.477z M89.411,109.32c0,4.293-0.637,7.79-1.927,10.482
      	c-1.293,2.693-2.947,4.809-4.974,6.346c-2.031,1.545-4.302,2.603-6.809,3.176c-2.512,0.576-4.994,0.864-7.438,0.864
      	c-2.449,0-4.931-0.288-7.439-0.864c-2.513-0.573-4.773-1.631-6.811-3.176c-2.027-1.537-3.668-3.652-4.925-6.346
      	c-1.253-2.692-1.881-6.189-1.881-10.482l0.101-13.566c0-3.843,1.043-6.588,3.135-8.222c2.091-1.637,5.132-2.455,9.124-2.455
      	c-0.062,0.387-0.127,0.959-0.189,1.73c-0.065,0.707-0.113,1.672-0.149,2.885c-0.029,1.219-0.052,2.787-0.052,4.712l0.103,14.915
      	c0,2.692,0.26,4.852,0.77,6.487c0.514,1.638,1.211,2.901,2.082,3.801c0.868,0.897,1.834,1.507,2.894,1.83
      	c1.063,0.318,2.14,0.481,3.239,0.481c1.093,0,2.173-0.163,3.231-0.481c1.065-0.323,2.031-0.933,2.898-1.83
      	c0.874-0.899,1.562-2.163,2.079-3.801c0.513-1.636,0.771-3.795,0.771-6.487l0.101-13.566c0-3.843,1.06-6.588,3.184-8.222
      	c2.124-1.637,5.184-2.455,9.175-2.455c-0.06,0.387-0.128,0.959-0.192,1.73c-0.062,0.707-0.11,1.672-0.142,2.885
      	c-0.036,1.219-0.051,2.787-0.051,4.712L89.411,109.32z M118.004,117.875c0.125-1.088-0.178-2-0.922-2.736
      	c-0.742-0.74-1.803-1.446-3.188-2.116c-1.383-0.672-3.012-1.397-4.875-2.164c-1.869-0.772-3.834-1.795-5.891-3.082
      	c-1.545-0.895-2.815-1.975-3.814-3.219c-1.001-1.25-1.726-2.597-2.175-4.042c-0.45-1.439-0.647-2.912-0.581-4.418
      	c0.062-1.51,0.422-3.004,1.062-4.482c0.645-1.407,1.483-2.594,2.513-3.555c1.033-0.963,2.175-1.747,3.429-2.358
      	c1.254-0.605,2.525-1.041,3.818-1.298c1.283-0.253,2.473-0.383,3.572-0.383h1.158c3.408,0.19,6.535,1.026,9.365,2.499
      	c2.834,1.479,5.254,3.434,7.246,5.871l0.291,0.282l-0.291,0.389c-0.645,0.897-1.35,1.569-2.123,2.018
      	c-0.709,0.447-1.512,0.675-2.414,0.675c-0.906,0-1.777-0.172-2.607-0.527c-0.842-0.349-1.736-0.723-2.705-1.109
      	c-1.225-0.508-2.369-0.939-3.432-1.297c-1.059-0.353-2.143-0.528-3.234-0.528c-1.418,0-2.48,0.306-3.188,0.913
      	c-0.705,0.606-1.131,1.334-1.258,2.166c-0.129,1.216,0.256,2.232,1.162,3.031c0.898,0.8,2.223,1.551,3.963,2.258
      	c1.99,0.835,4.115,1.746,6.367,2.745c2.258,0.992,4.25,2.209,5.992,3.652c1.732,1.446,3.061,3.174,3.959,5.196
      	c0.902,2.019,1,4.474,0.289,7.355c-0.32,1.153-0.869,2.357-1.643,3.606c-0.77,1.249-1.85,2.395-3.232,3.415
      	c-1.391,1.028-3.062,1.876-5.025,2.552c-1.959,0.678-4.262,1.007-6.9,1.007c-0.521,0-0.941-0.03-1.26-0.098
      	c-3.092-0.126-6.135-0.929-9.125-2.397c-2.994-1.477-5.488-3.431-7.488-5.871l-0.287-0.287l0.287-0.388
      	c0.326-0.51,0.676-0.929,1.06-1.252c0.392-0.313,0.741-0.606,1.067-0.863c0.771-0.386,1.544-0.574,2.314-0.574
      	c0.904,0,1.779,0.174,2.611,0.526c0.836,0.357,1.742,0.723,2.703,1.107c0.904,0.45,1.92,0.836,3.041,1.155
      	c1.133,0.319,2.369,0.549,3.723,0.673c2.316,0,3.973-0.449,4.975-1.347C117.311,119.673,117.873,118.777,118.004,117.875z
      	 M146.779,77.715c-1.35,2.241-3.219,3.367-5.596,3.367c-1.031,0-2.002-0.223-2.896-0.673c-0.973-0.575-1.709-1.324-2.221-2.259
      	c-0.52-0.929-0.777-1.94-0.777-3.03c0-1.222,0.291-2.312,0.873-3.273c0.578-1.088,1.379-1.937,2.412-2.55
      	c1.031-0.609,2.088-0.908,3.184-0.908c0.975,0,1.902,0.256,2.807,0.764c1.027,0.52,1.801,1.253,2.314,2.213
      	c0.514,0.962,0.773,1.989,0.773,3.08C147.652,75.602,147.359,76.688,146.779,77.715z M154.318,120.477
      	c-0.33,0.451-0.869,0.822-1.643,1.107c-0.771,0.281-1.547,0.369-2.32,0.238c-0.77-0.128-1.445-0.523-2.025-1.201
      	c-0.576-0.674-0.871-1.783-0.871-3.318V94.31c0-1.919,0.018-3.479,0.045-4.663c0.037-1.186,0.08-2.132,0.148-2.839
      	c0.064-0.771,0.131-1.374,0.191-1.829c-3.992,0-7.021,0.836-9.078,2.506c-2.062,1.667-3.086,4.426-3.086,8.269l-0.1,22.704
      	c0,1.604,0.242,3.14,0.727,4.614c0.479,1.473,1.217,2.753,2.221,3.85c0.996,1.09,2.232,1.938,3.719,2.548
      	c1.484,0.608,3.217,0.817,5.213,0.621c2.062-0.126,3.572-0.718,4.539-1.778c0.969-1.059,1.607-2.164,1.932-3.311
      	C154.318,123.65,154.449,122.141,154.318,120.477z M162.523,104.608c1.738-1.095,3.703-1.91,5.896-2.46
      	c2.184-0.54,4.471-0.816,6.852-0.816c3.346,0,6.543,0.478,9.566,1.447c-0.195-1.992-0.615-3.753-1.256-5.292
      	c-0.836-1.983-2.092-3.43-3.771-4.33c-0.646-0.324-1.332-0.559-2.074-0.721c-0.736-0.163-1.592-0.242-2.557-0.242h-1.064
      	c-0.898,0.064-1.807,0.209-2.699,0.437c-0.904,0.228-1.803,0.494-2.709,0.812c-0.9,0.325-1.807,0.596-2.705,0.823
      	c-0.898,0.224-1.771,0.335-2.607,0.335c-0.902,0-1.771-0.196-2.611-0.578c-0.768-0.449-1.445-1.19-2.025-2.216l-0.189-0.382
      	l0.189-0.29c1.936-2.046,4.201-3.7,6.814-4.954c2.604-1.25,5.449-1.971,8.543-2.162c0.32-0.066,0.594-0.099,0.822-0.099
      	c0.223,0,0.498,0,0.82,0c2.959,0,5.666,0.528,8.113,1.587c2.449,1.058,4.572,2.517,6.373,4.376c3.672,3.98,5.57,9.2,5.701,15.675
      	v0.773c0,6.671-1.709,12.09-5.121,16.256c-1.674,2.055-3.684,3.72-6.037,5.006c-2.352,1.281-4.977,2.078-7.871,2.402
      	c-0.387,0.061-0.771,0.093-1.158,0.093c-0.379,0-0.807,0-1.252,0c-1.357,0-2.775-0.108-4.254-0.335
      	c-1.479-0.228-2.93-0.654-4.35-1.296c-1.477-0.575-2.83-1.333-4.057-2.263c-1.221-0.928-2.254-2.129-3.088-3.607
      	c-1.223-1.857-1.871-4.132-1.93-6.83v-0.574c0-2.5,0.518-4.588,1.543-6.257C159.275,107.268,160.658,105.824,162.523,104.608z
      	 M182.523,109.223c-0.842-0.131-1.789-0.253-2.859-0.388c-1.055-0.127-2.139-0.189-3.234-0.189c-0.898,0-1.768,0.046-2.6,0.148
      	c-0.84,0.088-1.609,0.27-2.32,0.526c-1.613,0.516-2.834,1.311-3.668,2.402c-0.33,0.385-0.586,0.821-0.775,1.3
      	c-0.193,0.476-0.322,1.011-0.389,1.585c-0.064,0.191-0.094,0.479-0.094,0.867c0,0.636,0.094,1.331,0.289,2.066
      	c0.193,0.734,0.576,1.431,1.158,2.067c0.518,0.644,1.271,1.188,2.27,1.639c1,0.447,2.33,0.673,4.006,0.673
      	c0.52,0,0.898-0.035,1.158-0.098c1.803-0.065,3.381-0.575,4.738-1.539c0.637-0.388,1.189-0.847,1.643-1.396
      	c0.443-0.54,0.832-1.135,1.156-1.776c0.705-1.218,1.186-2.472,1.451-3.755c0.252-1.281,0.385-2.467,0.385-3.557
      	c-0.066,0-0.113-0.015-0.146-0.047c-0.031-0.035-0.078-0.054-0.145-0.054c-0.258-0.062-0.551-0.124-0.873-0.185
      	C183.352,109.447,182.967,109.351,182.523,109.223z"/>
    </g>
  )
}

export default logo
