
/**
 * @class: Alert extends React.Component
 * @author: Edgar Bermejo
 * @description: Son dialogos que interrumpen la nevegación del usuario para informar
 * sobre alguna situación y que siempre requieren una acción por parte del usuario,
 * o bien de confirmación o bien de cancelación.
 *
 * NOTE: Al ser un requisito esencial la interacción de usuario es importante bloquear
 * totalmente las posibles acciones para centrar la atención de usuario y forzarle
 * a la selección de las opciones que se le ofrezcan. Por esto, este tipo de dialogos
 * no disponen de la propiedad 'clickOnBackgroundClose' en su configuración.
 *
 * TODO:
 * - Parametrizar los tiempos de las transiciones
 * - Parametrizar los tiempos de las transiciones
 *
 * Parámetros de configuración:
 * @Config.type {[String]}: {default | primary | success | info | warning | danger} @default: 'default',
 * @Config.content {[Component]}: <Component /> | @required
 *
 *
 * @usage:
 *
 * // HoC
 * const Template = (props) => <div className="contenti">
 *   <h1>Soy un HoC Alert</h1>
 *   <button onClick={ props.clickEvent }>Close</button>
 * </div>
 *
 * // React.Component
 * class AlertTemplate extends Component {
 *   render () {
 *     return(
 *       <div className="contenti">
 *         <h1>Soy un React.Component Alert</h1>
 *         <button onClick={ this.props.clickEvent }>Close</button>
 *       </div>
 *     )
 *   }
 * }
 *
 * UIStore.toggleAlert({
 *   type: 'success'
 *   // content: () => <Template clickEvent={ testClick } />
 *   content: () => <AlertTemplate clickEvent={ testClick } />
 * })
 *
 * <Alert isOpen={ UIStore.isNotificationOpen }
 *        config={ UIStore.alertConfig }
 *        transitionName="alert-anim"/>
 */

import React, { Component } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'


class Alert extends Component {

  static propTypes = {
    isOpen: React.PropTypes.bool,
    config: React.PropTypes.object,
    transitionName: React.PropTypes.string
  }

  static defaultProps = {
    isOpen: false,
  }


  render () {
    return (
      <ReactCSSTransitionGroup transitionName={ this.props.transitionName }
                               transitionEnterTimeout={ 500 }
                               transitionLeaveTimeout={ 300 }>
          {(this.props.isOpen) ? this.renderContent() : null }
      </ReactCSSTransitionGroup>
    )
  }


  renderContent () {
    const { customClassName } = this.props
    const { type } = this.props.config
    return (
      <div className={ `alert-wrapper ${ customClassName }` } ref="alert" role="dialog">
        <div className={ `alert-content ${type || 'default'}` }>
          { this.props.config.content() }
        </div>
      </div>
    )
  }
}


export default Alert
