
import config from '../../config'
import checkApiResponse from './checkApiResponse'

/**
 * @func: postOrder
 * @return {[Object]} data
 *
 * @description:
 */

const postOrder = (idprovider, integrationtype, booking_id) => {
  const params = { id_provider: idprovider, empty_cart: 1, web: 1, integration_type: integrationtype, booking_id: booking_id }
  return config.API.ordersInsPost({}, params, {})
  .then(result => result.data)
  .catch(error => {checkApiResponse(error); console.log('error in api', error)})
}


export default postOrder
