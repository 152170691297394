import React, { Component } from 'react'


class FilterSwitch extends Component {


  constructor ( props ) {
    super( props );

    this.state = {
      isChecked: this.props.active
    }
  }


  componentWillMount () {
    this.setState({ isChecked: this.props.active })
  }


  render () {
    let id
    if (this.props.id == '' && this.props.name != '')
      id = this.props.name

    let checkClass
    (this.state.isChecked) ? checkClass = 'checked' : checkClass = ''

    return(
      <div className="switch-container">
        <label htmlFor={ id }>
          <input onChange={ this.handleChange }
                 defaultChecked={ this.props.isChecked }
                 id={ id }
                 name={ this.props.name }
                 className={"switch " + checkClass}
                 type="checkbox"
                 value="1" />
            <div className={ "switch-inner " + this.props.color }>
              <span className="texts">
                <p className="p regular s16 white left" ref="textLeft">{ 'Alérgenos' }</p>
                <p className="p regular s16 blue right" ref="textRight">{ 'Cuídate' }</p>
              </span>
              <div ref="switchButton"></div>
            </div>
        </label>
      </div>
    );
  }


  handleChange = (event) => {
    if (this.state.isChecked) {
      this.refs.textLeft.classList.remove('blue')
      this.refs.textLeft.classList.add('white')
      this.refs.textRight.classList.add('blue')
      this.refs.textRight.classList.remove('white')
    } else {
      this.refs.textLeft.classList.add('blue')
      this.refs.textLeft.classList.remove('white')
      this.refs.textRight.classList.remove('blue')
      this.refs.textRight.classList.add('white')
    }

    this.props.onSwitchChange()
    this.setState({ isChecked: !this.state.isChecked })
  }

}



export default FilterSwitch