
import React from 'react'
import { translate } from 'react-i18next'
import { AppStore } from '../../stores'
import { Icon, LinkButton } from '../'

/**
 * HoC para notificaciones de error de autenticación
 * @param {[Object]} props
 * @param {[Object]} t
 */
const EmptyCart = ({ props, t }) => <div className="container empty-cart">
  <div className="box-md-6 box-offset-md-3">
    <Icon type="add_to_cart" fill="light-grey" className="test" size="180" />
    <h2 className="h2 regular black s22">Su carrito está vacío</h2>
    <p className="p regular grey s16">No hay nada que ver en su carrito de la compra. Añada productos a su carrito para poder acceder a el.</p>
    <LinkButton placeholder="Volver a la tienda"
                color="blue"
                onClick={ `/` } />
  </div>
</div>

export default translate(['common'], { wait: false })(EmptyCart)
