
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { OrderStore } from '../stores'
import config from '../config'
import { OrderDetailTicket, OrderDetailHeader, OrderDetailProduct } from '../components'

@observer
class OrderDetail extends Component {

  render () {
    if (!OrderStore.order || !OrderStore.order.products) return <div className="section shopping-cart"></div>
    const products = Array.from(OrderStore.order.products)
                          .map(product => <OrderDetailProduct product={ product }
                                                              key={ product.id_product_eva } />)

    let units = 0
    let price = 0
    for (let product of OrderStore.order.products) {
      units += product.quantity
      price += product.total_price
    }

    return (
      <div className="section shopping-cart">
        <div className="container">
          <OrderDetailHeader units={ units }
                             price={ price } />

          <OrderDetailTicket products={ products }
                             providername={ OrderStore.providername } />
        </div>
      </div>
    )
  }
}

export default OrderDetail
