

import React, { Component } from 'react'
import { ProductAccordionButton } from './'


class ProductAccordionItem extends Component {
  render () {
    return (
      <div className={ `accordion-item ${ (this.props.current === this.props.id) ? 'active' : '' }` }>
        <ProductAccordionButton title={ this.props.title } onClick={ this.props.onClick.bind(this, this.props.id) } />
        <div className="info" dangerouslySetInnerHTML={{__html: this.props.content }} />
      </div>
    )
  }
}


export default ProductAccordionItem
