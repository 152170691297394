
import React from 'react'

const cart = () => {
  return (
    <g>
      <path d="M177.533,23.867H50.893c-0.227-0.918-0.438-1.764-0.609-2.422C47.555,10.971,41.756,5.717,37.369,3.156
       c-4.887-2.865-9.232-3.129-10.055-3.148L8.982,0C4.577,0,1,3.576,1,7.982c0,4.408,3.577,7.982,7.982,7.982h17.913
       c0.497,0.051,5.675,0.805,7.944,9.51c2.92,11.203,15,65.824,20.811,92.197c0.018,0.1,0.006,0.199,0.027,0.295
       c0.037,0.217,0.118,0.416,0.177,0.627c1.673,7.621,2.788,12.707,2.931,13.338c1.277,6.225,7.479,17.859,21.963,17.859h110.613
       c4.408,0,7.984-3.578,7.984-7.984c0-4.412-3.576-7.982-7.984-7.982H80.748c-4.597,0-5.973-3.625-6.355-5.209l-0.006-0.039
       c-0.111-0.506-0.566-2.59-1.267-5.787c24.413-4.617,96.548-18.035,107.051-20.248c9.132-1.926,13.959-6.789,16.397-10.533
       c2.656-4.074,3.154-7.746,3.24-8.777l0.027-0.338V46.17C199.836,29.811,186.496,23.867,177.533,23.867z M183.877,81.85
       c-0.281,1.053-1.543,3.922-7.002,5.072c-10.275,2.162-83.404,15.77-107.188,20.273c-4.308-19.529-10.643-48.123-15.046-67.363
       h122.893c0.021,0,1.898,0.1,3.592,0.943c1.428,0.719,2.752,1.863,2.752,5.395V81.85z"/>
     <path d="M80.424,153.906c-12.396,0-22.471,10.08-22.471,22.463c0,12.391,10.075,22.467,22.471,22.467
       c12.385,0,22.465-10.076,22.465-22.467C102.889,163.986,92.809,153.906,80.424,153.906z M80.424,182.875
       c-3.593,0-6.51-2.912-6.51-6.506c0-3.586,2.917-6.504,6.51-6.504c3.582,0,6.498,2.918,6.498,6.504
       C86.922,179.963,84.006,182.875,80.424,182.875z"/>
     <path d="M167.053,153.906c-12.391,0-22.463,10.08-22.463,22.463c0,12.391,10.072,22.467,22.463,22.467
       c12.389,0,22.465-10.076,22.465-22.467C189.518,163.986,179.441,153.906,167.053,153.906z M167.053,182.875
       c-3.586,0-6.504-2.912-6.504-6.506c0-3.586,2.918-6.504,6.504-6.504s6.51,2.918,6.51,6.504
       C173.562,179.963,170.639,182.875,167.053,182.875z"/>
    </g>
  )
}

export default cart
