
import React, { Component } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { Icon, Button, IconButton, SwitchButton, Dropdown } from '../../../'
import { getMasCartInformation } from '../../../../utils/api'
import { AppStore, UIStore, ShopStore } from '../../../../stores'

/**
 * @class: FinisOrderDIAModal
 */

class FinisOrderMas extends Component {
 static defaultProps = {
    isOpen: true
  }

  constructor (...args) {
    super(...args)
  }

   state = {
    pay: null,
    disabled : true,
    idaddress : null,
    classAddress: "dark",
    start_time: null,
    end_time: null,
    start_date: null,
    reset: false,
    serve: null 
  }
  MasInformation = AppStore.CatInformationMas


  render () {
    return ( this.renderContent() )
  }


  renderContent () {
    console.log(AppStore.CatInformationMas)
      const PAYMENT = [{'pay':'Efectivo', 'value':1}, {'pay':'Tarjeta', 'value':2}]
      const payment = PAYMENT.map(pay => {
        const obj = {
          text: pay.pay,
          active: false,
          action: () => this.setPAYMENT(pay.value)
        }
        return obj
      })
      const SERVE = [{'serve':'No servir', 'value':1}, {'serve':'Servir similar', 'value':2}]
      const serve = SERVE.map(serve => {
        const obj = {
          text: serve.serve,
          active: false,
          action: () => this.setSERVE(serve.value)
        }
        return obj
      })
      var compra = this.MasInformation.prices.total.toFixed(2)
      var descuento = this.MasInformation.prices.discount.toFixed(2)
      var tcompra = this.MasInformation.prices.shopping_price.toFixed(2)
      var preparacion = this.MasInformation.prices.shipping
      var tpedido = this.MasInformation.prices.final.toFixed(2)
      var delivery_ranges_times = []
      
      const address = this.MasInformation.addresses .map(address => {
          const obj = {
            text: address.description,
            active: false,
            action: () => this.setADDR(address.id)
          }
          return obj
       })
      const delivery_ranges = this.MasInformation.shipping_delivery_ranges.filter((thing, index, self) =>
                  index === self.findIndex((t) => (
                  t.start_date === thing.start_date
                  )
                )
          ).map(delivery_ranges => {
              const obj = {
                text: delivery_ranges.start_date,
                active: false,
                action: () => this.setDATE(delivery_ranges.start_date)
              }
              return obj
      })

      if (this.state.start_date != null){
              delivery_ranges_times = this.MasInformation.shipping_delivery_ranges.filter(key => key.start_date == this.state.start_date).map(delivery_ranges => {
              const obj = {
              text: delivery_ranges.start_time + ' - ' + delivery_ranges.end_time,
              active: false,
              action: () => this.setDATETIME(delivery_ranges.start_time, delivery_ranges.end_time)
            }
            return obj
         })  
      }


    return (
                <div className="FinishContainerMAS">
                    <div className="logotype">
                        <div className="logo-icon">
                            <Icon className="icon-logo" type="logo_mas" width="110" height="36" fill="grey" viewport="0 0 200 200"></Icon>
                        </div>
                    </div>
                    <h2 className="h2 regular black s18">Datos del pedido</h2>
                    <div className="MasSummary">
                        <div className="left">
                          <p className="p white s16"> Compra: </p>
                        </div>
                        <div className="right"> 
                          <p className="p white s16"> {compra} </p>
                        </div>
                        <div className="clear-both"></div>
                        <div className="left">
                          <p className="p white s16">Descuento: </p>
                        </div>
                        <div className="right">
                          <p className="p white s16"> {descuento} </p>
                        </div>
                        <div className="clear-both"></div>
                        <div className="left">
                          <p className="p white s16"> Total compra: </p>
                        </div>
                        <div className="right"> 
                          <p className="p white s16"> {tcompra} </p>
                        </div>
                        <div className="clear-both"></div>
                        <div className="left">
                          <p className="p white s16"> Preparación y envío: </p>
                        </div>
                        <div className="right"> 
                          <p className="p white s16"> {preparacion} </p>
                        </div>
                        <div className="clear-both"></div>
                        <div className="left">
                          <p className="p white s16"> Total pedido: </p>
                        </div>
                        <div className="right"> 
                          <p className="p white s16"> {tpedido} </p>
                        </div>
                        <div className="clear-both"></div>
                    </div>
                    <Dropdown options={ payment } 
                      content="list"
                      type="mas"
                      buttonColor="dark"
                      placeholder="Seleccionar forma de pago *"
                      align="center"
                      expandOnHover={ false }
                      collapseOnLeave={ false }
                      setSelectedAsPlaceholder={ true }
                      transitionName="modal-anim" />

                    <h2 className="h2 regular black s16">Horario</h2>
                    <Dropdown options={ delivery_ranges } 
                      content="list"
                      type="mas"
                      buttonColor="dark"
                      placeholder= "Dia de entrega *"
                      align="center"
                      expandOnHover={ false }
                      collapseOnLeave={ false }
                      setSelectedAsPlaceholder={ true }
                      transitionName="modal-anim" />
                    <Dropdown options={  delivery_ranges_times } 
                      content="list"
                      type="mas"
                      buttonColor="dark"
                      placeholder="Hora de entrega *"
                      disabled= {this.state.disabled}
                      align="center"
                      reset= {this.state.reset}
                      expandOnHover={ false }
                      collapseOnLeave={ false }
                      setSelectedAsPlaceholder={ true }
                      transitionName="modal-anim" />
                    <Dropdown options={ serve } 
                      content="list"
                      type="mas"
                      buttonColor="dark"
                      placeholder="En caso de falta de producto *"
                      align="center"
                      expandOnHover={ false }
                      collapseOnLeave={ false }
                      setSelectedAsPlaceholder={ true }
                      transitionName="modal-anim" />
                    <Dropdown options={ address } 
                      content="list"
                      type="mas"
                      buttonColor={this.state.classAddress}
                      placeholder="Dirección de entrega *"
                      align="center"
                      expandOnHover={ false }
                      collapseOnLeave={ false }
                      setSelectedAsPlaceholder={ true }
                      transitionName="modal-anim" />
                    <form onSubmit={ this.send } id="mas"> 
                        <textarea id="comments" className="textcomments" placeholder="Observaciones sobre su pedido..." cols="30" rows="5"></textarea>
                        <button type="submit"></button>
                        <Button onClick={ this.send } placeholder="Confirmar pedido" color="blue" />
                    </form>
                </div>

    )
  }
  setPAYMENT(pay){
    this.setState({pay : pay})
  }
  setADDR(address){
    this.setState({idaddress : address, classAddress: "dark address"} )
  }
 setDATE(start_date){
    this.setState({start_date : start_date, disabled : false, reset: true, start_time: null, end_time: null})
    //UIStore.hideModal()
  }

  setSERVE(serve){
    this.setState({serve: serve})
  }

  setDATETIME(start_time, end_time){
    this.setState({start_time: start_time, reset: false, end_time: end_time})
  }

  send = (event) => {
    event.preventDefault()
    var invalid = false
    const comments = document.querySelector('form textarea#comments').value
    //console.log(comments+this.state.pay+this.state.idaddress+ this.state.start_time+ this.state.end_time+ this.state.start_date+ this.state.serve)
    this.props.onSubmit(parseInt(this.state.idaddress), this.state.start_date, this.state.start_time, this.state.end_time, parseInt(this.state.pay), parseInt(this.state.serve), comments)
  }

}


export default FinisOrderMas
