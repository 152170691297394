
import React from 'react'

const cart_mas = () => {
  return (
    <g transform="scale(7.0) translate(1, 0)">
      <path fill="#FFFFFF" d="M13.542,0.851c-7.371,0-13.346,5.975-13.346,13.346s5.975,13.346,13.346,13.346
        s13.346-5.975,13.346-13.346S20.914,0.851,13.542,0.851z M13.542,24.667c-5.773,0-10.469-4.697-10.469-10.469
        c0-1.734,0.426-3.369,1.175-4.811c0.097,0.359,0.247,0.831,0.476,1.454c0.852,2.318,3.33,6.607,3.33,6.607
        s0.559,1.305,2.078,1.305s7.566,0,7.566,0s0.984,0.031,1.652-1.305c0.746-1.492,2.025-6.247,2.025-6.247s0.46-1.359-0.859-1.419
        l-11.928-0.1l-0.14-0.02c0,0-2.096-0.113-3.226-1.809c1.915-2.505,4.931-4.125,8.321-4.125c5.773,0,10.469,4.697,10.469,10.469
        S19.315,24.667,13.542,24.667z M10.372,20.631c0,0.82-0.665,1.485-1.485,1.485s-1.485-0.665-1.485-1.485
        c0-0.82,0.665-1.485,1.485-1.485S10.372,19.811,10.372,20.631z M19.403,20.631c0,0.82-0.665,1.485-1.485,1.485
        s-1.485-0.665-1.485-1.485c0-0.82,0.665-1.485,1.485-1.485S19.403,19.811,19.403,20.631z"/>
    </g>
  )
}

export default cart_mas
