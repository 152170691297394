import React from 'react'

const welfare_12 = () => {
  return (
    <g>
    <path d="M143.129,57.714c0,0-1.862,18.687-19.854,25.941c-17.993,7.253-32.726-7.018-32.726-7.018l83.047-44.322
        c-18.276-19.862-44.479-32.316-73.597-32.316C44.769-0.001,0,44.77,0,100c0,55.232,44.769,100.001,100.001,100.001
        C155.229,200.001,200,155.232,200,100c0-23.443-8.087-44.981-21.595-62.03C167.306,41.01,155.055,45.239,143.129,57.714z
         M94.389,98.192c0,2.378-1.927,4.305-4.305,4.305c-2.377,0-4.304-1.927-4.304-4.305c0-2.378,1.927-4.305,4.304-4.305
        C92.462,93.887,94.389,95.814,94.389,98.192z M85.779,62.599c2.377,0,4.304,1.927,4.304,4.305s-1.927,4.305-4.304,4.305
        c-2.378,0-4.306-1.927-4.306-4.305S83.402,62.599,85.779,62.599z M58.347,79.775h5.943v-5.943c0-2.144,1.736-3.88,3.879-3.88
        c2.141,0,3.88,1.737,3.88,3.88v5.943h5.943c2.143,0,3.879,1.738,3.879,3.88c0,2.144-1.737,3.879-3.879,3.879h-5.943v5.944
        c0,2.142-1.738,3.88-3.88,3.88c-2.144,0-3.879-1.738-3.879-3.88v-5.944h-5.943c-2.142,0-3.88-1.735-3.88-3.879
        C54.467,81.514,56.205,79.775,58.347,79.775z M70.844,135.456h-5.942v5.944c0,2.14-1.738,3.879-3.88,3.879
        c-2.143,0-3.879-1.738-3.879-3.879v-5.944h-5.943c-2.143,0-3.879-1.735-3.879-3.879c0-2.142,1.737-3.88,3.879-3.88h5.943v-5.944
        c0-2.142,1.736-3.879,3.879-3.879c2.142,0,3.88,1.737,3.88,3.879v5.944h5.942c2.144,0,3.88,1.738,3.88,3.88
        C74.724,133.721,72.987,135.456,70.844,135.456z M74.725,113.438c-2.378,0-4.306-1.929-4.306-4.305c0-2.38,1.928-4.305,4.306-4.305
        c2.377,0,4.304,1.926,4.304,4.305C79.029,111.509,77.102,113.438,74.725,113.438z M83.334,146.275c-2.376,0-4.305-1.929-4.305-4.307
        c0-2.376,1.929-4.305,4.305-4.305c2.378,0,4.306,1.929,4.306,4.305C87.64,144.346,85.712,146.275,83.334,146.275z M84.151,121.226
        c0-2.38,1.927-4.307,4.305-4.307c2.377,0,4.304,1.927,4.304,4.307c0,2.376-1.927,4.305-4.304,4.305
        C86.078,125.531,84.151,123.602,84.151,121.226z M94.854,158.565c-2.377,0-4.304-1.927-4.304-4.305c0-2.378,1.927-4.303,4.304-4.303
        c2.378,0,4.305,1.925,4.305,4.303C99.159,156.638,97.232,158.565,94.854,158.565z"/>
    </g>
  )
}

export default welfare_12