import React, { Component } from "react";
import { translate } from "react-i18next";
import config from "../../../config";
import { AppStore, AuthStore, OrderStore, TrackingApartments, UIStore } from "../../../stores";
import { capitalize } from "../../../utils";
import { OrderButtonApartments } from "../components";
import { LinkButton } from "../../";
import IframeModal from "../../Dialogs/Modal/modals/IframeModal";

const statusCart = {
  1: "Confirmado",
  2: "Confirmado",
  3: "En camino",
  5: "Cancelado",
  4: "Entregado",
};
@translate(["common"], { wait: false })
class FinishedCarts extends Component {
  render() {
    return (
      <div className="user-zone-view finished-carts">
        <header>
          <h2 className="h2 s30 regular blue">Carritos tramitados</h2>
        </header>
        { this.renderLastOrderIframe() }
        <article>
          <div className="order-header">
            <div className="headers header-order">
              <p className="p bold grey s19"># Pedido</p>
            </div>
            <div className="headers header-status">
              <p className="p bold grey s19">Estado</p>
            </div>
            <div className="headers header-details">
              <p className="p bold grey s19">Ver detalles</p>
            </div>
          </div>
          { this.renderOrders() }
        </article>
      </div>
    );
  }

  /**
   * Renderiza el listado de pedidos.
   */
  renderOrders() {

    if (Array.isArray(AppStore.orders) && AppStore.orders.length > 0){
      TrackingApartments.user_zone_order_history(AuthStore.apartmentId);
      return AppStore.orders.filter(order => order.job && order.token).sort((a, b) => b.id_order - a.id_order).map((order) => {
          return (
          <OrderButtonApartments
            idOrder={order.id_order}
            id={order.id_order}
            key={order.id_order}
            status={statusCart[order.order_status]}
            detailCart={`${config.LWS_URL_V2}&job=${order.job}&token=${order.token}&primaryColor=%2300BFDF`}
          />
        );
      });
    }
    
    return (
      this.renderOrdersEmpty()
    )

    
  }

  openIframe(iframeUrl) {
    UIStore.toggleModal({
      content: () => (
        <IframeModal
          url={iframeUrl}
          classIframe="iframe-modal-details-cart"
        />
      ),
    });
  }

  renderLastOrderIframe() {
    // Verifica si AppStore.orders es un arreglo y tiene al menos un elemento
    if (Array.isArray(AppStore.orders) && AppStore.orders.length > 0) {
      // Filtra y ordena los pedidos
      const filteredAndSortedOrders = AppStore.orders
        .filter(order => order.job && order.token)
        .sort((a, b) => b.id_order - a.id_order);
  
      // Verifica si hay al menos un pedido después de filtrar
      if (filteredAndSortedOrders.length > 0) {
        const order = filteredAndSortedOrders[0];
        return (
          <article>
            <div className="order-status-widget">
              <h2 className="h2 s20 regular blue">Estado del Pedido Más Reciente</h2>
              <div className="last-order-widget-iframe">
                <iframe src={`${config.LWS_URL_V3}?job=${order.job}&token=${order.token}&showOnly=steps&homeBarFontSizeTitle=10px&homeBarFontSizeSubtitle=10px&stepViewTitle=Pedido`}></iframe>
                <div className="view-order-details-container">
                  <button className="view-order-details-btn" onClick={() => this.openIframe(`${config.LWS_URL_V2}&job=${order.job}&token=${order.token}&primaryColor=%2300BFDF`)}>Ver Detalles</button>
                </div>
              </div>
            </div>
          </article>
        );
      }
    }
    
    return null;
  }
  

  /**
   * Renderiza el contenido de la vista cuando no existen
   * pedidos en el historial
   */
  renderOrdersEmpty() {
    const URL = "/";
    return (
      <div className="orders-empty">
        <h2 className="h2 regular black s22">
          ​Tu historial de pedidos está vacío.
        </h2>
        <p className="p regular grey s16">
          ¿A qué​ ​esperas para hacer tu primera compra?
        </p>
        <LinkButton placeholder="Ir a la tienda" color="blue" onClick={URL} />
      </div>
    );
  }
}

export default FinishedCarts;
