import React, { Component } from "react";
import { observer } from "mobx-react";
import config from "../../../config";
import { AppStore, ShopStore } from "../../../stores";
import { urliFy } from "../../../utils";


@observer
class CardProduct extends Component {
  state = {
    optionVisible: false
  }

  render(){
    const { description, image, price, type_price, brand } = this.props.product.product
    const { units } = this.props.product;
    const currency = (type_price === 1) ? ' €' : ' $'
    const productStatus = this.props.product.status || undefined
    return(
      <div className=" cart-product card-product">
        <div className="content">
          <div className="product-image">
            <img 
              src={ `https://${ config.S3_IMAGES }/images/${ AppStore.reviewOrderProductImageSize }/${ image }` }
              className="prod-img"
              alt={ description || 'No description' }/>
          </div>
          <div className={`quantity-badge ${(units > 0) ? 'visible' : '' }`}>
            <p className="p regular black s12">{units}</p>
          </div>
        </div>
      </div>
    )
  }


}

export default CardProduct