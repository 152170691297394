import React from 'react'

const allergen_3 = () => {
  return (
        <g>
            <circle cx="100.002" cy="110.431" r="20.343"/>
            <path d="M100.002,0C44.772,0,0,44.772,0,100c0,55.229,44.772,100,100.002,100C155.23,200,200,155.229,200,100
                C200,44.772,155.23,0,100.002,0z M99.987,142.515c-18.395,0-33.363-14.769-33.363-32.925c0-19.599,16.161-52.106,33.363-52.106
                c17.22,0,33.39,32.507,33.39,52.106C133.377,127.746,118.401,142.515,99.987,142.515z"/>
        </g>
  )
}

export default allergen_3