import React from 'react'

const allergen_6 = () => {
  return (
        <g>
                <path d="M110.385,65.104c-9.753-4.5-21.168,2.025-21.851,12.605c-0.518,7.953-3.001,14.856-7.922,20.511
                c-0.452,0.714-2.497,3.742-10.984,7.856c-1.726,0.837-3.742,2.087-5.479,3.671c-2.358,2.195-3.945,4.66-4.692,7.377
                c0,0.016-0.005,0.033-0.005,0.049c-0.011,0.347-0.102,0.67-0.249,0.961c-0.407,2.107-0.339,4.355,0.279,6.75
                c1.517,5.9,5.499,9.603,11.446,11.078c3.433,0.847,6.847,0.512,10.101-0.982c22.794-10.471,35.741-27.922,38.078-52.758
                C119.912,73.673,116.892,68.106,110.385,65.104z M112.72,81.083c-1.146,11.193-4.068,22.351-12.962,31.127
                c-0.709,0.697-1.498,1.337-2.326,1.881c-1.072,0.694-2.106,0.444-2.905-0.514c-0.751-0.898-0.618-1.844,0.039-2.738
                c0.205-0.265,0.442-0.5,0.705-0.72c8.689-7.62,11.43-17.811,12.678-28.645c0.105-0.906,0.034-1.822,0.137-2.73
                c0.136-1.358,0.767-2.509,2.279-2.509c1.646,0,2.258,1.206,2.352,2.687C112.749,79.42,112.72,79.918,112.72,81.083z"/>
            <path d="M140.589,121.072c-2.373-10.161-8.733-16.752-18.64-20.144c-1.362-0.466-2.082-0.314-2.66,1.164
                c-1.887,4.812-4.261,9.402-7.36,13.569c-1.091,1.478-0.707,2.266,0.67,3.184c4.738,3.144,9.787,5.475,15.549,6.026
                c1.625,0.158,3.232,0.609,3.098,2.517c-0.139,2.095-1.905,2.326-3.598,2.1c-6.547-0.862-12.581-3.163-17.765-7.156
                c-2.636-2.024-3.295,0.287-4.55,1.384c-1.659,1.449,0.05,2.205,0.855,2.905c8.518,7.392,18.146,9.467,29.056,6.063
                c2.479-0.773,3.977-2.225,4.625-3.487c1.987-3.882,0.926-6.7,0.914-6.733C140.74,122.234,140.643,121.299,140.589,121.072z"/>
            <path d="M100,0C44.772,0,0,44.772,0,100c0,55.229,44.772,100,100,100c55.23,0,100-44.771,100-100
                C200,44.772,155.23,0,100,0z M143.968,131.297c-2.602,4.797-9.209,6.451-9.478,6.491c-11.812,3.202-22.358,0.206-31.597-8.112
                c-1.501-1.36-2.392-1.445-3.929-0.163c-4.859,4.031-10.268,7.233-16.112,9.656c-12.219,5.072-25.32-1.371-28.004-13.979
                c-0.702-3.283-0.654-6.37,0.108-9.234c0.943-5.844,7.897-12.046,12.9-14.133c2.129-0.888,3.785-1.805,5.062-2.65
                c1.483-1.137,2.812-2.36,3.984-3.667c0.079-0.101,0.161-0.194,0.253-0.279c4.14-4.744,6.31-10.577,6.654-17.481
                c0.536-10.715,10.127-19.099,21.011-18.636c11.075,0.469,19.194,9.411,19.091,21.006c-0.055,4.502-0.702,8.936-1.858,13.275
                c-0.494,1.85-0.289,2.755,1.771,3.462c11.654,4.02,18.642,12.061,21.24,23.729C145.469,121.936,146.588,126.462,143.968,131.297z"
                />
        </g>
  )
}

export default allergen_6