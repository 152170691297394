
import React from 'react'

const logo_mas = () => {
  return (
  	<g transform="scale(6.5) translate(-28, 0)">
      <path fill="#FFFFFF" d="M13.542,0.851c-7.371,0-13.346,5.975-13.346,13.346s5.975,13.346,13.346,13.346
        s13.346-5.975,13.346-13.346S20.914,0.851,13.542,0.851z M13.542,24.667c-5.773,0-10.469-4.697-10.469-10.469
        c0-1.734,0.426-3.369,1.175-4.811c0.097,0.359,0.247,0.831,0.476,1.454c0.852,2.318,3.33,6.607,3.33,6.607
        s0.559,1.305,2.078,1.305s7.566,0,7.566,0s0.984,0.031,1.652-1.305c0.746-1.492,2.025-6.247,2.025-6.247s0.46-1.359-0.859-1.419
        l-11.928-0.1l-0.14-0.02c0,0-2.096-0.113-3.226-1.809c1.915-2.505,4.931-4.125,8.321-4.125c5.773,0,10.469,4.697,10.469,10.469
        S19.315,24.667,13.542,24.667z M10.372,20.631c0,0.82-0.665,1.485-1.485,1.485s-1.485-0.665-1.485-1.485
        c0-0.82,0.665-1.485,1.485-1.485S10.372,19.811,10.372,20.631z M19.403,20.631c0,0.82-0.665,1.485-1.485,1.485
        s-1.485-0.665-1.485-1.485c0-0.82,0.665-1.485,1.485-1.485S19.403,19.811,19.403,20.631z"/>

      <path fill="#FFFFFF" d="M83.541,16.055c-1.234-1.139-5.583-3.874-5.583-3.874s-1.481-0.741-1.481-1.538
        c0-0.798,0.475-1.5,1.804-1.5c1.329,0,1.69,1.785,1.69,1.785s0.095,1.358,1.51,1.358h2.01c0,0,1.176,0.076,1.176-1.176
        c0-1.252-0.062-6.037-6.177-6.037c0,0-5.343-0.443-6.811,4.279c0,0-0.532,1.633,0.076,3.203c0.608,1.57,3.216,3.165,3.216,3.165
        l4.887,3.241c0,0,0.937,0.684,0.456,1.798c0,0-0.557,1.114-1.848,1.114s-1.975-1.063-2.102-1.62s-0.152-1.545-0.152-1.545
        s-0.025-1.494-1.975-1.494c-1.95,0-2.861,0.101-3.216,1.367l-0.051,0.228L66.995,7.238c0,0-0.43-1.975-2.811-1.975
        c-2.38,0-3.342,1.747-3.342,1.747l-6.887,12.306L51.777,7.39c0,0-0.481-2.152-2.735-2.152h-1.469c0,0-1.848,0.051-2.633,1.924
        c-0.785,1.874-3.038,7.444-3.038,7.444l-0.734-7.444c0,0-0.279-1.95-2.178-1.95h-2.886c0,0-1.633,0-2.659,2.583
        S27.521,23.86,27.521,23.86s-0.57,1.329,0.532,1.329s2.924,0,2.924,0s1.367-0.114,1.861-1.215
        c0.494-1.101,4.368-12.002,4.368-12.002l1.671,11.47c0,0,0.076,1.063,1.557,1.063s2.013-0.342,2.469-1.139
        c0.456-0.798,4.558-11.28,4.558-11.28l1.557,11.812c0,0-0.038,1.291,1.215,1.291c1.253,0,4.899,0,4.899,0
        s1.367,0.076,1.937-0.987c0.157-0.292,0.702-1.404,1.41-2.861c0.574-0.798,1.572-0.709,1.572-0.709s3.912,0,4.975,0
        c1.063,0,1.006,0.285,1.006,0.285l0.012-0.003l0.9,3.231c0,0,0.304,1.006,1.633,1.006c1.329,0,2.678,0,2.678,0
        s1.101,0.038,1.101-1.31l-0.095-0.627c0,0,1.709,2.279,5.431,2.279c0.863,0,1.418,0,1.766,0c0,0,2.307,0.18,4.823-2.336
        c0,0,1.063-1.139,1.063-2.943C85.345,18.41,84.776,17.195,83.541,16.055z M60.859,16.416c1.328-2.756,2.49-5.178,2.49-5.178
        l1.442,5.178H60.859z"/>
    </g>
  )
}

export default logo_mas
