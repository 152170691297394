export function ValidateForm(fields, exceptions=[]){
    if (filter(fields, exceptions).length !== 0){
        return false
    }else{
        return true
    }
}

function filter(fields, exceptions=[]){
    let vec =[]
    for(let i in fields){
        if(fields[i] === '' && !exceptions.includes(i)){
            //vec.push({[i]:fields[i]})
            vec.push(i)
        }
    }
    return vec
}