import React from 'react'

const welfare_5 = () => {
  return (
<g>
<path d="M100,0.002c-55.231,0-100,44.771-100,100c0,55.226,44.769,99.997,100,99.997c55.23,0,100-44.771,100-99.997
    C200,44.772,155.23,0.002,100,0.002z M137.952,144.577c-1.083,1.18-2.611,1.851-4.211,1.851H75.825
    c-1.602,0-3.128-0.671-4.209-1.851c-1.084-1.18-1.621-2.759-1.484-4.356l1.193-13.821c-15.446-0.303-27.923-12.939-27.923-28.455
    c0-15.703,12.775-28.481,28.481-28.481c2.293,0,4.512,0.301,6.65,0.817c5.083-9.946,14.938-16.711,26.248-16.711
    c16.393,0,29.741,14.194,29.914,31.707l4.738,54.945C139.572,141.818,139.035,143.397,137.952,144.577z M94.652,97.944
    c0-12.552-10.215-22.767-22.769-22.767c-12.555,0-22.767,10.215-22.767,22.767c0,12.557,10.212,22.769,22.767,22.769
    C84.437,120.713,94.652,110.501,94.652,97.944z M82.508,102.319h-21.25c-2.318,0-4.196-1.877-4.196-4.196
    c0-2.316,1.878-4.196,4.196-4.196h21.25c2.316,0,4.196,1.88,4.196,4.196C86.704,100.443,84.824,102.319,82.508,102.319z
     M128.989,85.62l4.753,55.093H75.825l1.276-14.785c13.216-2.461,23.265-14.062,23.265-27.983c0-4.419-1.042-8.587-2.847-12.324
    H128.989z M93.903,79.906h34.492c-2.402-11.794-12.051-20.622-23.613-20.622c-8.859,0-16.564,5.199-20.775,12.926
    C87.848,74.027,91.235,76.655,93.903,79.906z M113.89,69.642c1.727,0,3.125,1.4,3.125,3.125c0,1.727-1.398,3.125-3.125,3.125
    c-1.725,0-3.125-1.398-3.125-3.125C110.765,71.042,112.165,69.642,113.89,69.642z M104.782,63.392c1.727,0,3.125,1.4,3.125,3.125
    c0,1.727-1.398,3.125-3.125,3.125c-1.724,0-3.125-1.398-3.125-3.125C101.657,64.792,103.058,63.392,104.782,63.392z M95.675,69.642
    c1.727,0,3.125,1.4,3.125,3.125c0,1.727-1.398,3.125-3.125,3.125c-1.725,0-3.125-1.398-3.125-3.125
    C92.55,71.042,93.95,69.642,95.675,69.642z"/>
</g>
  )
}

export default welfare_5