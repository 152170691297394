
import React from 'react'

const add = () => {
  return (
    <g>
     <path d="M163.596,91.715h-54.312V37.404c0-4.574-3.705-8.283-8.284-8.283c-4.573,0-8.284,3.709-8.284,8.283v54.311
       H38.405c-4.574,0-8.285,3.711-8.285,8.285c0,4.579,3.711,8.286,8.285,8.286h54.311v54.314c0,4.574,3.711,8.283,8.284,8.283
       c4.579,0,8.284-3.709,8.284-8.283v-54.314h54.312c4.579,0,8.284-3.707,8.284-8.286C171.88,95.426,168.175,91.715,163.596,91.715z"/>
    </g>
  )
}

export default add
