
import config from '../../config'

/**
 * @func: getProductsCategory
 * @return {[Object]} data
 *
 * @description:
 */

const getProductsCategory = (idprovider, idzone, idcategory) => {
  const params = { id_provider: idprovider, id_zone: idzone, id_category: idcategory }
  return config.API.productsCategoryGet(params, {}, {})
  .then(result => result.data.products)
  .catch(error => error)
}


export default getProductsCategory
