
import config from '../../config'

/**
 * @func: getDeliveyTime
 * @return {[Object]} data
 *
 * @description:
 */

const getDeliveryTime = (id_address, idprovider, booking_id) => {
  const params = {id_address: id_address, id_provider: idprovider, booking_id: booking_id}
  console.log("params", params);
  return config.API.deliveryTimeInstGet(params, {}, {})
  .then(result => result.data)
  .catch(error => error)
}


export default getDeliveryTime
