
import React, { Component } from 'react'
import { translate } from 'react-i18next'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { LinkIconButton, Icon } from '../'
import { AppStore } from '../../stores'
import config from '../../config'
import { capitalize } from '../../utils'
import { Link } from 'react-router'

@translate(['common'], { wait: false })
class FiltersTip extends Component {

      state = {
            isOver: false,
            welfaresEnable: AppStore.welfaresEnable,
            allergensEnable: AppStore.allergensEnable
      }

      render () {
        var tip = null
        var text = null
        var text2 = nill
        if (this.state.allergensEnable.length > 0 || this.state.welfaresEnable.length > 0){ 
            var countFilters = this.state.welfaresEnable.length + this.state.allergensEnable.length
            if (countFilters === 1) text2 = " filtro activado"
            else text2 = " filtros activados"
            text = "Tiene " + countFilters + text2
        }

          return (
              <div className="filterstip">
                 <div className="FiltersTiptrans" onClick={ () => this.DisableFilters()} > <p className="p light white s12 "> { text } </p></div>
              </div>
          )
      }

      DisableFilters(){

          this.setState({ 
              welfaresEnable : [],
              allergensEnable : []
            } )
          AppStore.welfaresEnable = []
          AppStore.allergensEnable = []

      }

}

export default FiltersTip
