
import React, { Component } from 'react'
import { translate } from 'react-i18next'
import config from '../../../../config'
import { AccordionItem } from './'

@translate(['common'], { wait: false })
class LinkedAccountsAccordion extends Component {

  state = {
    current: undefined,
    email: '',
    password: ''
  }


  render () {
    const items = config.AVAILABLE_PROVIDERS.map((provider, index) => {
      const i = index + 1
      return <AccordionItem onClick={ this.onAccordionItemClick }
                            provider={ provider }
                            id={ provider.id }
                            current={ this.state.current }
                            key={ i } />
    })

    return (
      <div className="linked-accounts-accordion">
        { items }
      </div>
    )
  }


  /**
   * Maneja el click de los elementos del acordeón.
   */
  onAccordionItemClick = (i, event) => {
    if (i === this.state.current) i = undefined
    this.setState({ current: i })
  }
}

export default LinkedAccountsAccordion
