
import React from 'react'
import { IconButton, LinkIconButton } from '../../'

const CartProductOptions = (props) => <div className={ `cart-product-options ${ (props.state) ? 'visible' : '' }` }>
  <div className="options-wrapper">
    <div className="units">
      <p className="p bold black s28">{ props.units }</p>
    </div>
    <LinkIconButton type="fab"
                    className="detail"
                    color="grey"
                    iconFill="white"
                    iconType="see"
                    onClick={ props.link } />
    <IconButton id={ props.product }
                onClick={ props.onSubtract }
                className="subtract" type="fab" color="blue" iconType="subtract" iconTitle="ALT_PARA_ICONO_AQUI" />
    <IconButton id={ props.product }
                onClick={ props.onAdd }
                className="add" type="fab" color="blue" iconType="add" iconTitle="ALT_PARA_ICONO_AQUI" />
    <IconButton id={ props.product }
                onClick={ props.onDelete }
                className="close" type="fab" color="red" iconType="close" iconTitle="ALT_PARA_ICONO_AQUI" />
  </div>
</div>

export default CartProductOptions
