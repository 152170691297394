
import React, { Component } from 'react'
import { UIStore, AuthStore } from '../../../../stores'
import LoginModal from './LoginModal'
import { Icon, Button, IconButton, SwitchButton, Dropdown } from '../../../'
import config from '../../../../config'

/**
 *
 */

class SignupMas extends Component {

  componentDidMount () {
    window.setTimeout(() => {
      this.refs.email.focus()
      window.clearTimeout(this)
    })
  }

   state = {
    privacity: false,
    cp: '',
    sexo: '',
    type: ''
  }



  render () {
      const MASSEX = [{'sexo':'Hombre'}, {'sexo':'Mujer'}]
      const sexo = MASSEX.map(sexo => {
        const obj = {
          text: sexo.sexo,
          active: false,
          action: () => this.setSEXO(sexo.sexo)
        }
        return obj
     })
       const type = config.MASTYPE.map(type => {
        const obj = {
          text: type.tipo,
          active: false,
          action: () => this.setTYPE(type.tipo)
        }
        return obj
     })
      const providers = config.MASCPLIST.map(provider => {
        const obj = {
          text: provider.tittle,
          active: false,
          action: () => this.setCP(provider.cp)
        }
        return obj
     })
    var className = "input dark"
    return (
      <div className="signup-modal-mas">
        <div className="logotype">
          <div className="logo-icon">
            <Icon className="icon-logo" type="logo_mas" width="110" height="36" fill="grey" viewport="0 0 200 200"></Icon>
          </div>
        </div>
        <h2 className="h2 light white s15">Quiero registrarme en supermercadosmas.com</h2>
        <form onSubmit={ this.send } id="mas"> 
          <input ref="email" className="input dark" id="email" type="email" placeholder="Correo electrónico *" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"/>
          <input className="input dark" id="password" type="password" maxLength="10" placeholder="Contraseña * " readOnly={ false }/>
          <input className="input dark" id="confpassword" type="password" maxLength="10" placeholder="Confirmar contraseña *" readOnly={ false } />
          <input className="input dark" id="name" type="text" maxLength="30" placeholder="Nombre *"/>
          <input className="input dark" id="surname" type="text" maxLength="30" placeholder="Apellidos *" />
          <input className="input dark" id="birthday" type="text" placeholder="Fecha de nacimiento DD/MM/YYYY*" pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])/(0[1-9]|1[012])/[0-9]{4}"/>
          <Dropdown options={ sexo } 
                      content="list"
                      type="mas"
                      buttonColor="dark"
                      placeholder="Sexo *"
                      align="center"
                      expandOnHover={ false }
                      collapseOnLeave={ false }
                      setSelectedAsPlaceholder={ true }
                      transitionName="modal-anim" 
                      form="fakeForm"/>
          <input className="input dark" id="NIF" type="text" placeholder="NIF *" maxLength="9" pattern="(([X-Z]{1})([-]?)(\d{7})([-]?)([A-Z]{1}))|((\d{8})([-]?)([A-Z]{1}))"/>
          <input className="input dark" id="phone" type="text" maxLength="11" placeholder="Teléfono *" pattern="[0-9]+"/>
          <Dropdown options={ type } 
                      content="list"
                      type="mas"
                      buttonColor="dark"
                      placeholder="Tipo de via *"
                      align="center"
                      expandOnHover={ false }
                      collapseOnLeave={ false }
                      setSelectedAsPlaceholder={ true }
                      transitionName="modal-anim" 
                      form="fakeForm"/>
          <input className="input dark" id="nameaddress" maxLength="40" type="text" placeholder="Nombre de la vía *" />
          <input className="input dark" id="numbddress" maxLength="5" type="text" placeholder="Número *"/>
          <Dropdown options={ providers } 
                      content="list"
                      type="mas"
                      buttonColor="dark"
                      placeholder="Código postal *"
                      align="center"
                      expandOnHover={ false }
                      collapseOnLeave={ false }
                      setSelectedAsPlaceholder={ true }
                      transitionName="modal-anim"
                      form="fakeForm" />

          <button type="submit"></button>
          <Button onClick={ this.send } type="outline white" placeholder="Registrarme" color="blue" />

          <div className="privacity">
          
           <SwitchButton name="privacity"
                            id="push"
                            onSwitchChange={ this.SwichPrivacity } />
              <p className="p white s13">
              <span> Acepto la </span><Button onClick={ this.openPrivacity } type="underline" placeholder="Política de Privacidad" color="white" />
             <span>{' '} y Seguridad</span>
          </p>
          </div>
        </form>
      </div>
    )
  }


SwichPrivacity = (event) => this.setState({ privacity: !this.state.privacity })

setTYPE(type){
  this.setState({type : type})
}

setCP(cp){
  this.setState({cp : cp})
}

setSEXO(sexo){
  this.setState({sexo : sexo})
}


  openPrivacity = (event) =>{
    window.open('https://tienda.supermercadosmas.com/tiendavirtual/InfoProteccionDatos.do', '_blank');
  }


  /**
   * Envia los datos del formulario.
   *
   * TODO: cambiar las referecias DOM a React.refs
   */
  send = (event) => {
    event.preventDefault()
    var invalid = false
    if (!document.querySelector('form input#email').checkValidity() || 
        !document.querySelector('form input#birthday').checkValidity() ||
        !document.querySelector('form input#NIF').checkValidity()){
        invalid = true
    }
    const email = document.querySelector('form input#email').value
    const password = document.querySelector('form input#password').value
    const confpassword = document.querySelector('form input#confpassword').value
    const name = document.querySelector('form input#name').value
    const surname = document.querySelector('form input#surname').value
    const birthday = document.querySelector('form input#birthday').value
    const NIF = document.querySelector('form input#NIF').value
    const phone = document.querySelector('form input#phone').value
    const nameaddress = document.querySelector('form input#nameaddress').value
    const numbddress = document.querySelector('form input#numbddress').value
    const typeaddress = this.state.type
    const cp = this.state.cp
    const sex = this.state.sexo
    const privacity = this.state.privacity
    this.props.onSubmit(email, password, confpassword, name, surname, birthday, sex, NIF, phone, typeaddress, nameaddress, numbddress, cp, privacity, invalid)
  }
}


export default SignupMas
