
import React from 'react'
import { translate } from 'react-i18next'

/**
 * HoC para notificaciones de error de autenticación
 * @param {[Object]} props
 * @param {[Object]} t
 */
const Notification = ({ t, ...props }) => <div className="subcontent-wrapper">
  <h1 className="h1 regular white s16">{ props.message }</h1>
</div>

export default translate(['common'], { wait: false })(Notification)
