
import React from 'react'

const compass = () => {
  return (
    <g>
      <path d="M100.001,84.65c-8.476,0-15.353,6.871-15.353,15.347c0,8.479,6.877,15.354,15.353,15.354
		    c8.478,0,15.35-6.874,15.35-15.354C115.352,91.521,108.479,84.65,100.001,84.65z"/>
    	<path d="M100,0C44.772,0,0,44.772,0,100s44.772,100,100,100s100-44.772,100-100S155.228,0,100,0z M137.09,100.39
    		c0,0.091-0.012,0.178-0.013,0.269c-0.027,3.845-0.226,7.922-1.37,11.327c-3.629,17.841-33.93,66.098-35.707,66.098
    		c-1.502,0-32.012-48.212-35.694-66.07c-1.152-3.415-1.356-7.497-1.383-11.35c-0.001-0.092-0.013-0.18-0.013-0.272
    		c0-0.128,0.005-0.264,0.005-0.393c-0.002-0.128-0.005-0.26-0.005-0.387c0-0.091,0.013-0.179,0.013-0.269
    		c0.027-3.845,0.227-7.922,1.37-11.327C67.922,70.174,98.224,21.917,100,21.917c1.502,0,32.012,48.212,35.695,66.069
    		c1.152,3.415,1.356,7.498,1.383,11.35c0.001,0.093,0.013,0.182,0.013,0.274c0,0.128-0.005,0.262-0.006,0.392
    		C137.086,100.13,137.09,100.263,137.09,100.39z"/>
  	</g>
  )
}

export default compass
