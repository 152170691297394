import React from "react";

const repeatCartOrder = () => {
  return (
    <g fill="none">
      <path
        d="M12.4522 23.9953C12.8287 23.9953 13.1339 23.6901 13.1339 23.3136C13.1339 22.9371 12.8287 22.6319 12.4522 22.6319C12.0757 22.6319 11.7705 22.9371 11.7705 23.3136C11.7705 23.6901 12.0757 23.9953 12.4522 23.9953Z"
        fill="#565656"
        stroke="#565656"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9961 23.9953C22.3726 23.9953 22.6778 23.6901 22.6778 23.3136C22.6778 22.9371 22.3726 22.6319 21.9961 22.6319C21.6197 22.6319 21.3145 22.9371 21.3145 23.3136C21.3145 23.6901 21.6197 23.9953 21.9961 23.9953Z"
        fill="#565656"
        stroke="#565656"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.99902 8.99844H9.72574L11.7708 20.587H22.6776"
        stroke="#565656"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7706 17.8603H22.398C22.4768 17.8603 22.5532 17.8331 22.6142 17.7831C22.6752 17.7332 22.7169 17.6636 22.7324 17.5863L23.9594 11.4512C23.9693 11.4017 23.9681 11.3507 23.9559 11.3017C23.9437 11.2528 23.9207 11.2072 23.8887 11.1682C23.8567 11.1292 23.8165 11.0977 23.7709 11.0762C23.7253 11.0546 23.6754 11.0435 23.625 11.0435H10.4072"
        fill="#565656"
      />
      <path
        d="M11.7706 17.8603H22.398C22.4768 17.8603 22.5532 17.8331 22.6142 17.7831C22.6752 17.7332 22.7169 17.6636 22.7324 17.5863L23.9594 11.4512C23.9693 11.4017 23.9681 11.3507 23.9559 11.3017C23.9437 11.2528 23.9207 11.2072 23.8887 11.1682C23.8567 11.1292 23.8165 11.0977 23.7709 11.0762C23.7253 11.0546 23.6754 11.0435 23.625 11.0435H10.4072"
        stroke="#565656"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.2239 10.4918C33.0833 17.7203 29.934 26.0386 22.8254 29.4879C16.3267 32.6472 8.65831 30.6876 4.40918 25.1788"
        stroke="#565656"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M29.4251 13.0013L30.3249 10.7717L32.5545 11.6715L31.9846 10.3418L29.7651 9.452L28.8652 11.6715L29.4251 13.0013Z"
        fill="#565656"
      />
      <path
        d="M2.32928 21.5095C-0.530139 14.281 2.61922 5.96268 9.72777 2.51339C16.1665 -0.615975 23.7349 1.27364 28.014 6.65254"
        stroke="#565656"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M3.11936 19.0099L2.21955 21.2395L0 20.3396L0.559886 21.6594L2.78943 22.5592L3.67925 20.3396L3.11936 19.0099Z"
        fill="#565656"
      />
    </g>
  );
};

export default repeatCartOrder;
