
import React from 'react'

/**
 * @func: Gradient
 * @param: {[Object]} props
 *
 * @description:
 */

const Gradient = (props) => <div className="bg"
                                 style={{ background: props.source.firstcolor,
                                          background: `linear-gradient(${ props.source.angle }deg, ${ props.source.lastcolor } 1%, ${ props.source.firstcolor } 95%)` }}>
</div>

export default Gradient
