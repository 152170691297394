
import React, { Component } from 'react'
import { translate } from 'react-i18next'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { LinkIconButton } from '../'
import { AppStore, AuthStore, TrackingApartments } from '../../stores'
import config from '../../config'

@translate(['common'], { wait: false })
class CustomerSupportTooltip extends Component {

  state = {
    isOver: false
  }

  render () {
    return (
      <div className="tooltip customer-support" onClick={ TrackingApartments.user_zone_chat(AuthStore.apartmentId) }>
        <LinkIconButton type="fab" color="grey" iconType="support" iconTitle="ALT_ICONO_AQUI"
                        onMouseEnter={ this.onMouseEnterHandler }
                        onMouseLeave={ this.onMouseLeaveHandler }
                        onClick={ config.CUSTOMER_SUPPORT }
                        target="_blank" />
        <ReactCSSTransitionGroup transitionName="modal-anim"
                                 transitionEnterTimeout={ 300 }
                                 transitionLeaveTimeout={ 300 }>
          { (this.state.isOver) ? this.renderContent() : null }
        </ReactCSSTransitionGroup>
      </div>
    )
  }


  renderContent () {
    const { t } = this.props

    return (
      <div className="tooltip-content">
        <p className="p regular black s18">¿Necesitas ayuda? Habla con</p>
        <p className="p regular blue s18">Atención al cliente</p>
      </div>
    )
  }


  onMouseEnterHandler = (event) => this.setState({ isOver: true })

  onMouseLeaveHandler = (event) => this.setState({ isOver: false })
}

export default CustomerSupportTooltip
