import React from 'react'

const welfare_8 = () => {
  return (
        <g>
        <path d="M76.071,86.184l-15.358,8.867V77.317l15.358-8.863V86.184z M97.142,104.95l-15.356,8.867v17.731
            l15.356-8.865V104.95z M60.713,122.683l15.358,8.865v-17.731l-15.358-8.867V122.683z M78.927,91.134l-15.358,8.867l15.358,8.867
            l15.358-8.867L78.927,91.134z M115.356,63.504L100,54.638l-15.358,8.866L100,72.367L115.356,63.504z M97.142,77.317l-15.356-8.863
            v17.73l15.356,8.867V77.317z M102.856,95.051l15.356-8.867v-17.73l-15.356,8.863V95.051z M121.071,91.134l-15.356,8.867
            l15.356,8.867l15.358-8.867L121.071,91.134z M84.642,136.498L100,145.364l15.356-8.866L100,127.633L84.642,136.498z M123.927,86.184
            l15.358,8.867V77.317l-15.358-8.863V86.184z M123.927,131.549l15.358-8.865V104.95l-15.358,8.867V131.549z M200,99.999
            c0,55.232-44.771,100.001-100,100.001c-55.231,0-100-44.769-100-100.001C0,44.769,44.769,0.001,100,0.001
            C155.229,0.001,200,44.769,200,99.999z M145,75.667c0-1.021-0.547-1.964-1.431-2.475L122.5,61.032V61.03l-21.073-12.166
            c-0.884-0.511-1.973-0.511-2.856,0L77.5,61.03c-0.002,0-0.002,0.002-0.003,0.002l-21.069,12.16C55.544,73.702,55,74.645,55,75.667
            v24.334v24.331c0,1.022,0.544,1.965,1.427,2.475L77.5,138.972l21.071,12.166c0.443,0.254,0.936,0.381,1.429,0.381
            c0.493,0,0.986-0.127,1.427-0.381l21.073-12.166l21.071-12.165c0.882-0.511,1.429-1.453,1.429-2.475v-24.331V75.667z
             M102.856,122.683l15.356,8.865v-17.731l-15.356-8.867V122.683z"/>
        </g>
  )
}

export default welfare_8