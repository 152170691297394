import React from "react";

const logo_symbol_hand_complete = () => {
  return (
    <g>
      <g>
        <rect
        fill="#ffffff"
          id="rect1498"
          width="41.162117"
          height="40.306522"
          x="8.3624926"
          y="9.3606834" />
    </g>
     <g
    ><path
      fill="#00bfdf"
      d="M 30,0 C 13.431,0 0,13.431001 0,30.000001 0,46.57 13.431,60 30,60 46.568,60 60,46.57 60,30.001 60,13.431001 46.568,0 30,0 Z m -3.995,44.128999 c 0,0.036 -0.004,0.067 -0.007,0.089 l -0.003,0.042 c -0.01,0.188001 -0.02,0.383001 -0.059,0.583 -0.017,0.096 -0.046,0.187 -0.072,0.271 -0.01,0.03 -0.019,0.06 -0.029,0.096 l -0.014,0.055 c -0.019,0.078 -0.04,0.165 -0.075,0.256 -0.049,0.133 -0.113,0.254 -0.175,0.372 l -0.006,0.012 c -0.014,0.026 -0.027,0.051 -0.039,0.074 l -0.009,0.016 c -0.007,0.012 -0.013,0.024 -0.022,0.042 -0.016,0.032 -0.035,0.072 -0.066,0.119 -0.252,0.409 -0.572,0.753 -0.955,1.026 -0.014,0.011 -0.027,0.02 -0.041,0.028 l -0.009,0.006 c -0.177,0.124 -0.358,0.227 -0.543,0.306 -0.031,0.015 -0.059,0.023 -0.077,0.028 l -0.014,0.005 c -0.412,0.167 -0.809,0.248 -1.216,0.248 -0.19,0 -0.392,-0.022 -0.63,-0.067 l -0.064,-0.016 c -0.171,-0.038 -0.328,-0.097 -0.464,-0.15 -0.011,-0.004 -0.023,-0.008 -0.036,-0.011 l -0.009,-0.002 c -0.029,-0.008 -0.058,-0.017 -0.082,-0.025 l -0.026,-0.011 c -0.167,-0.076 -0.321,-0.172 -0.449,-0.254 l -0.049,-0.03 c -0.025,-0.013 -0.057,-0.03 -0.09,-0.054 -0.208,-0.152 -0.38,-0.306 -0.528,-0.472 L 10.141,35.921 C 8.824,34.494 8.824,32.17 10.139,30.738999 c 0.652,-0.698 1.516,-1.082 2.434,-1.082 0.918,0 1.779,0.384 2.427,1.081001 l 3.068,3.314 0.123,0.131999 0.014,-0.028 c 0.095,0.054 0.203,0.083 0.314,0.083 0.351,0 0.637,-0.286 0.637,-0.637 0,-0.007 0,-0.013 -10e-4,-0.019 v -19.065 c 0,-2.024999 1.533,-3.672 3.417,-3.672 1.892,0 3.431,1.647001 3.431,3.672 V 44.129 Z m 10.138,0 c 0,2.025 -1.536,3.673001 -3.425,3.673001 -1.886,0 -3.421,-1.648001 -3.421,-3.673001 V 28.855 c 0,-2.023 1.535,-3.67 3.421,-3.67 1.888,0 3.425,1.647999 3.425,3.675001 z m 10.139,0 c 0,2.025 -1.531,3.673001 -3.413,3.673001 -1.894,0 -3.434,-1.648001 -3.434,-3.673001 v -10.134 C 39.435,31.97 40.974,30.323 42.865,30.323 h 0.009 c 1.879,0 3.408,1.647 3.408,3.671999 z"
      id="path716" /></g> 
    </g>
    
  );
};

export default logo_symbol_hand_complete;

