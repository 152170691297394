
import React, { Component } from 'react'
import Icon from '../Icons/Icon'
import OrderDetailTicketProduct from '../Products/OrderDetailTicketProduct'

class OrderDetailTicket extends Component {

  render () {
    const products = Array.from(this.props.products)
                          .map(product => <OrderDetailTicketProduct product={ product.props.product }
                                                                    key={ product.props.product.id_product_eva } />)
    return <div className="ticket">
      <span className="bg">
        <span className="barcode">
          <img src="/img/barcode.png" alt=""/>
        </span>
        <span className="logo_provider">
          <img src={ `/img/logo_providers/${ this.props.providername.toLowerCase() }.png` } alt=""/>
        </span>
      </span>
      <table>
        <thead>
          <tr>
            <th>Cantidad</th>
            <th>Artículo</th>
            <th>Precio uni.</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>{ products }</tbody>
      </table>
    </div>
  }
}

export default OrderDetailTicket
