
import React, { Component } from 'react'
import { Icon } from '../'

const Preloader = (props) =>
  <div className="loading-wrapper" role="dialog">
    <div className="overhead-loader">
      <svg className="circular" width="65px" height="65px" viewBox="0 0 66 66" >
        <circle className="path" fill="none" strokeWidth="3" strokeLinecap="round" cx="33" cy="33" r="26"></circle>
      </svg>
      <Icon className="logo" type="hand" fill="white" size="40"></Icon>
    </div>
  </div>

export default Preloader
