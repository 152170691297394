
/*****************************************************************************
* @class: Dropdown extends React.Component
* @author: Edgar Bermejo
* @description:
*
* TODO:
* - Alineación de la lista
* - Tipos de botón. Primero tenemos que plantear los botones!
* - Documentación
* - Refactorizar:
*   - 'options' dev¡bería ser un objeto
*
* @docs:
* https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_listbox_role
*
*
* @usage:
*
* Configuración de options:
* option.text {[String]}: @default: ''. Texto que se mostrará en el botón
* option.active {[Boolean]}: @default: false. Define el estado del botón
* option.action {[Function]}: @default: null. Acción para el evento click del botón
*
* const dropdownOptions = [
* {
*   text: 'Opción Uno',
*   active: false,
*   action: AppStore.testFn
* }, ...]
*
* <Dropdown options={ dropdownOptions }
*               buttonType="icon"
*               buttonColor="blue"
*               placeholder="Dropdown"
*               iconType="dots"
*               align=""
*               expandOnHover={ false }
*               collapseOnLeave={ false }
*               setSelectedAsPlaceholder="true"
*               transitionName="modal-anim" />
*
*****************************************************************************/

import React, { Component } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import config from '../../config'
import ListItem from './components/ListItem'
import DropdownButton from './components/DropdownButton'
import { UserIcon } from '../'
import { translate } from 'react-i18next'


// NOTE: para poder resetear el componente desde AppStore no podemos decorarlo.
// La solución pasa por decorar los componentes 'DropdownButton' y 'ListItem'
// @translate(['common'], { wait: false })
class Dropdown extends Component {

  static propTypes = {
    options: React.PropTypes.array,
    placeholder: React.PropTypes.string,
    setSelectedAsPlaceholder: React.PropTypes.bool,
    expandOnHover: React.PropTypes.bool,
    collapseOnLeave: React.PropTypes.bool,
    transitionName: React.PropTypes.string.isRequired
  }

  static defaultProps = {
    setSelectedAsPlaceholder: false,
    expandOnHover: false,
    collapseOnLeave: false
  }

  constructor (...args) {
    super(...args)

    this.state = {
      isOpen: false
    }

    // NOTE: no es una prop de estado para no influir en el render
    this.placeholder = this.props.placeholder
  }


  render () {
    const { expandOnHover, collapseOnLeave, transitionName, className } = this.props

    return (
      <div className={ `dropdown ${ className }` } onMouseEnter={ (expandOnHover) ? this.toggleDropdown : null }
                                onMouseLeave={ (collapseOnLeave) ? this.toggleDropdown : null } role="listbox" >

        { this.renderButton() }

        <ReactCSSTransitionGroup transitionName={ transitionName }
                                 transitionEnterTimeout={ 300 }
                                 transitionLeaveTimeout={ 300 }>
          { (this.state.isOpen) ? this.renderContent() : null }
        </ReactCSSTransitionGroup>
      </div>
    )
  }


  /**
   * @func: renderButton
   *
   * @description: renderiza el botón del Dropdown.
   * Si el usuario está logueado necesitamos un botón especial.
   * La propiedad reset es para cuando se usan dos dropdown y uno depende de otro, ver ejemplo en FinisOrderMas.js
   */
  renderButton () {
    const { type, buttonType, buttonColor, iconColor, iconType, iconSize, align, viewport } = this.props
    var place = this.placeholder
    if (this.props.reset){
      if (this.props.reset == false) place = this.placeholder
      else place = this.props.placeholder
    }
    if (iconType === 'custom') {
      return <button className="btn icon" onClick={ this.toggleDropdown } form = {this.props.form} >
        <UserIcon />
      </button>
    } else {
      return <DropdownButton form = {this.props.form} placeholder={ place } disabled = {this.props.disabled}
                             type={ type }
                             viewport = { viewport }
                             buttonType={ buttonType }
                             iconType={ iconType }
                             color={ buttonColor }
                             align={ align }
                             iconSize={ iconSize || '25px' }
                             iconColor={ iconColor || buttonColor }
                             iconTitle={ this.placeholder }
                             onClick={ this.toggleDropdown } />
    }
  }


  /**
   * @func: renderContent
   *
   * @description: renderiza el contenido del container.
   * Mapea las opciones de 'options' como componentes 'ListItem'
   * pasándoles las propiedades correspondientes.
   */
  renderContent () {
    const { align } = this.props
    if (this.props.content === '' || this.props.content === 'list') {
      return (
        <div className={ `dropdown-content list ${(!align || align === '' || align === 'center') ? 'center' : (align === 'left') ? 'left' : 'right' }` } >
          { this.props.options.map((option, i) => <ListItem text={ option.text } 
            active={ (option.active === true || option.default && option.default === true) ? option.active : '' }
            action={ option.action }
            form = {this.props.form}
            disabled = {this.props.disabled}
            id={ i }
            key={ i } />) }
          </div>
        )
    } else {
      return <div className={ `dropdown-content custom ${(!align || align === '' || align === 'center') ? 'center' : (align === 'left') ? 'left' : 'right' }` }>
        { this.props.children }
      </div>
    }
  }


  /**
   * @func: toggleDropdown
   * @param: {[Event]} event
   *
   * @description: Controla la propiedad de estado 'isOpen' del componete.
   * Para que el comonente se cierre al pulsar en cualquier punto de la
   * pantalla subscribimos el objeto 'document' a este mismo evento.
   * También comprobamos si el elemento que ha sido pulsado es un item del
   * componente para setearlo como 'active'.
   */
  toggleDropdown = (event) => {
    (this.state.isOpen) ? document.removeEventListener('click', this.toggleDropdown)
                        : document.addEventListener('click', this.toggleDropdown, false)

    if (event.type === 'click' && event.target.classList.contains('dropdown-list_item-button'))
      this.setActiveItem(parseInt(event.target.parentNode.id))

    this.setState({ isOpen: !this.state.isOpen })
  }


  /**
   * @func: setActiveItem
   * @param: {[Integer]} id
   *
   * @description: Controla la propiedad 'active' de los elementos del
   * listado del componente y setea el placeholder si es
   */
  setActiveItem (id) {
    this.props.options.map((option, i) => {
      // NOTE: seteamos como default la última seleccionada
      (option.active === true) ? option.default = true : delete option.default

      // NOTE: desactivactivamos todas las opciones
      option.active = false

      if (i === id) {
        // NOTE: si la opción no es seleccionable forzamos la por defecto
        // el ejemplo es el Dropdown del carrito
        if (option.selectable === false) {
          this.setDefaultActiveItem()
        } else {
          option.active = true
          if (this.props.setSelectedAsPlaceholder) this.placeholder = option.text
        }
      }
    })
  }


  setDefaultActiveItem () {
    this.props.options.map(option => {
      if (option.default === true) {
        option.active = true
        if (this.props.setSelectedAsPlaceholder) this.placeholder = option.text
      }
    })
  }
}


export default Dropdown
