
import React from 'react'
import { Button, IconButton } from '../../Buttons'

/**
 * IMPORTANT: este botón es provisional!
 * se reemplazará por el botón que corresponda.
 *
 * @func: DropdownButton
 * @param: {[Object]} props
 *
 * @props:
 *
 * @description:
 *
 * TODO: de momento vamos a crear un botón 'custom' con icono, fondo y sombra
 * porque el ButtonWithIcon no se va a utilizar, se cambia por las 'migas de pan'.
 */

const DropdownButton = (props) => {
  if (!props.buttonType || props.buttonType === '') {
    return <Button { ...props } />
  } else if (props.buttonType === 'icon') {
    return <IconButton { ...props } />
  } else if (props.buttonType === 'user') {
    return <IconButton { ...props } />
  }
}
export default DropdownButton
