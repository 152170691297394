
import React from 'react'
import { translate } from 'react-i18next'
import { AppStore, UIStore } from '../../../../stores'
import { Button } from '../../../'
import { capitalize } from '../../../../utils'

/**
 *
 */
const ToSendCarAlert = ({ t, ...props }) => <div className="empty-cart-alert">
  <div className="container">
    <div className="row">
      <p className="p light white s12">Al hacer click en el botón Aceptar tu carrito será enviado a { capitalize(AppStore.providername) }. Te informaremos del resultado del proceso una vez finalizado.</p>
    </div>
    <div className="row">
      <p className="p regular white s14">Tu carrito va a ser enviado a { capitalize(AppStore.providername) }</p>
      <ul>
        <li><Button placeholder="Cancelar" type="flat" color="dark-white" onClick={ props.onCancel } /></li>
        <li><Button placeholder="Aceptar" type="flat" color="blue" onClick={ props.onConfirm } /></li>
      </ul>
    </div>
  </div>
</div>

export default translate(['common'], { wait: false })(ToSendCarAlert)
