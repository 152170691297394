import React from "react";

const logo_ilusia = () => {
  return (
    <g transform="scale(6.5) translate(-25, 0)">
      <g>
        <path
          fill="#FFFFFF"
          d="M14.29,0A14.29,14.29,0,1,0,28.57,14.29,14.29,14.29,0,0,0,14.29,0ZM12.38,21v.07a2.48,2.48,0,0,1,0,.27l0,.13,0,.05v0a.93.93,0,0,1,0,.13.89.89,0,0,1-.08.17h0l0,0h0v0l0,.06a1.65,1.65,0,0,1-.46.49h0a1.35,1.35,0,0,1-.26.15h0a1.54,1.54,0,0,1-.58.12l-.3,0h0a.71.71,0,0,1-.22-.07h0l0,0h0l-.22-.12,0,0,0,0a1.47,1.47,0,0,1-.26-.23L4.83,17.1a1.84,1.84,0,0,1,0-2.46A1.55,1.55,0,0,1,6,14.12a1.53,1.53,0,0,1,1.15.52L8.6,16.21l.06.07v0a.26.26,0,0,0,.15,0,.29.29,0,0,0,.3-.3h0V6.91a1.7,1.7,0,0,1,1.63-1.75,1.7,1.7,0,0,1,1.63,1.75Zm4.83,0A1.63,1.63,0,1,1,14,21V13.74a1.63,1.63,0,1,1,3.26,0ZM22,21a1.63,1.63,0,1,1-3.26,0V16.19a1.69,1.69,0,0,1,1.63-1.75h0A1.69,1.69,0,0,1,22,16.19Z"
        />
        <path
          fill="#FFFFFF"
          d="M83.63,10.73a1.48,1.48,0,0,1,.19-.74,1.4,1.4,0,0,1,.54-.54,1.48,1.48,0,0,1,.76-.21,1.42,1.42,0,0,1,.75.21,1.4,1.4,0,0,1,.54.54,1.49,1.49,0,0,1,.2.74,1.47,1.47,0,0,1-.75,1.3,1.49,1.49,0,0,1-.74.2,1.52,1.52,0,0,1-1.3-.75A1.49,1.49,0,0,1,83.63,10.73Zm.27,0a1.23,1.23,0,0,0,.17.62,1.1,1.1,0,0,0,.44.44,1.15,1.15,0,0,0,.6.16,1.21,1.21,0,0,0,1.07-.61,1.29,1.29,0,0,0,.16-.61,1.22,1.22,0,0,0-.17-.61,1.32,1.32,0,0,0-.44-.44,1.22,1.22,0,0,0-.62-.16,1.15,1.15,0,0,0-.6.16,1.19,1.19,0,0,0-.45.44A1.24,1.24,0,0,0,83.9,10.73ZM85.26,10a.56.56,0,0,1,.36.12.39.39,0,0,1,.13.31.45.45,0,0,1-.12.32.48.48,0,0,1-.32.14h0a.37.37,0,0,1,.16.09,2.18,2.18,0,0,1,.2.29l.19.31h-.37l-.07-.14a1.7,1.7,0,0,0-.18-.31.67.67,0,0,0-.15-.17.39.39,0,0,0-.17,0h-.14v.66h-.29V10Zm-.12.69a.34.34,0,0,0,.22-.06.19.19,0,0,0,.08-.17.21.21,0,0,0-.08-.17.45.45,0,0,0-.22-.05h-.36v.45Z"
        />
        <path
          fill="#FFFFFF"
          d="M38.24,8.63a1.57,1.57,0,0,1-1.41.85,1.54,1.54,0,0,1-.73-.17,1.46,1.46,0,0,1-.56-.57,1.65,1.65,0,0,1,0-1.59,1.57,1.57,0,0,1,.6-.65A1.61,1.61,0,0,1,37,6.27a1.47,1.47,0,0,1,.7.19,1.36,1.36,0,0,1,.59.57,1.67,1.67,0,0,1,0,1.6Z"
        />
        <path
          fill="#FFFFFF"
          d="M45.87,18.48a3.3,3.3,0,0,1-.1,1.15,2,2,0,0,1-.49.84,1.67,1.67,0,0,1-1.14.45,2.78,2.78,0,0,1-1.32-.16,2.58,2.58,0,0,1-1.5-1.62A3.82,3.82,0,0,1,41.14,18V8.64c0-.49,0-.88,0-1.18s0-.54,0-.72,0-.35,0-.46a3.65,3.65,0,0,1,2.31.63A2.46,2.46,0,0,1,44.14,9v8.7a1.26,1.26,0,0,0,.22.84.8.8,0,0,0,.51.3,1.06,1.06,0,0,0,.58-.06A.84.84,0,0,0,45.87,18.48Z"
        />
        <path
          fill="#FFFFFF"
          d="M57,15.65a6.43,6.43,0,0,1-.46,2.65,4.2,4.2,0,0,1-1.2,1.61,4.12,4.12,0,0,1-1.64.8,7.39,7.39,0,0,1-3.58,0,4.05,4.05,0,0,1-1.64-.8A4.19,4.19,0,0,1,47.3,18.3a6.61,6.61,0,0,1-.45-2.65l0-3.43a2.58,2.58,0,0,1,.76-2.09,3.41,3.41,0,0,1,2.2-.62c0,.1,0,.25,0,.44s0,.42,0,.73,0,.71,0,1.19l0,3.78a6,6,0,0,0,.18,1.64,2.4,2.4,0,0,0,.5,1,1.64,1.64,0,0,0,.7.47,2.6,2.6,0,0,0,1.56,0,1.58,1.58,0,0,0,.7-.47,2.4,2.4,0,0,0,.5-1,6,6,0,0,0,.18-1.64l0-3.43a2.55,2.55,0,0,1,.76-2.09,3.46,3.46,0,0,1,2.21-.62c0,.1,0,.25,0,.44s0,.42,0,.73,0,.71,0,1.19Z"
        />
        <path
          fill="#FFFFFF"
          d="M63.93,17.82a.81.81,0,0,0-.24-.7,3,3,0,0,0-.8-.53c-.35-.17-.76-.36-1.23-.55a10.66,10.66,0,0,1-1.48-.78,3.55,3.55,0,0,1-1-.82,3.16,3.16,0,0,1-.55-1,3.25,3.25,0,0,1-.14-1.12,2.88,2.88,0,0,1,.27-1.13,2.7,2.7,0,0,1,.63-.9,3.33,3.33,0,0,1,.86-.6,4.45,4.45,0,0,1,1-.33,5,5,0,0,1,.9-.1h.29a5.64,5.64,0,0,1,2.36.64,5.74,5.74,0,0,1,1.83,1.48l.07.08-.07.09a1.62,1.62,0,0,1-.54.51,1.07,1.07,0,0,1-.61.18,1.65,1.65,0,0,1-.65-.14l-.69-.28c-.3-.13-.59-.24-.86-.33a2.56,2.56,0,0,0-.82-.13,1.23,1.23,0,0,0-.8.23.92.92,0,0,0-.32.55.87.87,0,0,0,.3.77,3.47,3.47,0,0,0,1,.57l1.6.69a6.18,6.18,0,0,1,1.51.93,3.56,3.56,0,0,1,1,1.31,2.83,2.83,0,0,1,.07,1.86,3.67,3.67,0,0,1-.41.92,3.15,3.15,0,0,1-.82.86,4.67,4.67,0,0,1-1.26.65,5.41,5.41,0,0,1-1.74.25,1.47,1.47,0,0,1-.32,0A5.67,5.67,0,0,1,60,20.3a5.56,5.56,0,0,1-1.89-1.48L58,18.74l.07-.1a1.37,1.37,0,0,1,.27-.31c.1-.08.18-.16.27-.22A1.25,1.25,0,0,1,59.2,18a1.66,1.66,0,0,1,.66.14l.68.28a4.59,4.59,0,0,0,.77.29,4.93,4.93,0,0,0,.94.17,1.83,1.83,0,0,0,1.25-.34A1.27,1.27,0,0,0,63.93,17.82Z"
        />
        <path
          fill="#FFFFFF"
          d="M71.18,8.63a1.57,1.57,0,0,1-1.41.85A1.54,1.54,0,0,1,69,9.31a1.46,1.46,0,0,1-.56-.57,1.54,1.54,0,0,1-.2-.76,1.63,1.63,0,0,1,.22-.83,1.66,1.66,0,0,1,.61-.65,1.61,1.61,0,0,1,.81-.23,1.5,1.5,0,0,1,.7.19,1.36,1.36,0,0,1,.59.57,1.67,1.67,0,0,1,0,1.6Z"
        />
        <path
          fill="#FFFFFF"
          d="M40.14,18.48a.81.81,0,0,1-.41.28,1.07,1.07,0,0,1-.58.06.85.85,0,0,1-.52-.3,1.32,1.32,0,0,1-.22-.84v-5c0-.49,0-.88,0-1.18s0-.54,0-.72,0-.35,0-.47a3.57,3.57,0,0,0-2.29.64A2.54,2.54,0,0,0,35.44,13l0,4.94a3.82,3.82,0,0,0,.18,1.17,2.6,2.6,0,0,0,.56,1,2.64,2.64,0,0,0,.94.65,2.74,2.74,0,0,0,1.31.16,1.7,1.7,0,0,0,1.15-.45,2.28,2.28,0,0,0,.49-.84A3.3,3.3,0,0,0,40.14,18.48Z"
        />
        <path
          fill="#FFFFFF"
          d="M73.08,18.48a.81.81,0,0,1-.41.28,1.09,1.09,0,0,1-.59.06.85.85,0,0,1-.51-.3,1.32,1.32,0,0,1-.22-.84v-5c0-.49,0-.88,0-1.18a6.83,6.83,0,0,1,0-.72c0-.2,0-.35,0-.47a3.57,3.57,0,0,0-2.29.64A2.54,2.54,0,0,0,68.38,13l0,4.94a3.82,3.82,0,0,0,.18,1.17,2.6,2.6,0,0,0,.56,1,2.55,2.55,0,0,0,.94.65,2.74,2.74,0,0,0,1.31.16,1.72,1.72,0,0,0,1.15-.45,2.12,2.12,0,0,0,.48-.84A3.07,3.07,0,0,0,73.08,18.48Z"
        />
        <path
          fill="#FFFFFF"
          d="M82.14,10.75a4.76,4.76,0,0,0-1.61-1.1,5,5,0,0,0-2-.41h-.21l-.21,0a5.78,5.78,0,0,0-3.87,1.8l0,.07,0,.1a1.48,1.48,0,0,0,.51.56,1.52,1.52,0,0,0,.66.15,2.61,2.61,0,0,0,.66-.09,5.82,5.82,0,0,0,.68-.2,6.57,6.57,0,0,1,.68-.21,4.18,4.18,0,0,1,.68-.11h.27a3.13,3.13,0,0,1,.65.06,2.28,2.28,0,0,1,.52.18,2.16,2.16,0,0,1,.95,1.1A4.7,4.7,0,0,1,80.78,14a7.74,7.74,0,0,0-2.41-.37,7,7,0,0,0-1.73.21,5.38,5.38,0,0,0-1.49.62,3,3,0,0,0-1.43,2.68v.15A3.13,3.13,0,0,0,74.2,19,2.94,2.94,0,0,0,75,20a4.61,4.61,0,0,0,1,.57,4.47,4.47,0,0,0,1.1.33,6.67,6.67,0,0,0,1.07.08h.32a1.42,1.42,0,0,0,.29,0,5.16,5.16,0,0,0,2-.61A5.07,5.07,0,0,0,82.29,19a6.28,6.28,0,0,0,1.29-4.11v-.2A5.79,5.79,0,0,0,82.14,10.75ZM80.68,16.7a3.28,3.28,0,0,1-.37.95,2.16,2.16,0,0,1-.29.45,1.55,1.55,0,0,1-.41.35,2.2,2.2,0,0,1-1.2.39,1.11,1.11,0,0,1-.29,0,2.39,2.39,0,0,1-1-.17,1.5,1.5,0,0,1-.57-.41,1.23,1.23,0,0,1-.29-.52,2.13,2.13,0,0,1-.08-.53.61.61,0,0,1,0-.22,1.67,1.67,0,0,1,.1-.4,1.09,1.09,0,0,1,.19-.33,1.85,1.85,0,0,1,.93-.61,2.59,2.59,0,0,1,.58-.13c.21,0,.43,0,.66,0a7.19,7.19,0,0,1,.81.05l.72.1.29.07.22,0h0l0,0A4.34,4.34,0,0,1,80.68,16.7Z"
        />
        <path
          fill="#FFFFFF"
          d="M35.43,23.45h.86V24a1.39,1.39,0,0,1,1.16-.65,1.19,1.19,0,0,1,1.12.67,1.55,1.55,0,0,1,1.28-.67,1.27,1.27,0,0,1,1.32,1.45v2.41h-.86V25.08c0-.6-.28-.92-.77-.92s-.81.33-.81.93v2.14h-.86V25.07c0-.58-.29-.91-.77-.91a.83.83,0,0,0-.81.94v2.13h-.86Z"
        />
        <path
          fill="#FFFFFF"
          d="M45.61,27.23v-.47a1.52,1.52,0,0,1-1.23.54A1.2,1.2,0,0,1,43,26.14v0c0-.83.65-1.23,1.53-1.23a3.23,3.23,0,0,1,1,.15V25c0-.52-.33-.81-.93-.81a2.71,2.71,0,0,0-1.08.24l-.23-.69a3.15,3.15,0,0,1,1.43-.32A1.46,1.46,0,0,1,46.46,25v2.24Zm0-1.58a2.38,2.38,0,0,0-.87-.15c-.54,0-.86.22-.86.58v0c0,.35.32.55.73.55.57,0,1-.32,1-.79Z"
        />
        <path
          fill="#FFFFFF"
          d="M48.57,23.45h.86v.85a1.34,1.34,0,0,1,1.34-.92v.91h-.05c-.76,0-1.29.5-1.29,1.5v1.44h-.86Z"
        />
        <path
          fill="#FFFFFF"
          d="M52.59,22h.87v3.12L55,23.45h1L54.57,25l1.56,2.24h-1L54,25.59l-.53.56v1.08h-.87Z"
        />
        <path
          fill="#FFFFFF"
          d="M57.44,25.35h0a1.88,1.88,0,0,1,1.85-2,1.82,1.82,0,0,1,1.81,2,2.29,2.29,0,0,1,0,.25H58.31a1.05,1.05,0,0,0,1.09,1,1.34,1.34,0,0,0,1-.45l.51.45a1.93,1.93,0,0,1-1.55.71A1.89,1.89,0,0,1,57.44,25.35Zm2.8-.28a1,1,0,0,0-1-1,1,1,0,0,0-1,1Z"
        />
        <path
          fill="#FFFFFF"
          d="M63.22,26.18v-2h-.48v-.74h.48v-1h.87v1h1v.74h-1v1.86a.41.41,0,0,0,.46.47,1.23,1.23,0,0,0,.54-.13v.71a1.55,1.55,0,0,1-.78.19A1,1,0,0,1,63.22,26.18Z"
        />
      </g>
    </g>
  );
};

export default logo_ilusia;
