import React, { Component } from "react";

export class AutocompletePlace extends Component {
  _apiKey = "AIzaSyBRQxmWCyOJGqMcQCrB5otWkILQU4A1e2k"; // AIzaSyB41DRUbKWJHPxaFjMAwdrzWzbVKartNGg
  constructor(props) {
    super(props);
    this.autoCompleteRef = null;
  }

  componentDidMount() {
    this.loadScript(
      "https://maps.googleapis.com/maps/api/js?key=" +
        this._apiKey +
        "&callback=initAutocomplete&libraries=places&v=weekly",
      () => this.handleScriptLoad()
    );
  }

  loadScript(url, callback) {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  }

  handleScriptLoad() {
    const autoCompleteRef = this.autoCompleteRef;

    const options = {
      componentRestrictions: { country: "es" },
      fields: [
        "address_components",
        "geometry",
        "formatted_address",
        "name",
        "adr_address",
        "vicinity",
      ],
      types: ["geocode"],
    };
    
    const autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef,
      options
    );

    autoComplete.addListener("place_changed", () => {
      autoComplete.getFields;
      if (!this.props.readOnly) {
        const place = autoComplete.getPlace();
        const getIndexOf = (component = "") =>
          place.address_components.findIndex((value) =>
            value.types.includes(component)
          );

        const haveStreetNumber = getIndexOf("street_number") != -1;
        if (!haveStreetNumber) {
          alert(
            "Asegurese de intrducir la dirección y su número correctamente"
          );
          this.props.onSelectAddress("Dirección invalida", null);
          return;
        }


        const latitude = place.geometry.location.lat(),
          longitude = place.geometry.location.lng(),
          globalcode = "",
          postalCode = place.address_components[getIndexOf("postal_code")].long_name,
          city = place.address_components[getIndexOf("locality")].long_name,
          province = place.address_components[getIndexOf("administrative_area_level_2")].long_name || ""
        const metadata = {
          latitude,
          longitude,
          globalcode,
          postalCode,
          city,
          province
        };

        const route = place.address_components[getIndexOf("route")].long_name;
        const street =
          place.address_components[getIndexOf("street_number")].long_name;
        const address = `${route}, ${street}`;

        this.props.onSelectAddress(address, metadata);
      }
    });
  }

  render() {
    return (
      <div className="search-location-input">
        <input
          ref={(r) => (this.autoCompleteRef = r)}
          value={this.props.value}
          readOnly={this.props.readOnly}
          onChange={(e) => {
            if (this.props.readOnly) return;
            this.props.onChange(e);
          }}
          className={this.props.className}
          disabled={this.props.readOnly}
        />
      </div>
    );
  }
}
