
import React from 'react'

/**
 * @func: Video
 * @param: {[Object]} props
 *
 * @description:
 */
const Video = (props) => <div className="bg" aria-hidden='true'>
  <video id="videoplayer" className="video-bg" preload="none" autoPlay="autoplay" loop="loop" poster="video/video_poster.png">
    <source src={ `${ props.source }.mp4` } type='video/mp4' />
    <source src={ `${ props.source }.webm` } type='video/webm' />
    <source src={ `${ props.source }.ogv` } type='video/ogg' />
  </video>
</div>

export default Video
