import React from "react";
import { Component } from "react";

class ConfirmationOrder extends Component {
  render() {
    return (
      <div className="confirmation-order-modal">
        <div className="container-confirmation">
          <h2 className="h2 black s32">Estás finalizando tu compra ... </h2>
          <div className="container-info">
            <p className="h1 black s20">
              Podrás encontrar tus carritos en tu zona de usuario.
            </p>
            <p className="p grey s16 ">
              ilusia podrá realizar una retención superior al precio de compra
              debido a la variación de precios en los productos al peso o por
              cambio de precios en el súper.
            </p>
          </div>
          <div className="container-buttons">
            <button className="btns btn outline grey" onClick={this.props.cancel}>
              Seguir comprando
            </button>
            <button
              className="btns btn outline blue"
              onClick={this.props.confirm}
            >
              Finalizar y pagar
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmationOrder;
