import React, { Component } from 'react';
import { observer } from "mobx-react";
import { translate } from "react-i18next";
import { AuthStore } from "../../stores"
import Icon from "../Icons/Icon";


@translate(['common'], { wait: false })
@observer
class OrderConfirmationAddress extends Component {
  render(){
    return(
      <div>
        <div className="container-title">
            <Icon 
              type="map"
              viewport="0 0 512 512"
              className="icon"
              height="35"
              width="35"
            />
          <div className="container-title-info">
            <h2 className='h1 light grey s28'>Dirección de entrega</h2>
          </div>
        </div>
        <div className="container-address">
          <div className="address">
            <div className="address-header">
              <h4 className='h1 bold grey s17'>{AuthStore.user.addresses[0].alias}</h4>
              {/* <button className="address-btn">CAMBIAR</button> */}
            </div>
            <div className="address-info">
              <p>{AuthStore.user.addresses[0].address1}</p>
              <p>{AuthStore.user.addresses[0].postcode} {AuthStore.user.addresses[0].province}, {AuthStore.user.addresses[0].city}</p>
              <p>{AuthStore.user.addresses[0].phone}</p>
            </div> 
          </div>
        </div>
      </div>
    )
  }
}

export default OrderConfirmationAddress