
import React, { Component } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

/**
 * @class: Notification
 * @author: Edgar Bermejo
 * @description: Proporciona información sobre algún flujo acción concreto.
 * No requiere de ningún tipo de confirmación o interacción de usuario.
 * (Quizás un botón para forzar el cierre)
 *
 * NOTE: por una cuestión de conflictos entre componentes del tipo Dialog y por las
 * características concretas de este container, no tiene un metodo 'toogle' si no un
 * metodo específico para mostrarlo y otro para ocultarlo, 'show' y 'hide' respectivamente.
 *
 * TODO:
 * - Debería tener un botón para cerrar?
 * - Parametrizar los tiempos de las transiciones
 * - Parametrizar los tiempos de las transiciones
 *
 *
 * Parámetros de configuración:
 * @Config.type {[String]}: {default | primary | success | info | warning | danger} @default: 'default',
 * @Config.content {[Component]}: <Component /> | @required
 *
 *
 * @usage:
 *
 * // HoC
 * const Template = (props) => <div className="contenti">
 *   <h1>Soy un HoC Alert</h1>
 *   <button onClick={ props.clickEvent }>Close</button>
 * </div>
 *
 * // React.Component
 * class NotificationTemplate extends Component {
 *   render () {
 *     return(
 *       <div className="contenti">
 *         <h1>Soy un React.Component Alert</h1>
 *         <button onClick={ this.props.clickEvent }>Close</button>
 *       </div>
 *     )
 *   }
 * }
 *
 * UIStore.toogleNotification({
 *   type: 'success'
 *   // content: () => <Template clickEvent={ testClick } />
 *   content: () => <AlertTemplate clickEvent={ testClick } />
 * })
 *
 * <Notification isOpen={ UIStore.isNotificationOpen }
 *               show={ UIStore.showNotification }
 *               hide={ UIStore.hideNotification }
 *               config={ UIStore.notificationConfig }
 *               timeOut={ 3000 }  // NOTE: esto es general para todas las de la app pero también se puede pasar como una prop en la config
 *               transitionName="notification-anim"/>
 */
class Notification extends Component {

  static propTypes = {
    isOpen: React.PropTypes.bool,
    timeOut: React.PropTypes.number,
    config: React.PropTypes.object,
    transitionName: React.PropTypes.string
  }

  static defaultProps = {
    isOpen: false,
    timeOut: 3000
  }

  componentWillReceiveProps (nextProps) {
    let timeout = this.props.timeOut
    if (nextProps.config.timeOut) timeout = nextProps.config.timeOut

    if (nextProps.isOpen === true) {
      let sto = window.setTimeout(() => {
        this.props.hide()
        window.clearTimeout(sto)
      }, timeout)
    }
  }

  render () {
    return (
      <ReactCSSTransitionGroup transitionName={ this.props.transitionName }
                               transitionEnterTimeout={ 500 }
                               transitionLeaveTimeout={ 300 }>
        {(this.props.isOpen) ? this.renderContent() : null }
      </ReactCSSTransitionGroup>
    )
  }


  renderContent () {
    const { customClassName, message } = this.props
    const { type } = this.props.config
    return (
      <div className={ `notification-wrapper ${ customClassName }` } ref="notification" role="dialog">
        <div className={ `notification-content ${type || 'default'}`}>
          { this.props.config.content(message) }
        </div>
      </div>
    )
  }
}


export default Notification
