import React from 'react'

const coments = () => {
  return (  
    <g>
    <path 
      d="m407 .5h-302c-57.898438 0-105 47.101562-105 105v162.171875c0 46.199219 30.332031 86.4375 74.285156 99.316406l50.710938 50.714844c2.816406 2.8125 6.628906 4.394531 10.609375 4.394531 3.976562 0 7.792969-1.582031 10.605469-4.394531l46.519531-46.523437h214.269531c57.898438 0 105-47.101563 105-105v-160.679688c0-57.898438-47.101562-105-105-105zm-261.242188 211.851562c0-8.285156 6.714844-15 15-15h190.484376c8.285156 0 15 6.714844 15 15 0 8.285157-6.714844 15-15 15h-190.484376c-8.285156 0-15-6.714843-15-15zm205.484376-38.023437h-190.484376c-8.285156 0-15-6.714844-15-15 0-8.28125 6.714844-15 15-15h190.484376c8.285156 0 15 6.71875 15 15 0 8.285156-6.714844 15-15 15zm0 0"
      />
  </g>
  );
}
 
export default coments
;