
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { ProductAccordion } from './accordion'

@observer
class ProductInfoNutriContainer extends Component {
  render () {
    // TODO: controlar has_nutritional_info
    //   0 No tiene ningún tipo de información.
    //   1 Solo tiene información adicional.
    //   2 Solo tiene ingredientes.
    //   3 Tiene información adicional e ingredientes.
    //   4 Si no tiene ningún tipo de información y tiene tabla.
    //   5 Si tiene información adicional y tabla.
    //   6 Si tiene ingredientes y tabla.
    //   7 Si tiene información adicional, nutricional y tabla.

    return (
      <div className="container-full info-nutri-wrapper">
        { this.props.nutritional ? <ProductAccordion info={ this.props.nutritional } /> : null }
      </div>
    )
  }
}

export default ProductInfoNutriContainer
