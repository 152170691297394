import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

class WebPushNotificationStore {

  initialized = false
  messaging = null

  constructor () {
    return;
    const firebaseConfig = {
      apiKey: "AIzaSyC1b4qEbOK1iMX7Hst7tTfIlcIfXSoZ6og",
      authDomain: "storetest-844c3.firebaseapp.com",
      projectId: "storetest-844c3",
      storageBucket: "storetest-844c3.appspot.com",
      messagingSenderId: "924192459325",
      appId: "1:924192459325:web:38ea841ae25ea52be57854"
    };
    
    this.app = initializeApp(firebaseConfig);
    this.messaging = getMessaging(this.app);
    this.initialized = true;
    this.getWebPushToken();
    window.requestPermission = this.requestPermission;
  }

  requestPermission() {
    console.log('Requesting permission...');
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
        // TODO(developer): Retrieve a registration token for use with FCM.
        // In many cases once an app has been granted notification permission,
        // it should update its UI reflecting this.
        this.getWebPushToken();
      } else {
        console.log('Unable to get permission to notify.');
      }
    });
  }

  getWebPushToken(){
    console.log("getWebPushToken");
    getToken(this.messaging, { vapidKey: 'BOpJZ6wXG504xrxe1RFQGaBjapNBWkpkG3URxzg3ZrGZV9lEoRRPN_kSEZ5idHfHTxw7qFTQdkR0A1G3F0IEZUc' }).then((currentToken) => {
      if (currentToken) {
        // Send the token to your server and update the UI if necessary
        console.log({
          currentToken
        });
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);

    }).catch(err => {
      console.log(err);
    });
  }
}

export default new WebPushNotificationStore()
