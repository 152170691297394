
import React from 'react'

const add_to_cart = () => {
  return (
    <g>
  		<path d="M86.625,54.994c-0.905-1.32-1.039-3.074-0.345-4.543c0.685-1.455,2.089-2.371,3.604-2.371h8.791l7.31-27.898
  			c0.491-1.811,2.013-3.084,3.769-3.148c0.016,0,0.043,0.012,0.059,0.012c0.029,0,0.053-0.012,0.072-0.012
  			c1.756,0.064,3.277,1.338,3.764,3.148l7.31,27.898h8.786c1.521,0,2.931,0.916,3.615,2.371c0.689,1.469,0.561,3.223-0.342,4.543
  			l-19.994,29.289c-0.744,1.078-1.908,1.742-3.158,1.779c-0.018,0-0.033,0-0.053-0.012c-0.01,0.012-0.031,0.012-0.043,0.012
  			c-1.252-0.037-2.416-0.701-3.158-1.779L86.625,54.994z M177.855,23.883L177.855,23.883h-0.111c-0.072,0-0.143-0.01-0.211-0.01
  			l-42.494,0.01c-4.41,0-7.98,3.57-7.98,7.977c0,4.408,3.57,7.979,7.98,7.979h42.605c0.291,0.021,1.928,0.168,3.48,0.939
  			c1.428,0.717,2.752,1.865,2.752,5.398v35.68c-0.281,1.053-1.543,3.914-7.002,5.064c-10.275,2.162-83.404,15.777-107.184,20.275
  			C65.379,87.67,59.049,59.084,54.641,39.838H84.34c4.41,0,7.982-3.57,7.982-7.979c0-4.406-3.572-7.977-7.982-7.977l-33.447-0.01
  			c-0.227-0.922-0.438-1.77-0.609-2.428C47.555,10.971,41.756,5.717,37.369,3.154c-4.887-2.863-9.232-3.123-10.055-3.148L8.982,0
  			C4.577,0,1,3.576,1,7.982c0,4.412,3.577,7.982,7.982,7.982h17.913c0.497,0.053,5.675,0.805,7.944,9.51
  			c2.92,11.209,15,65.82,20.811,92.195c0.018,0.098,0.006,0.199,0.027,0.297c0.037,0.221,0.118,0.416,0.177,0.631
  			c1.673,7.615,2.788,12.707,2.931,13.332c1.277,6.227,7.479,17.859,21.963,17.859h111.109c4.409,0,7.979-3.574,7.979-7.982
  			c0-4.406-3.569-7.982-7.979-7.982H80.748c-4.597,0-5.973-3.625-6.355-5.211l-0.006-0.037c-0.111-0.502-0.566-2.588-1.267-5.781
  			c24.413-4.625,96.548-18.043,107.051-20.254c9.132-1.926,13.959-6.793,16.397-10.529c2.656-4.076,3.154-7.75,3.24-8.775
  			l0.027-0.34V46.176C199.836,30,186.82,24.023,177.855,23.883z M80.424,153.904c-12.396,0-22.471,10.08-22.471,22.47
  			c0,12.385,10.075,22.459,22.471,22.459c12.385,0,22.465-10.074,22.465-22.459C102.889,163.984,92.809,153.904,80.424,153.904z
  			 M80.424,182.88c-3.593,0-6.51-2.918-6.51-6.506c0-3.587,2.917-6.503,6.51-6.503c3.582,0,6.498,2.916,6.498,6.503
  			C86.922,179.962,84.006,182.88,80.424,182.88z M167.053,153.904c-12.391,0-22.463,10.08-22.463,22.47
  			c0,12.385,10.072,22.459,22.463,22.459c12.389,0,22.465-10.074,22.465-22.459C189.518,163.984,179.441,153.904,167.053,153.904z
  			 M167.053,182.88c-3.586,0-6.504-2.918-6.504-6.506c0-3.587,2.918-6.503,6.504-6.503s6.51,2.916,6.51,6.503
  			C173.562,179.962,170.639,182.88,167.053,182.88z"/>
  	</g>
  )
}

export default add_to_cart
