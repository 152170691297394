
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { UIStore } from '../../stores'
import { AnimatedProduct, AnimatedQuantityBadge } from '../'

@observer
class AnimatedProductContainer extends Component {

  render () {
    const animatedProducts = UIStore.animatedProducts
    const animatedBadges = UIStore.animatedQuantityBadges
    const products = []
    const badges = []
    animatedProducts.forEach((product, key) => products.push(<AnimatedProduct product={ product } index={ key } key={ key } />))
    animatedBadges.forEach((product, key) => badges.push(<AnimatedQuantityBadge index={ key } units={ product.units } key={ key } />))

    return (
      <div className="animated-product-container">
        { products }
        { badges }
      </div>
    )
  }
}


export default AnimatedProductContainer
