import React from 'react'

const allergen_8 = () => {
  return (
        <g>
              <path d="M113.129,106.221c-1.126-0.407-1.598-0.328-1.962,0.938c-0.735,2.521-2.343,4.499-4.506,6.04
                c-1.205,0.857-2.507,1.456-3.593,0.068c-1.122-1.44-0.125-2.455,1.091-3.316c1.956-1.375,3.226-3.154,3.419-5.617
                c0.449-5.745-3.995-10.881-10.275-11.462c-1.797-0.168-2.077-0.704-2.119-2.353C95.048,84.8,90.73,80.38,84.974,79.772
                c-5.212-0.55-10.614,3.072-11.983,8.029c-1.485,5.388,1.15,10.971,6.205,13.267c1.304,0.589,2.11,0.775,2.6-0.994
                c0.646-2.321,2.25-4.077,4.204-5.501c1.169-0.859,2.441-1.559,3.566-0.116c1.124,1.448,0.173,2.481-1.044,3.341
                c-1.631,1.157-2.738,2.65-3.148,4.637c-1.302,6.376,3.73,12.45,10.77,12.71c1.633,0.057,1.771,0.552,1.789,1.905
                c0.076,6.045,5.383,11.06,11.427,10.921c6.018-0.144,11.224-5.388,11.193-11.265C120.527,112.038,117.48,107.8,113.129,106.221z"/>
            <path  d="M100.001,0.001c-55.229,0-100,44.771-100,99.999s44.771,99.999,100,99.999
                c55.228,0,99.998-44.771,99.998-99.999S155.228,0.001,100.001,0.001z M134.961,129.933c-0.28,1.554-1.556,1.987-2.833,2.207
                c-3.74,0.636-7.488,1.225-11.248,1.734c-3.629,0.492-7.283,0.617-10.95,0.678c-9.446,0.011-18.585-1.304-26.838-6.266
                c-9.437-5.675-14.359-14.364-16.625-24.737C64.35,93.857,64.723,84.125,66.26,74.4c0.342-2.163,0.7-4.331,1.14-6.477
                c0.236-1.151,0.794-2.166,2.115-2.415c1.368-0.257,2.352,0.363,3.064,1.485c1.722,2.692,3.479,5.364,5.146,8.095
                c0.59,0.97,1.12,1.309,2.361,1.028c8.692-2.011,16.528,2.699,18.756,11.244c0.309,1.194,0.882,1.674,2.043,2.042
                c5.294,1.693,8.725,5.293,10.335,10.514c0.403,1.293,0.989,1.879,2.349,2.27c7.602,2.186,12.179,9.641,10.698,17.337
                c-0.275,1.409-0.008,2.179,1.295,2.893c2.565,1.399,5.03,2.988,7.549,4.483C134.279,127.591,135.231,128.398,134.961,129.933z"/>
        </g>
  )
}

export default allergen_8