
/**
 * @func: isValidRGBColor
 * @param: {[String]} color
 * @return: {[Boolean]}
 *
 * @description: devuelve 'true' o 'false' dependiendo de si el valor
 * que se le pasa es un valor rgb o rgba valido.
 */

const isValidRGBColor = (color) => /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/i.test(color)
export default isValidRGBColor
