
import React, { Component } from 'react'
import { Link } from 'react-router'
import { translate } from 'react-i18next'
import { AuthStore, UIStore } from '../../../stores'
import { editUser } from '../../../utils/api'
import { Button, SwitchButton } from '../../'

@translate(['common'], { wait: false })
class Preferences extends Component {

  state = {
    mail_promotions: (AuthStore.user.enable_mail_promotions === 0) ? false : true,
    push_promotions: (AuthStore.user.enable_push_promotions === 0) ? false : true
  }

  render () {
    return (
      <div className="user-zone-view preferences">
        <header>
          <h2 className="h2 regular blue s30">Preferencias</h2>
        </header>
        <article className="notifications">
          <h2 className="h2 regular blue s22">Contacta con Nosotros</h2>
          <ul>
            <li>
              <p className="p light grey s16">Email: cliente@ilusia.com</p>
            </li>
            <li>
              <p className="p light grey s16">WatsApp: <a href="https://wa.me/34623340095" target='_blank'>+34 623340095</a></p>
            </li>
          </ul>
        </article>
        <article className="about">
          <h2 className="h2 regular blue s22">Acerca de Ilusia®</h2>
          <ul>
            <li>
              <Link to="https://www.ilusia.com/legales/" target="_blank">
                <p className="p light grey s16">Legal</p>
                <span className="arrow">▾</span>
              </Link>
            </li>
            <li>
              <Link to="https://www.ilusia.com/legales/#terminos" target="_blank">
                <p className="p light grey s16">Términos y condiciones de uso de la apliación</p>
                <span className="arrow">▾</span>
              </Link>
            </li>
            <li>
              <Link to="https://www.ilusia.com/faqs/" target="_blank">
                <p className="p light grey s16">FAQ's</p>
                <span className="arrow">▾</span>
              </Link>
            </li>
            <li>
              <p className="p light light-grey s14">Derechos Reservados © 2016 Ilusia®</p>
            </li>
          </ul>
        </article>
      </div>
    )
  }


  emailSwichButtonToogle = (event) => this.setState({ mail_promotions: !this.state.mail_promotions })
  pushSwichButtonToogle = (event) => this.setState({ push_promotions: !this.state.push_promotions })


  sendNotificationsConfig = (event) => {
    UIStore.showLoading()
    console.log((this.state.mail_promotions) ? '1' : '0');

    const user = {
      mail_promotions: (this.state.mail_promotions) ? '1' : '0',
      push_promotions: (this.state.push_promotions) ? '1' : '0'
    }
    console.log(user);
    editUser(user).then(response => {
      UIStore.hideLoading()
      console.log(response);
      if (response.result === 1) {
        console.log('todo ok');
        // TODO: cmostrar notificación
        AuthStore.setUser()
      } else {
        // TODO: controlar el error
        console.error('Error al enviar los nuevos datos.');
      }
    })
  }
}

export default Preferences
