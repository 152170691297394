
import React from 'react'
import { Icon } from '../'

/**
 * @func: ProviderSelectionButton
 * @param: {[Object]} props
 *
 *
 * @description:
 */

const ProviderSelectionButton = (props) => <button className="btn provider-selection_basic"
                                                   onClick={ props.onClick }
                                                   id={ props.id }
                                                   name={ props.name }
                                                   style={ props.style }>
  <Icon type={ props.icon } fill="white" width="130" height="40" viewport="0 0 190 230" />
  <svg className="icon-providers" fill="white" width="35" height="35" viewBox="0 -100 190 400">
                                            <path d="M144.735,9.783c-17.299,0-33.898,8.053-44.736,20.779C89.163,17.836,72.558,9.783,55.265,9.783
    		c-30.627,0-54.684,24.062-54.684,54.678c0,37.584,33.803,68.203,85.008,114.729l14.41,13.027l14.412-13.123
    		c51.205-46.43,85.008-77.049,85.008-114.633C199.419,33.846,175.357,9.783,144.735,9.783z M103.259,166.84l-3.301,3.004
    		l-3.615-3.27C48.649,123.242,17.151,94.617,17.151,64.461c0-21.371,16.743-38.107,38.113-38.107
    		c12.137,0,24.146,5.588,32.119,14.951l12.615,14.812l12.616-14.812c7.975-9.363,19.978-14.951,32.12-14.951
    		c21.369,0,38.113,16.736,38.113,38.107C182.849,94.672,151.239,123.326,103.259,166.84z M153.193,59.545
    		c3.235,3.232,3.235,8.48,0,11.717l-53.194,53.188L65.651,90.107c-3.233-3.234-3.233-8.484,0-11.715
    		c3.238-3.236,8.484-3.236,11.718,0l22.63,22.627l41.478-41.475C144.708,56.311,149.956,56.311,153.193,59.545z"></path>
                                          </svg>
                                          </button>

export default ProviderSelectionButton
