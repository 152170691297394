import React from 'react';

const clock = () => {
  return (
    <g>
				<path d="M255-1C113.618-1-1,113.618-1,255s114.618,256,256,256s256-114.618,256-256S396.382-1,255-1z M255,468.333
				c-117.818,0-213.333-95.515-213.333-213.333S137.182,41.667,255,41.667S468.333,137.182,468.333,255S372.818,468.333,255,468.333
				z" />
				<path
					d="M255,84.333c-11.782,0-21.333,9.551-21.333,21.333v128h-64c-11.782,0-21.333,9.551-21.333,21.333
				s9.551,21.333,21.333,21.333H255c11.782,0,21.333-9.551,21.333-21.333V105.667C276.333,93.885,266.782,84.333,255,84.333z" />
			</g>
  )
}

export default clock;