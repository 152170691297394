
import React, { Component } from 'react'
import { Link } from 'react-router'
import { translate } from 'react-i18next'
import { Icon, Button } from '../../'
import { AppStore } from '../../../stores'


@translate(['common'], { wait: false })
class LoginSignup extends Component {

  render () {
    const conected = (AppStore.postalcode) ? `Conectado para ${ AppStore.postalcode }` : ''

    return (
      <div className="login-signup">
        <div className="login-wrapper">
          <button className="btn-login" onClick={ this.props.onLoginClick }>
            <p className="p regular s14 black a60">¿Ya estás registrado?</p>
            <p className="p regular s14 blue">Iniciar sesión</p>
          </button>
        </div>
        <div className="userdata-wrapper">
          <ul>
            <li>
              <Icon type="start_user" fill="grey" size="60px" />
            </li>
            <li>
              <h3 className="h3 black s16 bold name">Usuario anónimo</h3>
              <Link className="btn-cp" to={ (AppStore.postalcode) ? '/' : null }><p className="p regular grey s14">{ conected }</p></Link>
              <Button placeholder="Registrarme" className="btn-signup" type="flat" color="blue" onClick={ this.props.onSignupClick } />
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default LoginSignup
