
import React, { Component } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { IconButton } from '../../../'

/**
 * @class: FinisOrderIframe
 */

class FinisOrderIframe extends Component {


  static defaultProps = {
    isOpen: true
  }

  constructor (...args) {
    super(...args)
  }

  render () {
    return ( this.renderContent() )
  }


  renderContent () {
    return (
      <div ref="modal" role="dialog">
                <iframe width={this.props.width} height={this.props.height} src={this.props.url}></iframe>
      </div>

    )
  }
}



export default FinisOrderIframe
