
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { SearchStore } from '../../stores'
import { Icon, IconButton } from '../../components'

@observer
class ShopBotInput extends Component {

  state = {
    value: SearchStore.string || ''
  }


  componentDidMount () {
    this.refs.input.focus()
  }


  render () {
    return (
      <div className="search-form">
        <Icon type="search" fill="grey" className="search" />
        <form onSubmit={ this.search }>
          <input ref="input"
                 type="text"
                 placeholder={ this.props.placeholder }
                 value={ this.state.value }
                 onChange={ this.handleInputChange } />
        </form>
        <IconButton onClick={ this.clear }
                    type="flat"
                    color="transparent"
                    iconType="close"
                    iconTitle="close"
                    iconFill="grey"
                    className="close" />

        {
          (SearchStore.matches !== null)
          ? (SearchStore.matches === 0 && SearchStore.result === null)
            ? this.renderSearchError()
            : this.renderSearchResult()
          : null
        }

        { (SearchStore.isSearching === true) ? this.renderSearchingMessage() : null }
      </div>
    )
  }


  renderSearchingMessage = () => <h2 className="search-result-text h2 regular grey s16">¡Estamos repasando los estantes para encontrar lo que buscas!</h2>


  renderSearchResult = () => {
    if (SearchStore.matches > 0) {
      return (
        <h2 className="search-result-text h2 regular grey s16">
          <span className="p regular dark-grey s16">{ SearchStore.matches }</span> resultados encontrados para "<span className="p regular dark-grey s16">{ SearchStore.string }</span>"
        </h2>
      )
    } else {
      return (
        <h2 className="search-result-text h2 regular grey s16">¡Vaya! Parece que no hay resultados para lo que estabas buscando, ¿probamos con otras palabras?</h2>
      )
    }
  }


  renderSearchError = () => <h2 className="search-result-text h2 regular grey s16">
    <span className="p regular dark-grey s16">¡Ops! Para realizar una búsqueda es necesario escribir dos o más caracteres.</span>
  </h2>


  handleInputChange = (event) => {
    this.setState({ value: this.refs.input.value })
  }


  search = (event) => {
    event.preventDefault()
    SearchStore.search(this.refs.input.value)
  }


  clear = (event) => {
    event.preventDefault()
    this.refs.input.value = ''
  }
}


export default ShopBotInput
