
import React, { Component } from 'react'
import { translate } from 'react-i18next'
// import { AppStore } from '../stores'
import { LinkedAccountsAccordion } from '../components'

@translate(['common'], { wait: false })
class LinkedAccounts extends Component {

  render () {
    return (
      <div className="user-zone-view linked-accounts">
        <header>
          <h2 className="h2 s30 regular blue">Cuentas vinculadas</h2>
        </header>
        <article>
          <p className="p s16 light grey">Vincula las cuentas de tus supermercados favoritos a tu cuenta de ilusia <br/>y agiliza el trámite de tus pedidos.</p>
          <LinkedAccountsAccordion />
        </article>
      </div>
    )
  }
}

export default LinkedAccounts
