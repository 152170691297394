
import React from 'react'

const cart_carrefour = () => {
  return (
    <g>
      <path fill="#FFFFFF" d="M61.784,38.2c0.327-0.353,0.647-0.505,0.912-0.505c0.48,0,0.814,0.474,0.799,1.047
      	c-0.012,0.349-0.144,0.709-0.447,1.041c-11.961,16.242-20.655,35.213-20.655,61.545c0,26.317,8.694,44.924,20.655,61.151
      	c0.302,0.335,0.434,0.713,0.447,1.045c0.015,0.565-0.319,1.034-0.799,1.034c-0.265,0-0.585-0.123-0.912-0.491l-50.951-53.829
      	c-2.541-2.374-4.166-5.16-4.166-9.098c0-3.968,1.626-6.739,4.166-9.101L61.784,38.2 M101.207,32.522
      	c-19.942,0-27.742,30.863-27.742,67.677c0,36.803,7.8,67.278,27.742,67.278c11.977,0,22.184-7.658,22.214-13.948
      	c0.006-1.378-0.477-2.697-1.539-3.848c-5.678-6.002-7.897-11.974-7.9-17.253c-0.046-10.089,7.96-17.599,15.34-17.599
      	c10.157,0,16.143,8.715,16.143,19.993c0,10.692-4.174,19.595-8.71,26.731c-0.178,0.285-0.257,0.621-0.257,0.901
      	c0,0.554,0.305,1.018,0.756,1.018c0.279,0,0.59-0.158,0.949-0.546l50.954-53.814c2.55-2.377,4.178-5.165,4.178-9.098
      	c0-3.977-1.626-6.745-4.178-9.097l-50.96-53.841c-0.353-0.389-0.67-0.543-0.949-0.543c-0.459,0-0.75,0.452-0.754,1.015
      	c0,0.285,0.081,0.617,0.262,0.905c4.536,7.131,8.703,16.039,8.703,26.719c0,11.274-5.979,19.992-16.137,19.992
      	c-7.38,0-15.385-7.501-15.352-17.586c0.015-5.287,2.235-11.275,7.912-17.25c1.058-1.167,1.545-2.476,1.533-3.847
      	C123.39,40.167,113.184,32.522,101.207,32.522"/>
      <rect fill="none" width="200" height="200"/>
    </g>
  )
}

export default cart_carrefour
