
import React from 'react'

const remove = () => {
  return (
    <g>
      <path d="M100,0.001C44.771,0.001,0,44.771,0,100c0,55.228,44.771,99.999,100,99.999c55.228,0,100-44.771,100-99.999
      	C200,44.771,155.228,0.001,100,0.001z M142.768,130.13c3.49,3.491,3.49,9.148,0,12.639c-1.747,1.746-4.033,2.619-6.32,2.619
      	s-4.574-0.873-6.319-2.619l-30.13-30.126l-30.126,30.126c-1.746,1.743-4.034,2.613-6.32,2.613c-2.291,0-4.578-0.87-6.324-2.613
      	c-3.487-3.491-3.487-9.152,0-12.641l30.131-30.127L57.229,69.877c-3.487-3.491-3.487-9.153,0-12.644
      	c3.491-3.488,9.152-3.488,12.644,0l30.126,30.129l30.13-30.129c3.491-3.492,9.146-3.492,12.639,0c3.49,3.491,3.49,9.147,0,12.639
      	l-30.13,30.13L142.768,130.13z"/>
    </g>
  )
}

export default remove
