
import React from 'react'

const logo_carrefour = () => {
  return (
  	<g transform="translate(-200, 50)">
      <path fill="#FFFFFF" d="M0,45.143c0,20.839,14.392,33.186,30.028,33.186c14.061,0,23.898-9.87,23.898-18.088
      	c0-4.377-2.718-7.568-6.286-7.568c-3.629,0-6.696,2.474-6.696,6.707c0,2.701,1.485,4.205,2.563,5.409
      	c-1.904,3.657-5.949,6.677-12.236,6.677c-10.426,0-17.949-10.833-17.949-26.415c0-15.609,7.19-26.516,17.609-26.516
      	c6.292,0,10.339,3.057,12.245,6.697c-1.073,1.243-2.558,2.717-2.558,5.449c0,4.216,3.057,6.684,6.689,6.684
      	c3.568,0,6.297-3.196,6.297-7.59c0-8.183-9.848-18.083-23.904-18.083C14.064,11.691,0,25.236,0,45.143 M390.217,38.662
      	c0.271,2.822,2.687,4.803,5.517,4.803c3.507,0,5.65-3.159,5.65-6.21c0-4.302-3.419-6.529-6.937-6.529
      	c-3.958,0-8.133,2.989-10.53,7.432l-0.238,0.08c-0.725-3.954-2.64-6.935-6.541-6.935c-0.965,0-1.916,0.163-3.046,0.498
      	c-3.271,0.983-8.134,3.548-6.36,8.089c4.651-0.902,6.28,0.665,6.28,4.692v19.207c0,3.821-0.474,5.424-1.841,6.54
      	c-1.189,0.948-2.556,1.343-3.347,1.509c-1.909,4.136,1.847,5.416,11.026,5.416c9.18,0,12.277-1.28,10.368-5.416
      	c-0.795-0.166-3.115-0.561-4.407-1.509c-1.253-1.116-1.795-2.719-1.795-6.54V52.217C384.004,41.87,387.739,39.163,390.217,38.662
      	 M170.049,38.662c0.254,2.822,2.652,4.803,5.466,4.803c3.56,0,5.707-3.159,5.707-6.21c0-4.302-3.476-6.529-6.952-6.529
      	c-3.966,0-8.188,2.989-10.585,7.432l-0.163,0.08c-0.747-3.954-2.65-6.935-6.62-6.935c-0.911,0-1.898,0.163-2.978,0.498
      	c-3.315,0.983-8.193,3.548-6.369,8.089c4.634-0.902,6.279,0.665,6.279,4.692v19.207c0,3.821-0.495,5.424-1.817,6.54
      	c-1.236,0.948-2.563,1.343-3.381,1.509c-1.911,4.136,1.82,5.416,10.998,5.416c9.171,0,12.327-1.28,10.416-5.416
      	c-0.816-0.166-3.139-0.561-4.376-1.509c-1.326-1.116-1.906-2.719-1.906-6.54V52.217C163.767,41.87,167.573,39.163,170.049,38.662
      	 M246.236,31.969h11.833c1.398,3.381-0.501,6.107-3.146,6.36c-0.823,0.074-1.739,0.074-2.57,0.074c-2.147,0-4.293-0.163-5.781-0.163
      	v25.558c0,3.821,0.658,5.414,1.985,6.54c1.25,0.944,4.139,1.337,4.969,1.504c1.905,4.136-2.569,5.425-11.752,5.425
      	c-9.18,0-12.979-1.289-11.081-5.425c0.824-0.167,2.153-0.561,3.379-1.504c1.332-1.125,1.825-2.718,1.825-6.54V38.082l-6.945-0.244
      	c-1.418-3.383,0.497-5.783,3.303-5.783h3.482v-3.298c0-6.699,1.235-10.411,2.891-12.885c2.573-3.978,6.944-6.687,13.815-6.687
      	c8.692,0,15.715,4.041,15.715,10.143c0,2.655-1.818,5.872-5.624,5.872c-2.811,0-5.458-2.391-5.458-5.363
      	c0-1.233,0.494-2.563,1.164-3.377c-1.005-1.17-3.066-1.737-5.054-1.737c-5.697,0-6.948,4.201-6.948,9.491V31.969z M132.419,38.662
      	c0.246,2.822,2.647,4.803,5.458,4.803c3.558,0,5.71-3.159,5.71-6.21c0-4.302-3.475-6.529-6.955-6.529
      	c-3.969,0-8.193,2.989-10.59,7.432l-0.16,0.08c-0.745-3.954-2.654-6.935-6.619-6.935c-0.912,0-1.905,0.163-2.975,0.498
      	c-3.311,0.983-8.191,3.548-6.378,8.089c4.64-0.902,6.297,0.665,6.297,4.692v19.207c0,3.821-0.501,5.424-1.832,6.54
      	c-1.231,0.948-2.557,1.343-3.386,1.509c-1.903,4.136,1.823,5.416,11.006,5.416c9.181,0,12.322-1.28,10.421-5.416
      	c-0.826-0.166-3.148-0.561-4.389-1.509c-1.327-1.116-1.898-2.719-1.898-6.54V52.217C126.132,41.87,129.94,39.163,132.419,38.662
      	 M295.454,54.954c0,10.736-4.634,16.567-11.163,16.567c-5.794,0-11.421-5.843-11.421-18.569c0-9.9,4.134-16.103,10.922-16.103
      	C290.481,36.848,295.454,43.714,295.454,54.954 M99.657,66.59c0,3.561,2.32,4.692,6.377,5.253c-0.256,4.136-3.146,5.742-6.537,6.05
      	c-3.807,0.319-7.529-1.427-9.018-5.407c-1.744,1.583-6.865,5.578-13.567,5.578c-9.178,0-16.05-6.145-16.05-14.508
      	c0-8.841,7.694-14.084,18.533-14.084c3.722,0,7.608,0.778,9.92,1.975v-5.929c0-5.289-2.312-8.669-8.349-8.669
      	c-1.242,0-3.965,0.337-6.124,1.144c0.253,0.853,0.413,1.733,0.413,2.563c0,3.248-2.066,5.86-5.534,5.86
      	c-3.062,0-5.387-2.466-5.387-5.61c0-5.771,7.289-9.977,17.376-9.977c8.02,0,12.907,1.893,15.468,5.191
      	c2.319,2.896,2.479,7.364,2.479,13.805V66.59z M307.275,54.278c0-13.971-9.675-23.459-23.075-23.459
      	c-12.4,0-23.407,9.488-23.407,23.459c0,14.139,10.342,23.615,22.999,23.615C297.193,77.892,307.275,68.258,307.275,54.278
      	 M328.206,53.352c0,10.752,1.496,16.246,8.458,16.246c7.59,0,9.671-6.761,9.671-12.927V44.932c0-4.037-1.93-5.61-6.297-5.127
      	c-1.82-4.539,4.223-7.43,7.617-8.087c1.069-0.159,2.067-0.33,2.994-0.33c4.785,0,6.622,2.985,6.622,7.444
      	c0,1.637-0.161,4.626-0.161,16.981c0,5.238-0.083,7.977-0.083,8.855c0,4.067,1.888,6.148,6.26,5.671
      	c1.794,4.544-4.201,6.687-7.617,7.321c-1.05,0.162-2.01,0.318-2.789,0.318c-4.55,0-5.478-2.94-6.394-7.161
      	c-0.639,0.782-1.227,1.427-2.464,2.613c-2.98,2.791-6.868,4.464-11.252,4.464c-4.806,0-8.857-1.834-11.414-4.852
      	c-3.976-4.703-3.976-10.757-3.976-19.607v-8.501c0-4.037-1.907-5.61-6.285-5.127c-1.811-4.539,4.217-7.43,7.614-8.087
      	c1.078-0.159,2.053-0.33,2.974-0.33c4.801,0,6.62,2.985,6.62,7.444L328.206,53.352z M211.495,48.483c1.98,0,2.076-1.156,2.076-2.702
      	c-0.096-4.633-3.731-8.932-9.279-8.932c-7.188,0-10.004,6.019-10.255,11.634H211.495z M193.96,54.177
      	c0.251,8.811,5.869,15.177,15.545,15.177c5.883,0,10.344-3.174,11.91-5.168c0.754,0.551,1.499,1.389,1.499,3.34
      	c0,4.382-5.29,10.438-17.626,10.438c-12.397,0-22.497-9.86-22.497-22.777c0-14.217,9.351-24.368,23.159-24.368
      	c12.577,0,18.371,9.233,18.371,17.1c0,2.33-0.499,4.476-1.492,6.258H193.96z M81.38,55.108c-5.209,0-9.013,2.631-9.013,7.64
      	c0,4.699,3.302,7.982,8.269,7.982c3.314,0,6.619-1.836,8.681-4.459v-6.697C89.316,57.346,86.424,55.108,81.38,55.108 M477.735,4.207
      	c0.265-0.262,0.525-0.375,0.739-0.375c0.389,0,0.66,0.351,0.648,0.776c-0.01,0.259-0.117,0.526-0.362,0.772
      	c-9.695,12.035-16.743,26.092-16.743,45.604c0,19.5,7.048,33.288,16.743,45.312c0.245,0.248,0.352,0.528,0.362,0.774
      	c0.012,0.419-0.258,0.766-0.648,0.766c-0.215,0-0.474-0.092-0.739-0.364l-41.302-39.887c-2.059-1.759-3.377-3.823-3.377-6.741
      	c0-2.94,1.318-4.993,3.377-6.744L477.735,4.207 M509.691,0c9.709,0,17.983,5.665,18.002,10.343c0.01,1.016-0.386,1.986-1.243,2.851
      	c-4.603,4.428-6.402,8.864-6.414,12.782c-0.027,7.473,6.462,13.031,12.445,13.031c8.234,0,13.081-6.46,13.081-14.814
      	c0-7.914-3.378-14.514-7.055-19.799c-0.146-0.213-0.212-0.459-0.212-0.67c0.004-0.417,0.24-0.752,0.612-0.752
      	c0.226,0,0.484,0.114,0.769,0.402l41.31,39.895c2.069,1.743,3.387,3.795,3.387,6.741c0,2.914-1.32,4.98-3.387,6.741L539.68,96.628
      	c-0.291,0.288-0.543,0.404-0.769,0.404c-0.366,0-0.613-0.344-0.613-0.754c0-0.207,0.065-0.457,0.208-0.668
      	c3.677-5.287,7.061-11.885,7.061-19.807c0-8.356-4.852-14.814-13.086-14.814c-5.982,0-12.472,5.564-12.435,13.04
      	c0.003,3.911,1.802,8.337,6.404,12.784c0.861,0.852,1.253,1.83,1.248,2.851C527.674,94.325,519.4,100,509.691,100
      	c-16.165,0-22.488-22.582-22.488-49.852C487.203,22.869,493.526,0,509.691,0"/>
  	</g>
  )
}

export default logo_carrefour
