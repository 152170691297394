
import React from 'react'

const ok = () => {
  return (
    <g>
      <path d="M166.035,54.121c4.854,4.854,4.854,12.729,0,17.578l-79.816,79.816L34.68,99.984
       c-4.855-4.855-4.855-12.73,0-17.578c4.854-4.859,12.73-4.859,17.584,0l33.955,33.953l62.236-62.238
       C153.305,49.266,161.18,49.266,166.035,54.121z"/>
    </g>
  )
}

export default ok
