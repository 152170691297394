import React from 'react'

const allergen_2 = () => {
  return (
        <g>
            <path d="M100,0C44.772,0,0,44.772,0,100c0,55.229,44.772,100,100,100c55.23,0,100-44.771,100-100
                C200,44.772,155.23,0,100,0z M70.254,95.006c1.159-3.977,2.647-7.839,4.245-11.662c1.104-2.65,2.505-5.1,4.814-6.946
                c2.011-1.609,2.967-3.828,3.567-6.241c0.629-2.522,1.369-5.003,2.634-7.31c1.585-2.9,3.903-4.747,7.29-5.159
                c0.405-0.052,0.799-0.218,1.204-0.22c1.62-0.027,3.638-1.483,4.62-0.512c0.959,0.952-0.617,2.763-1.075,4.194
                c-0.47,1.454,0.136,1.442,1.333,1.174c0.967-0.212,2.232-1.155,2.933-0.131c0.617,0.892-0.425,1.963-0.909,2.871
                c-2.534,4.754-6.457,8.364-10.35,11.956c-1.524,1.412-2.347,2.785-1.698,4.9c0.442,1.454-0.22,2.886-0.768,4.232
                c-1.341,3.314-1.956,6.698-1.172,10.232c0.355,1.583-0.494,2.203-1.84,2.581c-3.496,0.995-7.07,1.396-10.695,1.454
                C68.64,100.446,68.669,100.455,70.254,95.006z M70.593,119.413c-1.513-3.657-2.347-7.416-2.444-11.351
                c0-0.488-0.019-0.978,0.007-1.465c0.174-3.229,0.384-3.302,3.606-3.167c4.697,0.195,9.365-0.299,13.911-1.491
                c1.551-0.404,2.352-0.094,2.681,1.437c0.052,0.235,0.095,0.507,0.241,0.686c3.051,3.593,0.426,5.872-1.969,8.143
                c-3.774,3.567-8.061,6.391-12.833,8.503C72.156,121.435,71.284,121.075,70.593,119.413z M86.025,137.399
                c-5.117-2.457-9.136-5.94-11.775-10.992c-0.812-1.538-0.923-2.29,0.938-3.084c6.589-2.793,12.248-6.9,16.722-12.49
                c0.749-0.942,1.404-1.099,2.505-0.598c1.782,0.808,3.633,1.503,5.507,2.115c1.493,0.489,2.147,1.38,1.992,2.925
                c-0.179,0.952-0.261,1.934-0.533,2.86C99.219,125.44,95.655,131.9,89.8,137C88.583,138.058,87.51,138.109,86.025,137.399z
                 M115.465,133.404c-0.092,0.639-0.216,1.278-0.395,1.901c-2.081,7.285-1.117,7.614-11.217,8.051
                c-2.921,0.2-6.494-0.967-10.032-2.324c-2.57-0.98-2.586-1.052-0.678-2.923c4.8-4.711,8.101-10.353,10.444-16.572
                c0.638-1.671,1.041-3.408,1.099-5.21c0.05-1.562,0.663-2.197,2.339-1.631c2.01,0.682,3.985,1.48,6.173,1.581
                c1.454,0.071,1.659,1.411,2.001,2.455C116.785,123.59,116.187,128.5,115.465,133.404z M118.75,140.099
                c-1.267,0.594-1.885,0.346-1.443-1.215c0.946-3.365,1.438-6.792,1.742-10.281c0.371-4.194,0.026-8.258-1.492-12.176
                c-0.533-1.398-0.216-2.205,0.938-3.081c4.505-3.411,8.256-2.779,11.307,1.97c1.406,2.205,1.818,4.656,2.055,7.059
                C131.561,131.037,126.072,136.713,118.75,140.099z"/>
        </g>
  )
}

export default allergen_2