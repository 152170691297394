
import React from 'react'

const arrow = () => {
  return (
    <g>
     <path fillRule="evenodd" clipRule="evenodd" d="M177.247,98.495c-0.969-6.627-5.799-9.988-14.361-10
       c-37.359-0.053-99.691,0-99.691,0l5.771-5.748c10.479-10.428,22.949-22.941,37.072-37.195c6.449-6.518,7.363-14.004,2.312-19.08
       c-2.502-2.512-5.211-3.785-8.057-3.785c-3.486,0-7.297,1.941-11.025,5.615c-20.217,19.92-40.76,40.459-61.067,61.051
       c-3.701,3.75-5.534,7.195-5.523,10.691c-0.011,3.494,1.822,6.941,5.523,10.689c20.308,20.594,40.851,41.133,61.067,61.059
       c3.729,3.668,7.539,5.609,11.025,5.609c2.846,0,5.555-1.273,8.057-3.785c5.051-5.072,4.137-12.564-2.312-19.08
       c-14.123-14.254-26.594-26.77-37.072-37.195l-5.771-5.75c0,0,62.332,0.055,99.691,0c8.562-0.004,13.393-3.371,14.361-10
       c0.074-0.533,0.064-1.033,0.079-1.546C177.312,99.532,177.321,99.03,177.247,98.495z"/>
    </g>
  )
}

export default arrow
