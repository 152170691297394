
import React from 'react'

const arrow_back = () => {
  return (
    <g>
     <path fillRule="evenodd" clipRule="evenodd" d="M22.756,102.496c0.961,6.635,5.793,10,14.359,10.006
       c37.363,0.055,99.693,0,99.693,0l-5.777,5.744c-10.477,10.426-22.951,22.945-37.07,37.201c-6.451,6.516-7.357,14.002-2.316,19.078
       c2.51,2.514,5.216,3.787,8.064,3.787c3.484,0,7.293-1.943,11.02-5.615c20.215-19.92,40.76-40.459,61.074-61.051
       c3.695-3.75,5.533-7.197,5.522-10.691c0.011-3.496-1.827-6.941-5.522-10.691c-20.314-20.594-40.859-41.133-61.074-61.051
       c-3.727-3.674-7.535-5.621-11.02-5.621c-2.849,0-5.555,1.279-8.064,3.793c-5.041,5.068-4.135,12.555,2.316,19.076
       c14.119,14.256,26.594,26.77,37.07,37.197l5.777,5.748c0,0-62.33-0.055-99.693,0c-8.566,0.006-13.398,3.373-14.359,10
       c-0.079,0.535-0.068,1.037-0.079,1.549C22.688,101.467,22.677,101.969,22.756,102.496z"/>
    </g>
  )
}

export default arrow_back
