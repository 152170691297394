import React from 'react'

const welfare_10 = () => {
  return (
    <g>
        <path d="M100,0C44.769,0,0,44.767,0,99.997C0,155.23,44.769,200,100,200c55.23,0,100-44.77,100-100.003
        C200,44.767,155.23,0,100,0z M89.629,111.11v-7.23c4.26-2.803,7.342-8.465,7.342-13.283c0-6.445-4.819-11.099-11.377-11.099
        c-6.559,0-11.323,4.653-11.323,11.099c0,4.818,3.083,10.48,7.342,13.338v7.174H61.995v-7.791h9.867
        c-3.757-3.138-6.445-8.742-6.445-13.563c0-10.594,8.519-18.385,20.177-18.385s20.177,7.791,20.177,18.385
        c0,4.764-2.635,10.425-6.39,13.563h9.808v7.791H89.629z M122.982,128.63c-5.381,0-11.042-1.906-14.629-4.764l4.036-7.567
        c2.913,3.138,6.276,4.764,9.808,4.764c3.923,0,6.055-1.904,6.055-5.101c0-3.307-2.188-4.932-6.111-4.932h-6.278v-5.326l8.745-9.08
        h-14.294v-7.622h25.897v5.661l-9.809,10.202l1.85,0.278c5.716,0.898,9.751,4.653,9.751,10.706
        C138.003,123.361,132.119,128.63,122.982,128.63z"/>
    </g>
  )
}

export default welfare_10