import React, { Component } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { IconButton } from '../'

/**
 * @class: TutorialUser
 */

class TutorialUser extends Component {

  static propTypes = {
    isOpen: React.PropTypes.bool,
    toogle: React.PropTypes.func,
    transitionName: React.PropTypes.string
  }

  static defaultProps = {
    isOpen: false
  }

  constructor (...args) {
    super(...args)
  }

  render () {
    return (
      <ReactCSSTransitionGroup transitionName={ this.props.transitionName }
                               transitionEnterTimeout={ 500 }
                               transitionLeaveTimeout={ 300 }>
        { (this.props.isOpen) ? this.renderContent() : null }
      </ReactCSSTransitionGroup>
    )
  }


  renderContent () {
    const { customClassName } = this.props
    return (
      <div className={ `tutorial-wrapper modal-wrapper ${ customClassName }` } ref="modal" role="dialog">
        <IconButton iconType="close"
                    iconTitle="ALT_BOTON_CERRAR_AQUI"
                    iconFill="white"
                    className="btn-close"
                    onClick={ this.props.toogle }/>
        <div className="tutorial">
          <div className="bg-user"></div>
        </div>
        <div className="bg-close" onClick={ this.props.toogle }></div>
      </div>
    )
  }
}


export default TutorialUser
