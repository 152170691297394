
import config from '../../config'

/**
 * @func: getProductNutritionalInfo
 * @return {[Object]} data
 *
 * @description:
 */

const getProductNutritionalInfo = (id, lan) => {
  const params = { id_product_eva: id, language: lan }
  return config.API.productsNutritionalInfoGet(params, {}, {})
  .then(result => result.data)
  .catch(error => error)
}


export default getProductNutritionalInfo
