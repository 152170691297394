
/**
 * expires (GMT)
 */

 var d = new Date();
 d.setTime(d.getTime() + 86400*60*1000);

const setCookie = (key, value, expires) => document.cookie = `${ key }=${ value }; expires=${ expires || 'expires='+d.toGMTString() }`

export default setCookie