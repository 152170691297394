
import React from 'react'

const out = () => {
  return (
    <g>
      <path d="M112.154,190.312c0,4.703-3.815,8.523-8.523,8.523H8.517c-4.696,0-8.517-3.82-8.517-8.523V8.521
       C0,3.814,3.82,0,8.517,0h95.114c4.708,0,8.523,3.814,8.523,8.521c0,4.705-3.815,8.518-8.523,8.518H17.045v164.752h86.586
       C108.339,181.791,112.154,185.604,112.154,190.312z M198.822,99.115c0-0.053,0.016-0.107,0.016-0.172
       c0-3.053-1.631-5.719-4.059-7.223l-44.391-44.488c-3.325-3.328-8.715-3.338-12.053-0.016c-3.328,3.328-3.334,8.717-0.012,12.049
       l31.09,31.16H51.254c-4.711,0-8.527,3.814-8.527,8.518c0,4.713,3.816,8.527,8.527,8.527h118.877l-31.09,31.154
       c-3.328,3.332-3.324,8.732,0.01,12.055c1.664,1.656,3.836,2.492,6.021,2.492c2.178,0,4.361-0.836,6.029-2.502l45.248-45.346
       C198.055,103.615,198.869,101.359,198.822,99.115z"/>
    </g>
  )
}

export default out
