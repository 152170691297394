
import config from '../../config'

/**
 * @func: getLayouts
 * @return {[Object]} data
 *
 * @description:
 *
 * TODO: en lugar de un String podría ser un Array
 *
 * NOTE: para obtener los layouts necesitamos pasar a la API un listado tipo
 * String con los ids separados por comas.
 *
 * Para ello, primero debemos obtener los ids de las categorias:
 *
 * const getLayoutIds = (categories) => {
 *  const layoutsArray = categories.map(cat => {
 *    if (cat.id_layout !== null) return cat.id_layout
 *  })
 *  return layoutsArray.filter((item, pos) => layoutsArray.indexOf(item) === pos })
 *
 *
 * Y luego componer el jodido String:
 *
 * // SI el primer caracter es una coma lo borro
 * let ids = globals.layout_ids.toString()
 * if (ids.charAt(0) === ',' || ids.charAt(0) === '%2C') {
 *  globals.layout_ids = ids.substring(1)
 * }
 *
 */

const getLayouts = (ids, idprovider) => {
  const params = { id_layouts: ids, id_provider: idprovider }
  return config.API.layoutsGet(params, {}, {})
  .then(result => result.data)
  .catch(error => error)
}


export default getLayouts
