
import React from 'react'

const logo_mercadona = () => {
  return (
  	<g transform="scale(0.65) translate(-300, 110)">
      <g id="_x23_0d8142ff_6_" transform="matrix(0.12638954,0,0,0.12638954,-2.6813827,-5.9129015)">
    		<path id="path14_6_" fill="#FFFFFF" d="M2535.624,51.75c101.729-16.285,209.527,7.717,293.044,68.429
    			c49.237,35.404,98.466,79.979,112.739,141.433c14.833,60.523-25.041,126.83-84.523,144.678
    			c-36.525,11.07-79.406,5.817-109.533-18.672c-26.684-24.299-46.717-56.835-79.534-73.642
    			c-61.417-32.718-144.769-16.88-187.091,39.132c-34.039,41.888-40.396,103.309-17.551,151.871
    			c23.183,51.617,78.785,87.209,135.658,84.301c47.593,1.231,90.1-26.607,120.117-61.566c25.006-29.517,61.047-52.179,101.04-50.312
    			c75.245-1.528,142.474,78.187,120.042,151.946c-22.289,71.888-85.271,121.944-148.144,158.355
    			c-94.069,52.844-209.56,64.547-312.535,32.611c-89.673-27.355-168.942-87.396-219.106-166.444
    			c-62.35-95.965-77.535-219.809-43.267-328.561C2260.061,183.685,2389.141,73.48,2535.624,51.75z"/>
    		<path id="path16_6_" fill="#FFFFFF" d="M4904.208,51.75c79.941-11.887,163.795-2.195,236.936,33.322
    			c95.837,44.949,172.707,129.766,205.965,230.469c32.014,94.626,26.314,201.663-16.396,292.036
    			c-45.503,98.988-133.646,176.989-237.143,210.833c-93.397,30.486-198.158,25.303-287.718-15.13
    			c-92.013-40.776-166.477-118.259-204.155-211.352c-39.433-96.002-40.587-207.777-0.337-303.742
    			C4652.141,163.818,4771.309,71.541,4904.208,51.75 M4931.823,301.788c-57.32,14.388-103.347,66.08-108.899,125.117
    			c-8.313,71.329,42.432,143.775,112.479,159.841c55.175,14.87,117.229-8.276,151.347-53.553
    			c31.38-39.583,39.504-95.522,21.505-142.554C5082.873,321.955,5002.15,282.109,4931.823,301.788z"/>
    		<path id="path18_6_" fill="#FFFFFF" d="M110.954,167.699c8.556-52.368,48.861-103.8,104.95-107.187
    			c56.164-7.937,113.673,24.559,137.413,75.58c38.683,84.932,76.234,170.501,115.011,255.328
    			c42.001-89.221,83.796-178.48,126.303-267.439c31.12-63.955,120.528-83.336,178.143-44.092
    			c31.27,18.562,48.936,53.11,54.044,88.217c30.112,169.46,60.076,339.071,90.038,508.606c8.873,44.235-7.303,93.396-43.421,121.31
    			c-54.147,44.389-145.902,32.051-184.475-26.983c-14.945-21.838-17.481-48.599-22.811-73.792
    			c-7.303-35.404-13.153-71.106-22.137-106.218c-16.658,44.949-32.327,90.042-49.328,134.879
    			c-12.204,32.014-31.734,62.722-61.474,81.174c-36.818,21.871-84.954,23.256-123.32,4.692
    			c-32.144-15.616-51.915-47.441-64.812-79.382c-18.558-46.849-34.475-94.622-53.11-141.362
    			c-8.551,42.119-13.824,84.789-22.375,126.9c-6.149,28.661-19.665,56.503-41.798,76.218
    			c-34.213,32.833-88.517,38.575-131.226,20.46c-31.116-12.893-55.382-40.063-66.408-71.593
    			c-12.116-32.351-10.343-68.019-3.987-101.559C54.645,483.583,82.148,325.567,110.954,167.699z"/>
    		<path id="path20_6_" fill="#FFFFFF" d="M3268.178,130.54c19.792-41.184,63.024-72.117,109.63-70.551
    			c50.222-3.95,98.501,27.95,119.353,72.861c80.333,170.541,160.18,341.383,240.418,511.849
    			c19.566,39.729,16.847,90.005-9.128,126.158c-34.513,50.869-109.122,69.578-163.141,39.541
    			c-33.938-20.052-51.824-56.95-68.576-90.974c-76.289,0.561-152.527,0-228.814,0.297c-15.389,33.764-33.691,69.358-66.786,88.994
    			c-54.673,32.274-133.324,14.575-166.906-39.946c-25.583-39.169-25.213-90.606-5.086-132.045
    			C3108.655,467.857,3188.653,299.22,3268.178,130.54 M3343.148,541.244c25.548,0,51.058-0.04,76.568,0.108
    			c-12.708-29.777-25.008-59.816-38.833-89.069C3368.1,481.912,3355.687,511.575,3343.148,541.244z"/>
    		<path id="path22_6_" fill="#FFFFFF" d="M5456.012,118.017c37.954-67.72,147.582-80.798,196.667-18.709
    			c69.391,83.484,136.884,168.528,206.313,251.935c1.087-58.139-0.555-116.389,0.826-174.675
    			c0.521-36.525,20.068-71.036,49.116-92.691c35.746-27.166,87.101-31.825,127.534-12.558
    			c44.129,20.834,71.555,68.576,70.624,116.988c0.524,163.87,0.112,327.777,0.249,491.684c0.053,36.188-4.978,75.023-30.545,102.86
    			c-39.766,46.886-117.565,56.426-166.032,17.483c-23.78-19.418-40.567-45.616-59.746-69.173
    			c-54.11-69.541-108.561-138.747-162.639-208.217c-1.453,62.61,1.266,125.297-1.453,188.019
    			c-2.607,49.753-39.651,94.1-87.117,108.155c-54.766,17.661-120.656-8.166-146.875-59.671
    			c-14.558-26.27-14.888-56.983-15.41-86.163c0-149.04,0.071-297.925-0.112-446.929
    			C5436.869,189.762,5436.07,150.366,5456.012,118.017L5456.012,118.017z"/>
    		<path id="path24_6_" fill="#FFFFFF" d="M1023.745,85.072c37.939-24.262,85.12-20.502,128.164-20.946
    			c62.929,0.26,125.821-0.297,188.806,0.227c39.321,0.667,82.066,9.687,108.64,41.065c41.481,45.1,35.07,122.059-9.766,162.494
    			c-24.394,24.077-60.111,29.925-93.004,30.561c-43.269,0.295-86.5-0.112-129.751,0.035c0,16.922,0,33.804,0,50.726
    			c54.118,4.247,114.452-12.334,163.33,18.26c54.913,35.893,43.791,131.451-17.386,154.779
    			c-47.293,13.716-97.458,6.594-145.944,8.423c-0.095,18.593,0,37.34,0.075,55.939c49.603,0.295,99.395-0.859,149.035,0.412
    			c43.59,1.301,85.014,29.625,100.815,70.36c23.63,53.07,0.26,123.173-52.291,149.004c-24.892,12.818-53.59,11.814-80.685,11.814
    			c-80.012-0.524-160.142,0.484-240.068-0.414c-36.034-0.148-73.363-13.527-96.392-42.003
    			c-25.121-29.999-30.154-70.956-29.63-108.71c0.337-157.76,0.053-315.708,0.11-473.538
    			C967.092,151.26,986.718,107.207,1023.745,85.072L1023.745,85.072z"/>
    		<path id="path26_6_" fill="#FFFFFF" d="M1640.858,72.025c45.785-11.777,93.486-6.891,140.26-7.899
    			c41.369-0.784,82.702,0.445,123.697,5.59c80.316,11.407,153.958,61.866,193.684,132.452
    			c37.963,65.558,46.197,147.509,22.327,219.402c-13.379,40.29-38.874,75.098-66.49,106.629
    			c21.73,37.117,46.886,72.337,67.346,110.091c22.769,47.144,19.865,110.13-18.747,148.216
    			c-48.075,52.738-144.938,51.285-186.864-8.382c-38.427-59.376-72.639-121.312-110.687-180.871
    			c-1.453,38.427,0.218,77-0.819,115.425c-1.962,45.244-33.172,87.132-75.25,103.382c-49.493,19.678-111.026,4.104-144.141-37.604
    			c-23.269-29.517-28.524-68.535-28.472-105.064c0.17-142.549,0.095-285.107,0.095-427.619c0.15-28.064-1.416-56.313,3.564-84.043
    			C1567.698,119.994,1600.813,84.7,1640.858,72.025 M1804.766,296.42c0.059,27.17,0,54.451,0.132,81.621
    			c21.316-0.634,43.68,0.407,64.03-7.085c24.874-10.581,27.313-47.738,6.631-63.502
    			C1854.914,292.992,1828.189,298.767,1804.766,296.42z"/>
    		<path id="path28_6_" fill="#FFFFFF" d="M3936.154,65.094c84.226-0.26,169.01-5.555,252.604,6.002
    			c103.886,15.059,199.296,77.37,255.42,165.735c61.142,94.21,75.694,216.229,40.308,322.559
    			c-34.808,107.259-120.097,196.705-226.447,235.053c-80.014,30.603-167.073,22.512-250.817,23.782
    			c-34.046-0.709-68.367,2.087-101.966-3.432c-50.968-10.396-94.924-55.977-94.853-109.344c-0.557-174.832,0-349.655-0.33-524.486
    			c-0.671-36.039,18.26-71.443,47.833-91.793C3880.66,73.073,3908.946,67.816,3936.154,65.094 M4059.137,297.391
    			c0.167,96.451-0.075,192.865,0.167,289.354c46.569-1.451,99.639,3.243,138.604-27.69c35.646-28.099,58.545-73.235,56.256-118.998
    			c1.491-56.947-37.007-111.808-90.133-131.818C4130.653,295.119,4094.282,297.803,4059.137,297.391z"/>
    		<path id="path30_6_" fill="#FFFFFF" d="M6468.883,85.669c31.138-26.235,75.448-31.045,113.945-21.433
    			c35.631,9.916,64.7,37.309,80.056,70.476c80.069,170.428,159.883,340.934,240.163,511.217
    			c18.223,37.754,16.394,84.263-6.521,119.742c-32.223,54.187-108.717,75.208-164.396,45.657
    			c-34.548-20.017-54.094-56.685-70.325-91.645c-76.513,0.037-152.879,0-229.315,0c-17.891,42.225-45.246,87.398-92.574,99.956
    			c-59.057,18.445-128.915-12.558-151.389-70.923c-17.367-41.221-8.351-88.213,12.003-126.605
    			c69.206-145.493,137.593-291.071,206.502-436.639C6424.738,150.665,6436.272,110.116,6468.883,85.669 M6507.825,541.167
    			c25.4,0.077,50.893,0.077,76.421,0.037c-12.593-29.964-25.083-59.858-38.425-89.333
    			C6533.098,481.648,6520.333,511.277,6507.825,541.167z"/>
    	</g>
  	</g>
  )
}


// fill="#A5C73B" verde
// fill="#F4AB1E" amarillo
// fill="#E54B25" naranja
// fill="#96194B" granate
export default logo_mercadona
