import React from 'react'

const welfare_15 = () => {
  return (
        <g>
        <path d="M100.001-0.001C44.77-0.001,0,44.77,0,100c0,55.232,44.77,100.001,100.001,100.001
            C155.231,200.001,200,155.232,200,100C200,44.77,155.231-0.001,100.001-0.001z M142.922,69.262
            c-4.629,16.377-16.058,26.278-32.885,28.27c-1.253,0.148-2.585,0.146-3.812-0.106c-1.839-0.378-2.231,0.379-2.217,2.01
            c0.056,5.518,0.021,11.037,0.02,16.555c0,5.358-0.085,10.721,0.042,16.075c0.052,2.222-0.547,3.158-2.936,3.096
            c-5.977-0.155-5.089,0.368-5.113-5.179c-0.042-10.078-0.053-20.155,0.021-30.232c0.016-2.043-0.515-2.627-2.633-2.271
            c-3.285,0.553-6.513-0.301-9.686-1.148c-14.37-3.831-22.792-13.418-26.702-27.456c-0.57-2.039,0.342-2.507,1.982-2.874
            c16.086-3.612,29.143,1.182,39.281,14.1c1.772,2.258,1.701,2.261,3.419,0.062c10.111-12.94,23.155-17.744,39.255-14.2
            C142.991,66.412,143.508,67.192,142.922,69.262z"/>
        </g>
  )
}

export default welfare_15