
import React from 'react'

const user = () => {
  return (
    <g>
      <path d="M100.002,88.174c20.988,0,38.061-17.078,38.061-38.064s-17.072-38.062-38.061-38.062
        c-20.986,0-38.062,17.076-38.062,38.062S79.016,88.174,100.002,88.174z"/>
      <path d="M100.002,108.221c-44.299,0-80.34,23.838-80.34,53.137v24.875c0,1.412,1.15,2.557,2.561,2.557h155.557
        c1.414,0,2.559-1.145,2.559-2.557v-24.875C180.338,132.059,144.295,108.221,100.002,108.221z"/>
    </g>
  )
}

export default user
