
import config from '../../config'
import checkApiResponse from './checkApiResponse'

/**
 * @func: postEmailSubscription
 * @return {[Object]} data
 *
 * @description:
 */

const postEmailSubscription = (mail, cp, id) => {
  const params = { email: mail, postal_code: cp, id_provider: id }
  return config.API.emailSubscriptionPost({}, params, {})
  .then(result => result.data)
  .catch(error => checkApiResponse(error))
}


export default postEmailSubscription
