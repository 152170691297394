
import React from 'react'
import { capitalize } from '../../utils'

const TicketProduct = (props) => <tr key={ props.product.product.id_product_eva }>
  <td>{ props.product.units }</td>
  <td className="product-presentation-name">{ capitalize(props.product.product.description) }</td>
  <td>{ (props.product.product.price).toFixed(2) + ' €' }</td>
  <td>{ (parseFloat(props.product.price) * parseInt(props.product.units)).toFixed(2) + ' €' }</td>
</tr>

export default TicketProduct
