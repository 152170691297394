
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import CartProduct from '../Products/CartProduct'
import { ShopStore } from '../../stores'

@observer
class ShoppingCartGrid extends Component {
  render() {
    const render = ShopStore.shoppingCartForceUpdate
    const products = Array.from(this.props.products).map((product, i) => <CartProduct onClick={() => ShopStore.add(product.product.id_product_eva, 1)}
      product={product}
      state={product.status || null}
      key={i}
      layout={null} />)
    return <ul className="grid">
      {products}
    </ul>
  }
}

export default ShoppingCartGrid
