
import config from '../../config'

/**
 * @func: externalLoginApartments
 * @return {[Object]} data
 *
 * @description: Loguea un usuario para apartamentos
 */

const externalLoginApartments = (id, type) => {
  const params = { id, type }
  return config.API.externalLoginPost({}, params, {})
  .then(result => result.data)
  .catch(error => error)
}

export default externalLoginApartments
