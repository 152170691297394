
import React, { Component } from 'react'

const Shelf = (props) => (
  <div className="rack">
    <span className="left" style={ props.left }></span>
    <span className="center" style={ props.center }></span>
    <span className="right" style={ props.right }></span>
    <ul className="prods">{ props.products }</ul>
  </div>
)

export default Shelf
