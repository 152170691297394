
import React, { Component } from 'react'
import { browserHistory } from 'react-router'
import config from '../config'
import { AppStore, ShopStore, UIStore } from '../stores'
import { capitalize } from '../utils'
import { getMasCartInformation } from '../utils/api'
import { Icon, LinkButton, Button } from '../components'
import ProductImage from '../components/Products/components/ProductImage'
import FinisOrderMas from '../components/Dialogs/Modal/modals/FinisOrderMas'
import { Notification } from '../components/Dialogs/Notification/components'


class OrderConflictsReview extends Component {

  render () {
    const conflicts = ShopStore.orderConflicts.map(conflict => {
      return (
        <li key={ conflict.image }>
          <div className="img-wrapper">
            <ProductImage src={ conflict.image }
                          description={ conflict.description || '' }
                          brand={ conflict.brand || '' } />
          </div>
          <div className="content-wrapper">
            <h3 className="h3 regular black s16">{ conflict.description }</h3>
            <br/>
            <p className="p regular light-grey s16 conflict-description"><span className="p regular red s16">{ conflict.conflictTitle }</span> { conflict.conflictText }</p>
          </div>
        </li>
      )
    })

   
    const name = (AppStore.providername) ? capitalize(AppStore.providername) : ''
    var link = null
    var ButtonName = `Ir a ${ name }`
    var step01 = <p className="p light black s16"><span className="p regular black s16">Inicia sesión</span> en la web del supermercado para que aparezca tu carrito.</p>
    var step02 = <p className="p light black s16">Ve a <span className="p regular black s16">"Mi Cesta"</span>, revisa el pedido y pulsa en finalizar.</p>
    var step03 = <p className="p light black s16">rellena el formulario de entrega y pulsa en <span className="p regular black s16">finalizar</span>.</p>
    /* 
    ** Check if is MAS to Open form to finish order
    */
    if (AppStore.idprovider == 6){
      ButtonName = "Tramitar"
      step01 = <p className="p light black s16"><span className="p regular black s16">Finaliza la compra</span> pulsando en el botón de tramitar</p>
      step02 = <p className="p light black s16"><span className="p regular black s16">Revisa el total de la compra</span> y rellena los datos de entrega</p>
      step03 = <p className="p light black s16"><span className="p regular black s16">Para pagar</span> elige el método de pago y confirma el pedido</p>
    }else if (AppStore.idprovider == 5){
      ButtonName = "Tramitar"
      step01 = <p className="p light black s16"><span className="p regular black s16">Finaliza la compra</span> pulsando en el botón de tramitar</p>
      step02 = <p className="p light black s16"><span className="p regular black s16">Revisa el total de la compra</span> y pulse en tramitar pedido</p>
      step03 = <p className="p light black s16"><span className="p regular black s16">Para pagar</span> rellene los datos de entrga, elige el método de pago y confirma el pedido</p>
    }
      
    return (
      <div className="section">
        <div className="conflict-review">
          <header className="header container-full">
            <div className="container">
              <div className="icon-wrapper">
                <Icon type="warning" fill="white" size="40" className="i" />
              </div>
              <div className="box-md-12">
                <h1 className="h1 bold white s36">¡Oh, oh!</h1>
                <h1 className="h1 bold white s36">Parece que alguno de los artículos de tu carrito no se han sincronizado...</h1>
                <p className="p light white s21">Revisa tu pedido antes de validar la orden de compra</p>
              </div>
            </div>
          </header>
          <section className="container">
            <div className="box-md-12">
              <h2 className="h2 light blue s21 title">Errores de sincronización</h2>
              <ul className="conflicts">
                { conflicts }
              </ul>
              <h2 className="h2 light blue s21 title">Instrucciones para completar la compra</h2>
            </div>
            <div className="box-md-4">
              <Icon type="user" fill="grey" size="100" />
              {step01}
            </div>
            <div className="box-md-4">
              <Icon type="cart" fill="grey" size="100" />
              {step02}
            </div>
            <div className="box-md-4">
              <Icon type="credit_card" fill="grey" size="100" />
              {step03}
            </div>
            <div className="btn-wrapper">

              <Button onClick={ this.send } type="outline" placeholder={ ButtonName } color="blue" />
            </div>
          </section>
        </div>
      </div>
    )
  }

send = (event) => {
  if (AppStore.idprovider == 6) {
    UIStore.showLoading()
    getMasCartInformation().then(data => {

        AppStore.CatInformationMas = data.data
        if (data.code == 0){
          UIStore.showNotification({ type: 'danger', content: () => <Notification message={ `Se ha producido un error conectando con ${ capitalize(AppStore.providername) }.` } /> })
        }else{
            if (parseInt(data.data.prices.total) == 0){
                UIStore.showNotification({ type: 'danger', content: () => <Notification message={ `En este momento los productos seleccionados no están disponibles en ${ capitalize(AppStore.providername) }.` } /> })
            }else {
              browserHistory.push(`/${ AppStore.countryname }/${ AppStore.postalcode }/${ AppStore.providername }/Tramitar`);
              UIStore.hideLoading()

            }
        }
        UIStore.hideLoading()
    })
  } else if (AppStore.idprovider == 5) {
    browserHistory.push(`/${ AppStore.countryname }/${ AppStore.postalcode }/${ AppStore.providername }/Tramitar`);
    UIStore.hideLoading()

    } else{

        const link = config.AVAILABLE_PROVIDERS.filter(provider => provider.id === AppStore.idprovider)[0].URL || 'https://market.ilusia.com'
        window.open(link, "_blank")
    }

  }

}

export default OrderConflictsReview
