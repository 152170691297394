import React, { Component } from 'react'
import { browserHistory } from 'react-router'
import { translate } from 'react-i18next'
import { AppStore, UIStore } from '../stores'
import config from '../config'
import { postEmailSubscription } from '../utils/api'
import { Dropdown, Button, Icon, MultiSelect, } from '../components'
import { Notification } from '../components/Dialogs/Notification/components'
import  MultiSelectReact  from 'multi-select-react';
 

@translate(['common'], { wait: false })
class PotentialProvidersSelection extends Component {

  constructor (...args) {
    super(...args)
    
    this.selected = null

    this.state = {
      multiSelect: [{ value: 'carrefour', label: 'Carrefour' },
          {value: 'dia', label: 'DIA' },
          {value: 'mercadona', label: 'Mercadona'},
          {value: 'eroski', label: 'Eroski'},
          {value: 'mas', label: 'Mas' },
          {value: 'auchan', label: 'Auchan' },
          {value: 'lild', label: 'Lidl' },
          {value: 'hipercor', label: 'Hipercor' },
          {value: 'consum', label: 'Consum' },
          {value: 'aldi', label: 'ALDI' }
        ],
    };
  }

  render () {
    const providers = config.AVAILABLE_PROVIDERS.map(provider => {
        const obj = {
          text: provider.title,
          active: false,
          action: () => this.setProvider(provider.id),
        }
        return obj
      })

      const selectedOptionsStyles = {
        color: "#3c763d",
        backgroundColor: "#dff0d8",
        width: "320px",
    };
    const optionsListStyles = {
        backgroundColor: "#dff0d8",
        color: "#3c763d",
        width: "320px",
    };
    


    return (
      <div className="section">
        <div className="container not-providers">
          <div className="box-md-7">
            <h1 className="h1 light white s32">¿Te gustaría comprar <br/> en otras tiendas?</h1>
            <p className="p light white s20"><br/> Indícanos en que super te gustaría hacer la <br/> compra y déjanos tu correo electrónico si deseas <br/> que te avisemos cuando esté disponible en tu <br/> zona.</p>
            
            {/* { <Dropdown options={ providers }
                      content="list"
                      type=""
                      buttonColor="white"
                      placeholder="Selecciona supermercado"
                      align="left"
                      expandOnHover={ false }
                      collapseOnLeave={ false }
                      setSelectedAsPlaceholder={ true }
                      transitionName="modal-anim" /> } */}

                      <MultiSelect
                          options={this.state.multiSelect}
                          optionClicked={this.optionClicked.bind(this)}
                          selectedBadgeClicked={this.selectedBadgeClicked.bind(this)}
                          selectedOptionsStyles={selectedOptionsStyles}
                          optionsListStyles={optionsListStyles}
                          isTextWrap={true} />

          </div>
          
          <div className="">
          <img className="bg-img" src="/img/img_noprovider.png" alt=""/>
          <div className="form-market">
          <form onSubmit={ this.send }>
              <Icon type="mail" title="icono email" fill="white" size="20px" />
              <input ref="email" className="input underline" size="150" id="email" type="text" placeholder="Introduce tu email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" />
              <button type="submit"></button>
              <Button onClick={ this.send } align="btn-right" type="normal" placeholder="Enviar"/>
            </form>
            </div>
            </div>
        </div>
      </div>
    )
  }
// clase que tenia asignada antes la img "bg-img"

// optionClicked(optionsList) {
//   this.setState({ multiSelect: optionsList });
// }
// selectedBadgeClicked(optionsList) {
//   this.setState({ multiSelect: optionsList });
// }


setProvider = (id) => this.selected = id


  send = (event) => {
    event.preventDefault()

    const email = document.querySelector('form input#email').value

    if (!this.selected) {
      UIStore.showNotification({
        type: 'danger',
        content: () => <Notification message="No has seleccionado ningún proveedor." />
      })
      return
    }
    if (email === '') {
      UIStore.showNotification({
        type: 'danger',
        content: () => <Notification message="Necesitas introducir un email válido." />
      })
      return
    }

    UIStore.showLoading()

    postEmailSubscription(email.toString(), AppStore.postalcode.toString(), this.selected.toString(), (result) => {
      // TODO: controlar la respuesta de la API
      UIStore.hideLoading()
      UIStore.showNotification({
        type: 'success',
        timeOut: 6000,
        content: () => <Notification message="¡Gracias! Te enviaremos un email cuando el proveedor que has seleccionado esté disponible en tu código postal." />
      })
      browserHistory.push('/')
    })
  }
}


export default PotentialProvidersSelection
