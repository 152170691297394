import React, { Component } from "react";

import { observer } from "mobx-react";
import Hammer from "react-hammerjs";
import { TweenMax, Power2 } from "gsap";
import config from "../../config";
import { AppStore, UIStore, ShopStore, SyncStore } from "../../stores";
import { Dropdown, ProviderCartLink, FiltersButton } from "../";
import {
  ShopLineView,
  ShopLineControlls,
  ProductTooltip,
  AnimatedProductContainer,
} from "../ShopLineComponents";

// NOTE: opciones para el Dropdown
const SORT_SHOP_OPTIONS = [
  {
    text: "Orden de tienda",
    active: true,
    action: () => AppStore.sortShop("categoryEntryIndex", false, parseInt),
  },
  {
    text: "Precio ascendente",
    active: false,
    action: () => AppStore.sortShop("price", false, parseFloat),
  },
  {
    text: "Precio descendente",
    active: false,
    action: () => AppStore.sortShop("price", true, parseFloat),
  },
];

@observer
class ShopLine extends Component {
  startX = 0;
  endX = 0;
  offsetX = 0;
  dragOffset = 0;
  scrolling = false;

  constructor(...args) {
    super(...args);
  }

  state = {
    welfaresEnable: AppStore.welfaresEnable,
    allergensEnable: AppStore.allergensEnable,
  };

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyDown, false);
    window.setTimeout(() => {
      AppStore.dropdownInstance = this.child;
      window.clearTimeout(this);
    });
    SyncStore.cartsConfig.get(
      AppStore.idprovider.toString(),
      (error, value) => {
        SyncStore.checkCartStatus(null, (cart) => {
          UIStore.hideLoading();
        });
      }
    );
  }

  componentDidUpdate() {
    this.refs.scroller.style.left = "-100vw";
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDown, false);
  }

  render() {
    var tip = null;
    var text = null;
    var text2 = null;

    if (
      this.state.allergensEnable.length > 0 ||
      this.state.welfaresEnable.length > 0
    ) {
      var countFilters =
        this.state.welfaresEnable.length + this.state.allergensEnable.length;
      if (countFilters === 1) text2 = " filtro activo";
      else text2 = " filtros activos";
      text = countFilters + text2 + "\xa0\xa0\xa0\xa0\xa0" + "X";
    }
    console.log("aca");
    return (
      <div className="shop-line">
        <Dropdown
          options={SORT_SHOP_OPTIONS}
          ref={(instance) => {
            this.child = instance;
          }}
          className="sort-shop"
          content="list"
          type="flat"
          buttonColor="grey"
          placeholder="Ordenar"
          align="center"
          expandOnHover={false}
          collapseOnLeave={false}
          setSelectedAsPlaceholder={false}
          transitionName="modal-anim"
        />

        <ShopLineControlls
          onClickNext={this.next}
          onClickPrevious={this.previous}
        />

        <ProductTooltip />

        <AnimatedProductContainer />

        <Hammer
          onPanStart={this.handlePanStart}
          onPan={this.handlePan}
          onPanEnd={this.handlePanEnd}
          options={config.HAMMER_OPTIONS}
        >
          <div className="scroller" ref="scroller">
            <div className="shop-line_views-container">
              <ShopLineView
                racks={AppStore.previousView}
                layout={AppStore.previousLayout}
                ref="previous"
                className="previous"
              />
              <ShopLineView
                racks={AppStore.currentView}
                layout={AppStore.currentLayout}
                ref="current"
                className="current"
              />
              <ShopLineView
                racks={AppStore.nextView}
                layout={AppStore.nextLayout}
                ref="next"
                className="next"
              />
            </div>
          </div>
        </Hammer>

        <FiltersButton />

        <div className="filterstip">
          <div
            className="FiltersTiptrans"
            onClick={() => this.DisableFilters()}
          >
            {" "}
            <p className="p light white s13 "> {text} </p>{" "}
          </div>
        </div>

        <ProviderCartLink
          provider={AppStore.providername}
          link={`/carrito`}
        />
      </div>
    );
  }

  onKeyDown = (event) => {
    document.removeEventListener("keydown", this.onKeyDown, false);
    switch (event.keyCode) {
      case 39:
        this.scrollingLineViews("next");
        break;
      case 37:
        this.scrollingLineViews("prev");
        break;
    }
  };

  /**
   *  Delete All Filter enabled *
   */
  DisableFilters() {
    this.setState({
      welfaresEnable: [],
      allergensEnable: [],
    });
    AppStore.welfaresEnable = [];
    AppStore.allergensEnable = [];
    AppStore.sortShop("categoryEntryIndex", false, parseInt);
  }

  /**
   * Controla el evento 'click' para el botón 'next'
   */
  next = (event) => {
    event.preventDefault();
    if (!this.scrolling) this.scrollingLineViews("next");
  };

  /**
   * Controla el evento 'click' para el botón 'prev'
   */
  previous = (event) => {
    event.preventDefault();
    if (!this.scrolling) this.scrollingLineViews("prev");
  };

  /**
   * Controla el evento 'panStart'
   */
  handlePanStart = (event) => {
    event.preventDefault();

    if (!this.scrolling) {
      this.startX = event.pointers[0].clientX;
      this.offsetX = this.refs.scroller.getBoundingClientRect().left;
    }
  };

  /**
   * Controla el evento 'pan'
   */
  handlePan = (event) => {
    event.preventDefault();

    this.refs.scroller.style.left =
      parseInt(this.offsetX) + event.pointers[0].clientX - this.startX + "px";
    this.dragOffset =
      this.offsetX - this.refs.scroller.getBoundingClientRect().left;
  };

  /**
   * Controla el evento 'panEnd'
   */
  handlePanEnd = (event) => {
    event.preventDefault();
    this.endX = event.pointers[0].clientX;
    if (event.offsetDirection === 4) {
      this.scrollingLineViews("prev");
    } else if (event.offsetDirection === 2) {
      this.scrollingLineViews("next");
    }
  };

  /**
   * Anima las vistas
   */
  scrollingLineViews(dir) {
    this.scrolling = true;
    const container = this.refs.scroller;
    const containerPosition = container.getBoundingClientRect().left;
    const windowWidth = window.innerWidth;
    const transfromProps = { x: 0, y: 0, z: 0 };

    switch (dir) {
      case "prev":
        TweenMax.to(container, 0.5, {
          css: { left: containerPosition + windowWidth + this.dragOffset },
          ease: Power2.easeOut,
          immediateRender: true,
          onStart: () => AppStore.setBackground("prev"),
          onComplete: () => {
            AppStore.paginateShop("prev");
            this.onPaginateEnd();
          },
        });
        break;
      case "next":
        TweenMax.to(container, 0.5, {
          css: { left: containerPosition - windowWidth + this.dragOffset },
          ease: Power2.easeOut,
          immediateRender: true,
          onStart: () => AppStore.setBackground("next"),
          onComplete: () => {
            AppStore.paginateShop("next");
            this.onPaginateEnd();
          },
        });
        break;
    }
  }

  /**
   * Controla el fin de la animación para la paginación
   */
  onPaginateEnd() {
    this.dragOffset = 0;
    this.scrolling = false;
    document.addEventListener("keydown", this.onKeyDown, false);
    // TODO: controlar el cambio de categoria
  }
}

export default ShopLine;
