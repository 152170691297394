import React from 'react';

const euro = () => {
  return (
    <g>
      <path d="M345.176,382.016l-9.996-45.392c-0.567-2.669-1.995-4.668-4.284-5.995c-2.475-1.335-4.948-1.52-7.42-0.568l-1.43,0.568
        l-3.43,0.855c-1.525,0.376-3.285,0.808-5.283,1.283c-1.999,0.476-4.326,0.948-6.995,1.427c-2.655,0.476-5.476,0.907-8.419,1.287
        c-2.949,0.373-6.132,0.712-9.561,0.999c-3.43,0.281-6.852,0.425-10.281,0.425c-24.174,0-45.922-6.517-65.239-19.555
        c-19.32-13.042-33.548-30.696-42.683-52.961h110.486c2.098,0,4.001-0.712,5.708-2.143c1.719-1.424,2.766-3.183,3.139-5.284
        l6.852-31.977c0.574-2.847,0-5.42-1.708-7.706c-1.903-2.286-4.288-3.43-7.139-3.43h-131.04c-0.571-12.942-0.571-22.934,0-29.978
        h139.325c5.144,0,8.186-2.472,9.137-7.421l6.852-32.548c0.567-2.664-0.089-5.136-1.999-7.422c-1.707-2.284-4.086-3.431-7.132-3.431
        h-133.62c9.517-21.317,23.791-38.066,42.827-50.248c19.034-12.185,40.542-18.274,64.524-18.274c0.764-0.38,3.569-0.284,8.419,0.286
        c4.853,0.568,7.618,0.808,8.281,0.712c0.657-0.094,3.142,0.193,7.42,0.855c4.284,0.666,6.427,1,6.427,1l4.996,0.998
        c1.431,0.288,2.525,0.522,3.285,0.715l1.143,0.284c2.472,0.765,4.75,0.525,6.852-0.711c2.095-1.241,3.429-3.094,4.001-5.568
        l12.278-45.395c0.568-2.475,0.28-4.759-0.855-6.852c-1.715-2.288-3.621-3.715-5.715-4.284C315.39,2.19,296.92,0,277.51,0
        c-42.641,0-80.751,12.185-114.347,36.545c-33.595,24.362-56.77,56.532-69.523,96.501H65.663c-2.666,0-4.853,0.855-6.567,2.568
        c-1.709,1.711-2.568,3.901-2.568,6.567v32.548c0,2.664,0.856,4.854,2.568,6.563c1.715,1.715,3.905,2.568,6.567,2.568h19.13
        c-0.575,9.139-0.666,19.126-0.288,29.981H65.663c-2.474,0-4.615,0.903-6.423,2.711c-1.807,1.807-2.712,3.949-2.712,6.42v32.264
        c0,2.478,0.905,4.613,2.712,6.427c1.809,1.808,3.949,2.704,6.423,2.704h27.124c11.991,42.064,34.643,75.52,67.952,100.357
        c33.311,24.846,72.235,37.261,116.771,37.261c3.62,0,7.282-0.089,10.995-0.287c3.72-0.191,7.187-0.479,10.424-0.855
        c3.234-0.377,6.424-0.801,9.565-1.28c3.138-0.479,5.995-0.947,8.562-1.431c2.57-0.472,4.997-0.947,7.279-1.42
        c2.286-0.482,4.332-0.999,6.143-1.574c1.807-0.564,3.323-0.996,4.565-1.276c1.239-0.287,2.238-0.626,2.994-0.999l1.431-0.288
        c2.095-0.76,3.713-2.142,4.853-4.144C345.464,386.444,345.744,384.299,345.176,382.016z"/>
    </g>
  )
}
export default euro;