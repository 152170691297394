
/**
 * @func: splitURL
 * @param: {[String]} color
 * @return: {[Boolean]}
 *
 * @description: devuelve 'true' o 'false' dependiendo de si el valor
 * que se le pasa es un valor rgb o rgba valido.
 */

const splitURL = () => {
  const splited = window.location.pathname.split('/').filter((path) => path !== '')
  // const splited = window.location.hash.split('/').filter((path) => path !== '')
  return splited
}
export default splitURL
