
/**
 * @func: truncate
 *
 * @param {String}
 * @param {String}
 */

const truncate = (string, limit) => string.length > limit ? string.substr(0, limit - 1) + ' ...' : string

export default truncate
