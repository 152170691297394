
import React from 'react'

const arrow_go_back = () => {
  return (
    <g>
      <g>
        <polygon points="109.785,130.746 81.25,100.006 109.785,69.257 114.51,73.649 90.052,100.006 114.51,126.361 		"/>
      </g>
      <g opacity="0.7">
        <path d="M101.399,200l-2.797-0.038C44.106,198.501,0,153.931,0,100S44.106,1.503,98.601,0.038V0l2.797,0.041
          C155.941,1.553,200,46.097,200,100c0,53.931-44.103,98.501-98.601,99.962V200z M99.956,6.461C48.438,7.17,6.452,49.134,6.452,100
          c0,50.851,41.964,92.805,93.548,93.536c51.588-0.731,93.548-42.685,93.548-93.536C193.548,49.134,151.562,7.17,99.956,6.461z"/>
      </g>
    </g>
  )
}

export default arrow_go_back
