
import React, { Component } from 'react'
import { Button, Icon, IconButton } from '../../../'

/**
 *
 */

class MergeCartsModal extends Component {

  state = {
    step: 0
  }

  selected = null
  units = null
  date = null

  render () {
    const name = this.props.provider.provider.toLowerCase()
    const styler = { backgroundColor: this.props.provider.last_color }

    return (
      <div className="merge-carts-modal">
        <div className={ `steps ${ (this.state.step === 1) ? 'one' : '' }` }>

          <div className="step one">
            <h2 className="h2 light grey s32">Hola <span className="h2 light black s32">{ this.props.user.firstname },</span></h2>
            <p className="p regular grey s16">Tienes 2 carritos guardados. Continua solo con uno o bien unifica todos los artículos.</p>
            <button onClick={ this.next } id="local" className="provider-cart-btn">
              <div className="provider-icon" style={ styler }>
                <Icon type={ `cart_${ name }` } title="icon-logo" fill="white" width="40px" height="40px" />
              </div>
              <p className="p bold black s18">{ this.props.localUnits } productos.</p>
              <p className="p regular blue s16">Hoy <span className="p regular grey s16">en CP. { this.props.postalcode }</span></p>
            </button>
            <br/>
            <button onClick={ this.next } id="remote" className="provider-cart-btn">
              <div className="provider-icon" style={ styler }>
                <Icon type={ `cart_${ name }` } title="icon-logo" fill="white" width="40px" height="40px" />
              </div>
              <p className="p bold black s18">{ this.props.remoteUnits } productos.</p>
              <p className="p regular blue s16">{ this.props.remoteDate } <span className="p regular grey s16">en CP. { this.props.postalcode }</span></p>
            </button>
            <br/>
            <Button onClick={ () => this.props.onSelectCart('unify') } type="outline" placeholder="Unificar" color="blue" />
          </div>

          <div className="step two">
            <h2 className="h2 light black s32">Atención,</h2>
            <p className="p regular grey s16">El carrito no seleccionado se eliminará.</p>
            <button onClick={ null } className="provider-cart-btn">
              <div className="provider-icon" style={ styler }>
                <Icon type={ `cart_${ name }` } title="icon-logo" fill="white" width="40px" height="40px" />
              </div>
              <p className="p bold black s18">{ this.units } productos.</p>
              <p className="p regular blue s16">{ this.date } <span className="p regular grey s16">en CP. { this.props.postalcode }</span></p>
            </button>
            <Button onClick={ this.prev } type="outline" placeholder="Cancelar" color="grey" />
            <Button onClick={ () => this.props.onSelectCart(this.selected) } type="outline" placeholder="Aceptar" color="blue" />
          </div>

        </div>
      </div>
    )
  }


  /**
   *
   */
  next = (event) => {
    const selection = event.currentTarget.id

    switch (selection) {
      case 'local':
        this.selected = 'local'
        this.units = this.props.localUnits
        this.date = 'Hoy'
        break;
      case 'remote':
        this.selected = 'remote'
        this.units = this.props.remoteUnits
        
        this.date = this.props.remoteDate
        break;
      default:
    }

    this.setState({ step: 1 })
  }


  /**
   *
   */
  prev = (event) => {
    this.selected = null
    this.setState({ step: 0 })
  }
}



export default MergeCartsModal
