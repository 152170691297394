
import React from 'react'

const share = () => {
  return (
    <g>
      <path d="M138.157,24.76V0L200,50.661l-61.843,50.66V76.66c-64.409-2.311-97.851,30.982-97.851,30.982
    		C60.853,47.677,121.454,28.875,138.157,24.76z M145.259,162.372H20.979V37.629h57.333c17.593-11.182,35.052-17.557,46.636-20.936
    		v-0.12H11.239C5.032,16.573,0,21.623,0,27.854v144.294c0,6.229,5.032,11.279,11.239,11.279h143.76
    		c6.207,0,11.239-5.05,11.239-11.279V93.74l-20.979,16.487V162.372z"/>
  	</g>
  )
}

export default share
