
import React from 'react'

const dots = () => {
 return (
   <g>
     <path d="M125.257,25.258c0,13.949-11.306,25.254-25.256,25.254c-13.947,0-25.258-11.305-25.258-25.254
   		C74.743,11.311,86.054,0,100.001,0C113.951,0,125.257,11.311,125.257,25.258z M100.001,74.16
   		c-13.947,0-25.258,11.309-25.258,25.258c0,13.947,11.311,25.26,25.258,25.26c13.95,0,25.256-11.312,25.256-25.26
   		C125.257,85.469,113.951,74.16,100.001,74.16z M100.001,148.324c-13.947,0-25.258,11.303-25.258,25.252
   		c0,13.955,11.311,25.26,25.258,25.26c13.95,0,25.256-11.305,25.256-25.26C125.257,159.627,113.951,148.324,100.001,148.324z"/>
   </g>
 )
}

export default dots
