import React, { Component } from "react";
import { translate } from "react-i18next";
import { observer } from "mobx-react";
import { AppStore } from "../../stores";
import { NavigationButton } from "./components";
import { Icon, UserIcon } from "../";

@observer
@translate(["common"], { wait: false })
class NavigationMenu extends Component {
  state = {
    selected: AppStore.userZoneView,
  };


  render() {
    return (
      <div className="navigation box-md-3">
        <nav>
          <ul>
            <li>
              <UserIcon />
            </li>
            {/* <li>
              <NavigationButton
                placeholder="Mi cuenta"
                icon="user"
                selected={this.state.selected === 0 ? "selected" : ""}
                id={0}
                onClick={this.onNavButtonClick}
              />
            </li> */}

            <li>
              <NavigationButton
                placeholder="Pedidos"
                icon="filled_cart"
                selected={this.state.selected === 1 ? "selected" : ""}
                id={1}
                onClick={this.onNavButtonClick}
              />
            </li>

            <li>
              <NavigationButton
                placeholder="Ayuda"
                icon="config"
                selected={this.state.selected === 2 ? "selected" : ""}
                id={2}
                onClick={this.onNavButtonClick}
              />
            </li>
          </ul>
        </nav>
      </div>
    );
  }

  onNavButtonClick = (event) => {
    event.preventDefault();
    AppStore.userZoneView = parseInt(event.currentTarget.id);
    this.setState({ selected: parseInt(event.currentTarget.id) });
  };
}

export default NavigationMenu;
