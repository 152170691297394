
import config from '../../config'

/**
 * @func: getSearch
 * @return {[Object]} data
 *
 * @description:
 */

const getSearch = (idzone, idprovider, keyword, page, order, acronym) => {
  const params = { keyword: keyword, page: page, id_zone: idzone, order: order, id_provider: idprovider, acronym: acronym }
  return config.API.searchGet(params, {}, {})
  .then(result => result.data)
  .catch(error => error)
}


export default getSearch
