
import React from 'react'

const back = () => {
  return (
    <g>
      <g>
       <g>
         <polyline points="-485.194,-868.269 -584.697,-758.819 -684.194,-868.269 		"/>
       </g>
      </g>
      <rect x="0.583" y="0.583" fill="none" width="198.835" height="198.836"/>
      <polygon points="154.229,15.475 136.991,0.583 45.771,101.133 135.911,199.418 153.155,184.526 77.286,101.133 "/>
    </g>
  )
}

export default back
