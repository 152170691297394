/*****************************************************************************
 * @class: OrderStore
 *
 * @description:
 *
 *****************************************************************************/

import "aws-sdk/dist/aws-sdk";
import React from "react";
import { observable } from "mobx";
import { browserHistory } from "react-router";
import { AuthStore, AppStore, SyncStore, ShopStore, UIStore } from "./";
import { ProductModel } from "../models";
import config from "../config";
import { getDataAsync, asyncLoop, capitalize } from "../utils";
import { getProviders, getOrderProducts, getProductInfo } from "../utils/api";
import { Notification } from "../components/Dialogs/Notification/components";

class OrderStore {
  @observable order = null;
  @observable idorder = null;
  @observable postalcode = null;
  @observable idzone = null;
  @observable idprovider = null;
  @observable providername = null;

  processing = false;

  repeatOrder(idorder, postalcode, idzone, idprovider, providername) {
    UIStore.showLoading();
    this.idorder = idorder;
    this.postalcode = postalcode;
    this.idzone = idzone;
    this.idprovider = idprovider;
    this.providername = providername
      ? providername
      : config.AVAILABLE_PROVIDERS.filter(
          (prov) => parseInt(prov.id) === parseInt(idprovider)
        )[0].title;
    this.providername = capitalize(this.providername);
    console.log("Id de pedido: " + this.idorder);
    console.log("Codigo postal: " + this.postalcode);
    console.log("Id de zona: " + this.idzone);
    console.log("Id de proveedor: " + this.idprovider);
    console.log("Nombre de proveedor: " + this.providername);
    // Si no hemos inicializado la SyncStore creamos un nuevo syncManagerClient
    if (!SyncStore.initialized)
      SyncStore.syncManagerClient = new AWS.CognitoSyncManager();
    // Configuramos los datasets de AWS Cognito
    SyncStore.openOrCreateDataset("carts", (dataset) => {
      console.info(
        "%cEl dataset 'carts' se ha recuperado o creado con éxito!",
        "color: green"
      );
      SyncStore.cartsConfig = dataset;

      SyncStore.sync(SyncStore.cartsConfig, (error, dataset) => {
        if (error) console.error(error);

        // Pedimos el carro del proveedor del pedido
        SyncStore.cartsConfig.get(
          this.idprovider.toString(),
          (error, value) => {
            if (!value) {
              // Si no existe el carrito del proveedor lo creamos e intentamos
              // repetir de nuevo el pedido
              console.log(
                "El carrito de este proveedor no existe en Cognito. Lo creamos."
              );
              config.CART_CONFIG.postcode = AppStore.postalcode || postalcode;
              config.CART_CONFIG.status = 0;
              SyncStore.cartsConfig.put(
                this.idprovider.toString(),
                JSON.stringify(config.CART_CONFIG),
                () => {
                  this.repeatOrder(
                    idorder,
                    postalcode,
                    idzone,
                    idprovider,
                    providername
                  );
                }
              );
            } else {
              // Si ya existe el carrito del proveedor comprobamos su status
              SyncStore.getCartStatus(
                idprovider.toString(),
                (status, dataset) => {
                  // Si el carrito está cerrado mostramos un dialogo y paramos el proceso
                  if (status === 1) {
                    UIStore.hideLoading();
                    UIStore.showNotification({
                      type: "danger",
                      timeOut: 6000,
                      content: () => (
                        <Notification message="¡Vaya! Parece que hay algún problema con tu carrito. Contacta con Atención al Cliente." />
                      ),
                    });
                    return;
                  }

                  // Si el status del carrito es 0 (disponible)
                  // Seteamos el codigo postal del pedido en el carrito
                  config.CART_CONFIG.postcode = this.postalcode;
                  SyncStore.cartsConfig.put(
                    idprovider.toString(),
                    JSON.stringify(config.CART_CONFIG),
                    (error, record) => {
                      if (error) console.error(error);

                      SyncStore.sync(SyncStore.cartsConfig, () => {
                        // Obtenemos el dataset cart_
                        SyncStore.openOrCreateDataset(
                          `cart_${idprovider}`,
                          (dataset) => {
                            SyncStore.cartProducts = dataset;

                            SyncStore.sync(SyncStore.cartsConfig, () => {
                              ShopStore.quantity = 0;
                              let prods = [];

                              if (this.order !== null) {
                                prods = this.order.products.map(
                                  (product) => product.id_product_eva
                                );
                                this.getOrderProducts(
                                  prods,
                                  this.idzone,
                                  this.idprovider
                                );
                              } else {
                                this.getOrder((order) => {
                                  prods = this.order.products.map(
                                    (product) => product.id_product_eva
                                  );
                                  this.getOrderProducts(
                                    prods,
                                    this.idzone,
                                    this.idprovider
                                  );
                                });
                              }
                            });
                          }
                        );
                      });
                    }
                  );
                }
              );
            }
          }
        );
      });
    });

    return;
  }

  async getOrderProducts(prods) {
    const url = `/España/${this.postalcode}/${this.providername}/carrito`;
    let products = [];
    var alert = false;
    console.log("Total de productos " + prods.length);
    asyncLoop(
      prods.length,
      (loop) => {
        let i = loop.index();
        getProductInfo(prods[i], this.idzone, this.idprovider, config.LANGUAGE)
          .then((result) => {
            if (!result.id_product_eva) {
              getProductInfo(prods[i], 9999, this.idprovider, config.LANGUAGE)
                .then((newResult) => {
                  if (!newResult.status) {
                    products.push(newResult);
                  }
                  loop.next();
                })
                .catch((error) => {
                  throw error;
                });
            } else {
              if (result.status == undefined) {
                products.push(result);
              }
              loop.next();
            }
          })
          .catch((error) => {
            throw error;
          });
      },
      () => {
        if (products.length < prods.length) {
          alert = true;
        }
        this.mapOrderProducts(products, url, alert);
      }
    );
  }

  /**
   * El truco está en el callback, si venimos de la primera carga o del menú
   * de categorías el callback apuntará a 'categoryLoaded'. Si venimos de la
   * paginación del lineal el callback apuntará a TODO.
   */
  async getOrder(callback) {
    const paths = browserHistory.getCurrentLocation().pathname.split("/");
    console.info(
      `%cPidiendo el detalle del pedido %c${this.idorder || paths[4]}`,
      "color: blue",
      "color: blue; font-weight: bold"
    );
    this.order = await getDataAsync(
      getOrderProducts,
      this.idorder || paths[4],
      config.LANGUAGE
    );

    const providers = await getDataAsync(getProviders, this.postalcode, 1);
    if (providers.length === 0 || !Array.isArray(providers)) {
      // TODO: en este caso deberiamos avisar al usuario de que el supermercado
      // ya no está disponible en esa zona.
      console.warn(
        `%cNo hay proveedores para la zona %c${paths[2]}`,
        "color: red",
        "color: red; font-weight: bold"
      );
    } else {
      const provider = providers.filter(
        (prov) => prov.provider === this.providername.toUpperCase()
      )[0]; // || paths[3])[0]
      this.idzone = provider.id_zone;
      this.idprovider = provider.id_provider;
      this.providername = capitalize(provider.provider);
    }

    if (callback) return callback(this.order);
  }

  /**
   * Crea los modelos de los productos del pedido para su correcta subida al
   * dataset de AWS Cognito
   *
   * Hay que crear los modelos (ProductModel) con las unidades correctas y
   * pasarlos a AWS Cognito con el metodo putRecordsFromModel(models, callback)
   * de SyncStore. En el callback de este metodo redirigiremos al carrito.
   * La logica de la app deberia encargarse del resto.
   *
   * TODO: si estamos en la tienda hay que resetearla
   */
  async mapOrderProducts(products, url, alert) {
    SyncStore.cognitoProducts.clear();
    ShopStore.empty(() => {
      // Creamos los modelos
      let models = products.map((product, i) => {
        if (product.image === null)
          product.image = config.FAKE_BAGS[Math.round(Math.random())];
        if (product.width === null) product.width = 1617;
        if (product.height === null) product.height = 1040;
        return new ProductModel(product, i, i, this.order.products[i].quantity);
      });
      SyncStore.comeFromRepeatOrder = true;
      // Añadimos los productos al 'cart_' AWS Cognito
      SyncStore.putRecordsFromModel(models, () => {
        // Obtenemos los productos del 'cart_' AWS Cognito
        SyncStore.getAllRecords(SyncStore.cartProducts, (products) => {
          SyncStore.mapProducts(products, () => {
            UIStore.hideLoading();
            //ShopStore.isEmptyCart = false
            if (url) {
              SyncStore.forceCartSync();
              console.log("Este es el shopstore " + ShopStore.price);
              browserHistory.push(url);
              if (alert) {
                UIStore.showNotification({
                  type: "danger",
                  timeOut: 5000,
                  content: () => (
                    <Notification message="En este momento hay productos seleccionados que no están disponibles." />
                  ),
                });
              }
            }
          });
        });
      });
    });
  }

  reset() {
    this.order = null;
    this.idorder = null;
    this.postalcode = null;
    this.idzone = null;
    this.idprovider = null;
    this.providername = null;
    this.processing = false;
  }
}

export default new OrderStore();
