import React from "react";

const cart_ilusia = () => {
  return (
    <g transform="scale(8.5) translate(-3, -3)">
      <path
        fill="#FFFFFF"
        d="M15,5A10,10,0,1,0,25,15,10,10,0,0,0,15,5ZM13.67,19.71a0,0,0,0,1,0,0h0a.71.71,0,0,1,0,.2.29.29,0,0,1,0,.09v.05l0,.08-.06.13h0a0,0,0,0,0,0,0h0a0,0,0,0,0,0,0s0,0,0,0a1.16,1.16,0,0,1-.32.34h0a1,1,0,0,1-.18.1h0a1,1,0,0,1-.41.08.82.82,0,0,1-.21,0h0l-.15,0h-.05l-.15-.09h0a1.45,1.45,0,0,1-.18-.16L8.38,17a1.29,1.29,0,0,1,0-1.72,1.1,1.1,0,0,1,1.62,0l1,1.11,0,0h0l.11,0a.21.21,0,0,0,.21-.21h0V9.84a1.14,1.14,0,1,1,2.28,0v9.87Zm3.38,0a1.18,1.18,0,0,1-1.14,1.22,1.19,1.19,0,0,1-1.15-1.22V14.62a1.15,1.15,0,1,1,2.29,0Zm3.38,0a1.18,1.18,0,0,1-1.14,1.22,1.19,1.19,0,0,1-1.15-1.22V16.33a1.19,1.19,0,0,1,1.15-1.22h0a1.18,1.18,0,0,1,1.14,1.22Z"
      />
    </g>
  );
};

export default cart_ilusia;
