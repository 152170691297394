
import config from '../../config'

/**
 * @func: getCategories
 * @return {[Object]} data
 *
 * @description:
 */

const getCategories = (id, idzone, lan) => {
  const params = { id_provider: id, acronym: lan, id_zone: idzone }
  return config.API.categoriesGet(params, {}, {})
  .then(result => result.data.categories)
  .catch(error => error)
}


export default getCategories
