
import React, { Component } from 'react'
import { browserHistory } from 'react-router'
import { AuthStore } from '../stores'
import { Icon, Button, LinkButton } from '../components'

class LogInPage extends Component {

  componentDidMount () {
    window.setTimeout(() => {
      this.refs.email.focus()
      window.clearTimeout(this)
    })
  }


  render () {
    return (
      <div className="section">
        <div className="login-page">
          <div className="logotype">
            <div className="symbol">
              <Icon className="icon-symbol" type="symbol" width="60" height="60" fill="blue" viewport="0 0 200 200"></Icon>
            </div>
            <div className="logo">
              <Icon className="icon-logo" type="logo" width="120" height="40" fill="grey" viewport="0 0 200 70"></Icon>
            </div>
          </div>
          <h1 className="h1 light grey s32">Iniciar sesión</h1>
          <form onSubmit={ this.processForm }>
            <input ref="email" className="input" id="email" type="text" placeholder="Correo electrónico" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" />
            <input className="input" id="password" type="password" placeholder="Contraseña" readOnly={ false } />
            <button type="submit"></button>
            <LinkButton onClick="recuperar-clave" className="recover" type="flat" placeholder="He olvidado mi contraseña" color="blue" />
            <Button onClick={ this.processForm  } type="outline" placeholder="Iniciar sesión" color="blue" />
          </form>
          <p className="p light grey s14">
            <span>¿Primera vez en Ilusia? </span><LinkButton onClick="registro" type="flat" placeholder="Registrate" color="blue" />
          </p>
        </div>
      </div>
    )
  }


  processForm = (event) => {
    event.preventDefault()
    const email = document.querySelector('form input#email').value
    const password = document.querySelector('form input#password').value
    AuthStore.logIn(email, password, () => browserHistory.push(AuthStore.prevState))
  }
}

export default LogInPage
