import React from "react";

const dotsOrder = () => {
  return (
    <g fill="none">
      <path
        d="M13.5 21.3907H23.5M13.5 10.2188H23.5H13.5ZM13.5 15.8438H23.5H13.5Z"
        stroke="#565656"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31 16C31 7.71875 24.2812 1 16 1C7.71875 1 1 7.71875 1 16C1 24.2812 7.71875 31 16 31C24.2812 31 31 24.2812 31 16Z"
        stroke="#565656"
        strokeMiterlimit="10"
      />
      <path
        d="M9.125 10.8437C9.47018 10.8437 9.75 10.5639 9.75 10.2187C9.75 9.87351 9.47018 9.59369 9.125 9.59369C8.77982 9.59369 8.5 9.87351 8.5 10.2187C8.5 10.5639 8.77982 10.8437 9.125 10.8437Z"
        stroke="#565656"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.125 16.5469C9.47018 16.5469 9.75 16.2671 9.75 15.9219C9.75 15.5767 9.47018 15.2969 9.125 15.2969C8.77982 15.2969 8.5 15.5767 8.5 15.9219C8.5 16.2671 8.77982 16.5469 9.125 16.5469Z"
        stroke="#565656"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.125 22.0937C9.47018 22.0937 9.75 21.8139 9.75 21.4687C9.75 21.1235 9.47018 20.8437 9.125 20.8437C8.77982 20.8437 8.5 21.1235 8.5 21.4687C8.5 21.8139 8.77982 22.0937 9.125 22.0937Z"
        stroke="#565656"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  );
};

export default dotsOrder;
