
import React from 'react'

const list = () => {
  return (
    <g>
      <path d="M190.408,100.422l-16.467-88.371h-10.18H30.125v162.002c0,8.139,6.599,14.742,14.744,14.742h114.331
        c8.145,0,14.742-6.604,14.742-14.742v-73.631H190.408z M159.199,174.053H44.869v-147.4h114.331V174.053z"/>
      <path d="M64.669,58.281h74.729c4.068,0,7.361-3.299,7.361-7.367c0-4.066-3.293-7.363-7.361-7.363H64.669
        c-4.068,0-7.371,3.297-7.371,7.363C57.298,54.982,60.601,58.281,64.669,58.281z"/>
      <path d="M64.669,91.463h74.729c4.068,0,7.361-3.295,7.361-7.361s-3.293-7.361-7.361-7.361H64.669
        c-4.068,0-7.371,3.295-7.371,7.361S60.601,91.463,64.669,91.463z"/>
      <path d="M64.669,124.65h74.729c4.068,0,7.361-3.293,7.361-7.361s-3.293-7.367-7.361-7.367H64.669
        c-4.068,0-7.371,3.299-7.371,7.367S60.601,124.65,64.669,124.65z"/>
      <path d="M64.669,157.832h74.729c4.068,0,7.361-3.295,7.361-7.361s-3.293-7.367-7.361-7.367H64.669
        c-4.068,0-7.371,3.301-7.371,7.367S60.601,157.832,64.669,157.832z"/>
    </g>
  )
}

export default list
