import React, { Component } from 'react';
import { observer } from "mobx-react";
import { translate } from "react-i18next";
import { AppStore, ShopStore } from "../../stores"
import Icon from "../Icons/Icon";
import CardProduct from "./components/CardProduct";

@translate(['common'], { wait: false })
@observer
class OrderConfirmationReview extends Component {
  render(){
    this.units = ShopStore.units;
    this.isGrid = ShopStore.isGrid;
    this.isEmptyCart = ShopStore.isEmptyCart
    this.isInProgress = ShopStore.isInProgress
    this.products = ShopStore.products
    this.idProvider = AppStore.idProvider
    const total = ShopStore.price.toFixed(2) - ShopStore.saving.toFixed(2)
    const products = Array.from(this.products).map(
      (product, i) => 
        <CardProduct 
          onClick={ () => ShopStore.add(product.product.id_product_eva, 1) }
          product={ product }
          state={ product.status || null }
          key={ i }
          layout={ null } 
        />
    )

    return(
      <div>
        <div className="container-title">
          <Icon 
            type="euro"
            viewport="0 0 512 512"
            className="icon"
            height="35"
            width="35"
          />
          <div className="container-title-info">
            <h2 className="h1 light grey s28">Resumen del pedido</h2>
          </div>
        </div>
        <div className="container-review">
          <div className="review">
            <div className="review-dropdown">
              <h4>Carrito ({ShopStore.units})</h4>
              <Icon 
                type="arrow_dropdown"
                className="arrow-dropdown"
                height="15"
                width="15"
              />
            </div>
            <h4 className="h4 bold grey">TOTAL: {total.toFixed(2)} €</h4>
            <div className="review-product">
              {products}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default OrderConfirmationReview;
