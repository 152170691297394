
import React from 'react'

const add_to_list = () => {
  return (
    <g>
  		<path d="M78.039,100.375c0,0.016,0,0.033-0.011,0.047c0.011,0.012,0.011,0.033,0.011,0.043
  			c-0.031,1.197-0.662,2.311-1.697,3.021l-27.999,19.109c-0.724,0.496-1.594,0.752-2.454,0.752c-0.648,0-1.292-0.141-1.891-0.422
  			c-1.389-0.658-2.263-2.002-2.263-3.453v-8.402l-26.679-6.984c-1.729-0.471-2.949-1.92-3.011-3.609
  			c0-0.012,0.012-0.037,0.012-0.055c0-0.025-0.012-0.047-0.012-0.064c0.062-1.688,1.281-3.139,3.011-3.602l26.679-6.986v-8.398
  			c0-1.461,0.874-2.803,2.263-3.457c0.599-0.281,1.246-0.42,1.891-0.42c0.866,0,1.73,0.254,2.454,0.744l27.999,19.115
  			C77.377,98.066,78.008,99.178,78.039,100.375z M137.785,76.74H63.559l18.338,12.518c0.9,0.621,1.635,1.408,2.354,2.205h53.535
  			c4.067,0,7.363-3.295,7.363-7.361S141.853,76.74,137.785,76.74z M172.328,12.051h-10.177H28.508v50.523h0.12
  			c0.329,3.775,3.397,6.764,7.255,6.764c3.854,0,6.918-2.988,7.242-6.764h0.125V26.652h114.338v147.395H43.25v-37.621h-0.146
  			c-0.377-3.717-3.41-6.65-7.221-6.65c-3.814,0-6.846,2.934-7.228,6.65h-0.147v37.621c0,8.145,6.604,14.748,14.742,14.748h114.338
  			c8.137,0,14.74-6.604,14.74-14.748v-73.625h16.461L172.328,12.051z M137.785,43.551H63.051c-4.065,0-7.361,3.297-7.361,7.369
  			c0,4.062,3.296,7.361,7.361,7.361h74.734c4.067,0,7.363-3.299,7.363-7.361C145.148,46.848,141.853,43.551,137.785,43.551z
  			 M137.785,109.922H83.583c-0.569,0.559-1.034,1.213-1.708,1.672l-19.057,13.01c0.08,0,0.152,0.047,0.232,0.047h74.734
  			c4.067,0,7.363-3.293,7.363-7.361S141.853,109.922,137.785,109.922z M63.051,157.832h74.734c4.067,0,7.363-3.295,7.363-7.361
  			s-3.296-7.367-7.363-7.367H63.051c-4.065,0-7.361,3.301-7.361,7.367S58.985,157.832,63.051,157.832z"/>
    </g>
  )
}

export default add_to_list
