import React, { Component } from 'react'
import { AppStore, UIStore, ShopStore } from '../stores'
import FinisOrderIframe from '../components/Dialogs/Modal/modals/FinisOrderIframe'
import { browserHistory } from 'react-router'
import { Notification } from '../components/Dialogs/Notification/components'
import { getMasCartInformation } from '../utils/api'
import FinisOrderMas  from '../components/Dialogs/Modal/modals/FinisOrderMas'
import ReactGA from 'react-ga'

class Tramitar extends Component {



render () {

     return ( this.Rendercontent())
  }


Rendercontent(){
    var finishiframe = ""
    var url = null
    url = AppStore.iframeurl

    var ContainerclassName = ""
    if (url != null){
        if (AppStore.idprovider == 5 ){

            console.info(`%cEsta es la url del iframe: %c${ AppStore.iframeurl.url }`, 'color: green', 'color: green; font-weight: bold')
            finishiframe = <FinisOrderIframe url= { url.url } width='1200' height='900' />
            ContainerclassName = "containerDiaIframe"
        // Checking for MAS*//
        } else if (AppStore.idprovider == 6){
              if (url == "http"){

                  if (AppStore.CatInformationMas.prices.shopping_price > 0) {
                      ContainerclassName = "containerMasIframe"
                      finishiframe = <FinisOrderMas onSubmit={ ShopStore.SubmitMas }/>
                  }
              }else{
                console.info(`%cEsta es la url del iframe: %c${ AppStore.iframeurl }`, 'color: green', 'color: green; font-weight: bold')
                finishiframe = <FinisOrderIframe url= { url } width='600' height='600' />
                ContainerclassName = "containerMasIframe"
              }
        }
        
    } else {
        console.info(`La URL viene vacia en AppStore.iframeurl`, 'color: blue', 'color: blue; font-weight: bold')
    }
    return <div className="section defaultIframe">
                <div className= {ContainerclassName}>       
                        { finishiframe } 
                </div>
            </div>
}




}

export default Tramitar
