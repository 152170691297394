import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import './scss/main.scss';


import { i18n } from './js/internationalization'
import routes from './js/routes'

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(<I18nextProvider i18n={ i18n }>{ routes }</I18nextProvider>, document.querySelector('#application'))
})
