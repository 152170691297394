
import config from '../../config'

/**
 * @func: getSvgIcons
 * @return {[Object]} data
 *
 * @description:
 */

const getSvgIcons = (params) => {
  return config.API.svgIconsGet({ filenames: params }, {}, {})
  .then(result => result.data)
  .catch(error => error)
}


export default getSvgIcons
