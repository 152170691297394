

import React, { Component } from 'react'


class ProductAccordionButton extends Component {
  render () {
    return (
      <button onClick={ this.props.onClick } >
        <h1 className="h1 regular black s18">{ this.props.title }</h1>
      </button>
    )
  }
}


export default ProductAccordionButton
