
import React from 'react'

const logo_eroski = () => {
  return (
  	<g transform="scale(1.44) translate(-110, 35)">
            <path fill="#FFFFFF" d="M322.937,0h-1.618H180.865H92.121c-5.91,0-10.959,3.661-13.049,8.825C76.871,3.645,71.725,0,65.746,0H14.47
                  C6.493,0,0,6.486,0,14.457V65.65c0,2.647,0.723,5.228,2.096,7.48c0.048,0.083,0.099,0.169,0.153,0.248l0.87,1.341
                  c0.363,0.558,0.828,1.019,1.357,1.373c2.682,2.568,6.251,4.014,9.991,4.014h51.276c4.173,0,8.073-1.765,10.822-4.874
                  c0.319-0.28,0.599-0.608,0.838-0.975l0.526-0.819l0.137-0.217c0.389-0.644,0.714-1.303,0.997-1.975
                  c2.08,5.18,7.139,8.86,13.059,8.86h48.039h174.522h8.254c7.77,0,14.088-6.314,14.088-14.075V14.072
                  C337.028,6.314,330.707,0,322.937,0z M332.249,66.036c0,5.11-4.193,9.296-9.309,9.296h-8.254H140.16H92.121
                  c-5.123,0-9.309-4.183-9.309-9.296V14.072c0-5.113,4.186-9.293,9.309-9.293h88.744h140.453h1.618c5.123,0,9.309,4.18,9.309,9.293
                  L332.249,66.036L332.249,66.036z"/>
            <g>
            <path fill="#DF1727" d="M43.133,4.779h22.998c5.12,0,9.306,4.18,9.306,9.293v51.964c0,1.835-0.542,3.555-1.469,5.002
                  l-7.315-16.541c-3.934,3.96-9.325,6.486-15.251,6.633c-7.168,0.185-13.546-3.148-17.525-8.42
                  c17.073-2.297,21.985-10.363,22.419-24.422C50.441,36.828,41.82,41.683,29.778,42.68c-0.118-0.927-0.188-1.867-0.188-2.826
                  c0-11.415,8.831-21.049,20.004-22.256L43.133,4.779L43.133,4.779z M322.937,4.779h-1.618H180.865H92.121
                  c-5.123,0-9.309,4.18-9.309,9.293v51.964c0,5.11,4.186,9.296,9.309,9.296h48.039h174.522h8.254c5.12,0,9.309-4.183,9.309-9.296
                  V14.072C332.249,8.959,328.06,4.779,322.937,4.779z"/>
            <path fill="#D22B32" d="M14.084,4.779h28.042C27.006,9.796,15.83,24.553,15.83,41.282c0,2.192,0.188,4.326,0.554,6.388
                  c-6.862,5.253-9.156,13.83-10.128,23.374c-0.937-1.446-1.478-3.167-1.478-5.008V14.072C4.779,8.959,8.965,4.779,14.084,4.779
                  L14.084,4.779z M43.133,4.779L43.133,4.779L43.133,4.779L43.133,4.779z M72.363,67.434L72.363,67.434L72.363,67.434z
                   M72.379,67.466l1.58,3.584c-1.66,2.571-4.556,4.282-7.828,4.282H54.506C61.145,74.373,67.278,71.569,72.379,67.466L72.379,67.466
                  z M44.499,75.332H14.084c-3.211,0-6.053-1.644-7.726-4.135c2.937-6.034,7.222-10.924,12.858-14.655
                  C23.967,66.545,33.305,73.688,44.499,75.332L44.499,75.332z"/>
            </g>
            <path fill="#FFFFFF" d="M247.825,31.275c-2.784-2.434-4.932-4.145-8.85-4.145c-2.408,0-6.235,1.465-5.164,4.693
                  c0.685,2.077,4.431,4.438,6.33,5.719c7.292,4.913,12.026,13.76,1.81,20.628c-4.709,3.167-14.871,3.234-20.94,0.395v-9.134
                  c2.791,2.297,9.729,4.428,13.18,1.883c5.371-3.97-6.986-8.955-9.535-12.32c-7.722-10.226,0.742-19.688,12.565-19.695
                  c4.365-0.003,6.942,0.115,10.606,2.074V31.275L247.825,31.275z M128.984,19.631H99.286c1.23,2.084,1.771,4.282,1.631,6.601v34.012
                  h28.067v-8.567h-16.655v-8.232h11.3l3.552-7.538h-14.852V27.51h16.655L128.984,19.631L128.984,19.631z M196.17,61.18
                  c-11.459,0-20.81-9.57-20.81-21.313c0-11.733,9.35-21.31,20.81-21.31c11.463,0,20.803,9.577,20.803,21.31
                  C216.973,51.61,207.632,61.18,196.17,61.18L196.17,61.18z M310.614,19.631h-13.052c1.236,2.084,1.774,4.282,1.631,6.601v34.012
                  h11.421V19.631L310.614,19.631z M266.847,19.631h-13.049c1.23,2.084,1.775,4.282,1.628,6.601v34.012h11.421V41.553l12.957,18.554
                  h13.556l-14.648-22.154l15.588-18.322h-12.753l-13.97,16.311l-0.733,0.006V19.631H266.847z M136.331,19.631
                  c1.233,2.084,1.778,4.282,1.631,6.601v34.012h11.421V44.697h2.472l11.96,15.547h12.619L162.84,42.575
                  c4.444-1.876,7.554-6.082,7.554-10.95c0-6.607-5.728-11.995-12.756-11.995C150.52,19.656,143.476,19.631,136.331,19.631
                  L136.331,19.631z M149.386,38.8V27.411l2.545-0.006c3.339,0,6.056,2.558,6.056,5.696c0,3.135-2.717,5.696-6.056,5.696L149.386,38.8
                  L149.386,38.8z"/>
            <path fill="#DF1727" d="M196.17,50.193c-5.177,0-9.395-4.632-9.395-10.322c0-5.68,4.221-10.319,9.395-10.319
                  s9.395,4.639,9.395,10.319C205.565,45.557,201.344,50.193,196.17,50.193L196.17,50.193z"/>
  	</g>
  )
}

// fill="#0074B8"
// fill="#D32C33"
export default logo_eroski
