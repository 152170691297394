/*****************************************************************************
 * @class: ShopStore
 *
 * @description:
 *
 *****************************************************************************/

import React from "react";
import { browserHistory } from "react-router";
import { CognitoSyncManager } from "amazon-cognito-js";
import { observable, computed, action, toJS } from "mobx";
import {
  AuthStore,
  AppStore,
  SyncStore,
  UIStore,
  OrderStore,
  Tracking,
  TrackingApartments,
} from "./";
import config from "../config";
import { asyncLoop, capitalize } from "../utils";
import {
  checkCart,
  postOrder,
  exportCart,
  getProductInfo,
  getIntegrationsDIASSO,
  getMasCartInformation,
  postCheckoutMas,
  postAddress,
} from "../utils/api";
import { ProductModel } from "../models";
import {
  ToEmptyCarAlert,
  ToSendCarAlert,
  NoLinkedProviderAccountAlert,
  ShoppingCartConflictsAlert,
  OrderSuccessfullySentAlert,
  OrderLoginErrorAlert,
  ExportCartConflictsAlert,
} from "../components/Dialogs/Alert/components";
import { Notification } from "../components/Dialogs/Notification/components";
import LoginModal from "../components/Dialogs/Modal/modals/LoginModal";
import FinisOrderModal from "../components/Dialogs/Modal/modals/FinisOrderModal";
import FinisOrderDIAModal from "../components/Dialogs/Modal/modals/FinisOrderDIAModal";
import FinisOrderMas from "../components/Dialogs/Modal/modals/FinisOrderMas";
import SuccessMas from "../components/Dialogs/Modal/modals/SuccessMas";
import AddAddressModal from "../components/Dialogs/Modal/modals/AddAddress";

class ShopStore {
  products = new Set();
  @observable quantity = 0;
  @observable isGrid = true;
  // TODO: para bloquear la navegación al carro si está vacio
  @observable isEmptyCart = true;
  @observable isInProgress = false;
  // Productos con conflictos
  conflicts = [];
  orderConflicts = [];
  cognitoProducts = [];
  // La utilizamos para forzar el renderizado del carrito
  // cuando existen conflictos en el 'postOrder'
  @observable shoppingCartForceUpdate = false;
  // instancia del Dropdown para poder resetearlo
  dropdownInstance = null;
  AmountOrder = 0;

  //Guarda la url del checkout
  @observable url = null;

  constructor() {
    //if (this.units <= 0) this.isEmptyCart = true
  }

  /**
   * Calcula la cantidad de unidades añadidas al carro.
   */
  @computed get units() {
    return Array.from(this.products).reduce((sum, product) => {
      return sum + product.units;
    }, 0);
  }

  @action setUnits(units) {
    this.quantity = units;
  }

  /**
   * Obtiene todos los productos del carrito, filtrando los que ya no existen
  */
  @computed get allProducts() {
    const products = Array.from(this.products).filter(product =>{
      const doesntExistProduct = (product.product == undefined || product.product == null)
      if (doesntExistProduct) {
        return false
      }

      const productDoesntHavePrice = !(Object.keys(product.product).includes("price"))
      const productDoesntHaveUnits = !(Object.keys(product).includes("units"))
      if (productDoesntHavePrice || productDoesntHaveUnits) {
        return false
      }

      return true
    })

    return products
  }

  /**
   * Calcula el precio total del carrito.
   */
  @computed get price() {
    return Array.from(this.products).reduce((sum, product) => {
      const doesntExistProduct = (product.product == undefined || product.product == null)
      if (doesntExistProduct) {
        return 0
      }

      const productDoesntHavePrice = !(Object.keys(product.product).includes("price"))
      const productDoesntHaveUnits = !(Object.keys(product).includes("units"))
      if (productDoesntHavePrice || productDoesntHaveUnits) {
        return 0
      }

      const price = sum + product.product.price * product.units;
      return price;
    }, 0);
  }

  @computed get saving() {
    const saving = 25.6;
    var prod = Array.from(this.products);
    var deal_products_1 = [];
    var deal_products_2 = [];
    for (var i in prod) {
      if (typeof prod[i].product.deal !== "undefined") {
        var deal = prod[i].product.deal;
        if (deal.id_type == 1) {
          deal_products_1.push(prod[i]);
        } else if (deal.id_type == 2) {
          deal_products_2.push(prod[i]);
        }
      }
    }
    const saving_1 = this.calculateDeal_1(deal_products_1);
    const saving_2 = this.calculateDeal_2(deal_products_2);
    console.log(saving_2 + " - " + saving_1, "Here line 93");
    return saving_2 + saving_1;
  }
  /**
   * Calculo del precio con envio
   *
   */
  @computed get priceDelivery() {
    const delivery = AppStore.pricedelivery;
    return this.price.toFixed(2) - this.saving.toFixed(2) + delivery.toFixed(2);
  }

  /**
   * Devuelve todas las ofertas diferentes en un array.
   */
  returnUniqueDeal(deal_products) {
    let uniques_deal = [
      ...new Set(
        deal_products
          .map((prod) => prod.product)
          .map((deal) => deal.deal)
          .map((deals) => deals.id_deal)
      ),
    ]; //.map(deals =>({id_deal: deals.id_deal, m: deals.m, n: deals.n})))];
    return uniques_deal;
  }

  /**
   * Cálculo de oferta tipo mxn Type 1
   */

  calculateDeal_1(deal_products) {
    let uniques_deal = this.returnUniqueDeal(deal_products);
    var saving = 0;
    for (var deal_id in uniques_deal) {
      var dproducts = [];
      var totproducts = [];
      var prodsize = null;
      var m = 0;
      var units = 0;
      var sav = 0;
      var n_deals = 0;
      for (var prod in deal_products) {
        if (
          parseInt(deal_products[prod].product.deal.id_deal) ==
          parseInt(uniques_deal[deal_id])
        ) {
          dproducts.push(deal_products[prod]);
          m = deal_products[prod].product.deal.m;
        }
      }
      var totproducts = [];
      // Count all units by products
      for (var i in dproducts) {
        for (var a = 0; a < dproducts[i].units; a++) {
          totproducts.push(dproducts[i]);
        }
        units = units + dproducts[i].units;
      }
      // Check if the deal is necessary to apply
      if (units >= m) {
        var remainder = units % m;
        n_deals = parseInt(units / m);
        if (remainder == 0 || n_deals > 0) {
          var dproducts_sorted = totproducts.sort(function (a, b) {
            return a.price - b.price;
          });
          for (var i = 0; i < n_deals; i++) {
            sav = sav + dproducts_sorted[i].price;
          }
          //sav = sav + (dproducts_sorted[0].price * n_deals)
        }
      }
      saving = saving + sav;
      console.log(
        "Oferta tipo 1 mxn, número total de unidades: " +
          units +
          " el número de ofertas a aplicar es :" +
          n_deals +
          " para el id oferta: " +
          uniques_deal[deal_id]
      );
    }
    //return saving
    return saving;
  }

  /**
   * Cálculo de oferta tipo 2ª unidad X% Type 2
   */

  calculateDeal_2(deal_products) {
    let uniques_deal = this.returnUniqueDeal(deal_products);
    var saving = 0;
    for (var deal_id in uniques_deal) {
      var dproducts = [];
      var totproducts = [];
      var prodsize = null;
      var percent = 0;
      var units = 0;
      var sav = 0;
      var n_deals = 0;
      for (var prod in deal_products) {
        if (
          parseInt(deal_products[prod].product.deal.id_deal) ==
          parseInt(uniques_deal[deal_id])
        ) {
          dproducts.push(deal_products[prod]);
          percent = deal_products[prod].product.deal.percent;
        }
      }
      // Count all units by products
      for (var i in dproducts) {
        for (var a = 0; a < dproducts[i].units; a++) {
          totproducts.push(dproducts[i]);
        }
        units = units + dproducts[i].units;
      }
      // Check if the deal is necessary to apply
      if (units >= 2) {
        var remainder = units % 2;
        n_deals = parseInt(units / 2);
        if (remainder == 0 || n_deals > 0) {
          var dproducts_sorted = totproducts.sort(function (a, b) {
            return a.price - b.price;
          });
          for (var i = 0; i < n_deals; i++) {
            sav = sav + (dproducts_sorted[i].price * percent) / 100;
          }
          //sav = sav + (((dproducts_sorted[0].price * percent) / 100)* n_deals)
        }
      }
      saving = saving + sav;
      console.log(
        "Oferta tipo 2 - segunda unidad " +
          percent +
          " %, número total de unidades: " +
          units +
          " el número de ofertas a aplicar es :" +
          n_deals +
          " para el id oferta: " +
          uniques_deal[deal_id]
      );
    }
    //return saving
    return saving;
  }

  /**
   * Cálculo de oferta tipo -X% De momento no se usa. Type 4
   */
  calculateDeal_4(deal_products) {
    var saving = 0;
    for (var prod in deal_products) {
      saving =
        saving +
        ((deal_products[prod].product.price *
          deal_products[prod].product.deal.percent) /
          100) *
          deal_products[prod].units;
    }
    return saving;
  }

  /**
   * Añadimos un producto al carro
   */
  @action add(idproduct, _units) {
    if (this.isEmptyCart) this.isEmptyCart = false;

    const model = AppStore.products.get(idproduct);
    if (!model) return;
    // return
    console.log("unit", this.quantity);
    this.quantity += _units;
    // this.quantity = this.units  // get units()?
    model.add(_units);
    model.cartEntryIndex = model.cartEntryIndex || this.quantity + _units;
    this.products.add(model);

    const record = {
      type: model.product.type_price,
      quantity: model.units,
      product: toJS(model.product),
    };

    SyncStore.putRecord(
      model.product.id_product_eva.toString(),
      JSON.stringify(record)
    );

    console.info(
      `%cSe ha añadido al carrito: %c${model.product.description}.`,
      "color: green",
      "color: green; font-weight: bold"
    );
  }

  /**
   * Restamos unidades de un producto
   */
  @action subtract(idproduct, units) {
    const model = AppStore.products.get(idproduct);
    this.quantity = this.quantity <= 1 ? 0 : this.quantity - units;
    model.subtract(units);

    const record = {
      type: model.product.type_price,
      quantity: model.units,
      product: toJS(model.product),
    };

    SyncStore.putRecord(
      model.product.id_product_eva.toString(),
      JSON.stringify(record)
    );
  }

  /**
   * Eliminamos un producto del carro
   */
  @action remove(idproduct) {
    const model = AppStore.products.get(idproduct);
    this.quantity = this.quantity - model.units;
    model.units = 0;
    model.cartEntryIndex = null;
    this.products.delete(model);
    SyncStore.deleteRecord(model);
    if (this.quantity === 0) {
      this.products.clear();
      this.isEmptyCart = true;
    }
  }

  /**
   * Acción previa al vaciado del carro.
   * Mostramos un Alert informando al usuario de lo que va a suceder.
   */
  emptyCart = (event) => {
    const confirmClick = (event) => this.empty();
    const cancelClick = (event) => UIStore.toggleAlert();
    UIStore.toggleAlert({
      type: "default",
      content: () => (
        <ToEmptyCarAlert onConfirm={confirmClick} onCancel={cancelClick} />
      ),
    });
  };

  /**
   * Vaciamos el carrito
   */
  @action empty(callback) {
    for (let model of this.products.values()) {
      model.units = 0;
      model.cartEntryIndex = null;
      SyncStore.deleteRecord(model);
    }
    this.products.clear();
    this.quantity = 0;
    this.isEmptyCart = true;
    sessionStorage.removeItem("ProductsWithoutAuthenticated");
    UIStore.hideAlert(); // NOTE: cerramos el Alert de confirmación
    console.info(`%cEl carrito se ha vaciado correctamente.`, "color: green;");
    if (callback && typeof callback === "function") return callback();
  }

  /**
   * Gestiona el tipo de visualización para el carrito de la compra
   * @param {[Number]} - type. 0 | 1.
   * 0: grid
   * 1: ticket
   */
  @action toggleView(type) {
    switch (type) {
      case 0:
        this.isGrid = true;
        break;
      case 1:
        this.isGrid = false;
        break;
      default:
    }
  }

  /**
   * Controlador previo al envio del carrito.
   * NOTE: Comprobamos si el usuario está logueado y si tiene una cuenta
   * vinculada para el proveedore seleccionado.
   * NOTE: Hay que tener en cuenta que en un futuro habrá probeedores con
   * los que se podrá completar el pedido desde Ilusia®.
   *
   * NOTE: pedimos los records del carrito para tenerlos a mano en el caso de
   * necesitarlos para pintar conflictos.
   */
  preSendCart = (event) => {
    if (!AuthStore.isUserAuthenticated()) {
      // Tracking.order_without_login(AppStore.providername);
      UIStore.toggleModal({
        content: () => <LoginModal onSubmit={AuthStore.logIn} />,
      });
    } else {
      let linked = false;
      for (let provider of config.AVAILABLE_PROVIDERS) {
        if (provider.id === AppStore.idprovider && provider.status === 1)
          linked = true;
      }

      SyncStore.sync(SyncStore.cartsConfig, () => {
        SyncStore.cartsConfig.get(
          AppStore.idprovider.toString(),
          (error, value) => {
            if (error) console.error(error);
            if (value) {
              console.log("A ver el status del cart");
              console.log(JSON.parse(value).status);
              if (JSON.parse(value).status !== 0) {
                UIStore.hideLoading();
                UIStore.showNotification({
                  type: "danger",
                  content: () => (
                    <Notification message="¡Vaya! Parece que tu carrito ya ha sido enviado anteriormente desde otro dispositivo." />
                  ),
                });
              } else {
                SyncStore.sync(SyncStore.cartProducts, () => {
                  SyncStore.getAllRecords(SyncStore.cartProducts, (records) => {
                    this.cognitoProducts = records
                      .map((record) => {
                        if (record.value !== "")
                          return JSON.parse(record.value);
                      })
                      .filter((record) => record !== undefined);
                  });
                  if (
                    AppStore.provider.integration_type === "D" &&
                    AuthStore.user.addresses.length !== 0
                  ) {
                    UIStore.hideModal();
                    browserHistory.push(
                      `/compra`
                    );
                  } else if (
                    AppStore.provider.integration_type === "D" &&
                    AuthStore.user.addresses.length === 0
                  ) {
                    UIStore.toggleModal({
                      content: () => <AddAddressModal onSubmit={postAddress} />,
                    });
                  } else {
                    if (!linked) {
                      // Tracking.order_without_provider_account(
                      //   AuthStore.user.email,
                      //   AppStore.providername
                      // );
                      UIStore.toggleAlert({
                        type: "default",
                        content: () => (
                          <NoLinkedProviderAccountAlert
                            onConfirm={this.goToLinkProviderAccount}
                            onCancel={UIStore.toggleAlert}
                          />
                        ),
                      });
                      // TODO: volver a setear el status cel cart a 0
                    } else {
                      UIStore.toggleAlert({
                        type: "default",
                        content: () => (
                          <ToSendCarAlert
                            onConfirm={this.sendCart}
                            onCancel={UIStore.toggleAlert}
                          />
                        ),
                      });
                    }
                  }
                });
              }
            } else {
              SyncStore.sync(SyncStore.cartProducts, () => {
                SyncStore.getAllRecords(SyncStore.cartProducts, (records) => {
                  this.cognitoProducts = records
                    .map((record) => {
                      if (record.value !== "") return JSON.parse(record.value);
                    })
                    .filter((record) => record !== undefined);
                });
                if (
                  AppStore.provider.integration_type === "D" &&
                  AuthStore.user.addresses.length !== 0
                ) {
                  UIStore.hideModal();
                  // browserHistory.push(`/${AppStore.countryname}/${AppStore.postalcode}/${AppStore.providername}/Compra`)
                  browserHistory.push(
                    `/compra`
                  );
                } else if (
                  AppStore.provider.integration_type === "D" &&
                  AuthStore.user.addresses.length === 0
                ) {
                  UIStore.toggleModal({
                    content: () => <AddAddressModal onSubmit={postAddress} />,
                  });
                } else {
                  if (!linked) {
                    // Tracking.order_without_provider_account(
                    //   AuthStore.user.email,
                    //   AppStore.providername
                    // );
                    UIStore.toggleAlert({
                      type: "default",
                      content: () => (
                        <NoLinkedProviderAccountAlert
                          onConfirm={this.goToLinkProviderAccount}
                          onCancel={UIStore.toggleAlert}
                        />
                      ),
                    });
                    // TODO: volver a setear el status cel cart a 0
                  } else {
                    UIStore.toggleAlert({
                      type: "default",
                      content: () => (
                        <ToSendCarAlert
                          onConfirm={this.sendCart}
                          onCancel={UIStore.toggleAlert}
                        />
                      ),
                    });
                  }
                }
              });
            }
          }
        );
      });
    }
  };

  /**
   * Envia el carrito.
   *
   * - Modificamos el status del cart en AWS Cognito y sync.
   * - Llamamos a 'postOrder' que nos devuelve un 'id' de pedido si todo va bien
   *   o un código de error.
   * - Si disponemos de 'id' finaliza el proceso de envio de carrito.
   * - Guardamos la cantidad del carrito enviado.
   */
  sendCart = (event) => {
    this.AmountOrder = this.price.toFixed(2);
    console.info(`%cEnviando pedido a DB`, "color: blue;");
    if (AppStore.provider.integration_type !== "D") UIStore.toggleAlert();

    UIStore.showLoading();

    SyncStore.setCartStatus(0, (cart) => {
      console.log(config.CART_CONFIG);
      postOrder(AppStore.idprovider, AppStore.provider.integration_type, AuthStore.bookingId).then(
        (data) => {
          console.log("Response of post oreder ->", data);
          if (data.id_order === null) {
            SyncStore.setCartStatus(0);
            console.log(config.CART_CONFIG);
            if (data.code === "5001") {
              // NOTE: dataset 'carts' está mal
              UIStore.showNotification({
                type: "danger",
                content: () => (
                  <Notification message="Se ha producido un error técnico en el carrito, por favor póngase en contacto con Atención al Cliente para que podamos solucionarlo" />
                ),
              });
            }
            if (data.code === "5002") {
              // NOTE: problemas con productos
              // Este caso será borderline ya que la comprobación la hacemos
              // al cargar el catálogo y sincronizar el carrito.
              // 0: unknown error
              // 1: invalid product id
              // 2: no in zip code
              // 3: invalid acronim
              // 4: invalid quantity type
              // 5: invalid price
              // 6: invalid quantity
              if (AppStore.provider.integration_type === "D") {
                UIStore.showNotification({
                  type: "danger",
                  content: () => (
                    <Notification message="Se ha producido un error procesando el pedido, por favor inténtelo de nuevo más tarde. Si persiste el error, ponte en contacto con atención al cliente" />
                  ),
                });
                this.clearCartConflicts();
              } else {
                this.setCartConflicts(
                  data.context,
                  this.cognitoProducts,
                  () => {
                    UIStore.toggleAlert({
                      type: "default",
                      content: () => (
                        <ShoppingCartConflictsAlert
                          onConfirm={() => {
                            UIStore.toggleAlert();
                            this.clearCartConflicts();
                          }}
                        />
                      ),
                    });
                  }
                );
              }
              this.shoppingCartForceUpdate = !this.shoppingCartForceUpdate;
              UIStore.hideLoading();
            }
            if (data.code === "5003") {
              // NOTE: no se ha podido hacer login
              UIStore.showNotification({
                type: "danger",
                content: () => (
                  <Notification message="Parece que su cuenta vinculada no está actualizada. Por favor, compruebe sus datos e inténtelo de nuevo." />
                ),
              });
            }
            if (data.code === "5004") {
              UIStore.showNotification({
                type: "danger",
                content: () => (
                  <Notification
                    message="Se ha producido un error y no podemos procesar tu solicitud. Ponte en
                  contacto con atención al cliente para recibir ayuda."
                  />
                ),
              });
              this.clearCartConflicts();
              UIStore.hideLoading();
            }
            if (data.code === "5005") {
              UIStore.showNotification({
                type: "danger",
                content: () => (
                  <Notification message="Reparto no disponible en el código postal de la dirección de entrega seleccionada" />
                ),
              });
              UIStore.hideLoading();
            }
            if (data.code === "5006") {
              UIStore.showNotification({
                type: "danger",
                content: () => (
                  <Notification message="El horario de reparto seleccionado no es válido" />
                ),
              });
              UIStore.hideLoading();
            }
            if (data.code === "5007") {
              UIStore.showNotification({
                type: "danger",
                content: () => (
                  <Notification message="Se ha producido un error y no podemos procesar tu solicitud. Ponte en contacto con atención al cliente para recibir ayuda" />
                ),
              });
              UIStore.hideLoading();
            }
            if (data.code === "5008") {
              UIStore.showNotification({
                type: "danger",
                content: () => (
                  <Notification message="Código de descuento invalido." />
                ),
              });
              UIStore.hideLoading();
            }
            if (data.code === "5009") {
              UIStore.showNotification({
                type: "danger",
                content: () => <Notification message={`${data.message}`} />,
              });
              UIStore.hideLoading();
            }
          } else {
            // NOTE: todo ok
            console.info(`%cID de pedido ${data.id_order}`, "color: green;");
            console.log(data);
            TrackingApartments.order_create(AuthStore.apartmentId);
            /* 
            UIStore.hideAlert() */
            // En este caso no podemos utilizar 'this.emppty' puesto que no debemos
            // sincronizar con AWS Cognito puesto que nuestro cart ha sido eliminado.
            for (let model of this.products.values()) {
              model.units = 0;
              model.cartEntryIndex = null;
            }
            //this.products.clear();
            //this.quantity = 0;
            if (AppStore.idprovider != 6 && AppStore.idprovider != 5) {
              this.isInProgress = false;
              //UIStore.hideLoading()
            }

            SyncStore.sync(SyncStore.cartsConfig);
            SyncStore.sync(SyncStore.cartProducts);

            if (data.code === "1000") {
              this.url = data.url;
              UIStore.hideAlert();
              this.goToPayment();

              /* UIStore.hideLoading() */
            } else {
              this.finishOrder(data.id_order);
            }
          }
        }
      );
    });
  };

  /**
   * Finaliza el proceso de envio de carrito
   * @param: {id} - id del pedido
   *
   * Llamamos a 'exportCart'
   */
  finishOrder(id) {
    console.info(`%cExportando pedido ${id}`, "color: blue;");
    AppStore.lastOrderID = id;
    exportCart(id).then((data) => {
      console.info(`%cRespuesta exportación pedido`, "color: green;");

      AuthStore.getProcessedOrders();
      this.isInProgress = false;

      this.isEmptyCart = true;

      SyncStore.initialize();

      UIStore.hideLoading();

      // response errors
      // 0: Error genérico y ningún producto ha sido exportado
      // 1: Todo el carrito ha sido exportado sin problema alguno
      // 2: Hubo algún problema exportando algunos de los productos (context)
      // 3: El usuario y pass de la página del proveedor no son correctos
      // 4: Hubo un error no controlado en el proveedor
      if (data.code === 0) {
        UIStore.showNotification({
          type: "danger",
          timeOut: 3000,
          content: () => (
            <Notification message="Se ha producido un error inesperado y no hemos podido tramitar tu pedido." />
          ),
        });

        OrderStore.reset();
        OrderStore.repeatOrder(
          id,
          AppStore.postalcode,
          AppStore.idzone,
          AppStore.idprovider,
          AppStore.providername
        );
      } else if (data.code === 1) {
        // Tracking.order_exported(
        //   AuthStore.user.email,
        //   AppStore.lastOrderID,
        //   AppStore.providername,
        //   this.AmountOrder,
        //   AppStore.ccid
        // );
        //In case it's DIA, we have to open the iframe on the web showing the clients cart there
        if (AppStore.idprovider == 5) {
          browserHistory.push(
            `/${AppStore.countryname}/${AppStore.postalcode}/${AppStore.providername}/Tramitar`
          );
          UIStore.hideLoading();
        } else if (AppStore.idprovider == 6) {
          getMasCartInformation().then((data) => {
            AppStore.CatInformationMas = data.data;

            if (data.code == 0) {
              UIStore.showNotification({
                type: "danger",
                content: () => (
                  <Notification
                    message={`Se ha producido un error conectando con ${capitalize(
                      AppStore.providername
                    )}.`}
                  />
                ),
              });
              UIStore.hideLoading();
            } else {
              //UIStore.toggleModal({ content: () => <FinisOrderMas onSubmit={ ShopStore.SubmitMas }/> })
              //UIStore.hideLoading()
              browserHistory.push(
                `/${AppStore.countryname}/${AppStore.postalcode}/${AppStore.providername}/Tramitar`
              );
              UIStore.hideLoading();
            }
          });
        } else {
          UIStore.toggleModal({
            clickOnBackgroundClose: false,
            content: () => <FinisOrderModal />,
          });
        }
      } else if (data.code === 2) {
        // Tracking.order_exported(
        //   AuthStore.user.email,
        //   AppStore.lastOrderID,
        //   AppStore.providername,
        //   this.AmountOrder,
        //   AppStore.ccid
        // );
        UIStore.hideLoading();
        // NOTE: problemas con algunos productos
        UIStore.toggleAlert({
          type: "default",
          content: () => (
            <ExportCartConflictsAlert
              onConfirm={() => {
                this.setExportationCartConflicts(data.context);
              }}
              onCancel={() => {
                UIStore.toggleAlert();
              }}
            />
          ),
        });
      } else if (data.code === 3) {
        UIStore.hideLoading();
        UIStore.toggleAlert({
          type: "default",
          content: () => (
            <OrderLoginErrorAlert
              onConfirm={() => {
                UIStore.toggleAlert();
                AppStore.userZoneView = 2;
                browserHistory.push(`/zona-usuario`);
              }}
              onCancel={() => {
                UIStore.toggleAlert();
              }}
            />
          ),
        });
      } else if (data.code === 4) {
        UIStore.hideLoading();
        UIStore.showNotification({
          type: "danger",
          content: () => (
            <Notification
              message={`La web de ${capitalize(
                AppStore.providername
              )} no responde.`}
            />
          ),
        });
      }
    });
  }

  /**
   * Setea los conflictos devueltos por la API al intentar exportar el carrito
   * al proveedor correspondiente.
   *
   * NOTE: No confundir con los conflictos de 'checkCart' o 'postOrder'
   *
   * @param [{Array}]
   */
  setExportationCartConflicts(conflicts) {
    UIStore.toggleAlert();

    // context
    for (let conflict of conflicts) {
      let product = AppStore.products.get(conflict.product);
      let obj = {};
      obj.description = product.product.description;
      obj.image = product.product.image;
      obj.brand = product.product.brand;

      if (conflict.type === 1) {
        // NOTE: Este producto no existe o no está disponible
        obj.conflictTitle = "Artículo no disponible:";
        obj.conflictText = "Este artículo no existe o no está disponible.";
      } else if (conflict.type === 2) {
        // NOTE: La cantidad que se ha querido meter en el carrito y la que hay en el carrito del proveedor tras la exportación, no coincide
        obj.conflictTitle = "Cantidad incorrecta:";
        obj.conflictText = `Se han introducido ${conflict.quantity} unidades de este artículo en el carro. Por favor, revisa que es correcto.`;
      } else if (conflict.type === 3) {
        // NOTE: El precio del proveedor y el nuestro no coincide
        obj.conflictTitle = "El precio ha cambiado:";
        obj.conflictText = `${product.product.price.toFixed(
          2
        )} EUR (Antes) - ${conflict.price.toFixed(2)} EUR (Actual)`;
      }

      this.orderConflicts.push(obj);
    }

    browserHistory.push("/revision-conflictos");
  }

  /**
   * Comprueba si existen conflictos entre los productos del carrito almacenado
   * en AWS Cognito y los productos del catálogo.
   *
   * @param [{Array}] - Array con los productos de AWS Cognito
   *                    Necesário para poder pintar los datos en el carrito.
   *
   *
   */
  checkCartConflicts = (records) => {
    if (!AppStore.idprovider) return;
    checkCart(AppStore.idprovider).then((data) => {
      // console.log('checkCart response');
      // console.log(data);
      if (data.code === "5002") {
        this.setCartConflicts(data.context, records);
      }
    });
  };

  /**
   * Recupera los conflictos existentes con el carrito.
   */
  setCartConflicts(reasons, records, callback) {
    for (let reason of reasons) {
      let conflict = {};
      conflict.id_product_eva = parseInt(reason.id_product_eva);

      if (records && reason.reason === 5) {
        for (let record of records) {
          if (record.product.id_product_eva === conflict.id_product_eva)
            conflict.price = {
              bad: record.product.price,
              correct: reason.unit_price,
            };
        }
      } else if (records && reason.reason === 2) {
        SyncStore.getRecord(reason.id_product_eva, (record) => {
          conflict.status = "deleted";
        });
      }

      // TODO: controlar el resto de errores
      reason.reason === 5
        ? (conflict.status = "updated")
        : (conflict.status = "deleted");
      this.conflicts.push(conflict);
    }

    if (this.conflicts.length) this.mapCartConflicts(callback);
    else if (!this.conflicts.length && callback) return callback();
  }

  /**
   * Mapea los conflictos exiistentes en el carrito a los productos del
   * catalogo para poder disponer de ellos al pintar el carrito.
   */
  mapCartConflicts(callback) {
    for (let product of this.products.values()) {
      for (let conflict of this.conflicts) {
        if (
          product.product.id_product_eva === parseInt(conflict.id_product_eva)
        ) {
          product.status = {
            status: conflict.status,
            price: conflict.price,
          };
        }
      }
    }

    if (callback) return callback();
  }

  /**
   * Elimina los conflictos de los productos del carrito, resetea el Array
   * 'conflicts' y sincroniza el carrito de AWS Cognito.
   *
   */
  clearCartConflicts(callback) {
    for (let product of this.products.values()) {
      for (let conflict of this.conflicts) {
        if (product.product.id_product_eva === conflict.id_product_eva) {
          if (product.status && product.status.status === "deleted") {
            SyncStore.deleteRecord(product);
            this.remove(product.product.id_product_eva);
          } else if (product.status && product.status.status === "updated") {
            product.product.price = conflict.price.correct;
          }
        }
      }
    }
    this.conflicts = [];

    for (var item of this.products.values())
      SyncStore.putRecordsFromModel(this.products);

    if (callback) return callback();
  }

  /**
   * Elimina los status de conflictos para los productos
   */
  clearProductConflictStatuss(callback) {
    for (let product of this.products.values()) {
      if (product.status) delete product.status;
    }

    if (callback) return callback();
  }

  /**
   * Maneja la confirmación para linkar una cuenta de proveedor desde
   * el dialogo que se muestra al enviar carrito.
   */
  goToLinkProviderAccount = (event) => {
    UIStore.toggleAlert();
    AppStore.userZoneView = 2;
    browserHistory.push(`/zona-usuario`);
  };

  goToPayment = () => {
    UIStore.hideLoading();
    browserHistory.push(
      `/pagar`
    );
  };

  /*
Finalización de pedido en MAS
Errores posibles:
* const _CODE_UNKNOWN_ERROR = 0;
* const _CODE_SUCCESS = 1;
* const _CODE_UNEXPECTED_PAY_METHOD = 2;
* const _CODE_UNEXPECTED_REPLACEMENT_METHOD = 3;
* const _CODE_EMPTY_CART = 4;
*/

  SubmitMas = (
    idaddress,
    start_date,
    start_time,
    end_time,
    pay,
    serve,
    comments
  ) => {
    UIStore.showLoading();
    if (
      !pay ||
      !idaddress ||
      !start_time ||
      !end_time ||
      !start_date ||
      !serve
    ) {
      UIStore.showNotification({
        type: "danger",
        content: () => (
          <Notification message="Es necesario rellenar todos los campos obligatorios" />
        ),
      });
      UIStore.hideLoading();
    } else {
      postCheckoutMas(
        idaddress,
        start_date,
        start_time,
        end_time,
        pay,
        serve,
        comments
      ).then((data) => {
        if (data.code == 0) {
          UIStore.showNotification({
            type: "danger",
            content: () => (
              <Notification message="Se ha producido un error procesando el pedido, por favor, inténtalo de nuevo." />
            ),
          });
          UIStore.hideLoading();
        } else if (data.code == 2) {
          UIStore.showNotification({
            type: "danger",
            content: () => (
              <Notification message="El método de pago elegido es incorrecto" />
            ),
          });
          UIStore.hideLoading();
        } else if (data.code == 3) {
          UIStore.showNotification({
            type: "danger",
            content: () => (
              <Notification message="El método de sustitución de productos es incorrecto" />
            ),
          });
          UIStore.hideLoading();
        } else if (data.code == 4) {
          UIStore.showNotification({
            type: "danger",
            content: () => (
              <Notification message="¡Vaya! Parece que tu pedido ya ha sido finalizado anteriormente desde otro dispositivo." />
            ),
          });
          UIStore.hideLoading();
        } else {
          if (pay == 1) {
            UIStore.showLoading();
            UIStore.hideModal();
            UIStore.toggleModal({
              clickOnBackgroundClose: false,
              content: () => <SuccessMas OrderID={data.data.id_order} />,
            });
            UIStore.hideLoading();
          } else {
            UIStore.showLoading();
            if (data.data.url) {
              UIStore.hideModal();
              AppStore.iframeurl = data.data.url;

              browserHistory.push(
                `/${AppStore.countryname}/${AppStore.postalcode}/${AppStore.providername}/Tramitar`
              );
            } else {
              UIStore.showNotification({
                type: "danger",
                content: () => (
                  <Notification message="Se ha producido un error finalizando el pedido, por favor, inténtalo de nuevo." />
                ),
              });
            }
            UIStore.hideLoading();
          }
        }
      });
    }
  };

  /**
   * Reseteando datos...
   */
  reset() {
    if (this.dropdownInstance) this.dropdownInstance.setActiveItem(0);
    this.products.clear();
    this.products = undefined;
    this.products = new Set();
    this.quantity = 0;
    this.isGrid = true;
    this.isEmptyCart = false;
    this.isInProgress = false;
    this.conflicts = [];
    this.orderConflicts = [];
    this.cognitoProducts = [];
  }
}

export default new ShopStore();
