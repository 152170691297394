
import React from 'react'

const ChildButton = (props) => <button className={ `child-button level_${ props.level }` }
                                        id={ props.id }
                                        onClick={ props.onClick }>
  <p className="p light s16 white">{ props.placeholder }</p>
</button>


export default ChildButton
