// Component for deals type 1 and 3
import React, { Component } from 'react'

class ProductDeals_1 extends Component {

  render () {

  	var text = null
  	var text2 = null
  	var classname = this.props.class1
  	if (this.props.type == 4){
  		text = <p className="p bold white s20">-{ this.props.deal }<span className="p regular white s12">%</span></p>
  	}else if (this.props.type == 1){
  		text = <p className="p bold yellow_deal s20">{ this.props.deal }</p>
  	}

		return (
			<div className={classname}>
				{text}
			</div>
		)
	}
}

export default ProductDeals_1
