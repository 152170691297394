
import React from 'react'

const trash = () => {
  return (
    <g>
      <path d="M164.311,45.643c-0.34,2.26-0.949,4.471-1.521,7.125C120.58,41.467,78.775,30.275,36.664,19.002
       c1.594-6.289,3.268-12.27,8.467-16.42C47.375,0.793,49.889,0,52.521,0c1.262,0,2.545,0.184,3.852,0.523
       c17.744,4.682,35.473,9.422,53.201,14.158c13.832,3.695,27.602,7.654,41.508,11.043C160.75,28.086,166.254,32.859,164.311,45.643z
        M36.01,68.387c41.123,0,81.795,0,122.762,0c0,0.875,0.055,1.662-0.004,2.439c-1.564,20.236-3.145,40.463-4.697,60.695
       c-1.193,15.475-2.219,30.961-3.604,46.414c-0.35,3.855-1.234,7.873-2.855,11.357c-2.906,6.27-8.289,9.412-15.27,9.424
       c-1.473,0-2.951,0.004-4.42,0.004c-11.898,0-23.795-0.062-35.693-0.062c-9.328,0-18.65,0.037-27.982,0.178c-0.119,0-0.236,0-0.357,0
       c-10.127,0-18.268-6.402-19.211-18.098c-2.363-29.287-4.51-58.592-6.752-87.885C37.311,84.812,36.664,76.77,36.01,68.387z
        M53.936,84.957c0.17,2.211,0.344,4.424,0.51,6.635c0.684,8.943,1.359,17.896,2.041,26.85c1.508,19.99,3.068,40.664,4.705,60.965
       c0.229,2.73,0.828,2.859,2.697,2.859c8.342-0.125,17.311-0.178,28.34-0.178c5.967,0,11.928,0.016,17.887,0.031
       c5.939,0.012,11.871,0.033,17.807,0.033l4.398-0.006c0.131,0,0.244-0.006,0.346-0.01c0.631-1.445,1.102-3.502,1.293-5.682
       c0.947-10.537,1.744-21.33,2.51-31.768c0.357-4.811,0.713-9.623,1.078-14.434c0.863-11.246,1.738-22.49,2.611-33.742l0.895-11.555
       H53.936z"/>
    </g>
  )
}

export default trash
