
import React, { Component } from 'react'
import { Rack } from './'
import { Notification } from '../Dialogs/Notification/components'
import { UIStore } from '../../stores'

class ShopLineView extends Component {
	render() {
		// NOTE: peek() es un método de mobx que devuelve un Array con todos los
		// valores que se pueden pasar con seguridad del observable array,
		// similar a slice()
		try {
			console.log(this.props)
			const racks = this.props.racks.peek()
				.map((rack, i) => <Rack key={i} products={rack.peek()} layout={this.props.layout} />)
			return <div className={`shop-line_view ${this.props.className}`}>{racks}</div>
		} catch (e) {
			console.log(e)
			UIStore.showNotification({ type: 'danger', content: () => <Notification message="Se ha producido un error cargando la tienda" /> })
			return null
		}

	}
}


export default ShopLineView
