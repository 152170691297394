
import React from 'react'

const linked_profiles = () => {
  return (
    <g>
      <path d="M88.475,121.624l-14.022-14.022c-2.761-2.761-2.761-7.237,0-9.998c2.761-2.761,7.237-2.761,9.998,0
       l14.022,14.022c10.009,10.009,26.295,10.009,36.304,0c10.008-10.009,10.008-26.295,0-36.304L81.095,21.64
       c-10.009-10.009-26.295-10.009-36.304,0c-10.009,10.009-10.009,26.295,0,36.304l2.743,2.742c2.761,2.761,2.761,7.238,0,9.999
       c-2.761,2.761-7.238,2.761-9.999,0l-2.743-2.742c-15.522-15.522-15.522-40.778,0-56.3c15.522-15.523,40.778-15.523,56.301,0
       l53.682,53.682c15.522,15.522,15.522,40.778,0,56.3C129.253,137.147,103.997,137.147,88.475,121.624z"/>
     <path d="M108.907,188.358l-53.682-53.682c-15.522-15.523-15.522-40.779,0-56.301
       c15.523-15.522,40.778-15.522,56.3,0l14.022,14.023c2.761,2.761,2.761,7.238,0,9.998c-2.761,2.761-7.237,2.761-9.998,0
       l-14.022-14.022c-10.009-10.009-26.295-10.009-36.304,0s-10.009,26.295,0,36.304l53.682,53.682
       c10.009,10.009,26.295,10.009,36.304,0s10.009-26.295,0-36.304l-2.742-2.742c-2.761-2.761-2.761-7.238,0-9.999
       c2.761-2.761,7.237-2.761,9.998,0l2.742,2.743c15.523,15.522,15.523,40.778,0,56.3
       C149.685,203.881,124.429,203.881,108.907,188.358z"/>
    </g>
  )
}

export default linked_profiles
