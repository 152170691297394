
/**
 * @func: requireAuth
 * @param {[Object]} nextState
 * @param {[Function]} replaceState
 *
 * @description: comprueba si el usuario está autenticado.
 * En el caso de no estar autenticado redirige al login y muestra una
 * notificación informando al usuario del porque de la redirección.
 */

import React from 'react'
import { AuthStore, UIStore } from '../../stores'
import { Notification } from '../../components/Dialogs/Notification/components'

const requireAuth = (nextState, replaceState) => {
  if (!AuthStore.isUserAuthenticated()) {
    UIStore.showNotification({ type: 'danger', content: () => <Notification message="Necesitas estar registrado como usuario para acceder a esta zona." /> })
    AuthStore.prevState = nextState.location.pathname
    replaceState({ pathname: 'login', state: { nextPathname: nextState.location.pathname } })
  }
}

export default requireAuth
