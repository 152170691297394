
import React from 'react'

/**
 * @func: Image
 * @param: {[Object]} props
 *
 * @description:
 */

const Image = (props) => <div className="bg" style={{ backgroundImage: `url(${ props.source })` }}></div>

export default Image
