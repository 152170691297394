
/**
 * @func: onLeaveShop
 * @param {[Object]} nextState
 * @param {[Function]} replaceState
 *
 * @description:
 */

import React from 'react'
import { UIStore } from '../../stores'
import { synOnEnter } from './'

const onLeaveShop = (nextState, replaceState) => {
  if (UIStore.isCategoriesOpen) UIStore.isCategoriesOpen = false

  synOnEnter()
}

export default onLeaveShop
