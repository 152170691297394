import React from "react";

const repeatCart = () => {
  return (
    <g>
      <path
        className="cls-1"
        d="M228,0A229.71,229.71,0,0,1,368.25,48L391.5,27.75c2.25-1.5,4.5-2.25,7.5-.75,2.25.75,3.75,3,4.5,5.25l9,83.25a12.1,12.1,0,0,1-2.25,6.75,7.29,7.29,0,0,1-6.75.75L326.25,99c-2.25-.75-4.5-3-4.5-5.25Q320.63,89.25,324,87l20.25-18A194.59,194.59,0,0,0,228,30.75C119.25,30.75,30.75,119.25,30.75,228a194.76,194.76,0,0,0,10.5,63A8.46,8.46,0,0,1,39,299.25L24.75,309c-2.25,1.5-4.5,2.25-6,1.5-2.25-.75-4.5-2.25-5.25-4.5C4.5,282,0,255,0,228,0,102,102,0,228,0ZM441.75,150A221.67,221.67,0,0,1,456,228c0,126-102,228-228,228-53.25,0-102-18-140.25-48.75L64.5,427.5A7.16,7.16,0,0,1,57,429a6.53,6.53,0,0,1-4.5-6l-9-82.5a6.93,6.93,0,0,1,2.25-6.75c2.25-1.5,4.5-2.25,6.75-1.5L129.75,357c2.25.75,3.75,2.25,4.5,5.25A6,6,0,0,1,132,369l-20.25,18C144,411,184.5,424.5,228,424.5A196.21,196.21,0,0,0,424.5,228c0-22.5-3-43.5-9.75-63-1.5-3,0-6,2.25-8.25l14.25-10.5c1.5-1.5,3.75-1.5,6-.75A6.76,6.76,0,0,1,441.75,150Z"
      />
      <path
        className="cls-1"
        d="M255.75,197.25a10.25,10.25,0,0,1,10.5,10.5v46.5a10.5,10.5,0,0,1-21,0v-46.5A10.25,10.25,0,0,1,255.75,197.25Zm50.25,0a10.92,10.92,0,0,1,9,12l-6.75,46.5c-.75,6-6,9.75-12,9s-9.75-6-9-12l6.75-46.5C294.75,200.25,300,196.5,306,197.25Zm-105,0c6-.75,11.25,3,12,9L221.25,252A11.67,11.67,0,0,1,213,264.75a11.53,11.53,0,0,1-12.75-9L192.75,210C191.25,204,195,198.75,201,197.25Zm108.75,114a25.29,25.29,0,0,1,25.5,25.5c0,13.5-11.25,24.75-25.5,24.75s-25.5-11.25-25.5-24.75A25.29,25.29,0,0,1,309.75,311.25Zm-111,0a25.29,25.29,0,0,1,25.5,25.5c0,13.5-11.25,24.75-25.5,24.75A25,25,0,0,1,174,336.75C174,322.5,185.25,311.25,198.75,311.25Zm-37.5-142.5h183c10.5,0,19.5,7.5,15.75,20.25l-26.25,96c-3,9-7.5,12-18,12H179.25c-9.75,0-15.75-7.5-18-16.5L129,151.5a7.2,7.2,0,0,0-6.75-5.25H109.5c-24,0-23.25-29.25-.75-29.25h21a23.41,23.41,0,0,1,22.5,16.5Z"
      />
    </g>
  );
};

export default repeatCart;
