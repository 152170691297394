
import React from 'react'

const cart_mercadona = () => {
  return (
  	<g transform="scale(1.05) translate(0, -5)">

        <path fill="#FFFFFF" d="M89.653,6.475c20.141-1.798,40.845,3.198,57.807,14.229
          c17.971,11.477,31.692,29.454,37.947,49.84c6.157,19.671,5.448,41.408-2.067,60.589c-7.998,20.722-23.792,38.265-43.548,48.336
          c-13.232,6.812-28.121,10.352-43.01,10.071c-21.419-0.061-42.704-8.102-58.841-22.19C19.91,151.99,8.292,129.298,6.47,105.647
          C4.593,84.833,10.212,63.475,21.989,46.22C37.134,23.748,62.638,8.7,89.653,6.475 M84.914,13.292
          c-11.93,1.828-23.468,6.145-33.581,12.743C34.236,37.029,21.328,54.352,15.721,73.9c-5.491,18.821-4.286,39.519,3.418,57.575
          c7.191,16.937,20.031,31.386,35.99,40.54c17.689,10.352,39.201,13.666,59.263,9.783c19.787-3.797,38.063-14.932,50.482-30.818
          c9.245-11.569,15.378-25.614,17.5-40.283c3.265-20.735-1.565-42.637-13.22-60.082c-11.33-17.225-29.105-30.12-49.07-35.33
          C108.675,12.112,96.605,11.825,84.914,13.292"/>
        <path  fill="#FFFFFF" d="M29.765,67.137c3.644-1.253,7.478-0.379,10.835,1.296
          c-0.275,2.207-0.416,4.439-0.416,6.677c5.259,3.033,11.281,4.091,17.133,5.399c-0.294,0.789-0.862,2.403-1.143,3.192
          c-8.787-1.834-18.148-0.923-26.091,3.351c-2.299-0.538-4.433-1.651-5.014-4.14c-0.703-2.837-5.057-3.326-4.916-6.42
          C21.112,71.197,29.025,72.689,29.765,67.137"/>
        <path  fill="#FFFFFF" d="M94.557,40.353c-0.141-2.024,1.59-3.498,3.375-3.993
          c2.324-1.229,4.439,0.844,6.451,1.767c0.208,17.842,0.306,35.691,0.232,53.545c13.574,0.055,27.13,0,40.699,0.012
          c4.14-0.165,8.456,3.253,8.31,7.613c-0.135,14.467-0.037,28.959-0.079,43.438c0.22,4.03-2.623,7.857-6.396,9.141
          c-27.43-0.624-54.94-0.122-82.394-0.318c-4.17-0.177-8.31,0.532-12.468,0.342c-3.504-1.009-6.64-4.378-6.506-8.175
          c-0.037-11.716,0.049-23.425-0.018-35.141c0.037-3.889-0.666-7.857,0.122-11.722c1.272-3.363,5.032-5.442,8.567-5.191
          c13.348,0.031,26.708,0.012,40.057,0.012C94.557,74.583,94.429,57.468,94.557,40.353"/>
        <path fill="#FFFFFF" d="M153.609,51.274c3.241,0,5.54,2.385,7.876,4.268
          c-2.684,4.757-7.386,8.236-9.123,13.556c-2.617,6.53-0.929,14.528-5.968,20.111c-9.734-0.483-19.487-0.177-29.228-0.018
          c4.855-5.662,8.285-12.529,13.966-17.482c2.721-2.495,6.402-3.332,9.704-4.775C147.073,63.772,151.115,57.627,153.609,51.274"/>
        <path  fill="#FFFFFF" d="M41.177,60.317c1.743-3.259,5.864-1.651,8.615-0.978
          c2.281,0.978,4.354-0.746,6.579-1.003c2.99,0.789,5.992,3.125,9.007,0.881c2.201,3.993,1.021,8.805,3.18,12.816
          c-1.694,0.801-3.436,1.498-5.191,2.128c-1.498,1.847-2.947,4.445-5.778,3.895c-5.216-0.734-10.248-2.427-15.335-3.767
          c0.269-1.908,0.611-3.797,1.027-5.68C41.654,66.248,39.41,63.227,41.177,60.317"/>
        <path  fill="#FFFFFF" d="M113.641,61.546c2.177-0.489,4.323,0.446,6.433,0.856
          c2.507,0.807,4.782-1.547,7.295-0.868c2.929,0.63,4.751,3.363,4.928,6.243c-8.75,4.329-12.62,13.807-18.234,21.199h-6.09
          c0.422-6.946-0.795-13.978,0.66-20.845C109.183,65.361,110.797,62.469,113.641,61.546"/>
        <path fill="#FFFFFF" d="M154.902,76.703c3.375-0.532,6.983-1.076,10.071,0.819
          c-1.058,3.125,0.838,5.797,2.158,8.475c1.192,2.617-1.651,4.537-2.966,6.451c-1.675,1.663-3.4,5.124-6.261,3.595
          c-2.605-1.932-4.14-5.02-7.001-6.702C152.505,85.221,153.979,81.026,154.902,76.703"/>
        <path  fill="#FFFFFF" d="M34.574,88.726c5.852-4.549,13.862-4.47,20.783-2.911
          c0.092,0.752,0.263,2.293,0.342,3.063C49.647,88.8,43.349,89.9,38.31,93.453c-2.055,1.357-4.421,4.097-6.812,1.645
          C30.471,92.468,32.819,90.316,34.574,88.726"/>
        <path  fill="#FFFFFF" d="M70.424,72.217c3.051-1.883,6.121-3.974,9.741-4.58
          c2.941-0.691,5.888,0.202,8.805,0.624c0.055,1.7,0.092,3.436,0.061,5.161c-2.776,0.245-5.411,1.186-7.9,2.403
          C77.951,73.648,74.233,72.59,70.424,72.217"/>
        <path  fill="#FFFFFF" d="M63.264,77.154C68.125,74.03,74.27,75.4,79.18,77.668
          c-3.32,3.014-5.271,7.062-5.68,11.52c-4.831-0.049-9.637-0.049-14.455,0C57.999,84.773,59.809,79.998,63.264,77.154"/>
        <path fill="#FFFFFF" d="M82.181,79.442c2.213-1.388,4.812-1.755,7.325-2.201
          c0.031,3.981,0.024,7.943,0.024,11.917c-4.231,0-8.456,0.031-12.688-0.061C77.797,85.581,78.604,81.233,82.181,79.442"/>

  	</g>
  )
}

export default cart_mercadona
