
import React, { Component } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { Icon } from '../'

class Loading extends Component {

  constructor (...args) {
    super(...args)
  }

  render () {
    return (
      <ReactCSSTransitionGroup transitionName={ this.props.transitionName }
                               transitionEnterTimeout={ 500 }
                               transitionLeaveTimeout={ 300 }>
        {(this.props.isOpen) ? this.renderContent() : null }
      </ReactCSSTransitionGroup>
    )
  }


  renderContent () {
    return (
      <div className="loading-wrapper" role="dialog">
        <div className="overhead-loader">
          <svg className="circular" width="65px" height="65px" viewBox="0 0 66 66" >
            <circle className="path" fill="none" strokeWidth="3" strokeLinecap="round" cx="33" cy="33" r="26"></circle>
          </svg>
          <Icon className="logo" type="hand" fill="white" size="40"></Icon>
        </div>
      </div>
    )
  }
}


export default Loading
