
import React, { Component } from 'react'
import { TweenMax, Back } from 'gsap'
import { UIStore } from '../../../stores'


class AnimatedQuantityBadge extends Component {

  componentDidMount () {
    const pos = (window.innerHeight / 4)
    TweenMax.to(this.refs.badge, 0.8, { bottom: pos + 'px',
                                        opacity: 1,
                                        ease: Back.easeInOut.config(3) })
    TweenMax.to(this.refs.badge, 0.4, { bottom: (pos - 80) + 'px',
                                        opacity: 0,
                                        ease: Back.easeOut,
                                        delay: 0.8,
                                        onComplete: () => UIStore.removeAnimatedQuantityBadge(this.props.index) })
  }

  render () {
    const quantity = this.props.units + 1

    return (
      <div ref="badge" className="animated-badge">
        <h1 className="h1 bold black s36">{ quantity }</h1>
      </div>
    )
  }
}

export default AnimatedQuantityBadge
