
import config from '../../config'

/**
 * @func: getAllergens
 * @return {[Object]} data
 *
 * @description:
 */

const getAllergens = (lan) => {
  const params = { language: lan }
  return config.API.allergensGet(params, {}, {})
  .then(result => result.data)
  .catch(error => error)
}


export default getAllergens
