
import React, { Component } from 'react'
import { translate } from 'react-i18next'


@translate(['common'], { wait: false })
class NotFound extends Component {

  constructor (...args) {
    super(...args)
  }

  render () {
    return (
      <div className="section">
        <div className="absolute-center">
          <h1>Oh, Oh...</h1>
          <h2>404</h2>
          <h2>La página que estás buscando no existe.</h2>
        </div>
      </div>
    )
  }
}


export default NotFound
