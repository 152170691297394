
import React from 'react'
import { Link } from 'react-router'
import { AppStore } from "../../stores"

/**
 * @func: ProviderCartLink
 * @param: {[Object]} props
 *
 *
 * @description:
 */

const ProviderCartLink = (props) => {
  return (
    <Link className="link provider-cart-link"
                                            to={ props.link } >
  <img src={ (AppStore.provider.ilusia === 0) ? `/img/carts/${ props.provider.toLowerCase() }.png` : `/img/carts/ilusia.png` } alt="ALT_TEXT_GOES_HERE"/>
</Link>
  )
}

export default ProviderCartLink
