
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import config from '../../config'
import { AppStore } from '../../stores'
import ProductImage from './components/ProductImage'
import QuantityBadge from './components/QuantityBadge'
import PriceTag from './components/PriceTag'
import { IconButton, LinkIconButton } from '../../components'
import { urliFy } from '../../utils'
import ProductDeals from './components/ProductDeals'

@observer
class ShopProduct extends Component {

  render () {
    const { description, image, price, type_price, id_product_eva, brand, width, height } = this.props.product.product
    const currency = (type_price === 1) ? ' €' : ' $'
    const filter = this.props.filter
    var pricetag = <PriceTag type="" price={ (price).toFixed(2) } currency={ currency } font={ this.props.font } color={ this.props.color } tag={ this.props.tag } />
    if (typeof this.props.product.product !== "undefined"){
        if(typeof this.props.product.product.deal !== "undefined") {
            if (this.props.product.product.deal.id_type == 3){
                pricetag = <PriceTag type="" price={ (price).toFixed(2) } currency={ currency } font={ this.props.font } color="white" tag="feb2_114508.png" />
            }
        }
    }
    var product = null
    if (filter === 1){
          product = <li className="rack-product" onMouseEnter={ this.props.onMouseEnter } onMouseLeave={ this.props.onMouseLeave }>
          <ProductImage src={ image }
                          filter = { filter }
                          shadow={ (this.props.shadow === true) ? true : false }
                          width={ this.props.product.product.width }
                          height={ this.props.product.product.height }
                          description={ description || '' }
                          brand={ brand || '' } />
          </li>
    } else {
          product =
              <li className="rack-product" onMouseEnter={ this.props.onMouseEnter } onMouseLeave={ this.props.onMouseLeave }>
                <ProductImage src={ image }
                              filter = { filter }
                              shadow={ (this.props.shadow === true) ? true : false }
                              width={ this.props.product.product.width }
                              height={ this.props.product.product.height }
                              description={ description || '' }
                              brand={ brand || '' } 
                              product={this.props.product.product}
                              />
                <IconButton id={ id_product_eva }
                            onClick={ this.props.onClick }
                            className="add"
                            type="fab"
                            color="grey"
                            size="large"
                            iconType="cart"
                            iconTitle="ALT_PARA_ICONO_AQUI" />
                <LinkIconButton onClick={ `/producto/${ id_product_eva }/${ urliFy(description) }` }
                                id={ id_product_eva }
                                className="detail"
                                type="fab"
                                color="blue"
                                iconType="see"
                                iconTitle="ALT_PARA_ICONO_AQUI" />
                <QuantityBadge units={ this.props.product.units } />
                
                { pricetag }

              </li>

    }
      return(product)
  }
}


export default ShopProduct
