import React from 'react'

const allergen_7 = () => {
  return (
        <g>
              <path d="M131.447,92.397c0.266,0.287,2.587,2.92,2.161,6.529c-0.15,1.274-1.303,2.184-2.575,2.034
                    c-0.538-0.065-1.014-0.307-1.369-0.663c-0.484-0.484-0.751-1.178-0.665-1.911c0.168-1.411-0.82-2.67-0.949-2.826l-4.089-4.089
                    l-0.234-0.234l-4.087-4.089c-0.158-0.129-1.416-1.116-2.828-0.949c-0.733,0.086-1.425-0.181-1.911-0.665
                    c-0.357-0.357-0.599-0.83-0.663-1.367c-0.148-1.274,0.762-2.426,2.034-2.576c3.611-0.424,6.242,1.897,6.531,2.161l4.205,4.205
                    l0.116,0.118l0.118,0.116L131.447,92.397z M89.671,119.385c0.579-0.459,0.881-1.136,0.881-1.822c0-0.504-0.161-1.01-0.497-1.437
                    c-0.794-1.006-2.253-1.178-3.261-0.384c-2.852,2.253-3.072,5.754-3.086,6.147l-0.001,5.947l0.001,0.166l-0.001,0.166l0.001,5.947
                    c0.015,0.392,0.234,3.893,3.088,6.145c1.006,0.793,2.465,0.623,3.259-0.383c0.336-0.424,0.497-0.933,0.497-1.437
                    c0-0.686-0.302-1.364-0.881-1.822c-1.116-0.88-1.308-2.466-1.327-2.67l0.002-5.78l-0.002-0.332v-5.782
                    C88.364,121.85,88.556,120.263,89.671,119.385z M90.105,63.11c0.457,0.581,1.136,0.883,1.822,0.883c0.502,0,1.009-0.163,1.437-0.499
                    c1.002-0.794,1.177-2.253,0.381-3.259c-2.252-2.854-5.754-3.073-6.145-3.089h-5.948h-0.165h-0.166h-5.947
                    c-0.392,0.016-3.893,0.236-6.147,3.089c-0.793,1.006-0.621,2.465,0.384,3.259c0.426,0.336,0.931,0.499,1.437,0.499
                    c0.684,0,1.364-0.302,1.821-0.883c0.881-1.115,2.468-1.307,2.671-1.327h5.78h0.331h5.783C87.637,61.802,89.226,61.994,90.105,63.11z
                     M199.999,99.999C199.999,155.229,155.229,200,100,200C44.772,200,0.001,155.229,0.001,99.999C0.001,44.772,44.772,0,100,0
                    C155.229,0,199.999,44.772,199.999,99.999z M76.351,90.059h9.94c7.322,0,13.255-5.934,13.255-13.255v-9.94
                    c0-7.32-5.934-13.254-13.255-13.254h-9.94c-7.322,0-13.254,5.934-13.254,13.254v9.94C63.097,84.126,69.03,90.059,76.351,90.059z
                     M133.022,88.971l-7.03-7.03c-5.175-5.176-13.568-5.176-18.743,0l-7.028,7.03c-5.178,5.176-5.178,13.568-0.003,18.745l2.228,2.226
                    h-9.02c-7.32,0-13.254,5.934-13.254,13.254v9.942c0,7.32,5.934,13.254,13.254,13.254h9.942c7.32,0,13.254-5.934,13.254-13.254
                    v-9.942c0-1.624-0.307-3.172-0.841-4.61c3.667,0.232,7.412-1.039,10.213-3.842l7.028-7.028
                    C138.199,102.54,138.199,94.148,133.022,88.971z"/>
        </g>
  )
}

export default allergen_7