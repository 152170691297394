import React from 'react'

const allergen_11 = () => {
  return (
        <g>
        <path d="M99.771,101.564l-11.15,0.011c-3.666,0-6.585-0.011-9.289-0.037l-0.889-0.008l0.056,0.888
            c0.384,6.047,1.37,10.996,3.104,15.575c2.437,6.421,5.53,10.839,9.731,13.903c2.656,1.934,5.436,2.915,8.262,2.915
            c1.131,0,2.291-0.155,3.448-0.46c3.864-1.014,7.156-3.454,10.063-7.462c4.736-6.542,7.226-14.311,7.836-24.449l0.053-0.878
            l-8.741,0.005L99.771,101.564z"/>
        <path d="M100.001,0.001c-55.229,0-100,44.771-100,99.999c0,55.229,44.771,99.999,100,99.999
            c55.228,0,99.998-44.77,99.998-99.999C199.999,44.772,155.228,0.001,100.001,0.001z M115.526,128.645
            c-3.328,4.586-7.162,7.398-11.723,8.595c-1.409,0.37-2.824,0.557-4.206,0.557c-3.472,0-6.844-1.174-10.022-3.488
            c-4.691-3.422-8.112-8.269-10.765-15.259c-1.955-5.162-3.023-10.794-3.364-17.723c-0.033-0.681,0-1.537,0.562-2.128
            c0.551-0.578,1.366-0.659,2.165-0.659c3.552,0.037,7,0.047,10.448,0.047l11.15-0.01l12.485,0.001l9.586-0.008
            c0.443,0,1.186,0,1.695,0.528c0.349,0.361,0.508,0.877,0.483,1.572C123.595,112.335,120.896,121.224,115.526,128.645z
             M126.236,94.464c-0.785,1.098-2.431,1.149-5.515,1.149l-29.627,0.002l-14.817-0.002c-1.326,0-2.056,0-2.547-0.563
            c-0.487-0.559-0.386-1.299-0.217-2.526c1.223-8.865,6.544-14.955,16.267-18.622c2.031-0.767,4.269-1.274,6.649-1.504l0.258-0.019
            c0.143-0.01,0.382-0.026,0.445-0.063c0.427-1.746,1.241-3.223,2.029-4.654c0.412-0.747,0.825-1.495,1.177-2.27l0.069-0.15
            c0.342-0.744,0.638-1.386,0.518-2.068l-0.169-0.968h3.888l0.069,0.751c0.186,2.045-0.683,3.666-1.524,5.232
            c-0.149,0.276-0.296,0.552-0.438,0.83c-0.2,0.392-0.466,0.799-0.737,1.207c-0.493,0.742-1.051,1.583-0.855,2.013
            c0.054,0.068,0.3,0.178,1.348,0.178l0.584-0.002c0.462,0,0.951,0.011,1.397,0.095c11.109,2.066,18,7.419,21.065,16.359
            C126.521,91.697,127.03,93.351,126.236,94.464z"/>
    </g>
  )
}

export default allergen_11