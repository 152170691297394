
/**
 * @func: shoppingCartAuth
 * @param {[Object]} nextState
 * @param {[Function]} replaceState
 *
 * @description:
 */

import React from 'react'
import { browserHistory } from 'react-router'
import { ShopStore, SyncStore, UIStore } from '../../stores'
import { splitURL, capitalize } from '../'
import { Notification } from '../../components/Dialogs/Notification/components'
import { ShoppingCartConflictsAlert } from '../../components/Dialogs/Alert/components'

const shoppingCartAuth = (nextState, replaceState) => {

  if (ShopStore.isEmptyCart) {
    UIStore.showNotification({ type: 'danger', content: () => <Notification message="Tu carrito está vacío." /> })

    const path = splitURL()
    const countryname = path[0]
    const postalcode = path[1]
    const provider = path[2]

    // NOTE: window.previousLocation se setea desde el metodo 'componentWillReceiveProps'
    // de AsyncRouteManager.
    if (window.previousLocation) {
      replaceState({
        pathname: window.previousLocation.pathname,
        state: { nextPathname: nextState.location.pathname }
      })
    } else if (countryname && postalcode && provider) {
      browserHistory.push(`/${ countryname }/${ postalcode }/${ capitalize(provider) }`)
    } else {
      browserHistory.push('/')
    }
  }

  // NOTE: Sincronizamos
  if (ShopStore.quantity > 0) SyncStore.forceCartSync()

  // NOTE: si al acceder al carro tenemos conflictos entre el carrito y el catálogo
  // actual lanzamos la alerta y los seteamos a los productos de nuestro carrito.
  if (ShopStore.conflicts.length) {
    UIStore.toggleAlert({
      type: 'default',
      content: () => <ShoppingCartConflictsAlert onConfirm={ () => {
        UIStore.toggleAlert ()
        ShopStore.clearCartConflicts()
      } } />
    })
  }
}

export default shoppingCartAuth
