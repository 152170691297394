

import React, { Component } from 'react'
import { ProductAccordionItem } from './'
import { pick } from '../../../utils'


class ProductAccordion extends Component {

  state = {
    current: undefined
  }



  render () {
    const info = pick(['ingredients', 'additional'], this.props.info)

    const items = Object.keys(info).map((key, idx) => {
      if (info[key] === '' || info[key] === null) return false

      let title
      if (key === 'ingredients') title = 'Ingredientes'
      if (key === 'additional') title = 'Información Adicional'

      return <ProductAccordionItem title={ title }
                                   content={ info[key] }
                                   id={ idx }
                                   current={ this.state.current }
                                   key={ idx }
                                   onClick={ this.onAccordionItemClick } />
    })

    return (
      <div className="container">
        <h4 className="h4 bold grey s16 upper">Información alimentaria</h4>
        <div className="product-info-accordion">
          { items }
        </div>
      </div>
    )
  }


  /**
   * Maneja el click de los elementos del acordeón.
   */
  onAccordionItemClick = (i, event) => {
    if (i === this.state.current) i = undefined
    this.setState({ current: i })
  }
}


export default ProductAccordion
