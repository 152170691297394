
import React from 'react'

/**
 * @func: Button
 * @param: {[Object]} props
 *
 * @props:
 * - placeholder: el texto que se mostrará en el botón.
 * - type: { normal | flat | block | outline | underline } @default: normal
 * - color: { blue | green | ??? } @default: ?????
 * - align: { left | center | right } @default: center
 * - onClickEvent: {[Event]}
 *
 * @description: Botón.
 */

const Button = (props) => <button form = { props.form } className={ `btn ${ props.type || 'normal' } ${ props.align || '' } ${ props.color || '' } ${ props.className || '' }` }
                                  id={ props.id || null }
                                  onClick={ props.onClick || null } disabled = {props.disabled}>
  { props.placeholder }
</button>

export default Button
