
/*****************************************************************************
 * @class: BackgroundTransitionManager
 * @description: Este contenedor es el encargado de controlar las transicines
 * de los fondos de la aplicación. Dado que el fondo cambia tanto al cambiar
 * de ruta como al cambiar de categoría en el lineal hemos optado por crear
 * este componente autónomo.
 *
 * @usage:
 *
 * // In App Component:
 * <BackgroundTransitionManager source={ UIStore.backgroundSource }
 *                              type={ UIStore.backgroundType }
 *                              transitionName="background" />
 *
 *
 * UIStore.setBackground('img/bg_underconstruction2.png', 'img')
 * UIStore.setBackground('video/video_mobile', 'video')
 * UIStore.setBackground({ firstcolor: '#685095', lastcolor: '#00BFDF', angle: -45 }, 'gradient')
 *****************************************************************************/

import React, { Component } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import config from '../../config'
import Image from './components/Image'
import Video from './components/Video'
import Gradient from './components/Gradient'



class BackgroundTransitionManager extends Component {

  static propTypes = {
    source: React.PropTypes.oneOfType([
      React.PropTypes.string,
      React.PropTypes.object,
    ]).isRequired,
    type: React.PropTypes.string.isRequired,
    transitionName: React.PropTypes.string.isRequired
  }

  constructor (...args) {
    super(...args)
  }


  // NOTE: Nos aseguramos de no renderizar el componente
  // si el fondo es el mismo.
  shouldComponentUpdate (nextProps, nextState) {
    if (nextProps.source === this.props.source)
      return false
    else
      return true
  }


  render () {
    return (
      <div className="background-wrapper">
        <ReactCSSTransitionGroup transitionName="background"
                                 transitionEnterTimeout={ 600 }
                                 transitionLeaveTimeout={ 600 }>
          { this.renderContent() }
        </ReactCSSTransitionGroup>
      </div>
    )
  }


  /**
   * @func: renderContent
   *
   * @description: renderiza el contenido del container.
   * Controla el tipo de recurso que necesita para renderizar el
   * componente indicado en cada caso.
   */
  renderContent () {
    const { source, type } = this.props
    if (type === 'img' || type === '') {
      return <Image source={ source } key={ source } />
    } else if (type === 'video') {
      return <Video source={ `${ config.ABSOLUTE_PATH }/${ source }` } key={ source } />
    } else if (type === 'gradient') {
      return <Gradient source={ source } key={ source } />
    } else {
      throw new Error('The source type specified is not a valid type. Please, take a look at the component doc.')
    }
  }
}


export default BackgroundTransitionManager
