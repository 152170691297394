import React from 'react'

const welfare_11 = () => {
  return (
    <g>
        <path d="M191.344,140.689c0.132-0.295,0.27-0.586,0.399-0.884c0.43-0.986,0.833-1.987,1.23-2.99
            c0.182-0.462,0.369-0.921,0.547-1.385c0.347-0.915,0.672-1.839,0.993-2.767c0.199-0.571,0.395-1.144,0.584-1.72
            c0.28-0.864,0.548-1.735,0.806-2.611c0.195-0.659,0.382-1.32,0.563-1.986c0.224-0.824,0.443-1.653,0.646-2.484
            c0.181-0.737,0.348-1.476,0.513-2.217c0.173-0.789,0.345-1.577,0.501-2.373c0.158-0.81,0.298-1.631,0.436-2.449
            c0.127-0.746,0.257-1.489,0.368-2.24c0.132-0.9,0.238-1.81,0.345-2.716c0.081-0.69,0.173-1.375,0.241-2.067
            c0.103-1.047,0.171-2.1,0.241-3.154c0.036-0.571,0.089-1.138,0.117-1.709c0.08-1.636,0.125-3.281,0.125-4.937
            c0-55.231-44.769-100-99.998-100c-1.668,0-3.322,0.046-4.969,0.127c-0.485,0.024-0.964,0.072-1.447,0.103
            c-1.144,0.072-2.287,0.146-3.42,0.259c-0.633,0.062-1.258,0.146-1.888,0.22c-0.972,0.114-1.938,0.226-2.9,0.369
            c-0.703,0.103-1.4,0.226-2.098,0.344c-0.869,0.146-1.737,0.294-2.598,0.462c-0.752,0.148-1.497,0.311-2.243,0.475
            c-0.789,0.174-1.576,0.351-2.355,0.543c-0.791,0.194-1.577,0.401-2.36,0.614c-0.711,0.192-1.418,0.394-2.122,0.602
            c-0.832,0.246-1.66,0.5-2.482,0.767c-0.627,0.204-1.248,0.417-1.868,0.632c-0.879,0.306-1.755,0.615-2.624,0.944
            c-0.522,0.197-1.04,0.407-1.557,0.614c-0.947,0.376-1.891,0.757-2.824,1.162c-0.369,0.16-0.732,0.334-1.1,0.498
            c-1.063,0.475-2.121,0.957-3.164,1.47c-0.072,0.036-0.145,0.075-0.218,0.112c-7.845,3.877-15.099,8.755-21.615,14.465
            c-0.049,0.042-0.101,0.083-0.151,0.127h0.003c-3.223,2.835-6.258,5.871-9.092,9.092h-0.002c-0.036,0.041-0.07,0.085-0.106,0.125
            c-5.727,6.532-10.62,13.809-14.507,21.676c-0.028,0.059-0.059,0.116-0.088,0.174c-0.517,1.051-1.003,2.118-1.483,3.188
            c-0.161,0.36-0.329,0.715-0.485,1.076c-0.407,0.938-0.789,1.89-1.168,2.842c-0.204,0.513-0.412,1.024-0.609,1.54
            c-0.329,0.872-0.64,1.751-0.946,2.637c-0.215,0.617-0.426,1.234-0.63,1.857c-0.267,0.825-0.523,1.655-0.77,2.49
            c-0.208,0.701-0.407,1.406-0.599,2.114c-0.215,0.785-0.42,1.572-0.615,2.367c-0.192,0.778-0.369,1.562-0.544,2.349
            c-0.164,0.749-0.327,1.494-0.474,2.248c-0.169,0.859-0.317,1.725-0.464,2.594c-0.117,0.7-0.241,1.396-0.345,2.101
            c-0.14,0.959-0.254,1.924-0.366,2.892c-0.073,0.632-0.16,1.261-0.222,1.896c-0.111,1.131-0.187,2.272-0.26,3.415
            c-0.031,0.485-0.076,0.965-0.101,1.453C0.045,96.678,0,98.335,0,100c0,55.23,44.771,100,100.002,100
            c1.655,0,3.301-0.044,4.935-0.125c0.571-0.026,1.134-0.081,1.702-0.117c1.058-0.07,2.114-0.14,3.162-0.241
            c0.688-0.068,1.372-0.16,2.056-0.241c0.913-0.107,1.826-0.213,2.729-0.345c0.747-0.111,1.488-0.241,2.231-0.366
            c0.82-0.14,1.644-0.28,2.459-0.439c0.793-0.155,1.576-0.327,2.36-0.5c0.747-0.164,1.491-0.332,2.231-0.514
            c0.827-0.202,1.65-0.417,2.471-0.641c0.669-0.184,1.338-0.373,2.002-0.568c0.869-0.257,1.733-0.522,2.593-0.802
            c0.584-0.191,1.165-0.389,1.743-0.591c0.92-0.317,1.836-0.641,2.741-0.985c0.477-0.181,0.949-0.373,1.419-0.56
            c0.995-0.394,1.983-0.791,2.959-1.216c0.312-0.135,0.618-0.281,0.928-0.42c9.237-4.126,17.728-9.605,25.229-16.195
            c0.016-0.013,0.034-0.026,0.049-0.041h-0.001C176.746,165.641,185.438,153.928,191.344,140.689z M110.501,150.951
            c-3.236,0-5.692,0-7.58,0c0,0-0.99,0-5.843,0c-1.889,0-4.342,0-7.58,0c-11.556,0-10.854-10.856-10.854-10.856s0-5.029,0-11.906
            h40.452l-21.009-21.011H78.644c0-6.143,0-11.616,0-14.743c0-1.626,0.09-3.104,0.231-4.47L35.06,44.152
            c2.803-3.25,5.84-6.289,9.092-9.09l39.204,39.201c1.401-2.915,3.826-7.804,6.26-11.921c3.735-6.317,1.727-6.989,1.727-6.989h-0.343
            c-1.74,0-3.151-1.413-3.151-3.151c0-1.74,1.411-3.153,3.151-3.153h17.998c1.743,0,3.153,1.413,3.153,3.153
            c0,1.738-1.41,3.151-3.153,3.151h-0.34c0,0-2.008,0.672,1.727,6.989c3.737,6.318,7.471,14.489,7.471,14.489
            s3.502,5.062,3.502,15.604c0,3.126,0,8.6,0,14.743h-5.088l48.672,48.67c-2.801,3.254-5.84,6.292-9.092,9.092l-34.492-34.492
            c0,5.69,0,9.647,0,9.647S122.057,150.951,110.501,150.951z"/>
    </g>
  )
}

export default welfare_11