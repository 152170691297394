
import React, { Component } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { IconButton } from '../../../'

/**
 * @class: IframeModal
 */


  class IframeModal extends Component {
  
    static propTypes = {
      isOpen: React.PropTypes.bool,
      toogle: React.PropTypes.func,
      transitionName: React.PropTypes.string
    }
  
    static defaultProps = {
      isOpen: true
    }
  
    constructor (...args) {
      super(...args)
    }
  
    render () {
      return (
        this.renderContent ()
      )
    }
  
  
    renderContent () {
      const classIFrame = this.props.classIframe
      return (
        <div className = { classIFrame } >
          <iframe src={this.props.url}></iframe>
        </div>
  
      )
    }
  }
  
  
  
  export default IframeModal