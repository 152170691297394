
import React, { Component } from 'react'
import { browserHistory } from 'react-router'
import { observer } from 'mobx-react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import config from '../../../../config'
import { AppStore, AuthStore, TrackingApartments, UIStore } from '../../../../stores'
import { adjustColor, capitalize, scrollTo } from '../../../../utils'
import DropdownButton from '../../../Dropdowns/components/DropdownButton'
import ParentButton from './components/ParentButton'
import ChildButton from './components/ChildButton'

@observer
class CategoriesMenu extends Component {

  constructor (...args) {
    super(...args)

    this.ready = false
    this.menu = null
  }


  /**
   * Fix para que crear el menu una vez tengamos las
   * categorías disponibles.
   */
  componentWillReceiveProps (nextProps) {
    if (nextProps.categories !== null && this.ready === false) {
      this.ready = true
      this.menu = this.createMenu(nextProps.categories, null)
    } else if (nextProps.categories === null) {
      this.ready = false
    }
  }


  render () {
    const { placeholder } = this.props

    return (
      <div className={ `categories-menu ${ (UIStore.isCategoriesOpen) ? 'open' : '' }` } role="listbox">
        <DropdownButton placeholder={ placeholder }
                        type="flat"
                        color="white"
                        align="center"
                        onClick={ UIStore.toggleCategories } />

        <ReactCSSTransitionGroup transitionName="modal-anim"
                                 transitionEnterTimeout={ 300 }
                                 transitionLeaveTimeout={ 300 }>
          { (UIStore.isCategoriesOpen) ? this.renderMenu() : null }
        </ReactCSSTransitionGroup>
      </div>
    )
  }


  /**
   * Renderiza el arbol de categorias cuando se despliega el menú
   */
  renderMenu () {
    window.setTimeout(() => {
      this.openCurrentCategory();
    }, 1000);

    const styler = { backgroundColor: (AppStore.provider) ? AppStore.provider.last_color : 'white' }
    return <div className="categories-menu_content" ref="scroller" style={ styler }>
      <ul className="categories-menu_list">
        { this.menu }
      </ul>
    </div>
  }


  /**
   * Crea el árbol de categorias
   */
  createMenu (categories, id) {
    let dec = config.AVAILABLE_PROVIDERS.filter(provider => provider.id === AppStore.idprovider)[0].dec
    return categories.filter(node => node.id_parent === id)
      // NOTE: no hace falta porque ya viene ordenado. Además ordena mal :(
      // .sort((a, b) => {
      //   let x = a.order
      //   let y = b.order
      //   return ((x < y) ? -1 : ((x > y) ? 1 : 0))
      // })
      .map((node, i) => {
        let exists = categories.some(childNode => childNode.id_parent === node.id_category)
        if (node.children.length === 0 && node.id_category !== 4147) {
        // if (node.children.length === 0) {
          return <li key={ node.id_category } className={ `child ${ (node.products < 1) ? 'disabled' : '' }` }>
            <ChildButton placeholder={ capitalize(node.name) }
                         id={ node.id_category }
                         level={ node.level }
                         onClick={ this.handleCategoryClick } />
          </li>
        } else {
          let styler = { backgroundColor: 'inherit' }
          const color = adjustColor(AppStore.provider.last_color, dec)
          if (node.level === 1) {
            dec -= 0.05
            styler = { backgroundColor: color }
          }
          let subMenu = (exists) ? <ul className="categories-menu_list_ul" style={ styler } key={ node.id_category }>{ this.createMenu(categories, node.id_category) }</ul> : null
          return <li key={ node.id_category } className="parent">
            <ParentButton placeholder={ capitalize(node.name) }
                          id={ node.id_category }
                          level={ node.level }
                          onClick={ this.handleParentCategoryClick }
                          colorStyle={ styler }
                          hicon={ node.h_icon || null }
                          icon={ node.id_icon || null } />
            { subMenu }
          </li>
        }
      })
  }


  /**
   * Maneja el evento click de los parents.
   */
  handleParentCategoryClick = (event) => {
    const target = event.target
    const parentContainer = target.parentNode.parentNode
    const brothers = parentContainer.querySelectorAll('li.parent.expanded')

    target.parentNode.classList.toggle('expanded')

    window.setTimeout(() => {
      // NOTE: nucho más rápido que 'for' con length calculado, 'for of' o por supuesto que 'forEach'
      let i = brothers.length
      while (i--) {
        brothers[i].classList.remove('expanded')
      }
      this.refs.scroller.scrollTop = target.offsetTop
      window.clearTimeout(this)
    }, 400)
  }

  openCurrentCategory() {
    try {

      const activeParentCategoryId = AppStore.activeParentCategoryId;
      const currentCategoryId = AppStore.currentCategoryId;
      const secondLevelCategoryId = AppStore.secondLevelCategoryId;
      if (!activeParentCategoryId || !currentCategoryId || !secondLevelCategoryId){
        return;
      }
  
      const parentCategory = document.querySelector(`[id='${activeParentCategoryId}']`);
      if (parentCategory) {
        parentCategory.parentElement.classList.add('expanded');
        // parentCategory.click();
        // parentCategory.parentElement.querySelector("button.level_2").click();
        const levelTwoCategory = document.querySelector(`[id='${secondLevelCategoryId}']`);
        levelTwoCategory.parentElement.classList.add('expanded');

        // Delay to show the parent category
        setTimeout(function() {
          parentCategory.parentElement.scrollIntoView({ block: "start", inline: "nearest" });
        }, 500);
      
        // const currentCategoryElement = document.querySelector(`[id='${AppStore.currentCategoryId}']`);
        // if (currentCategoryElement){
        //   currentCategoryElement.scrollIntoView({ block: "start", inline: "nearest" });
        // }
  
      }
    } catch (err){
      console.log(err);
    }

  }

  // handleCategoryParentActive(){
  //   const parentCategory = document.querySelector(".parent.expanded .parent-button.level_1");
  //   if (parentCategory){
  //     AppStore.activeParentCategoryId = parentCategory.id;
  //   }
  // }

  /**
   * Maneja el evento click de los childs
   */
  handleCategoryClick = (event) => {
    TrackingApartments.click_on_category(AuthStore.apartmentId);
    AppStore.currentCategoryId = event.target.id;
    // this.handleCategoryParentActive();
    UIStore.toggleCategories()
    browserHistory.push(`/`)
    AppStore.goToCategory(event.target.id)
  }

}


export default CategoriesMenu
