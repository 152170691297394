
import React from 'react'

const sort_by = () => {
  return (
    <g>
     <path d="M199,28.88c0,7.692-6.237,13.959-13.904,13.959H13.903C6.236,42.839,0,36.572,0,28.88
       c0-7.706,6.236-13.973,13.903-13.973h171.192C192.763,14.907,199,21.174,199,28.88z"/>
     <path d="M13.903,113.972h86.18c7.668,0,13.903-6.269,13.903-13.973s-6.235-13.972-13.903-13.972h-86.18
       C6.236,86.028,0,92.295,0,100S6.236,113.972,13.903,113.972z"/>
     <path d="M75.194,157.159H13.903C6.236,157.159,0,163.416,0,171.12s6.236,13.974,13.903,13.974h61.291
       c7.669,0,13.905-6.27,13.905-13.974S82.863,157.159,75.194,157.159z"/>
    </g>
  )
}

export default sort_by
