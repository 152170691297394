import React from 'react'

const allergen_10 = () => {
  return (
        <g>
             <path d="M136.242,70.418l-50.409,49.733c-0.351,0.346-0.806,0.518-1.262,0.518c-0.464,0-0.928-0.179-1.28-0.534
                    c-0.697-0.707-0.689-1.846,0.018-2.542l45.061-44.458c-0.385,0.131-0.764,0.19-1.13,0.19c-0.492,0-0.964-0.106-1.411-0.283
                    l-26.09,24.151c-0.346,0.32-0.784,0.479-1.221,0.479c-0.483,0-0.965-0.193-1.319-0.577c-0.675-0.728-0.631-1.865,0.098-2.538
                    l25.676-23.768c-0.584-0.701-1.004-1.364-1.211-1.716l-35.188,32.911c-0.346,0.324-0.788,0.483-1.227,0.483
                    c-0.48,0-0.959-0.19-1.313-0.569c-0.679-0.725-0.641-1.862,0.085-2.54l35.865-33.544c-0.531-0.486-1.215-0.835-2.089-0.835
                    c-1.319,0-3.069,0.79-5.371,3.091c-6.698,6.698-49.543,46.154-49.543,46.154s-6.778,5.423,4.067,16.268
                    c3.369,3.369,6.787,4.529,9.899,4.529c3.455,0,6.533-1.431,8.745-2.862c-0.018-0.109-0.025-0.219-0.022-0.335
                    c0.003-0.144,0.018-2.901-2.223-4.46c-1.903-1.324-4.169-1.119-6.57-0.903c-2.538,0.23-5.161,0.467-7.388-1.181
                    c-4.547-3.368-3.042-7.269-2.976-7.433c0,0,0.486-1.548,2.224-0.95c1.737,0.6,1.11,2.293,1.11,2.293s0.009-0.016,0.02-0.052
                    c-0.052,0.161-0.446,1.618,1.762,3.253c1.127,0.835,2.888,0.675,4.926,0.49c2.659-0.238,5.968-0.538,8.945,1.531
                    c2.148,1.494,3.072,3.574,3.469,5.17c0.182-0.174,0.279-0.278,0.279-0.278l46.329-48.701c0,0,6.026-5.178,2.364-8.802
                    C137.936,71.802,137.305,70.982,136.242,70.418z"/>
            <path d="M100.001,0C44.772,0,0,44.772,0,100c0,55.229,44.772,100,100.001,100C155.229,200,200,155.229,200,100
                C200,44.772,155.229,0,100.001,0z M139.551,84.764l-46.157,48.521c-0.868,0.912-7.447,7.488-16.45,7.488
                c-5.071,0-9.9-2.149-13.966-6.215c-5.478-5.478-8.109-10.884-7.823-16.066c0.266-4.806,2.937-7.621,3.953-8.523
                c2.739-2.598,42.889-39.508,49.346-45.965c3.215-3.215,6.302-4.777,9.438-4.777c3.238,0,6.117,1.729,7.898,4.741
                c0.55,0.929,0.91,1.834,1.136,2.524c0.096,0.14,0.196,0.279,0.3,0.413c2.658-2.254,5.247-2.756,7.024-2.756
                c4.254,0,7.002,2.684,7.963,3.804c1.799,1.898,2.737,4.334,2.646,6.894C144.689,79.746,140.802,83.635,139.551,84.764z"/>
                </g>
  )
}

export default allergen_10