
/**
 * Obtiene el CP de la localización actual del usuario
 */

const getPostalCode = (lat, lon) => {
  return getGoogleData(lat, lon).then(data => {
    let results = data.results

    return results.find(item => {
      return (item.address_components[0].types[0] === 'postal_code') ? item.address_components[0].long_name : null
    }, this)
  })
  .catch(error => error)
}

/**
 * Pedimos los datos a la API de Google Maps
 * @param  {Number} lat
 * @param  {Number} lon
 * @return {Object} Google Maps Object
 */
const getGoogleData = (lat, lon) => {
  return fetch('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lon + '&sensor=true', {
    method: 'POST'
  }).then(response => {
    return response.json()
  }).catch(error => error)
}

export default getPostalCode
