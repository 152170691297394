
import React, { Component } from 'react'
import { NavigationMenu, ViewsWrapper } from '../components/UserZoneComponents'
import { CustomerSupportTooltip } from '../components/Tooltips'
import { Dropdown } from "../components";
import { AppStore, UIStore } from "../stores";
import { debounce } from '../utils'


const DROPDOWN_OPTIONS = [
  // {
  //   text: "Mi cuenta",
  //   active: AppStore.userZoneView == 0,
  //   action: () => {
  //     AppStore.userZoneView = 0;
  //     AppStore.userZoneViewPlaceholder = "Mi cuenta";
  //   },
  // },
  {
    text: "Pedidos",
    active: AppStore.userZoneView == 1,
    action: () => {
      AppStore.userZoneView = 1;
      AppStore.userZoneViewPlaceholder = "Pedidos";
    },
  },
  {
    text: "Ayuda",
    active: AppStore.userZoneView == 2,
    action: () => {
      AppStore.userZoneView = 2;
      AppStore.userZoneViewPlaceholder = "Ayuda";
    },
  },
];

class UserZone extends Component {
  componentDidMount () {
    this.debouncedOnResizeHandler = debounce(this.onResizeHandler, 250, true);
    window.addEventListener('resize', this.debouncedOnResizeHandler, false)
    this.onResizeHandler()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedOnResizeHandler, false);
  }

  onResizeHandler = (event) => {
    console.log('onResizeHandler', this.props.route)
    const config = this.props.route.config
    const width = window.innerWidth

    if (width <= 768){
      UIStore.setBackground(config.backgroundMobile[0], config.backgroundMobile[1])
    } else {
      UIStore.setBackground(config.background[0], config.background[1])
    }
  }

  render () {
    return (
      <div className="section user-zone-page">
        <div className="container">
          <div className="user-zone-dropdown-navigation">
            <Dropdown
              options={DROPDOWN_OPTIONS}
              ref={(instance) => {
                this.child = instance;
              }}
              content="list"
              type="flat"
              buttonColor="gray"
              placeholder= {AppStore.userZoneViewPlaceholder}
              align="center"
              expandOnHover={false}
              collapseOnLeave={false}
              setSelectedAsPlaceholder={true}
              transitionName="modal-anim"
            />
          </div>
          <NavigationMenu />
          <ViewsWrapper />
        </div>
        <CustomerSupportTooltip />
      </div>
    )
  }
}

export default UserZone
