
import React from 'react'

const subtract = () => {
  return (
    <g>
      <path d="M163.012,109.283H37.824c-4.574,0-8.285-3.707-8.285-8.285c0-4.574,3.711-8.285,8.285-8.285h125.188
         c4.58,0,8.285,3.711,8.285,8.285C171.297,105.576,167.592,109.283,163.012,109.283z"/>
    </g>
  )
}

export default subtract
