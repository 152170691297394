import React from "react";

const trackingOrder = () => {
  return (
    <g fill="none">
      <path
        d="M31 16C31 7.71875 24.2812 1 16 1C7.71875 1 1 7.71875 1 16C1 24.2812 7.71875 31 16 31C24.2812 31 31 24.2812 31 16Z"
        stroke="#565656"
      />
      <path
        d="M10.8843 18.0493C11.7591 18.0493 12.5281 18.5079 12.9648 19.1973H20.3828C20.6616 18.7565 21.0763 18.4091 21.5693 18.2168V8.5H5.5V19.1973H8.80394C9.23924 18.5079 10.0082 18.0493 10.8843 18.0493Z"
        fill="#565656"
      />
      <path
        d="M5.5 20.1255C5.5 20.223 5.5412 20.3205 5.62496 20.396C5.70873 20.4702 5.81721 20.5072 5.92707 20.5072H8.4263C8.4263 20.1735 8.49222 19.855 8.61306 19.5652H5.5V20.1255Z"
        fill="#565656"
      />
      <path
        d="M13.343 20.5072H20.0058C20.0058 20.1735 20.0717 19.855 20.1926 19.5652H13.1562C13.2771 19.855 13.343 20.1735 13.343 20.5072Z"
        fill="#565656"
      />
      <path
        d="M26.98 12.5894C26.3002 11.9097 25.4132 11.3247 24.5631 11.1984C23.7145 11.072 22.0557 11.0638 22.0557 11.0638V18.0836C22.1889 18.0603 22.3248 18.0493 22.4635 18.0493C23.8202 18.0493 24.9215 19.1506 24.9215 20.5073H26.9814C27.2423 20.5073 27.5032 20.4085 27.7009 20.2093C27.9 20.0102 28.0003 19.7507 28.0003 19.4898V15.0516C28.0003 14.1604 27.6597 13.2692 26.98 12.5894ZM27.2848 15.8947C27.2848 16.0005 27.2395 16.1035 27.1516 16.1762C27.0638 16.2476 26.9539 16.271 26.8509 16.2504L23.2105 15.524C23.1309 15.5089 23.0567 15.4663 23.0018 15.399C22.9455 15.3317 22.9194 15.2493 22.9194 15.1683V12.058C22.9194 12.058 24.1374 12.0635 24.7609 12.1569C25.3843 12.2502 26.0366 12.68 26.5351 13.1785C27.0349 13.6784 27.2848 14.332 27.2848 14.987V15.8947Z"
        fill="#565656"
      />
      <path
        d="M12.6268 19.3346C12.5939 19.2879 12.5609 19.2412 12.5252 19.1973C12.1407 18.7153 11.5488 18.4077 10.8842 18.4077C10.2196 18.4077 9.62773 18.7153 9.24185 19.1973C9.20615 19.2412 9.17319 19.2879 9.14024 19.3346C9.0908 19.4088 9.04549 19.4857 9.00704 19.5653C8.86285 19.8482 8.7832 20.1681 8.7832 20.5073C8.7832 21.6677 9.72385 22.6083 10.8842 22.6083C12.0446 22.6083 12.9838 21.6677 12.9838 20.5073C12.9838 20.1681 12.9042 19.8482 12.76 19.5653C12.7216 19.4857 12.6762 19.4088 12.6268 19.3346ZM10.8842 21.5949C10.2841 21.5949 9.79663 21.1074 9.79663 20.5073C9.79663 20.105 10.0163 19.7521 10.3432 19.5653C10.5025 19.4719 10.6878 19.4197 10.8842 19.4197C11.0806 19.4197 11.266 19.4719 11.4253 19.5653C11.7521 19.7521 11.9718 20.105 11.9718 20.5073C11.9718 21.1074 11.4843 21.5949 10.8842 21.5949Z"
        fill="#565656"
      />
      <path
        d="M22.4634 18.4077C22.3233 18.4077 22.1874 18.4214 22.0556 18.4475C21.8853 18.4805 21.7219 18.5354 21.5694 18.6082C21.2248 18.7688 20.9323 19.0215 20.7208 19.3346C20.4956 19.6697 20.3638 20.0734 20.3638 20.5073C20.3638 21.6677 21.303 22.6083 22.4634 22.6083C23.6238 22.6083 24.563 21.6677 24.563 20.5073C24.563 19.347 23.6238 18.4077 22.4634 18.4077ZM22.4634 21.5949C21.8619 21.5949 21.3758 21.1074 21.3758 20.5073C21.3758 20.278 21.4472 20.0638 21.5694 19.888C21.6889 19.715 21.8578 19.579 22.0556 19.4994C22.1805 19.4486 22.3192 19.4197 22.4634 19.4197C23.0635 19.4197 23.551 19.9072 23.551 20.5073C23.551 21.1074 23.0635 21.5949 22.4634 21.5949Z"
        fill="#565656"
      />
    </g>
  );
};

export default trackingOrder;
