import React from "react";
import * as Ico from "./icons";
import { isValidHEXColor, isValidRGBColor } from "../../utils";
import config from "../../config";

/**
 * @func: Icon
 * @param: {[Object]} props
 *
 * @description: Icono.
 *
 * @usage:
 *
 * <Icon type="start_user" title="icon-logo" fill="rgba(37, 49, 55, 0.4)" size="large" />
 * <Icon type="start_user" title="icon-logo" fill="rgba(37, 49, 55, 0.4)" width="10px" height="10px" />
 *
 * @props:
 * - type: define el tipo de icono que se renderizará.
 * - title: define el texto para los tags 'aria-labelledby' y 'title' del elemento SVG.
 * - fill: define el color para el icono.
 * - size: define el tamaño del icono sobre las medidas estandar.
 *
 * TODO: las variables de los tamaños por defecto deberian estar en config.
 */

const Icon = ({viewport,title,fill,className,size,width,height,type}) => (
  // props
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewport || "0 0 200 200"}
    aria-labelledby={title || null}
    title={title || null}
    className={`icon ${type} ${
      fill && !isValidHEXColor(fill) && !isValidRGBColor(fill)
        ? fill
        : ""
    } ${className || ""}`}
    width={`${
      size && size !== ""
        ? size
        : width && width !== ""
        ? width
        : config.ICON_SIZE
    }`}
    height={`${
      size && size !== ""
        ? size
        : height && height !== ""
        ? height
        : config.ICON_SIZE
    }`}
    fill={
      (fill && isValidHEXColor(fill)) || isValidRGBColor(fill)
        ? fill
        : "white"
    }
  >
    {Ico[type]()}
  </svg>
);

export default Icon;
