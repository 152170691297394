
import React from 'react'

const search = () => {
  return (
    <g>
      <g>
        <path d="M196.205,179.941l-59.396-59.742c9.423-12.977,14.385-28.295,14.385-44.465C151.193,33.975,117.406,0,75.879,0
         C34.363,0,0.582,33.975,0.582,75.734c0,41.758,33.781,75.732,75.297,75.732c16.715,0,32.406-5.328,45.551-15.436l59.225,59.568
         c2.093,2.088,4.854,3.236,7.771,3.236c2.931,0,5.692-1.148,7.78-3.252C200.492,191.258,200.486,184.24,196.205,179.941z
          M129.191,75.734c0,14.174-5.437,27.514-15.308,37.572c-10.098,10.34-23.597,16.031-38.005,16.031
         c-29.385,0-53.291-24.047-53.291-53.604c0-29.549,23.906-53.594,53.291-53.594C105.275,22.141,129.191,46.186,129.191,75.734z"/>
      </g>
    </g>
  )
}

export default search
