
import React, { Component } from 'react'
import { browserHistory } from 'react-router'
import { observer, peek } from 'mobx-react'
import config from '../../config'
import { setFacebookMetas } from '../../utils'
import { AppStore, ShopStore } from '../../stores'
import ProductDetailImage from './ProductDetailImage'
import { Button, IconButton, LinkIconButton, Dropdown, Tabs, Icon } from '../../components'
import { capitalize } from '../../utils'

@observer
class ProductDetailContainer extends Component {

  state = {
    tab: 0
  }

  render () {
      const URL = 'https://market.ilusia.com/' + browserHistory.getCurrentLocation().pathname
      const { id_product_eva, description, brand, price, price_measure, if_food} = this.props.product.product
      var deal_description = null
      
      if(typeof this.props.product.product.deal !== "undefined") {
        deal_description = <p className="product_datail_deal"> { this.props.product.product.deal.name } </p>
      }

      var pending_check = null
      var showawelfares = null
      var showallergens = null
      var classbackground = "container-full product-info"
      var showbrand = null
      if ( brand != null ){
        showbrand = <h3 className="h3 regular black s20">Marca: { brand }</h3>
      }
      if (AppStore.provider.allergen === 1){
            
            if (this.props.product.product.is_food === 3){
                  var pending_check = <p className="p bold red s14"><br />Producto pendiente de revisión nutricional. Puede contener trazas de Alérgenos</p> 
            } else {

                  let allergens = []
                  if (this.props.product.product.allergens) {
                      allergens = this.props.product.product.allergens.peek().map(allergens => {

                          const id = parseInt(allergens)
                          console.log (allergens)
                            for (let aller of AppStore.allergens) {
                              console.log(aller)
                                  if (aller.id_allergen === id) {
                                        var aller_button =
                                        <div className = "ico-allergen-container" key = {id + Math.random()}>
                                             <div className = "ico-allergen-wrapper" key = {id + Math.random()}>
                                                          <Icon type={'allergen_' + id}
                                                                  fill= { aller.colour }
                                                                  size="42"
                                                                  id={id}
                                                                  key = {id + Math.random()}
                                                                  title={aller.name_internal} 
                                                          />           
                                                </div> <div className="text-allergen-wrapper"><p className="p light black s10 price_measure">{ aller.name_internal }</p></div>
                                                </div>

                                      return  ( aller_button )
                                  }     
                            }
                      })
                  }
                  
                  
                  if (allergens.length > 0){
                          classbackground = "container-full product-info-full"
                          showallergens = <div className="allergen-wrapper">
                                              <p className="p bold black s15">Contiene Alérgenos:</p>
                                                 { allergens } 
                                                 <br />
                                                 <br />

                                        </div>                                
                  }
            }
            let welfares = []
            if (this.props.product.product.welfares) {
                welfares = this.props.product.product.welfares.peek().map(welfares => {
                    const id = parseInt(welfares)
                      for (let welf of AppStore.welfares) {
                            if (welf.id_welfare === id) {
                              var wel_button =
                                       <div className = "ico-allergen-container" key = {id + Math.random()}>
                                                  <div className="ico-allergen-wrapper" key = {id +Math.random()}>
                                                      <Icon type={'welfare_' + id}
                                                                  fill= { welf.colour }
                                                                  size="42"
                                                                  id={id}
                                                                  key = {id + Math.random()}
                                                                  title={welf.name} 
                                                          />           
                                                  </div>
                                            
                                              <div className="text-allergen-wrapper"><p className="p light black s10 price_measure">{ welf.name }</p></div>
                                            </div>
                                    return (wel_button)
                            }     
                      }
                })
            }
            
            if (welfares.length > 0){
              classbackground = "container-full product-info-full"
                showawelfares = <div className="welfare-wrapper">
                                        <p className="p bold black s15">Clasificación:</p>
                                           { welfares }
                                </div>
            }
    }
    const hr = this.props.product.product.hr === 1 ? true : false
    let { image } = this.props.product.product
    if (!image) image = config.FAKE_BAGS[Math.round(Math.random())]
    let categories = []

    if (this.props.product.product.categories) {
      categories = this.props.product.product.categories.peek().map(category => {
        const id = parseInt(category)
        for (let cat of AppStore.categories) {
          if (cat.id_category === id) {
            return <li key={ id } >
              <Button type="outline" className="small tag" color="blue" placeholder={ capitalize(cat.name) } id={ id } onClick={ (event) => this.handleCategoryClick(event) } />
            </li>
          }
        }
      })
    }

    return (
      <div className={ classbackground } >
        <div className="box-md-6">
          <ProductDetailImage src={ image } hr={ hr } description={ description } units={ this.props.product.units } />
        </div>
        <div className="box-md-6 info">
                {/* <Tabs current="1" onSwitch={ this.onSwitchTab } /> */}

                <h1 className="h1 bold black s38">{ capitalize(description) }</h1>
                { showbrand }
                <ul className="categories">
                  { categories || null }
                </ul>
                <div className="price-wrapper">
                  <p className="p bold black s40 price">{ (price).toFixed(2) } { config.CURRENCY }</p>
                  <p className="p light grey s16 price_measure">{ price_measure } { (price_measure) ? config.CURRENCY : null }</p>
                  { pending_check }
                  { deal_description }
                </div>
                <IconButton type="rounded" color="green" iconType="cart" onClick={ () => ShopStore.add(id_product_eva, 1) } />
                <Dropdown content="custom"
                          buttonType="icon"
                          type="rounded"
                          buttonColor="grey"
                          iconColor="white"
                          placeholder="Custom Dropdown"
                          iconType="share"
                          align="center"
                          expandOnHoover={ false }
                          collapseOnLeave={ false }
                          setSelectedAsPlaceholder={ true }
                          transitionName="modal-anim" >
                      <IconButton type="fab" color="facebook" iconFill="white !important" iconType="facebook" onClick={ () => this.shareFacebook() } />
                      <LinkIconButton type="fab"
                                      className="twitter-share-button"
                                      color="twitter"
                                      iconFill="white"
                                      iconType="twitter"
                                      onClick={ `https://twitter.com/share?text=Mira que producto he encontrado para ti en @ilusiaES: ${ description }&url=https://market.ilusia.com${ browserHistory.getCurrentLocation().pathname }&via=ilusiaES` }
                                      target="_blank" />
                      {/* <IconButton type="fab" color="mail" iconFill="white !important" iconType="mail" onClick={ () => this.shareMail() } /> */}
                      <LinkIconButton type="fab"
                                      className=""
                                      color="mail"
                                      iconFill="whiter"
                                      iconType="mail"
                                      onClick={ `mailto:?body=Mira que producto he encontrado para ti en Ilusia:
                                        ${ capitalize(description) }
                                        ${ URL }` }
                                      target="_blank" />
                </Dropdown>
                { showallergens }
                { showawelfares }


          </div>
      </div>
    )
  }


  onSwitchTab = (id, event) => {
    console.log(id);
    // this.setState({ tab: id })
    this.setState((prevState, props) => {
      tab: id
    })
  }


  handleCategoryClick = (event) => {
    console.log(event);
    browserHistory.push(`/`);
    AppStore.goToCategory(event.target.id)
  }


  shareFacebook = (event) => {
    const { description, image, brand } = this.props.product.product
    // https://developers.facebook.com/apps/1215506051904672/settings/
    // https://developers.facebook.com/docs/sharing/reference/feed-dialog#response
    // https://developers.facebook.com/tools/debug/sharing/
    // FB.XFBML.parse()
    const picture = `https://${ config.S3_IMAGES }/images/${ config.DETAIL_IMAGE_SIZE }/${ image }`
    const URL = 'https://market.ilusia.com/' + browserHistory.getCurrentLocation().pathname
    window.description = description
    window.brand = brand
    window.picture = picture
    window.URL = URL

    FB.ui({
      method: 'share_open_graph',
      action_type: 'og.shares',
      action_properties: JSON.stringify({
        object : {
         'og:url': window.URL,
         'og:title': window.description,
         'og:description': `Marca: ${ window.brand }`,
         'og:image': window.picture
        }
      })
    },
    function (response) {
      if (response && !response.error_message) {
        // then get post content
        // console.log('successfully posted. Status id : ' + response.post_id)
      } else {
        // console.log('Something went error.')
      }
    })

    // FB.ui({
    //   method: 'feed',
    //   app_id: config.FACEBOOK_APP_ID,
    //   display: 'popup',
    //   name: window.description,
    //   description: `Marca: ${ window.brand }`,
    //   link: window.URL,
    //   picture: window.picture,
    // }, (response) => console.log(response))
  }

  shareTwitter = (event) => console.log('shareTwitter');
}

export default ProductDetailContainer
