import React from 'react'

const two = () => {
  return (
    <g>
      <path
        d="m436.879 148.037c0-73.682-52.808-148.037-170.786-148.037-105.308 0-166.875 53.555-178.067 154.878l-1.67 15.103 124.336 12.114 1.025-15.439c3.545-53.467 28.286-60.674 52.031-60.674 32.285 0 48.647 16.348 48.647 48.589 0 94.33-216.104 166.375-235.43 340.774l-1.844 16.655h361.758v-117.107h-171.797c54.405-60.11 171.797-126.067 171.797-246.856z" />
    </g>
  )
}

export default two;