/*****************************************************************************
 * @class: SyncStore
 *
 * @description: este store es el encargado de manejar la sincronización de los
 * datos de la aplicacion con AWS Cognito.
 *
 * Los procesos son:
 *
 * A)
 * B)
 * ...
 *****************************************************************************/

import React from "react";
import "aws-sdk/dist/aws-sdk";
import { toJS } from "mobx";
import { CognitoSyncManager } from "amazon-cognito-js";
import { AuthStore, AppStore, ShopStore, UIStore } from "../stores";
import { ProductModel } from "../models";
import config from "../config";
import { formatDate, capitalize } from "../utils";
import { MergeCartsModal } from "../components";
import { Notification } from "../components/Dialogs/Notification/components";
import { browserHistory } from "react-router";

class SyncStore {
  initialized = false;
  // Cliente para cognitoSync
  cognitoSync = undefined;
  // Cliente para CognitoSyncManager
  syncManagerClient = undefined;
  // Configuración para 'carts'
  cartsConfig = undefined;
  cartProducts = undefined;
  // los productos que se sincronizan en AWS COgnito
  cognitoProducts = new Map();

  // Se utiliza para diferenciar si venimos de repetir un pedido o no
  comeFromRepeatOrder = false;

  // Se utiliza para pintar en el dropdown los carritos abiertos
  openCarts = [];

  /**
   * Inicializa la sincronización.
   * Se ejecuta desde AppStore una vez termina de pedir los datos
   * necesarios, y AuthStore
   */
  initialize(callback) {
    this.initialized = true;
    this.syncManagerClient = new AWS.CognitoSyncManager();
    // this.syncManagerClient = new AWS.CognitoSyncManager(console)

    // NOTE: Sincronizamos carts ya que da igual en que punto de la app
    // estemos para hacer esto y además lo necesitaremos en el futuro.
    
    this.openOrCreateDataset("carts", (dataset) => {
      console.info(
        "%cEl dataset 'carts' se ha recuperado o creado con éxito!",
        "color: green"
      );
      this.cartsConfig = dataset;

      this.sync(this.cartsConfig, (error, dataset) => {
        if (error) console.error(error);

        console.info(
          "%cEl dataset 'carts' se ha sincronizado con éxito!",
          "color: green"
        );

        // if (AuthStore.authenticated) this.getOpenCarts()

        if (AuthStore.bookingId) {
          this.cartsConfig.get(
            AuthStore.bookingId,
            (error, value) => {
              if (error) console.error(error);
              // console.log(JSON.parse(value))
              this.checkCartStatus(value, callback);
            }
          );
        } else {
          if (callback && typeof callback === "function")
            return callback(this.cartsConfig);
        }
      });
    });
  }

  /**
   * Comprueba el status del cart.
   * NOTE: Comprobamos que existe el record y si no lo lo creamos.
   * Si existe pero el status es '1' el carrito está cerrado y no podemos
   * trabajar con el. Hay que mostrar un aviso.
   * Si el status es '0' podemos trabajar con el con total normalidad.
   *
   * TODO: controlar bien 'CART_CONFIG'
   */
  checkCartStatus(value, callback) {
    if (!value) {
      config.CART_CONFIG.postcode = AppStore.postalcode;
      config.CART_CONFIG.status = 0;
      this.cartsConfig.put(
        AuthStore.bookingId,
        JSON.stringify(config.CART_CONFIG),
        (error, record) => {
          if (error) console.error(error);

          this.sync(this.cartsConfig, () => {
            if (callback && typeof callback === "function")
              return callback(this.cartsConfig, null);
            console.log("Este es el syc");
          });
        }
      );
    } else if (JSON.parse(value).status === 1) {
      UIStore.showNotification({
        type: "danger",
        timeOut: 6000,
        content: () => (
          <Notification message="¡Vaya! Parece que hay algún problema con tu carrito. Contacta con Atención al Cliente." />
        ),
      });
      if (callback && typeof callback === "function")
        return callback(null, new Error("carrito bloqueado"));
    } else {
      this.checkCartData(value, (records) => {
        if (callback && typeof callback === "function")
          return callback(records, null);
      });
    }
  }

  /**
   * Obtiene el status del cart del proveedor seleccionado
   *
   * Devuelve el valor del status del carro y el propio dataset si existe
   * SI el dataset no existe devuelve 0
   */
  getCartStatus(idprovider, callback) {
    if (!this.cartsConfig) return callback(0, null);

    this.cartsConfig.get(idprovider.toString(), (error, value) => {
      if (error) console.error(error);
      if (callback && typeof callback === "function")
        return callback(JSON.parse(value).status, value);
    });
  }

  /**
   * Modifica el status del cart del proveedor seleccionado
   */
  setCartStatus(status, callback) {
    config.CART_CONFIG.status = status;
    config.CART_CONFIG.postcode = AppStore.postalcode;
    if (AppStore.provider.integration_type === "D") {
      config.CART_CONFIG.address_billing = AuthStore.user.addresses[0].id;
      config.CART_CONFIG.address_shipping = AuthStore.user.addresses[0].id;
      config.CART_CONFIG.id_delivery_time_slot = AppStore.iddeliverytimeslot;
    }
    console.log("desde setCartStatus", config.CART_CONFIG);

    this.cartsConfig.put(
      AuthStore.bookingId,
      JSON.stringify(config.CART_CONFIG),
      (error, record) => {
        if (error) console.error(error);

        this.sync(this.cartsConfig, () => {
          if (callback && typeof callback === "function")
            return callback(this.cartsConfig);
        });
      }
    );
  }

  /**
   * Comprueba que el carrito que tenemos en AWS Cognito está en el mismo CP
   * que el carrito actual.
   * - Si coincide seguimos con el flujo normal de la aplicación.
   * - Si el código postal no coincide:
   *   - Si no tiene productos en el carrito cambiamos el CP del cart y seguimos
   *     con el flujo normal de la aplicación.
   *   - Si tiene productos en el carrito mostramos un Dialog desde el que el usuario
   *     podrá seleccionar uno de los dos carritos abiertos en el proveedor seleccionado
   *     o bien unificar ambos carros.
   */
  async checkCartData(value, callback) {
    const cart = await JSON.parse(value);
    console.log(cart);
    if (cart.postcode !== AppStore.postalcode) {
      // TODO: mostrar algún tipo de notificación al usuario.
      console.log(
        `El código postal del carrito existente es ${cart.postcode} y no coincide con el código postal seleccionado.`
      );

      // TODO: en un futuro habrá que realizar las comprobaciones necesarias
      // para mantener actualizado Cognito con la info del catalogo descargado.
      // De momento cambiamos el codigo postal del carrito de Cognito y listo.
      config.CART_CONFIG.postcode = AppStore.postalcode;
      this.cartsConfig.put(
        AuthStore.bookingId,
        JSON.stringify(config.CART_CONFIG),
        (error, record) => {
          if (error) console.error(error);

          this.sync(this.cartsConfig, () => {
            if (callback && typeof callback === "function")
              return callback(this.cartsConfig);
          });
        }
      );
    }

    this.openOrCreateProviderCartDataset(AuthStore.bookingId, (records) => {
      // NOTE: este puede ser el caso de ser un user anonimo comprando
      // y loguearse. Los productos que tiene en el carrito anonimo
      // los metemos en AWS Cognito
      if (ShopStore.units > 0 && records.length === 0) {
        ShopStore.isEmptyCart = false;
        this.onSelectCart("local");
      } else if (
        ShopStore.units > 0 &&
        records.length > 0 &&
        !this.comeFromRepeatOrder
      ) {
        ShopStore.isEmptyCart = false;
        // NOTE: esta modal no se puede cerrar haciendo click en el bg
        // para forzar al usuario a seleccionar una de las opciones.
        const units = this.extractProviderCartDatasetUnits(records);
        this.cognitoSync = new AWS.CognitoSync({ apiVersion: "2014-06-30" });
        const params = {
          DatasetName: `cart_${AuthStore.bookingId}`,
          IdentityId: sessionStorage.getItem("identityId"),
          IdentityPoolId: config.IDENTITY_POOL_ID,
        };
        this.cognitoSync.describeDataset(params, (error, data) => {
          if (error) console.log(error, error.stack);
          const date = formatDate(data.Dataset.LastModifiedDate);

          // NOTE: de momento solo le pasamos el CP que tenemos seleccionado
          // puesto que si no coincide lo cambiamos por detrás.
          // TODO: cuando se controle esta situación tenemos que pasar los CPs
          // correctos para cada carrito.
          UIStore.toggleModal({
            clickOnBackgroundClose: false,
            content: () => (
              <MergeCartsModal
                user={AuthStore.user}
                postalcode={AppStore.postalcode}
                provider={AppStore.provider}
                onSelectCart={this.onSelectCart}
                remoteUnits={units}
                remoteDate={date}
                localUnits={ShopStore.units}
              />
            ),
          });
        });
      } else {
        this.comeFromRepeatOrder = false;
        if (callback && typeof callback === "function")
          return callback(records);
      }
    });
  }

  /**
   * Obtiene todos los carritos que el usuario tiene en AWS Cognito
   */
  getOpenCarts = () => {
    let carts = [];
    this.openOrCreateDataset("carts", (dataset) => {
      this.cartsConfig = this.cartsConfig || dataset;
      this.getAllRecords(this.cartsConfig, (datasets) => {
        // NOTE: aqui tenemos los datasets de los carritos
        // tenemos que comprobar que no estan deleted y su status
        for (let cart of datasets) {
          if (cart.value === "") continue;
          const value = JSON.parse(cart.value);
          if (value.status === 1) continue;
          this.openOrCreateProviderCartDataset(cart.key, (records) => {
            const units = this.extractProviderCartDatasetUnits(records);
            const provider = config.AVAILABLE_PROVIDERS.filter(
              (p) => p.id.toString() === cart.key
            )[0];
            const obj = {
              id: cart.key,
              providername: capitalize(provider.title),
              icon: provider.icon,
              color: provider.bubblecolor,
              date: new Date(cart.lastModifiedDate).toLocaleDateString("es-ES"),
              units: units,
              postalcode: value.postcode,
              link: `/España/${value.postcode}/${capitalize(provider.title)}`,
            };

            if (units > 0) this.openCarts.push(obj);
          });
        }
      });
    });
  };

  /**
   * Estrae las unidades totales de un carrito en AWS Cognito.
   */
  extractProviderCartDatasetUnits(records) {
    let units = 0;
    for (let record of records) {
      if (record.value !== "") {
        const value = JSON.parse(record.value);
        units += value.quantity;
      }
    }
    return units;
  }

  /**
   * Setea el carrito seleccioonado en MergeCartsModal.
   * @param [{String}] - carrito
   */
  onSelectCart = (cart) => {
    UIStore.showLoading();
    UIStore.hideModal();

    this.getAllRecords(this.cartProducts, (records) => {
      const remoteProducts = records.filter((record) => record.value !== "");
      switch (cart) {
        case "local":
          this.deleteRecords(records, () => {
            const products = ShopStore.products;
            this.putRecordsFromModel(products, () => UIStore.hideLoading());
          });
          break;
        case "remote":
          for (let model of ShopStore.products.values()) {
            model.units = 0;
            model.cartEntryIndex = null;
          }
          ShopStore.reset();
          this.mapProducts(remoteProducts, () => UIStore.hideLoading());
          break;
        case "unify":
          const products = ShopStore.products;
          this.putRecordsFromModel(products, () => {
            this.mapProducts(remoteProducts, () => UIStore.hideLoading());
          });
          break;
        default:
      }
    });
  };

  /**
   * Interface para el método de Cognito
   *
   * @param [{String}] - nombre del dataset
   * @param [{Function}] - callback
   */
  async openOrCreateDataset(dataset, callback) {
    await this.syncManagerClient.openOrCreateDataset(
      dataset,
      (error, dataset) => {
        // TODO: controlar este error
        if (error) console.error(error);

        if (callback && typeof callback === "function")
          return callback(dataset);
      }
    );
  }

  /**
   *
   */
  async openOrCreateProviderCartDataset(idprovider, callback) {
    if (this.initialized) {
      console.log({idprovider})
      await this.openOrCreateDataset(`cart_${idprovider}`, (dataset) => {
        console.log(
          `%cEl dataset cart_${idprovider} se ha recuperado o creato con exito!`,
          "color: green"
        );
        this.cartProducts = dataset;
        this.sync(dataset, (error, dataset) => {
          if (error) console.error(error);
          console.info(
            `%cEl dataset cart_${idprovider} se ha sincronizado con éxito!`,
            "color: green"
          );
          this.getAllRecords(dataset, callback);
        });
      });
    }
  }

  /**
   * Recupera los records de un dataset.
   */
  async getAllRecords(dataset, callback) {
    await dataset.getAllRecords((error, records) => {
      // NOTE: controlar el error
      if (error) console.error(error);

      // TODO: controlar que si hacemos login desde el lineal se ejecuta dos veces
      // y se duplican los conflictos!!!!
      // No pasa nada porque se controla con los modelos pero no mola.. De momento
      // con ocultar el preloader vamos bien.
      const products = records
        .map((record) => {
          if (record.value !== "") return JSON.parse(record.value);
        })
        .filter((record) => record !== undefined);
      // NOTE: si existen productos en el carrito de AWS Cognito comprobamo
      // si existen conflictos con el catalogo que hemos descargado.
      // if (products.length && AuthStore.user && !ShopStore.conflicts.length)
      //   ShopStore.checkCartConflicts(products);
      
      console.log("currentLocation: ", browserHistory.getCurrentLocation().pathname);
      if (
        !browserHistory.getCurrentLocation().pathname.includes("compra") &&
        !browserHistory.getCurrentLocation().pathname.includes("carrito")
      ) {
        console.log("hideLoading");
        UIStore.hideLoading();
      }

      console.info(
        `%cLos productos del dataset cart_${AuthStore.bookingId} se han sincronizado con éxito!`,
        "color: green"
      );
      if (callback && typeof callback === "function")
        return callback(records, products);
    });
  }

  /**
   * Sincroniza los records del dataset con los modelos de AppStore.
   * NOTE: hay que actulizar el modelo y actualizar el record con el precio
   * del modelo ya que el precio del record puede estar desactulizado.
   */
  syncronizeCartProducts(products, callback) {
    products.forEach((product) => {
      //ShopStore.quantity += product.units
      product.cartEntryIndex =
        product.cartEntryIndex || ShopStore.quantity + product.units;
      ShopStore.products.add(product);
      ShopStore.quantity = ShopStore.units;
      if (ShopStore.isEmptyCart) ShopStore.isEmptyCart = false;
    });

    if (callback && typeof callback === "function") return callback();
  }

  /**
   * Obtiene un record (producto).
   */
  getRecord = (key, callback) => {
    console.log("getRecord");
    if (this.cartProducts === undefined && callback) return callback();
    if (this.cartProducts === undefined) {
      return this.checkCartStatus(null, () => {
        this.openOrCreateProviderCartDataset(AuthStore.bookingId, () =>
          this.getRecord(key, null)
        );
      });
    }

    this.cartProducts.get(key, (error, record) => {
      if (error) console.error(error);
      if (callback && typeof callback === "function") return callback(record);
    });
  };

  /**
   * Añade o sobreescribe un record (producto).
   * Lo llamamos desde los métodos 'add' y 'subtract' de ShopStore.
   */
  putRecord = (key, value, callback) => {
    console.log("putRecord");
    if (this.cartProducts === undefined && callback) return callback();
    if (this.cartProducts === undefined) {
      return this.checkCartStatus(null, () => {
        this.openOrCreateProviderCartDataset(AuthStore.bookingId, () =>
          this.putRecord(key, value, null)
        );
      });
    }
    if (!AuthStore.user) {
      sessionStorage.setItem(
        "ProductsWithoutAuthenticated",
        JSON.stringify(ShopStore.products)
      );
    }
    this.cartProducts.put(key, value, (error, record) => {
      if (error) console.error(error);
      this.sync(this.cartProducts, () => {
        if (callback && typeof callback === "function") return callback(record);
      });
    });
  };

  /**
   * Eliminamos un record (producto)
   * Lo llamamos desde el método 'remove' de ShopStore.
   */
  deleteRecord(model, callback) {
    console.log("deleteRecord");
    console.info(`%cEliminando producto de AWS Cognito`, "color: green");
    // console.log(model)
    this.cartProducts.remove(
      model.product.id_product_eva.toString(),
      (error, record) => {
        if (error) console.error(error);

        this.sync(this.cartProducts, () => {
          if (callback && typeof callback === "function")
            return callback(record);
        });
      }
    );
  }

  /**
   * Añade productos a un 'cart_' de AWS Cognito
   */
  putRecordsFromModel(products, callback) {
    console.log("putRecordsFromModel");
    for (let product of products) {
      const json = {
        type: product.product.type_price,
        quantity: product.units,
        product: toJS(product.product),
      };

      this.cartProducts.put(
        product.product.id_product_eva.toString(),
        JSON.stringify(json),
        (error, product) => {
          if (error) console.error(error);
        }
      );
    }

    this.sync(this.cartProducts, () => {
      if (callback && typeof callback === "function") return callback();
    });
  }

  /**
   * Elimina productos de un 'cart_' de AWS Cognito
   */
  deleteRecords(records, callback) {
    console.log("deleteRecords");
    for (let record of records) {
      this.cartProducts.remove(record.key.toString(), (error, record) => {
        if (error) console.error(error);
      });
    }

    this.sync(this.cartProducts, () => {
      if (callback && typeof callback === "function") return callback();
    });
  }

  /**
   * Fuerza el sincronizado del carrito.
   * NOTE: Se utiliza desde los metodos onEnter de las vistas que requieren de
   * sincronización de carrito
   */
  forceCartSync() {
    this.sync(this.cartProducts, (nulled, dataset) => {
      this.getAllRecords(dataset, (records) => {
        const products = records
          .map((record) => {
            if (record.value !== "") return JSON.parse(record.value);
          })
          .filter((record) => record !== undefined);

        let units = 0;
        products.forEach((product, index) => {
          if (!product) return;

          const quantity = product.quantity || 0;
          units += quantity;

          let model = AppStore.products.get(product.product.id_product_eva);
          if (!model) {
            model = new ProductModel(product.product, null, index, quantity);
            AppStore.products.set(product.product.id_product_eva, model);
          }

          // model.units = quantity
          model.setUnits(quantity);
          ShopStore.products.add(model);
        });

        ShopStore.setUnits(units);
        ShopStore.shoppingCartForceUpdate = !ShopStore.shoppingCartForceUpdate;
      });
    });
  }

  /**
   * Sincronizando...
   */
  sync(dataset, callback) {
    let that = this;
    dataset.synchronize({
      onSuccess(_dataset, newRecords) {
        // NOTE: controlamos que el carrito está vacio
        // if (ShopStore.units <= 0 && _dataset.datasetName === `cart_${ AppStore.idprovider }`) ShopStore.isEmptyCart = true
        if (callback && typeof callback === "function")
          return callback(null, _dataset);
      },
      onFailure(error) {
        console.log("onFailure", error);
        if (callback && typeof callback === "function") return callback(error);
      },
      onConflict(_dataset, conflicts, cb) {
        console.log("onConflict");
        let resolved = [];
        for (let i = 0; i < conflicts.length; i++) {
          // Take remote version.
          resolved.push(conflicts[i].resolveWithRemoteRecord());
          // Or... take local version.
          // resolved.push(conflicts[i].resolveWithLocalRecord());
          // Or... use custom logic.
          // var newValue = conflicts[i].getRemoteRecord().getValue() + conflicts[i].getLocalRecord().getValue();
          // resolved.push(conflicts[i].resolveWithValue(newValue)
        }

        _dataset.resolve(resolved, () => cb(true));
        // Or... cb false to stop the synchronization process.
        // return cb(false);
      },
      onDatasetDeleted(_dataset, datasetName, cb) {
        console.log("onDatasetDeleted" + datasetName + _dataset);
        // Return true to delete the local copy of the dataset.
        // Return false to handle deleted datasets outside the synchronization cb.
        // NOTE: remedio temporal para borrar los datasets locales
        //return cb(true)
        for (let model of ShopStore.products.values()) {
          model.units = 0;
          model.cartEntryIndex = null;
        }
        ShopStore.reset();
        return cb(() => {
          that.cartProducts = undefined;
          return true;
        });
      },
      onDatasetsMerged(_dataset, datasetNames, cb) {
        // Return true to continue the synchronization process.
        // Return false to handle dataset merges outside the synchroniziation cb.
        return cb(false);
      },
    });
  }

  /**
   * Añade al objeto map 'this.cognitoProducts' los productos que se
   * recuperan del dataset 'cart_'
   *
   * TODO: controlar el precio del producto y si ha cambiado lo pisamos
   * por el que viene nuevo en el catalogo.
   */
  mapProducts(products, callback) {
    if (!products) return;

    products.forEach((record, index) => {
      let data = record.value !== "" ? JSON.parse(record.value) : null;

      if (data) {
        const product = data.product;
        const quantity = data.quantity || 0;

        let model;
        if (!AppStore.products.get(product.id_product_eva)) {
          model = new ProductModel(product, null, index, quantity);
          AppStore.products.set(product.id_product_eva, model);
        } else {
          model = AppStore.products.get(product.id_product_eva);
          model.units = quantity;
        }

        if (!this.cognitoProducts.get(product.id_product_eva))
          this.cognitoProducts.set(product.id_product_eva, model);
      }
    });
    console.log("Esntro aqui mapProducts");
    if (this.cognitoProducts.size > 0)
      this.syncronizeCartProducts(this.cognitoProducts, callback);
  }

  reset() {
    this.initialized = false;
    this.syncManagerClient = undefined;
    this.cartsConfig = undefined;
    this.cartProducts = undefined;
    this.cognitoProducts = undefined;
    this.cognitoProducts = new Map();
  }
}

export default new SyncStore();
