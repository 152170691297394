
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import config from '../../config'
import { AppStore, ShopStore } from '../../stores'
import { urliFy } from '../../utils'
import ProductImage from './components/ProductImage'
import QuantityBadge from './components/QuantityBadge'
import CartProductOptions from './components/CartProductOptions'

@observer
class CartProduct extends Component {

  state = {
    optionsVisible: false
  }

  render () {
    const { description, image, price, type_price, brand } = this.props.product.product
    const currency = (type_price === 1) ? ' €' : ' $'
    const productStatus = this.props.product.status || undefined;
    return(
      <li className="cart-product">
        <div className="content" onMouseOver={ this.onMouseOver } onMouseOut={ this.onMouseOut }>
          { (!productStatus || productStatus.status !== 'deleted') ? this.renderOptions() : null }
          <ProductImage src={ image } description={ description || '' } brand={ brand || '' } className={ `${ (productStatus) ? productStatus.status : 'rack-product' } ` } product={this.props.product.product}/>
          <QuantityBadge units={ this.props.product.units } />
          { (productStatus) ? this.renderConflictPrice(price, productStatus, currency) : this.renderRegularPrice(price, currency) }
        </div>
      </li>
    )
  }


  /**
   * Renderiza el menú de opciones para el producto
   */
  renderOptions () {
    const { id_product_eva, description } = this.props.product.product
    const link = `/producto/${ id_product_eva }/${ urliFy(description) }`
    return (
      <CartProductOptions product={ id_product_eva }
                          link={ link }
                          onAdd={ () => this.onAddHandler(id_product_eva) }
                          onSubtract={ () => this.onSubtractHandler(id_product_eva) }
                          onDelete={ () => this.onDeleteHandler(id_product_eva) }
                          units={ this.props.product.units }
                          state={ this.state.optionsVisible } />
    )
  }

  // Precio para productos sin conflictos
  renderRegularPrice = (price, currency) => <p className="p regular black s16 price">{ (price || 0).toFixed(2) } { currency }</p>

  // Precio para productos con conflictos
  renderConflictPrice (price, status, currency) {
    console.log(status);
    if (status.status === 'deleted') {
      return <p className="p regular red s16 price">No disponible</p>
    } else {
      return <p className="p regular black s16 price"><span className="p regular grey s16">{ (status.price.bad).toFixed(2) } { currency }</span> - { (status.price.correct).toFixed(2) } { currency }</p>
    }
  }

  // Mouse over
  onMouseOver = (event) => this.setState({ optionsVisible: true })

  // Mouse out
  onMouseOut = (event) => this.setState({ optionsVisible: false })

  // Incrementamos las unidades del produto
  onAddHandler = (id) => ShopStore.add(id, 1)

  // Restamos las unidades del produto
  onSubtractHandler = (id) => ShopStore.subtract(id, 1)

  // Eliminamos el producto del carro
  onDeleteHandler = (id) => ShopStore.remove(id)
}


export default CartProduct
