import React, { Component } from 'react'
import { translate } from 'react-i18next'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { LinkIconButton, Icon, IconButton } from '../'
import { AppStore } from '../../stores'
import config from '../../config'
import { capitalize } from '../../utils'
import { Link } from 'react-router'

@translate(['common'], { wait: false })
class FiltersAllergens extends Component {

    state = {
      allergensEnable: AppStore.allergensEnable
    }


    render () {

      const icons = AppStore.allergens.map(allergens => {
          var colour = "rgba(0, 172, 183, 0.3)"
          var fill = "rgba(37,49,55, 0.3)"
          const id = allergens.id_allergen
          const name = allergens.name
          if (this.state.allergensEnable.includes(id)) {
              colour =  "rgba(0, 209, 195, 0.7)"
              fill = "rgba(255,255,255, 0.8)"
          }
          return <div className="icon_position_up" key={id}> 
                    <div className="icon_up">
                          <IconButton type="fab large"
                              style={ { backgroundColor: colour } }
                              iconFill= { fill }
                              id={id}
                              iconSize="82"
                              viewport = '0 0 200 200'
                              iconType= { "allergen_" + id }
                              iconTitle={name}
                              onClick={ () => this.AllergenEnabled(id)} />
                    </div>
            <div className="icon_down"> <p className="p s13 regular white" >{name}</p></div>
          </div>
      });

      return (
                <div className="parent_icon_position">
                  <div className="icon_top">
                    {icons}
                   </div>  
                </div>
      )

  }

    AllergenEnabled(id) {
        
        if (!AppStore.allergensEnable.includes(id)){
              AppStore.allergensEnable.push(id)
              this.setState({ allergensEnable : AppStore.allergensEnable} )
        } else {
              var index = AppStore.allergensEnable.indexOf(id);
              if (index > -1) {
                  AppStore.allergensEnable.splice(index, 1);
                  this.setState({ allergensEnable : AppStore.allergensEnable} )
              }
        }

    }

}
export default FiltersAllergens