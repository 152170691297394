
import React from 'react'

const store = () => {
  return (
    <g>
      <rect x="1846.309" y="994.559" width="0" height="4.601"/>
      <g>
       <rect x="12.047" y="-0.417" width="176.743" height="33.139"/>
       <path d="M199.754,98.575c0.012-0.287-0.172-0.836-0.172-0.836L188.79,43.768H12.047L1,97.739c0,0.285,0.07,0.549,0.086,0.836L1,99
         h0.129c0.508,6.638,4.881,12.146,10.918,14.294v85.123h110.464v-85.123c6.036-2.148,10.409-7.656,10.923-14.294h0.254
         c0.652,8.548,7.723,15.308,16.439,15.308c8.723,0,15.787-6.76,16.445-15.308h0.125v99.417h22.093v-85.123
         c6.036-2.148,10.409-7.656,10.923-14.294h0.123L199.754,98.575z M100.419,176.324H34.14V99h0.13
         c0.65,8.548,7.725,15.308,16.439,15.308c8.723,0,15.787-6.76,16.445-15.308h0.254c0.652,8.548,7.727,15.308,16.439,15.308
         c8.723,0,15.788-6.76,16.447-15.308h0.124V176.324z"/>
      </g>
    </g>
  )
}

export default store
