
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { UIStore } from '../../stores'

@observer
class ProductTooltip extends Component {
  render () {
    return (
      <div className={ `product-tooltip ${ UIStore.isProductTooltipVisible ? ' visible' : '' }` }>
        <h1 className="h1 light white s18">{ UIStore.productTooltipDescription || '' }</h1>
        <h2 className="h2 bold white s16">{ UIStore.productTooltipBrand || '' }</h2>
      </div>
    )
  }
}

export default ProductTooltip
