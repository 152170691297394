
import React from 'react'

const send_order = () => {
  return (
    <g>
      <g>
     		<g>
     			<path d="M177.566,24.01H50.183c-0.229-0.929-0.441-1.778-0.614-2.441C46.822,11.035,40.992,5.751,36.586,3.171
     				c-4.919-2.879-9.293-3.143-10.118-3.168L8.027,0C3.593,0,0,3.595,0,8.029c0,4.437,3.593,8.029,8.027,8.029h18.021
     				c0.499,0.052,5.703,0.809,7.988,9.564c2.939,11.272,15.091,66.211,20.932,92.738c0.014,0.1,0.006,0.198,0.024,0.297
     				c0.042,0.22,0.12,0.42,0.18,0.632c1.686,7.664,2.808,12.782,2.947,13.417c1.287,6.26,7.526,17.959,22.096,17.959h111.26
     				c4.434,0,8.028-3.592,8.028-8.029c0-4.433-3.593-8.027-8.028-8.027H80.214c-4.622,0-6.007-3.647-6.391-5.242l-0.008-0.037
     				c-0.111-0.509-0.571-2.606-1.277-5.821c24.56-4.646,97.116-18.143,107.677-20.368c9.19-1.937,14.043-6.83,16.494-10.593
     				c2.672-4.101,3.171-7.791,3.26-8.827l0.03-0.342V46.443C200,29.983,186.584,24.01,177.566,24.01z M183.943,82.331
     				c-0.281,1.058-1.547,3.941-7.038,5.096c-10.338,2.178-83.894,15.867-107.819,20.394c-4.331-19.646-10.703-48.403-15.136-67.758
     				h123.613c0.019,0,1.909,0.102,3.613,0.952c1.438,0.718,2.766,1.872,2.766,5.428V82.331z"/>
     			<path d="M79.883,154.805c-12.461,0-22.598,10.14-22.598,22.598c0,12.462,10.137,22.597,22.598,22.597
     				c12.458,0,22.599-10.134,22.599-22.597C102.482,164.946,92.341,154.805,79.883,154.805z M79.883,183.947
     				c-3.609,0-6.542-2.933-6.542-6.543c0-3.607,2.933-6.542,6.542-6.542c3.608,0,6.542,2.936,6.542,6.542
     				C86.426,181.013,83.491,183.947,79.883,183.947z"/>
     			<path d="M167.022,154.805c-12.459,0-22.594,10.14-22.594,22.598c0,12.462,10.136,22.597,22.594,22.597
     				c12.461,0,22.6-10.134,22.6-22.597C189.622,164.946,179.483,154.805,167.022,154.805z M167.022,183.947
     				c-3.607,0-6.541-2.933-6.541-6.543c0-3.607,2.934-6.542,6.541-6.542c3.608,0,6.547,2.936,6.547,6.542
     				C173.569,181.013,170.63,183.947,167.022,183.947z"/>
     		</g>
     	</g>
     	<g>
     		<path d="M31.863,75.788c0,4.48-3.631,8.111-8.11,8.111H8.11c-4.479,0-8.11-3.631-8.11-8.111l0,0
     			c0-4.479,3.631-8.11,8.11-8.11h15.643C28.232,67.678,31.863,71.309,31.863,75.788L31.863,75.788z"/>
     		<path d="M42.974,109.17c0,4.48-3.631,8.111-8.11,8.111H8.11c-4.479,0-8.11-3.631-8.11-8.111l0,0
     			c0-4.479,3.631-8.11,8.11-8.11h26.754C39.343,101.06,42.974,104.691,42.974,109.17L42.974,109.17z"/>
     		<path d="M54.086,142.553c0,4.479-3.631,8.11-8.11,8.11H8.11c-4.479,0-8.11-3.631-8.11-8.11l0,0
     			c0-4.48,3.631-8.111,8.11-8.111h37.866C50.454,134.442,54.086,138.073,54.086,142.553L54.086,142.553z"/>
     	</g>
  	</g>
  )
}

export default send_order
