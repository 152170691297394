
import React from 'react'
import { Link } from 'react-router'
import { ShopStore } from '../../../../stores'
import { IconButton, LinkButton, Button } from '../../../'

const OrderButton = (props) => <div className="order-button"
                                     id={ props.id }
                                     onClick={ props.onClick } >
  <IconButton type="fab"
              style={ { backgroundColor: props.bubblecolor } }
              size="60"
              iconSize="40"
              iconType={ props.icon }
              iconTitle="AL TEXT GOES HERE"
              onClick={ null } />
  <p className="p bold black s16">{ props.units } productos:</p>
  <p className="p regular grey s12">{ props.date }</p>
</div>

export default OrderButton
