
import React from 'react'

const logo_symbol_basic = () => {
 return (
   <g transform="translate(0, -65)">
     <g>
       <g>
         <g>
           <path d="M195.762,83.539c0-0.378,0.092-0.728,0.278-1.053c0.184-0.323,0.445-0.582,0.774-0.774
             c0.334-0.192,0.689-0.288,1.067-0.288c0.391,0,0.75,0.094,1.074,0.286c0.326,0.19,0.581,0.449,0.77,0.775
             c0.184,0.328,0.277,0.681,0.277,1.053c0,0.391-0.096,0.75-0.287,1.074c-0.189,0.329-0.448,0.582-0.775,0.772
             c-0.328,0.186-0.679,0.278-1.059,0.278c-0.378,0-0.733-0.095-1.067-0.292c-0.329-0.191-0.59-0.451-0.774-0.772
             C195.854,84.276,195.762,83.923,195.762,83.539z M196.15,83.539c0,0.315,0.081,0.609,0.238,0.876
             c0.155,0.269,0.365,0.476,0.63,0.626c0.263,0.154,0.549,0.23,0.858,0.23c0.313,0,0.604-0.079,0.874-0.233
             c0.269-0.158,0.484-0.367,0.636-0.631c0.152-0.268,0.229-0.555,0.229-0.868c0-0.308-0.077-0.596-0.231-0.864
             c-0.158-0.264-0.371-0.476-0.638-0.632c-0.267-0.155-0.557-0.233-0.87-0.233c-0.309,0-0.595,0.078-0.863,0.23
             c-0.262,0.156-0.474,0.364-0.63,0.635C196.229,82.947,196.15,83.235,196.15,83.539z M198.083,82.426
             c0.208,0,0.375,0.06,0.507,0.173c0.13,0.113,0.195,0.26,0.195,0.444c0,0.178-0.059,0.323-0.171,0.445
             c-0.114,0.116-0.265,0.185-0.454,0.2V83.7c0.079,0.027,0.157,0.074,0.225,0.139c0.069,0.066,0.159,0.202,0.277,0.404
             l0.272,0.448h-0.516l-0.104-0.199c-0.091-0.186-0.175-0.335-0.256-0.45c-0.079-0.117-0.152-0.19-0.211-0.233
             c-0.06-0.038-0.143-0.059-0.247-0.059h-0.191v0.94h-0.414v-2.265H198.083z M197.921,83.411c0.136,0,0.24-0.032,0.311-0.085
             c0.075-0.062,0.111-0.139,0.111-0.241c0-0.104-0.041-0.184-0.115-0.24c-0.077-0.054-0.18-0.081-0.308-0.081h-0.512v0.647
             H197.921z M76.378,77.055c-0.895,1.485-2.127,2.229-3.701,2.229c-0.678,0-1.315-0.147-1.91-0.446
             c-0.636-0.38-1.125-0.878-1.465-1.496c-0.34-0.616-0.511-1.285-0.511-2.007c0-0.808,0.191-1.531,0.575-2.169
             c0.381-0.72,0.912-1.283,1.594-1.689c0.679-0.403,1.379-0.603,2.102-0.603c0.637,0,1.253,0.17,1.848,0.508
             c0.678,0.343,1.188,0.83,1.528,1.466c0.343,0.637,0.512,1.317,0.512,2.04C76.949,75.654,76.758,76.374,76.378,77.055z
              M81.343,105.381c-0.211,0.298-0.572,0.543-1.08,0.733c-0.51,0.188-1.021,0.244-1.529,0.158
             c-0.514-0.086-0.959-0.348-1.34-0.797c-0.379-0.445-0.572-1.18-0.572-2.199v-15.23c0-1.271,0.01-2.304,0.03-3.088
             c0.02-0.786,0.054-1.412,0.098-1.879c0.04-0.513,0.085-0.914,0.125-1.212c-2.634,0-4.629,0.555-5.988,1.659
             c-1.36,1.103-2.039,2.932-2.039,5.479l-0.064,15.039c0,1.063,0.16,2.079,0.481,3.057c0.316,0.977,0.804,1.825,1.462,2.551
             c0.66,0.721,1.478,1.282,2.454,1.687c0.977,0.404,2.124,0.542,3.442,0.412c1.359-0.083,2.357-0.476,2.995-1.177
             c0.634-0.701,1.06-1.435,1.275-2.195C81.343,107.483,81.43,106.484,81.343,105.381z M96.318,105.381
             c0.085,1.103,0,2.102-0.254,2.997c-0.214,0.76-0.635,1.494-1.276,2.195c-0.636,0.701-1.636,1.094-2.993,1.177
             c-1.318,0.13-2.464-0.008-3.44-0.412c-0.979-0.404-1.797-0.965-2.455-1.687c-0.659-0.726-1.146-1.574-1.466-2.551
             c-0.318-0.978-0.477-1.993-0.477-3.057V77.565c-0.043-1.275-0.076-2.306-0.096-3.091c-0.025-0.786-0.055-1.41-0.097-1.881
             c-0.041-0.508-0.084-0.91-0.127-1.21c2.633,0,4.652,0.555,6.055,1.658c1.399,1.106,2.101,2.91,2.101,5.417v24.819
             c0,1.019,0.19,1.755,0.575,2.199c0.38,0.448,0.824,0.711,1.336,0.797c0.508,0.086,1.02,0.03,1.531-0.158
             C95.745,105.924,96.104,105.679,96.318,105.381z M127.412,97.99c0,2.845-0.423,5.162-1.273,6.945
             c-0.851,1.784-1.944,3.185-3.282,4.203c-1.337,1.023-2.837,1.724-4.492,2.104c-1.656,0.382-3.293,0.573-4.906,0.573
             c-1.617,0-3.251-0.191-4.909-0.573c-1.656-0.38-3.15-1.081-4.491-2.104c-1.336-1.018-2.42-2.419-3.251-4.203
             c-0.827-1.783-1.241-4.099-1.241-6.945l0.065-8.985c0-2.547,0.689-4.364,2.068-5.448c1.382-1.083,3.388-1.625,6.023-1.625
             c-0.043,0.255-0.086,0.635-0.128,1.148c-0.042,0.467-0.075,1.105-0.095,1.91c-0.022,0.807-0.035,1.846-0.035,3.122l0.065,9.878
             c0,1.785,0.172,3.216,0.51,4.299c0.338,1.084,0.798,1.923,1.372,2.517c0.573,0.595,1.209,0.998,1.91,1.212
             c0.702,0.211,1.412,0.318,2.137,0.318c0.72,0,1.432-0.107,2.133-0.318c0.702-0.214,1.339-0.618,1.91-1.212
             c0.576-0.595,1.032-1.433,1.371-2.517c0.34-1.083,0.511-2.514,0.511-4.299l0.065-8.985c0-2.547,0.699-4.364,2.101-5.448
             c1.401-1.083,3.418-1.625,6.054-1.625c-0.042,0.255-0.085,0.635-0.13,1.148c-0.04,0.467-0.071,1.105-0.092,1.91
             c-0.023,0.807-0.033,1.846-0.033,3.122L127.412,97.99z M146.273,103.659c0.082-0.722-0.12-1.327-0.608-1.816
             c-0.489-0.489-1.187-0.957-2.103-1.401c-0.913-0.445-1.986-0.926-3.218-1.433c-1.23-0.511-2.527-1.189-3.883-2.042
             c-1.02-0.593-1.858-1.307-2.518-2.131c-0.659-0.829-1.138-1.721-1.435-2.678c-0.296-0.954-0.425-1.93-0.382-2.928
             c0.04-1,0.277-1.989,0.701-2.968c0.425-0.932,0.977-1.719,1.657-2.355c0.681-0.637,1.435-1.158,2.262-1.563
             c0.828-0.401,1.667-0.689,2.519-0.858c0.847-0.169,1.633-0.255,2.357-0.255h0.765c2.25,0.127,4.31,0.68,6.179,1.656
             c1.869,0.978,3.464,2.274,4.782,3.889l0.192,0.189l-0.192,0.256c-0.427,0.594-0.892,1.039-1.402,1.336
             c-0.469,0.296-0.998,0.447-1.593,0.447c-0.597,0-1.17-0.115-1.721-0.35c-0.552-0.232-1.145-0.48-1.784-0.734
             c-0.809-0.338-1.561-0.623-2.264-0.859c-0.699-0.234-1.412-0.35-2.134-0.35c-0.935,0-1.635,0.201-2.104,0.605
             c-0.466,0.402-0.743,0.882-0.828,1.432c-0.085,0.807,0.171,1.479,0.766,2.009c0.595,0.53,1.467,1.029,2.614,1.496
             c1.313,0.554,2.715,1.156,4.204,1.816c1.486,0.659,2.802,1.465,3.951,2.42c1.144,0.957,2.017,2.102,2.611,3.442
             c0.596,1.336,0.66,2.963,0.191,4.873c-0.212,0.764-0.573,1.561-1.084,2.389c-0.507,0.827-1.22,1.585-2.133,2.262
             c-0.917,0.68-2.018,1.243-3.314,1.69c-1.296,0.447-2.814,0.667-4.554,0.667c-0.344,0-0.618-0.021-0.828-0.065
             c-2.039-0.083-4.047-0.615-6.022-1.589c-1.976-0.978-3.62-2.272-4.938-3.888l-0.193-0.192l0.193-0.255
             c0.214-0.337,0.444-0.614,0.698-0.829c0.257-0.21,0.487-0.403,0.703-0.574c0.509-0.255,1.018-0.379,1.529-0.379
             c0.594,0,1.17,0.115,1.72,0.349c0.552,0.236,1.15,0.48,1.785,0.733c0.595,0.299,1.265,0.555,2.007,0.767
             c0.744,0.211,1.562,0.363,2.454,0.445c1.528,0,2.621-0.297,3.28-0.892C145.816,104.848,146.187,104.255,146.273,103.659z
              M165.26,77.055c-0.892,1.485-2.126,2.229-3.695,2.229c-0.681,0-1.32-0.147-1.911-0.446c-0.638-0.38-1.126-0.878-1.467-1.496
             c-0.34-0.616-0.509-1.285-0.509-2.007c0-0.808,0.191-1.531,0.573-2.169c0.383-0.72,0.912-1.283,1.592-1.689
             c0.681-0.403,1.38-0.603,2.103-0.603c0.639,0,1.253,0.17,1.849,0.508c0.678,0.343,1.189,0.83,1.529,1.466
             c0.338,0.637,0.509,1.317,0.509,2.04C165.833,75.654,165.643,76.374,165.26,77.055z M170.231,105.381
             c-0.216,0.298-0.573,0.543-1.083,0.733c-0.51,0.188-1.02,0.244-1.531,0.158c-0.508-0.086-0.953-0.348-1.338-0.797
             c-0.38-0.445-0.572-1.18-0.572-2.199v-15.23c0-1.271,0.01-2.304,0.032-3.088c0.021-0.786,0.05-1.412,0.095-1.879
             c0.042-0.513,0.085-0.914,0.129-1.212c-2.635,0-4.632,0.555-5.99,1.659c-1.361,1.103-2.037,2.932-2.037,5.479l-0.065,15.039
             c0,1.063,0.159,2.079,0.478,3.057c0.318,0.977,0.805,1.825,1.465,2.551c0.656,0.721,1.474,1.282,2.454,1.687
             c0.979,0.404,2.123,0.542,3.441,0.412c1.36-0.083,2.356-0.476,2.994-1.177c0.638-0.701,1.062-1.435,1.276-2.195
             C170.231,107.483,170.317,106.484,170.231,105.381z M175.644,94.868c1.147-0.723,2.444-1.264,3.889-1.627
             c1.442-0.359,2.95-0.542,4.522-0.542c2.208,0,4.314,0.317,6.31,0.957c-0.128-1.317-0.405-2.484-0.828-3.503
             c-0.552-1.316-1.38-2.273-2.486-2.868c-0.425-0.214-0.881-0.371-1.37-0.48c-0.487-0.105-1.05-0.159-1.687-0.159h-0.703
             c-0.593,0.044-1.191,0.139-1.782,0.289c-0.595,0.151-1.189,0.327-1.786,0.54c-0.594,0.213-1.191,0.393-1.784,0.544
             c-0.595,0.149-1.171,0.222-1.719,0.222c-0.596,0-1.17-0.129-1.722-0.384c-0.508-0.295-0.955-0.786-1.336-1.466l-0.127-0.254
             l0.127-0.191c1.274-1.358,2.77-2.452,4.492-3.281c1.72-0.829,3.597-1.307,5.637-1.433c0.214-0.043,0.393-0.064,0.542-0.064
             s0.329,0,0.543,0c1.951,0,3.736,0.349,5.351,1.052c1.615,0.7,3.015,1.666,4.206,2.898c2.423,2.635,3.675,6.095,3.76,10.383
             v0.512c0,4.418-1.127,8.008-3.379,10.769c-1.104,1.361-2.43,2.464-3.982,3.315c-1.55,0.85-3.282,1.377-5.193,1.592
             c-0.254,0.041-0.509,0.062-0.762,0.062c-0.253,0-0.533,0-0.828,0c-0.895,0-1.829-0.073-2.806-0.221
             c-0.977-0.151-1.933-0.435-2.868-0.86c-0.976-0.381-1.868-0.883-2.676-1.5c-0.805-0.613-1.485-1.409-2.036-2.388
             c-0.808-1.231-1.235-2.738-1.275-4.525v-0.381c0-1.655,0.341-3.038,1.02-4.142C173.501,96.63,174.414,95.675,175.644,94.868z
              M188.837,97.925c-0.555-0.085-1.179-0.17-1.883-0.257c-0.7-0.082-1.414-0.126-2.135-0.126c-0.595,0-1.168,0.032-1.718,0.098
             c-0.553,0.061-1.061,0.18-1.53,0.35c-1.063,0.34-1.869,0.87-2.418,1.592c-0.217,0.255-0.386,0.543-0.512,0.861
             c-0.128,0.316-0.213,0.669-0.255,1.049c-0.043,0.128-0.063,0.318-0.063,0.575c0,0.422,0.063,0.882,0.191,1.369
             c0.128,0.488,0.382,0.947,0.765,1.37c0.341,0.425,0.839,0.787,1.497,1.086c0.66,0.295,1.539,0.445,2.644,0.445
             c0.342,0,0.594-0.022,0.765-0.063c1.189-0.044,2.23-0.381,3.123-1.019c0.422-0.256,0.786-0.562,1.082-0.925
             c0.297-0.359,0.553-0.753,0.767-1.176c0.466-0.807,0.782-1.638,0.956-2.486c0.17-0.85,0.253-1.636,0.253-2.358
             c-0.044,0-0.074-0.009-0.096-0.031c-0.02-0.023-0.051-0.035-0.095-0.035c-0.17-0.04-0.363-0.082-0.576-0.125
             C189.387,98.074,189.13,98.011,188.837,97.925z"/>
           <path d="M54.769,93.916c0,15.125-12.261,27.384-27.384,27.384C12.26,121.3,0,109.04,0,93.916
             c0-15.125,12.26-27.385,27.385-27.385C42.508,66.531,54.769,78.791,54.769,93.916z M24.681,80.034
             c0-1.601-1.206-2.896-2.689-2.896c-1.479,0-2.678,1.296-2.678,2.896v18.371l-3.991-4.31c-1.048-1.129-2.744-1.127-3.797,0
             c-1.044,1.135-1.044,2.969,0,4.101l8.572,9.244c0.124,0.14,0.261,0.258,0.41,0.367c0.035,0.025,0.073,0.038,0.108,0.064
             c0.117,0.074,0.235,0.147,0.357,0.203c0.039,0.014,0.079,0.022,0.119,0.037c0.124,0.049,0.252,0.096,0.389,0.123
             c0.005,0.002,0.009,0.002,0.013,0.004c0.162,0.031,0.328,0.054,0.497,0.054c0.341,0,0.657-0.075,0.953-0.196
             c0.019-0.009,0.041-0.01,0.063-0.022c0.155-0.066,0.303-0.152,0.442-0.251c0.006-0.002,0.013-0.007,0.021-0.013
             c0.303-0.214,0.559-0.491,0.758-0.815c0.029-0.042,0.044-0.085,0.068-0.127c0.063-0.122,0.131-0.24,0.179-0.369
             c0.03-0.08,0.048-0.163,0.068-0.244c0.028-0.098,0.064-0.192,0.083-0.296c0.03-0.155,0.038-0.315,0.046-0.472
             c0-0.034,0.008-0.063,0.008-0.092V80.034z M33.235,92.317c0-1.601-1.202-2.898-2.684-2.898c-1.479,0-2.681,1.298-2.681,2.894
             v13.081c0,1.6,1.202,2.897,2.681,2.897c1.482,0,2.684-1.298,2.684-2.897V92.317z M41.919,96.716
             c0-1.599-1.196-2.898-2.674-2.896c-1.486-0.002-2.692,1.297-2.692,2.896v8.679c0,1.6,1.207,2.897,2.692,2.897
             c1.478,0,2.674-1.298,2.674-2.897V96.716z"/>
         </g>
       </g>
     </g>
    
    </g>
  )
}

export default logo_symbol_basic
