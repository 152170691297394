import React from 'react'

const welfare_9 = () => {
  return (
    <g>
        <path d="M115.104,86.449c-0.588,0-1.094,0.234-1.444,0.547c-1.992,1.68-3.945,2.617-6.833,2.617
            c-5.233,0-9.022-4.336-9.022-9.684v-0.078c0-5.349,3.826-9.646,9.022-9.646c2.614,0,4.645,0.938,6.519,2.463
            c0.314,0.233,0.781,0.467,1.444,0.467c1.328,0,2.383-1.016,2.383-2.342c0-0.861-0.43-1.525-0.938-1.912
            c-2.38-1.836-5.153-3.086-9.37-3.086c-8.278,0-14.095,6.365-14.095,14.135v0.078c0,7.848,5.934,14.055,13.9,14.055
            c4.413,0,7.34-1.405,9.917-3.669c0.43-0.39,0.781-0.977,0.781-1.718C117.368,87.465,116.313,86.449,115.104,86.449z"/>
        <path d="M64.907,102.141c0-3.403-2.043-5.086-4.98-6.089c1.864-0.968,3.582-2.58,3.582-5.59V90.39
            c0-1.684-0.571-3.045-1.681-4.156c-1.436-1.432-3.692-2.221-6.559-2.221H46.24c-1.255,0-2.222,0.967-2.222,2.221v20.636
            c0,1.254,0.967,2.221,2.222,2.221h9.387c5.589,0,9.28-2.4,9.28-6.879V102.141z M48.353,87.918h6.343
            c2.832,0,4.408,1.218,4.408,3.188v0.072c0,2.293-1.9,3.404-4.728,3.404h-6.022V87.918z M60.5,101.748
            c0,2.256-1.826,3.44-4.836,3.44h-7.311V98.31h6.989c3.475,0,5.158,1.252,5.158,3.367V101.748z"/>
        <path d="M85.96,112.187c-0.472-1.047-1.284-1.689-2.467-1.689h-0.204c-1.183,0-2.026,0.643-2.5,1.689L72,131.794
            c-0.169,0.339-0.27,0.641-0.27,0.946c0,1.081,0.845,1.925,1.927,1.925c0.879,0,1.589-0.472,1.925-1.317l1.995-4.565h11.493
            l1.927,4.431c0.373,0.879,1.047,1.45,2.026,1.45c1.117,0,1.961-0.879,1.961-1.992c0-0.27-0.067-0.574-0.236-0.879L85.96,112.187z
             M79.167,125.1l4.157-9.563l4.159,9.563H79.167z"/>
        <path d="M100,0C44.769,0,0,44.768,0,99.997C0,155.228,44.769,200,100,200c55.231,0,100-44.772,100-100.003
            C200,44.768,155.231,0,100,0z M30.739,96.547c0-13.192,10.695-23.885,23.887-23.885c13.192,0,23.887,10.693,23.887,23.885
            c0,13.193-10.695,23.887-23.887,23.887C41.434,120.433,30.739,109.74,30.739,96.547z M83.325,146.106
            c-12.428,0-22.503-10.073-22.503-22.503c0-0.146,0.01-0.291,0.011-0.435c10.632-2.469,18.878-11.139,20.732-21.994
            c0.579-0.046,1.165-0.073,1.759-0.073c0.378,0,0.754,0.01,1.128,0.028c4.391,4.473,10.16,7.585,16.621,8.652
            c2.977,3.814,4.754,8.61,4.754,13.822C105.828,136.032,95.754,146.106,83.325,146.106z M80.01,79.927
            c0-14.379,11.657-26.035,26.037-26.035c14.378,0,26.034,11.656,26.034,26.035c0,14.379-11.655,26.035-26.034,26.035
            C91.667,105.962,80.01,94.306,80.01,79.927z M145.658,128.231c-12.712,0-23.071-10.046-23.577-22.637
            c7.72-4.831,13.104-13.042,14.074-22.565c2.908-1.281,6.121-1.998,9.503-1.998c13.035,0,23.6,10.566,23.6,23.6
            C169.258,117.668,158.693,128.231,145.658,128.231z"/>
        <path d="M69.347,109.201H67.48l0.716-0.598c1.162-0.945,1.865-1.606,1.865-2.861c0-1.365-1.042-2.32-2.529-2.32
            c-1.056,0-1.797,0.36-2.471,1.2c-0.129,0.16-0.205,0.363-0.205,0.564c0,0.467,0.381,0.848,0.851,0.848
            c0.249,0,0.477-0.102,0.653-0.294c0.382-0.466,0.675-0.64,1.084-0.64c0.373,0,0.771,0.194,0.771,0.736
            c0,0.474-0.256,0.827-1.146,1.579l-2.059,1.753c-0.281,0.229-0.421,0.508-0.421,0.828c0,0.502,0.396,0.868,0.946,0.868h3.812
            c0.457,0,0.828-0.371,0.828-0.832C70.176,109.574,69.805,109.201,69.347,109.201z"/>
        <path d="M145.394,92.249h-7.046c-1.239,0-2.194,0.956-2.194,2.197v20.386c0,1.24,0.955,2.193,2.194,2.193h7.046
            c7.786,0,13.167-5.414,13.167-12.387v-0.07C158.561,97.596,153.18,92.249,145.394,92.249z M153.992,104.709
            c0,4.851-3.395,8.353-8.599,8.353h-4.886V96.214h4.886c5.204,0,8.599,3.575,8.599,8.424V104.709z"/>
    </g>
  )
}

export default welfare_9