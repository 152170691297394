import React from "react";
import { Icon } from "../../";

const NavigationButton = (props) => (
  <button
    className={`navigarion-button ${props.selected}`}
    id={props.id}
    onClick={props.onClick}
  >
    <Icon
      className="icon"
      type={props.icon}
      size="22"
      fill="dark-grey"
      viewport={props.viewport ? props.viewport : ""}
    ></Icon>
    <p className="p regular white s16 a70">{props.placeholder}</p>
  </button>
);

export default NavigationButton;
