
import config from '../../config'

/**
 * @func: getLayoutIds
 * @return {[Object]} data
 *
 * @description:
 */

const getLayoutIds = (categories) => {
  const layoutsArray = categories.map(cat => {
    if (cat.id_layout !== null) return cat.id_layout
  })

  return layoutsArray.filter((item, pos) => layoutsArray.indexOf(item) === pos)
}


export default getLayoutIds
