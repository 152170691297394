
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Link, IndexLink } from 'react-router'
import { AppStore, ShopStore } from '../../../stores'
import { Icon, LinkButton } from '../../../components'
import ShoppingcartQuantityBadge from './ShoppingcartQuantityBadge'
import CategoriesMenu from './CategoriesMenu/CategoriesMenu'
import Dropdown from '../../Dropdowns/Dropdown'
import { browserHistory } from "react-router";

const SHOW_MORE_OPTIONS = [
  {
    text: "Filtros",
    active: true,
    action: () => {
      browserHistory.push("/filtros")
    },
  },
  {
    text: "Mis Pedidos",
    active: false,
    action: () => {
      AppStore.userZoneView = 1;
      AppStore.userZoneViewPlaceholder = "Pedidos";
      browserHistory.push("/zona-usuario");
    },
  },
  {
    text: "Ayuda",
    active: false,
    action: () => {
      AppStore.userZoneView = 2;
      AppStore.userZoneViewPlaceholder = "Ayuda";
      browserHistory.push("/zona-usuario");
    },
  },
];

const ProviderOptions = (props) => <div className="nav-wrapper">
  <nav className="provider-nav">
    <ul>
      <li>
        <CategoriesMenu placeholder={ AppStore.currentcategoryName }
                        categories={ AppStore.categories } />
      </li>
    </ul>
  </nav>

  <nav className="shop-nav">
    <ul>
      <li>
        <IndexLink to="/" activeClassName="active">
          <Icon type="shopline" size="25px" />
        </IndexLink>
      </li>
      <li>
        <Link to="/buscar" activeClassName="active">
          <Icon type="search" size="25px" />
        </Link>
      </li>
      <li className={ShopStore.quantity > 0 ? "cart-has-products" : ""}>
        <Link to="/carrito" activeClassName="active">
          <Icon type="cart" size="25px"/>
        </Link>
        <ShoppingcartQuantityBadge units={ ShopStore.quantity }/>
      </li>
      <li className='user-zone-toolbar-btn'>
        <Link to="/zona-usuario" activeClassName="active">
          <Icon type="start_user" size="25px" />
        </Link>
      </li>

      <li className='show-more-toolbar-btn'>
        
        <Dropdown
              options={SHOW_MORE_OPTIONS}
              className="show_more_dropdown"
              content="list"
              buttonType="icon"
              buttonColor="white"
              placeholder="Show More"
              iconType="show_more"
              align="right"
              expandOnHoover={false}
              collapseOnLeave={false}
              setSelectedAsPlaceholder={false}
              transitionName="modal-anim"
              viewport= {"0 0 512 512"}
            />
      </li>

    </ul>
  </nav>
</div>

export default observer(ProviderOptions)
