import React from 'react'

const welfare_13 = () => {
  return (
        <g>
        <path d="M100,0C44.772,0,0,44.771,0,100c0,55.231,44.772,100,100,100c55.231,0,100-44.769,100-100
            C200,44.771,155.231,0,100,0z M75.407,131.808c0,0-7.969,6.895-8.17,7.168c-0.401,0.402-0.469,0.731-0.469,0.731
            s-0.133-0.395-0.332-0.669c-0.199-0.202-8.239-7.16-8.239-7.16c-2.207-2.214-2.207-2.214-2.207-10.916l8.708,7.969
            c1.4,1.406,1.937,1.937,2.07,4.621c0.137-2.747,0.671-3.214,2.145-4.688l8.704-7.969C77.617,129.665,77.617,129.665,75.407,131.808z
             M75.537,120.156c0,0-7.969,6.968-8.164,7.165c-0.404,0.405-0.469,0.737-0.469,0.737s-0.137-0.332-0.404-0.737
            c-0.199-0.197-8.239-7.165-8.239-7.165c-2.207-2.21-2.207-2.21-2.207-10.915l8.704,7.974c1.406,1.401,1.943,1.94,2.077,4.618
            c0.133-2.677,0.602-3.146,2.077-4.618l8.704-7.974C77.617,118.013,77.617,118.013,75.537,120.156z M75.407,108.708
            c0,0-7.969,6.895-8.034,7.166c-0.404,0.399-0.469,0.736-0.469,0.736s-0.137-0.337-0.404-0.736c-0.199-0.207-8.239-7.166-8.239-7.166
            c-2.207-2.209-2.207-2.209-2.207-10.918l8.704,7.972c1.406,1.406,1.943,1.945,2.077,4.621c0.133-2.676,0.602-3.148,2.077-4.621
            l8.704-7.972C77.617,106.499,77.617,106.499,75.407,108.708z M75.407,97.054c0,0-7.969,6.97-8.17,7.165
            c-0.401,0.405-0.469,0.739-0.469,0.739s-0.133-0.334-0.404-0.739c-0.198-0.195-8.236-7.165-8.236-7.165
            c-2.21-2.207-2.21-2.207-2.21-10.91l8.841,7.969c1.406,1.403,1.943,1.939,2.077,4.614c0.133-2.676,0.602-3.141,2.077-4.614
            l8.704-7.969C77.617,94.847,77.617,94.847,75.407,97.054z M112.809,127.997c0,0-11.816,10.221-12.113,10.628
            c-0.596,0.591-0.697,1.082-0.697,1.082s-0.195-0.589-0.495-0.993c-0.296-0.296-12.214-10.614-12.214-10.614
            c-3.268-3.281-3.268-3.281-3.268-16.186l12.904,11.813c2.08,2.088,2.878,2.874,3.073,6.851c0.199-4.071,0.993-4.762,3.177-6.948
            l12.91-11.816C116.087,124.819,116.087,124.819,112.809,127.997z M113.005,110.72c0,0-11.816,10.327-12.109,10.623
            c-0.599,0.602-0.697,1.094-0.697,1.094s-0.199-0.492-0.592-1.094c-0.296-0.296-12.217-10.623-12.217-10.623
            c-3.271-3.278-3.271-3.278-3.271-16.185l12.907,11.821c2.083,2.078,2.878,2.878,3.076,6.847c0.195-3.97,0.892-4.665,3.076-6.847
            l12.91-11.821C116.087,107.542,116.087,107.542,113.005,110.72z M112.809,93.747c0,0-11.816,10.221-11.914,10.623
            c-0.599,0.591-0.697,1.09-0.697,1.09s-0.199-0.5-0.592-1.09c-0.296-0.304-12.217-10.623-12.217-10.623
            c-3.271-3.278-3.271-3.278-3.271-16.188l12.907,11.816c2.083,2.087,2.878,2.882,3.076,6.852c0.195-3.97,0.892-4.668,3.076-6.852
            l12.91-11.816C116.087,90.469,116.087,90.469,112.809,93.747z M112.809,76.468c0,0-11.816,10.329-12.113,10.622
            c-0.596,0.601-0.697,1.097-0.697,1.097s-0.195-0.496-0.599-1.097c-0.293-0.293-12.21-10.622-12.21-10.622
            c-3.278-3.273-3.278-3.273-3.278-16.179l13.112,11.817c2.083,2.078,2.878,2.871,3.076,6.842c0.195-3.971,0.892-4.66,3.076-6.842
            l12.91-11.817C116.087,73.195,116.087,73.195,112.809,76.468z M141.868,131.808c0,0-7.969,6.895-8.167,7.168
            c-0.404,0.402-0.469,0.731-0.469,0.731s-0.133-0.395-0.332-0.669c-0.202-0.202-8.242-7.16-8.242-7.16
            c-2.204-2.214-2.204-2.214-2.204-10.916l8.704,7.969c1.4,1.406,1.94,1.937,2.074,4.621c0.133-2.747,0.667-3.214,2.142-4.688
            l8.708-7.969C144.082,129.665,144.082,129.665,141.868,131.808z M142.002,120.156c0,0-7.969,6.968-8.167,7.165
            c-0.404,0.405-0.469,0.737-0.469,0.737s-0.133-0.332-0.4-0.737c-0.202-0.197-8.239-7.165-8.239-7.165
            c-2.207-2.21-2.207-2.21-2.207-10.915l8.704,7.974c1.403,1.401,1.94,1.94,2.074,4.618c0.133-2.677,0.602-3.146,2.077-4.618
            l8.708-7.974C144.082,118.013,144.082,118.013,142.002,120.156z M141.868,108.708c0,0-7.969,6.895-8.034,7.166
            c-0.404,0.399-0.469,0.736-0.469,0.736s-0.133-0.337-0.4-0.736c-0.202-0.207-8.239-7.166-8.239-7.166
            c-2.207-2.209-2.207-2.209-2.207-10.918l8.704,7.972c1.403,1.406,1.94,1.945,2.074,4.621c0.133-2.676,0.602-3.148,2.077-4.621
            l8.708-7.972C144.082,106.499,144.082,106.499,141.868,108.708z M141.868,97.054c0,0-7.969,6.97-8.167,7.165
            c-0.404,0.405-0.469,0.739-0.469,0.739s-0.133-0.334-0.407-0.739c-0.199-0.195-8.232-7.165-8.232-7.165
            c-2.214-2.207-2.214-2.207-2.214-10.91l8.844,7.969c1.403,1.403,1.94,1.939,2.074,4.614c0.133-2.676,0.602-3.141,2.077-4.614
            l8.708-7.969C144.082,94.847,144.082,94.847,141.868,97.054z"/>
        </g>
  )
}

export default welfare_13