
import config from '../../config'
import checkApiResponse from './checkApiResponse'

/**
 * @func: editUser
 * @return {[Object]} data
 *
 * @description:
 */

const editUser = (user) => {
  return config.API.usersPut({}, user, {})
  .then(result => result.data)
  .catch(error => checkApiResponse(error))
}


export default editUser
