
import React from 'react'

const facebook = () => {
  return (
    <g>
      <path d="M144.058,102.296l3.538-35.288h-31.722V49.276c0-5.262,0.78-8.904,2.378-10.954
  			c1.564-2.024,5.446-3.052,11.632-3.052h17.538V0h-28.188c-16.432,0-28.15,3.862-35.188,11.62
  			c-7.02,7.766-10.56,19.182-10.56,34.31v21.08H52.404v35.288h21.084V200h42.184v-97.704
  			C115.672,102.296,144.058,102.296,144.058,102.296z"/>
  	</g>
  )
}

export default facebook
