import React, { Component } from "react";
import { observer } from "mobx-react";
import config from "../../config";
import { Icon } from "../";
import { AppStore } from "../../stores";
import { capitalize } from "../../utils";

const LoginPreloader = (props) => (
  <div
    className="shop-loader-wrapper"
    style={{
      background: AppStore.provider
        ? AppStore.provider.last_color
        : config.COLORS.BLUE,
      background: AppStore.provider
        ? `linear-gradient(0deg, ${AppStore.provider.last_color} 1%, ${AppStore.provider.first_color} 95%)`
        : config.COLORS.BLUE,
    }}
  >
    <img
      className="provider_loader"
      src="/img/preloaders/ilusia_default.png"
      alt="Ilusia"
    />
    <div className="overhead-loader transparent">
      <svg className="circular" width="65px" height="65px" viewBox="0 0 66 66">
        <circle
          className="path"
          fill="none"
          strokeWidth="3"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="26"
        ></circle>
      </svg>
      <Icon className="logo" type="hand" fill="white" size="40"></Icon>
    </div>
    <p className="p regular white">
      Autenticando Usuario
    </p>
  </div>
);

export default LoginPreloader;
