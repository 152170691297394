import React from 'react'

const welfare_6 = () => {
  return (
<g>
<path d="M67.025,106.657l31.668,27.096c-10.337,0.033-47.083,0.143-63.517,0.156L67.025,106.657z M174.963,166.15
    L174.963,166.15c-2.754,3.117-5.697,6.061-8.814,8.813l0,0c-0.003,0.002-0.005,0.003-0.007,0.007
    c-6.56,5.79-13.878,10.735-21.798,14.66c-0.075,0.036-0.147,0.078-0.222,0.114c-1.083,0.534-2.182,1.035-3.286,1.53
    c-0.326,0.146-0.648,0.299-0.975,0.443c-0.983,0.426-1.979,0.83-2.979,1.227c-0.465,0.186-0.926,0.371-1.394,0.55
    c-0.924,0.352-1.855,0.684-2.792,1.006c-0.562,0.195-1.124,0.387-1.69,0.573c-0.882,0.286-1.769,0.56-2.66,0.824
    c-0.64,0.192-1.283,0.371-1.929,0.55c-0.849,0.231-1.698,0.452-2.555,0.664c-0.708,0.176-1.42,0.335-2.134,0.492
    c-0.819,0.182-1.638,0.361-2.465,0.524c-0.775,0.15-1.554,0.283-2.334,0.417c-0.785,0.133-1.571,0.27-2.362,0.387
    c-0.851,0.124-1.709,0.225-2.566,0.326c-0.74,0.088-1.476,0.189-2.221,0.26c-0.965,0.094-1.94,0.156-2.913,0.221
    c-0.653,0.046-1.301,0.104-1.957,0.137c-1.399,0.068-2.809,0.098-4.221,0.107c-0.232,0.003-0.459,0.016-0.69,0.016
    c-38.215,0-71.412-21.443-88.245-52.948C4.26,133.025,0,117.013,0,100.001l0,0V100c0-0.006,0-0.011,0-0.015
    c0.001-1.651,0.045-3.292,0.124-4.924c0.029-0.579,0.083-1.151,0.121-1.727c0.069-1.05,0.138-2.101,0.239-3.142
    c0.067-0.698,0.161-1.388,0.243-2.082c0.107-0.905,0.211-1.811,0.343-2.706c0.111-0.758,0.244-1.507,0.371-2.259
    c0.138-0.815,0.278-1.629,0.434-2.438c0.156-0.801,0.331-1.592,0.506-2.385c0.164-0.741,0.33-1.479,0.511-2.214
    c0.204-0.834,0.423-1.663,0.648-2.489c0.183-0.668,0.371-1.334,0.567-1.996c0.258-0.872,0.526-1.74,0.806-2.603
    c0.191-0.586,0.391-1.166,0.592-1.748c0.318-0.92,0.644-1.834,0.988-2.74c0.185-0.487,0.378-0.967,0.571-1.449
    c0.391-0.982,0.788-1.961,1.209-2.93c0.15-0.344,0.31-0.684,0.464-1.027c0.488-1.086,0.984-2.169,1.508-3.235
    c0.05-0.099,0.103-0.198,0.153-0.297c3.922-7.898,8.855-15.197,14.632-21.74c0.002-0.001,0.002-0.001,0.003-0.002l0,0
    c8.532-9.662,18.896-17.66,30.557-23.451c0.1-0.049,0.197-0.102,0.297-0.151c1.061-0.523,2.139-1.017,3.223-1.502
    c0.348-0.158,0.693-0.32,1.044-0.473c0.963-0.419,1.937-0.812,2.915-1.203c0.487-0.193,0.973-0.389,1.463-0.576
    c0.902-0.342,1.812-0.666,2.728-0.984c0.586-0.203,1.172-0.403,1.762-0.596c0.858-0.28,1.722-0.547,2.59-0.803
    c0.667-0.197,1.337-0.387,2.01-0.571c0.822-0.223,1.646-0.441,2.476-0.645c0.739-0.182,1.483-0.349,2.229-0.515
    c0.788-0.174,1.575-0.348,2.371-0.502c0.813-0.16,1.633-0.299,2.453-0.438c0.747-0.127,1.492-0.258,2.244-0.369
    c0.902-0.132,1.811-0.238,2.719-0.345c0.689-0.082,1.376-0.174,2.07-0.241c1.046-0.103,2.101-0.171,3.156-0.24
    c0.573-0.039,1.14-0.092,1.715-0.12c1.634-0.081,3.279-0.125,4.933-0.125c0.002,0,0.005,0,0.007,0H100l0,0
    c1.552,0,3.093,0.047,4.627,0.117c0.452,0.02,0.902,0.051,1.352,0.076c1.141,0.067,2.275,0.154,3.407,0.258
    c0.437,0.042,0.876,0.078,1.31,0.124c1.502,0.16,2.997,0.344,4.478,0.569c0.123,0.019,0.245,0.044,0.369,0.064
    c1.362,0.212,2.712,0.457,4.055,0.724c0.457,0.091,0.911,0.19,1.366,0.287c1.064,0.227,2.121,0.471,3.174,0.733
    c0.43,0.107,0.861,0.208,1.289,0.32c1.405,0.369,2.8,0.76,4.18,1.187c0.254,0.078,0.504,0.168,0.757,0.248
    c1.143,0.365,2.277,0.748,3.403,1.152c0.481,0.172,0.957,0.352,1.434,0.53c0.94,0.355,1.872,0.723,2.798,1.104
    c0.451,0.185,0.903,0.366,1.351,0.557c1.274,0.547,2.537,1.113,3.783,1.711c0.381,0.183,0.753,0.379,1.13,0.566
    c0.909,0.449,1.811,0.908,2.704,1.384c0.516,0.276,1.028,0.557,1.538,0.841c0.776,0.431,1.543,0.875,2.306,1.326
    c0.492,0.291,0.985,0.575,1.469,0.873c1.118,0.688,2.224,1.394,3.312,2.122c0.488,0.327,0.964,0.672,1.447,1.007
    c0.675,0.471,1.346,0.943,2.008,1.429c0.549,0.401,1.093,0.811,1.633,1.225c0.593,0.455,1.18,0.918,1.764,1.385
    c0.531,0.426,1.064,0.844,1.585,1.279c0.951,0.795,1.888,1.605,2.809,2.435c0.548,0.492,1.078,1,1.615,1.504
    c0.477,0.449,0.954,0.899,1.423,1.358c0.555,0.541,1.103,1.09,1.645,1.645c0.426,0.436,0.844,0.88,1.261,1.324
    c1.936,2.055,3.784,4.19,5.542,6.403c0.33,0.416,0.66,0.832,0.984,1.252c0.522,0.68,1.036,1.364,1.541,2.057
    c0.29,0.396,0.571,0.798,0.854,1.2c1.704,2.412,3.302,4.899,4.792,7.463c0.234,0.404,0.469,0.806,0.697,1.214
    c0.443,0.786,0.877,1.577,1.299,2.378c0.202,0.381,0.394,0.767,0.591,1.152c0.889,1.74,1.732,3.506,2.521,5.303
    c0.054,0.123,0.114,0.243,0.168,0.367c-0.001,0-0.001,0-0.001,0c1.393,3.213,2.636,6.507,3.691,9.884h0.001
    c0.003,0.01,0.006,0.02,0.009,0.031c0.066,0.209,0.122,0.424,0.186,0.636c0.366,1.203,0.71,2.414,1.032,3.634
    c0.125,0.477,0.243,0.957,0.362,1.437c0.252,1.021,0.49,2.048,0.711,3.082c0.098,0.459,0.201,0.918,0.293,1.38
    c0.291,1.457,0.559,2.922,0.786,4.4c0.012,0.08,0.02,0.16,0.033,0.238c0.213,1.42,0.391,2.852,0.544,4.29
    c0.047,0.439,0.086,0.881,0.126,1.322c0.107,1.147,0.195,2.298,0.264,3.455c0.024,0.427,0.054,0.854,0.073,1.282
    c0.072,1.55,0.12,3.107,0.12,4.675c0,1.668-0.046,3.325-0.127,4.974c-0.024,0.48-0.07,0.954-0.1,1.432
    c-0.073,1.151-0.15,2.301-0.262,3.442c-0.062,0.628-0.146,1.25-0.22,1.877c-0.114,0.975-0.229,1.951-0.371,2.918
    c-0.102,0.701-0.226,1.395-0.343,2.09c-0.146,0.874-0.295,1.746-0.465,2.612c-0.147,0.75-0.311,1.494-0.474,2.238
    c-0.174,0.793-0.353,1.584-0.547,2.368c-0.194,0.789-0.4,1.572-0.613,2.354c-0.195,0.716-0.397,1.429-0.608,2.14
    c-0.246,0.829-0.5,1.652-0.766,2.472c-0.207,0.633-0.422,1.261-0.641,1.89c-0.303,0.876-0.613,1.746-0.94,2.611
    c-0.203,0.534-0.417,1.063-0.629,1.593c-0.373,0.936-0.752,1.87-1.153,2.795c-0.173,0.397-0.356,0.788-0.535,1.185
    c-0.466,1.035-0.938,2.069-1.439,3.086c-0.081,0.164-0.169,0.324-0.251,0.488c-3.894,7.806-8.777,15.028-14.489,21.507
    C175.007,166.1,174.986,166.126,174.963,166.15z M164.81,155.997l-36.528-36.528c15.465-15.908,28.497-26.432,28.605-26.519
    c8.353-6.412,16.952-11.819,25.534-16.413c-0.976-3.424-2.14-6.772-3.517-10.008c-9.521,5.032-19.109,11.02-28.465,18.201
    c-0.735,0.592-13.84,11.168-29.552,27.341L44.004,35.188c-3.147,2.723-6.092,5.668-8.815,8.814l77.161,77.162
    c-2.661,2.935-5.346,5.998-8.012,9.172l-33.237-28.442c-1.174-1.004-2.625-1.505-4.076-1.505c-1.45,0-2.901,0.5-4.075,1.505
    l-32.616,27.907c-1.762,1.509-2.547,3.865-2.041,6.13c0.022,0.101,0.077,0.181,0.105,0.282c-0.007,0.021-0.022,0.049-0.019,0.057
    c0.435,2.09,3.603,7.563,13.669,14.363c6.873,4.648,19.813,8.589,29.696,8.649c22.991,0.145,38.629-19.688,40.182-21.738
    c2.591-3.101,5.213-6.099,7.822-8.983l36.249,36.25C159.143,162.088,162.088,159.144,164.81,155.997z"/>
</g>
  )
}

export default welfare_6