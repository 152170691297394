import config from "../../config";
import checkApiResponse from './checkApiResponse'

/**
 * @func: getOrdersByBookingId
 * @return {[Object]} data
 *
 * @description:
 */

const getOrdersByBookingId = (booking_id) => {
  console.log("BookingId: ", booking_id)
  return config.API.ordersBookingGet({booking_id}, {}, {})
  .then( result => result.data)
  .catch(error => checkApiResponse(error));
}

export default getOrdersByBookingId;