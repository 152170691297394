import React, { Component } from "react";
import config from "../../config";
import { browserHistory } from "react-router";
import { observer } from "mobx-react";
import { translate } from "react-i18next";
import { OrderCheckout } from "../Dialogs/Alert/components";
import { Notification } from "../Dialogs/Notification/components";
import { AppStore, ShopStore, SyncStore, UIStore, AuthStore, TrackingApartments } from "../../stores";
import Icon from "../Icons/Icon";
import checkVoucherValidation from "../../utils/api/checkVoucherValidati";
import ConfirmationOrderModal from "../../components/Dialogs/Modal/modals/ConfirmationOrder";

@translate(["common"], { wait: false })
@observer
class OrderConfirmationPayment extends Component {
  constructor(props) {
    super(props);
    this.pay = this.pay.bind(this);
    this.confirmPay = this.confirmPay.bind(this);
  }
  state = {
    subtotal: ShopStore.price.toFixed(2) - ShopStore.saving.toFixed(2),
    total:
      parseFloat(ShopStore.price.toFixed(2)) +
      parseFloat(
        this.props.deliveryPricey ? this.props.deliveryPrice.toFixed(2) : 0
      ),
    envio: this.props.deliveryPrice ? this.props.deliveryPrice.toFixed(2) : 0,
    consent: 1,
    comment: "",
    phone_consent: 1,
    cupon: "",
    discountString: "0 €",
    discount: 0,
  };
  // componentDidMount(){
  //   const {subtotal, discount} = this.state;
  //   this.setState({
  //     total: ((subtotal- discount) + parseFloat(this.props.deliveryPrice ? this.props.deliveryPrice : 0)).toFixed(2),
  //   })
  // }
  componentWillReceiveProps(newProps) {
    this.setState({
      envio: parseFloat(newProps.deliveryPrice ? newProps.deliveryPrice : 0),
    });
  }

  render() {
    const { envio, subtotal, total, discountString } = this.state;
    return (
      <div>
        <hr className="separator" ></hr>
        <div className="container-comment">
          <div className="container-title-info">
            <h2 className="h1 light grey s28">Preferencias de compra</h2>
          </div>
        </div>
        <div className="container-content">
          <textarea
            value={this.state.comment}
            maxLength="350"
            rows="3"
            className="input-comment"
            placeholder="Indica cualquier información adicional para que el personal de Ilusia lo tenga en cuenta a la hora de realizar la compra."
            onChange={(e) => this.setState({ comment: e.target.value })}
          ></textarea>
          <div className="container-consent">
            <label className="container-checkbox">
              <input
                type="checkbox"
                checked={this.state.consent}
                onChange={() =>
                  this.setState({ consent: !this.state.consent ? 1 : 0 })
                }
              />
              <span className="checkmark"></span>
            </label>
            <p className="replacement-products-label" style={{ paddingTop: "15px" }}>
              Reemplazar producto por uno de similar precio en caso de no haber existencias.
            </p>
          </div>
          <div className="container-consent">
            <label className="container-checkbox">
              <input
                type="checkbox"
                checked={this.state.phone_consent}
                onChange={() =>
                  this.setState({
                    phone_consent: !this.state.phone_consent ? 1 : 0,
                  })
                }
              />
              <span className="checkmark"></span>
            </label>
            <p style={{ paddingTop: "15px" }}>Contactar en caso de dudas.</p>
          </div>
        </div>
        <div className="container-cupon">
          <h2 className="h1 light grey s28">Código promocional</h2>
          <div>
            <input
              className="cupon"
              value={this.state.cupon}
              onChange={(e) => this.setState({ cupon: e.target.value })}
              placeholder="Código de descuento"
            />
            <button className="btn-cupon" onClick={() => this.applyCupon()}>
              Aplicar
            </button>
          </div>
        </div>
        <div className="container-payment">
          <div className="payment">
          <h2 className="h1 light grey s28">Total</h2>
            <div className="section-payment">
              <h4 className="h4 s19">COMPRA</h4>
              <h4 className="h4 s19">{subtotal.toFixed(2)} €</h4>
            </div>
            <div className="section-payment">
              <h4 className="h4 s19">CODIGO DE DESCUENTO</h4>
              <h4
                className={`h4 s19 ${discountString !== "0 €" && "blue-text"}`}
              >
                {discountString}
              </h4>
            </div>
            <div className="section-payment">
              <h4 className="h4 s19">GASTOS DE ENVÍO</h4>
              <h4 className="h4 s19">
                {parseFloat(envio || 0).toFixed(envio ? 2 : 0)} €
              </h4>
            </div>
            <div className="section-payment">
              <h4 className="h4 s19">TOTAL</h4>
              <h4 className="h4 s19">
                {(parseFloat(total || 0) + parseFloat(envio || 0)).toFixed(
                  total + envio ? 2 : 0
                )}{" "}
                €
              </h4>
            </div>
          </div>
        </div>
        <div className="ctn">
          <div className="container-btn">
            <button className="btn-payment" onClick={() => this.confirmPay()}>
              PAGAR
            </button>
          </div>
        </div>
      </div>
    );
  }

  async applyCupon() {
    const { cupon, total, subtotal } = this.state;
    const idProvider = AppStore.idprovider;
    const booking_id = AuthStore.bookingId;
    if (total == 0) {
      return UIStore.showNotification({
        type: "danger",
        content: () => <Notification message="El total no puede ser cero" />,
      });
    }
    if (cupon.length >= 10 && cupon.length <= 15) {
      UIStore.showLoading();
      const data = await checkVoucherValidation(cupon, idProvider, booking_id);
      console.log("dataaa->", data);
      if (data.status && data.status === 503) {
        UIStore.showNotification({
          type: "danger",
          content: () => (
            <Notification message="Parece que ha ocurrido un error inesperado" />
          ),
        });
        return UIStore.hideLoading();
      }
      if (data.code == "100") {
        const { type, amount } = data;
        if (type == 1) {
          const discount = subtotal - amount;
          const _total = discount;
          // console.log('_total1', _total, amount, subtotal);
          if (discount > 0) {
            this.setState({
              discountString: "-" + amount + "€",
              total: _total,
              discount,
            });
            config.CART_CONFIG.voucher = cupon;
            TrackingApartments.voucher_valid(AuthStore.apartmentId, cupon);
          } else {
            UIStore.showNotification({
              type: "danger",
              content: () => (
                <Notification
                  message={"El cupón de descuenta supera el total de la compra"}
                />
              ),
            });
            TrackingApartments.voucher_minimum_requirements(AuthStore.apartmentId, cupon);
          }
        } else if (type == 2) {
          const discountedPrecent = (amount * subtotal) / 100;
          const _total = subtotal - discountedPrecent;
          // console.log('_total2', _total, "discountedPrecent", discountedPrecent, "subtotal",subtotal);
          this.setState({
            discountString: "-" + amount + "%",
            total: _total,
            discount: discountedPrecent,
          });
          config.CART_CONFIG.voucher = cupon;
          TrackingApartments.voucher_valid(AuthStore.apartmentId, cupon);
        }
      } else {
        const _CODE_INVALID = "5001";
        const _CODE_EXPIRED = "5002";
        const _CODE_USED = "5003";
        const _CODE_REQUIREMENT = "5004";
        const _CODE_NOT_TRIES = "5005";
        const _CODE_IVALID_USER = "5006";
        const _CODE_NOT_AVAILABLE = "5007";
        const _CODE_INVALID_VOUCHER = "5008";
        let message = "";
        switch (data.code) {
          case _CODE_INVALID:
            message = "Código de descuento invalido";
            TrackingApartments.voucher_invalid(AuthStore.apartmentId, cupon);
            break;
          case _CODE_EXPIRED:
            message = "Código de descuento no disponible";
            TrackingApartments.voucher_expire(AuthStore.apartmentId, cupon);
            break;
          case _CODE_USED:
            message = "Código de descuento usado anteriormente";
            break;
          case _CODE_REQUIREMENT:
            message = "No cumple los requisitos para este código de descuento";
            TrackingApartments.voucher_minimum_requirements(AuthStore.apartmentId, cupon);
            break;
          case _CODE_NOT_TRIES:
            message =
              "Se ha superado el límite de usos para este código de descuento";
            break;
          case _CODE_IVALID_USER:
            message = "Usuario invalido para este código de descuento";
            break;
          case _CODE_NOT_AVAILABLE:
            message = "Código de descuento no disponible";
            break;
          case _CODE_INVALID_VOUCHER:
            message = "Código de descuento invalido";
            TrackingApartments.voucher_invalid(AuthStore.apartmentId, cupon);
            break;
        }
        this.setState({ cupon: "" });
        UIStore.showNotification({
          type: "danger",
          content: () => <Notification message={message} />,
        });
      }
      UIStore.hideLoading();
    } else {
      UIStore.showNotification({
        type: "danger",
        content: () => <Notification message="Código de descuento invalido" />,
      });
      TrackingApartments.voucher_invalid(AuthStore.apartmentId, cupon);
    }
  }

  confirmPay() {
    const totalCart = Number(ShopStore.price.toFixed(2));
    const minOrder = Number(AppStore.minorder);
    
    if ( totalCart < minOrder ){
      const remainingAmount = (minOrder - totalCart).toFixed(2);
      UIStore.showNotification({
        type: "danger",
        timeOut: 4000,
        content: () => (
          <Notification message={`Pedido mínimo ${AppStore.minorder}€, te quedan ${remainingAmount}€ para poder finalizar.`} />
        ),
      });
      return;
    }

    if (AppStore.iddeliverytimeslot == 0 || AppStore.iddeliverytimeslot == null) {
      UIStore.showNotification({
        type: "danger",
        content: () => (
          <Notification message="Debe seleccionar una franja horaria para continuar con su compra" />
        ),
      });
    } else {
      UIStore.toggleModal({
        clickOnBackgroundClose: false,
        content: () => (
          <ConfirmationOrderModal
            confirm={this.pay}
            cancel={UIStore.hideModal}
          />
        ),
      });
    }
  }

  pay() {
    config.CART_CONFIG.replace_confirm = this.state.consent;
    config.CART_CONFIG.comments = this.state.comment;
    config.CART_CONFIG.replace_phone = this.state.phone_consent;
    // AppStore.orders = null;
    ShopStore.clearCartConflicts();
    UIStore.hideModal();
    ShopStore.sendCart();
    sessionStorage.removeItem("ProductsWithoutAuthenticated");
  }
}

export default OrderConfirmationPayment;
