
import React from 'react'

const show_more = () => {
  return (
    <g>
     <path d="M256,0C114.62,0,0,114.62,0,256S114.62,512,256,512,512,397.39,512,256,397.38,0,256,0ZM115,301a45,45,0,1,1,45-45A45,45,0,0,1,115,301Zm141,0a45,45,0,1,1,45-45A45,45,0,0,1,256,301Zm141,0a45,45,0,1,1,45-45A45,45,0,0,1,397,301Z"/>
    </g>
  )
}

export default show_more
