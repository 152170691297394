
import config from '../../config'

/**
 * @func: getMasCartInformation
 * @return {[Object]} data
 *
 * @description:
 */

const getMasCartInformation= () => {
  
  return config.API.integrationsMasCartGet({}, {}, {})
	  .then(result => result.data)
	  .catch(error => error)
}


export default getMasCartInformation
