
import config from '../../config'
import checkApiResponse from './checkApiResponse'

/**
 * @func: getOrderProducts
 * @return {[Object]} data
 *
 * @description:
 */

const getOrderProducts = (id, lan) => {
  const params = { id_order: id, language: lan }
  return config.API.orderProductsGet(params, {}, {})
  .then(result => result.data)
  .catch(error => checkApiResponse(error))
}


export default getOrderProducts
