
import React from 'react'

const warning = () => {
  return (
    <g>
      <path d="M99.939,199.973c-25.639,0-51.278-0.086-76.916,0.049c-8.596,0.045-15.768-2.788-20.238-11.378
       c-4.57-8.781-3.152-17.168,1.305-25.744c25.74-49.537,51.333-99.17,76.819-148.87c4.408-8.595,10.235-14.137,19.352-14.005
       c8.883,0.129,14.481,5.695,18.783,14.079c25.584,49.861,51.326,99.621,77.121,149.346c3.601,6.94,5.057,13.957,2.575,21.621
       c-3.012,9.3-10.552,14.839-20.326,14.859c-26.158,0.053-52.317,0.018-78.475,0.018C99.939,199.947,99.939,199.973,99.939,199.973z
        M102.405,131.132c1.11-6.845,2.04-13.514,3.294-20.105c2.445-12.859,5.186-25.648,7.57-38.52c1.22-6.594-0.361-12.371-5.628-16.244
       c-4.919-3.616-10.109-3.865-15.182-0.463c-5.158,3.46-7.336,8.818-6.165,15.348c3.592,20.036,7.41,40.02,11.133,59.986
       C99.136,131.132,100.638,131.132,102.405,131.132z M99.646,170.093c7.396,0.062,13.629-6.401,13.747-14.252
       c0.119-7.994-6.389-15.089-13.759-14.997c-7.299,0.091-13.331,6.739-13.35,14.715C86.265,163.66,92.122,170.03,99.646,170.093z"/>
    </g>
  )
}

export default warning
