
import { observable, action, autorun } from 'mobx'


class ProductModel {
  // Producto obtenido del catálogo
  @observable product
  // Cantidad de unidades añadidas al carro
  @observable units


  constructor (product, entryIndex, cartEntryIndex, units = 0) {
    this.product = product
    this.units = units
    // NOTE: aunque el precio no lo guardamos en AWS Cognito lo mantenemos aquí
    // para trabajar más facil con el.
    this.price = product.price
    this.entryIndex = entryIndex
    this.categoryEntryIndex = {}
    this.cartEntryIndex = cartEntryIndex || null
  }


  /**
   * Setea el indice para el orden de tienda en cada categoria
   * en que aparece el producto.
   */
  setIndex (idcategory, index) {
    // this.categoryEntryIndex.set(idcategory, index)
    this.categoryEntryIndex[idcategory] = index
  }

  /**
   * Setea las unidades para el producto
   */
  @action setUnits (units) {
    this.units = units
  }


  /**
   * Incrementa las unidades del producto
   */
  @action add (units) {
    this.units = this.units + units
  }


  /**
   * Resta las unidades del producto
   */
  @action subtract (units) {
    this.units = (this.units === 1) ? 1 : this.units - units
  }
}


export default ProductModel
