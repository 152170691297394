
import React, { Component } from 'react'
import { UIStore, AuthStore } from '../../../../stores'
import LoginModal from './LoginModal'
import { Icon, Button, IconButton, SwitchButton } from '../../../'

/**
 *
 */

class SignupDia extends Component {

  componentDidMount () {
    window.setTimeout(() => {
      this.refs.email.focus()
      window.clearTimeout(this)
    })
  }

   state = {
    privacity: false
  }



  render () {
    return (
      <div className="signup-modal-dia">
        <div className="logotype">
          <div className="logo-icon">
            <Icon className="icon-logo" type="logo_dia" width="110" height="36" fill="grey" viewport="0 0 200 200"></Icon>
          </div>
        </div>
        <h2 className="h2 light white s15">Quiero registrarme en dia.es</h2>
        <form onSubmit={ this.send }> 
          <input ref="email" className="input dark" id="email" type="email" placeholder="Correo electrónico *" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" />
          <input className="input dark" id="password" type="password" placeholder="Contraseña" readOnly={ false } />
          <p className="p light white s12">
            Mínima longitud 8 caracteres
          </p>
          <button type="submit"></button>
          <Button onClick={ this.send } type="outline white" placeholder="Registrarme" color="blue" />

          <div className="privacity">
          
           <SwitchButton name="privacity"
                            id="push"
                            onSwitchChange={ this.SwichPrivacity } />
              <p className="p white s13">
              <span> Acepto la </span><Button onClick={ this.openPrivacity } type="underline" placeholder="Política de Privacidad" color="white" />
             <span>{' '} y Seguridad</span>
          </p>
          </div>
        </form>
      </div>
    )
  }


SwichPrivacity = (event) => this.setState({ privacity: !this.state.privacity })



  openPrivacity = (event) =>{
    window.open('https://www.dia.es/compra-online/aviso-legal#5', '_blank');
  }


  /**
   * Envia los datos del formulario.
   *
   * TODO: cambiar las referecias DOM a React.refs
   */
  send = (event) => {
    event.preventDefault()
    const email = document.querySelector('form input#email').value
    const password = document.querySelector('form input#password').value
    const privacity = this.state.privacity
    this.props.onSubmit(email, password, privacity)
  }
}


export default SignupDia
