
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import Hammer from 'react-hammerjs'
import { TweenMax, Power2 } from "gsap"
import config from '../../config'
import { AppStore, SearchStore } from '../../stores'
import { Dropdown, ProviderCartLink } from '../'
import { ShopLineView, ShopLineControlls, ProductTooltip, AnimatedProductContainer } from '../ShopLineComponents'
import ShopBotInput from './ShopBotInput'

// NOTE: opciones para el Dropdown
const SORT_SHOP_OPTIONS = [
  {
    text: 'Orden de tienda',
    active: true,
    action: () => SearchStore.sortSearch(1)
  },
  {
    text: 'Precio ascendente',
    active: false,
    action: () => SearchStore.sortSearch(2)
  },
  {
    text: 'Precio descendente',
    active: false,
    action: () => SearchStore.sortSearch(3)
  }
]

@observer
class ShopBotLine extends Component {

  startX = 0
  endX = 0
  offsetX = 0
  dragOffset = 0
  scrolling = false

  constructor (...args) {
    super (...args)
  }

  componentDidMount () {
    document.querySelector('#next').style.display = 'none'
    document.querySelector('#prev').style.display = 'none'
    document.addEventListener('keydown', this.onKeyDown, false)
  }

  componentDidUpdate () {
    if (SearchStore.previousView) {
      document.querySelector('#prev').style.display = 'inline-block'
    } else {
      document.querySelector('#prev').style.display = 'none'
    }

    if (SearchStore.nextView) {
      document.querySelector('#next').style.display = 'inline-block'
    } else {
      document.querySelector('#next').style.display = 'none'
    }

    this.refs.scroller.style.left = '-100vw'
    document.addEventListener('keydown', this.onKeyDown, false)
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.onKeyDown, false)
  }

  render () {
    this.previousView = SearchStore.previousView
    this.currentView = SearchStore.currentView
    this.nextView = SearchStore.nextView

    return(
      <div className="shop-line">
        <ShopBotInput placeholder="Buscar" />

        <Dropdown options={ SORT_SHOP_OPTIONS }
                  className="sort-shop"
                  content="list"
                  type="flat"
                  buttonColor="grey"
                  placeholder="Ordenar"
                  align="center"
                  expandOnHoover={ false }
                  collapseOnLeave={ false }
                  setSelectedAsPlaceholder={ false }
                  transitionName="modal-anim" />

        <ShopLineControlls onClickNext={ this.next }
                           onClickPrevious={ this.previous } />

        <ProductTooltip />

        <AnimatedProductContainer />

        <Hammer onPanStart={ this.handlePanStart }
                onPan={ this.handlePan }
                onPanEnd={ this.handlePanEnd }
                options={ config.HAMMER_OPTIONS } >

          <div className="scroller" ref="scroller">
            { (this.currentView) ? this.renderShopLines() : null }
          </div>

        </Hammer>

        <ProviderCartLink provider={ AppStore.providername }
                          link={ `/carrito` } />
      </div>
    )
  }


  /**
   * Renderizamos las tres vistas necesarias para pintar el carrusel.
   * NOTE: esto no me gusta mucho como está. TODO: estudiar otra opción.
   */
  renderShopLines = () => <div className="shop-line_views-container">
      { (this.previousView) ? <ShopLineView racks={ this.previousView } ref="previous" className="previous" /> : null }
      <ShopLineView racks={ this.currentView } ref="current" className="current" />
      { (this.nextView) ? <ShopLineView racks={ this.nextView } ref="next" className="next" /> : null }
    </div>


  onKeyDown = (event) => {
    document.removeEventListener('keydown', this.onKeyDown, false)
    switch (event.keyCode) {
      case 39:
        this.scrollingLineViews('next')
        break;
      case 37:
        this.scrollingLineViews('prev')
        break;
    }
  }


  /**
   * Controla el evento 'click' para el botón 'next'
   */
  next = (event) => {
    if (!this.scrolling && SearchStore.nextView) this.scrollingLineViews('next')
  }


  /**
   * Controla el evento 'click' para el botón 'prev'
   */
  previous = (event) => {
    if (!this.scrolling && SearchStore.previousView) this.scrollingLineViews('prev')
  }


  /**
   * Controla el evento 'panStart'
   */
  handlePanStart = (event) => {
    event.preventDefault()

    if (!this.scrolling) {
      this.startX = event.pointers[0].clientX
      this.offsetX = this.refs.scroller.getBoundingClientRect().left
    }
  }


  /**
   * Controla el evento 'pan'
   */
  handlePan = (event) => {
    event.preventDefault()

    this.refs.scroller.style.left = parseInt(this.offsetX) + event.pointers[0].clientX - this.startX + 'px'
    this.dragOffset = this.offsetX - this.refs.scroller.getBoundingClientRect().left
  }


  /**
   * Controla el evento 'panEnd'
   */
  handlePanEnd = (event) => {
    event.preventDefault()

    const container = this.refs.scroller
    if (this.dragOffset < 0 && !SearchStore.previousView || this.dragOffset > 0 && !SearchStore.nextView) {
      TweenMax.to(container, 1.0, {
        css: { left: '-100vw' },
        ease: Power2.easeOut,
        immediateRender: true,
        onComplete: () => {
          this.onPaginateEnd()
        }
      })
      return
    }

    this.endX = event.pointers[0].clientX
    if (event.offsetDirection === 4) {
      this.scrollingLineViews('prev')
    } else if (event.offsetDirection === 2) {
      this.scrollingLineViews('next')
    }
  }


  /**
   * Anima las vistas
   */
  scrollingLineViews (dir) {
    this.scrolling = true
    const container = this.refs.scroller
    const containerPosition = container.getBoundingClientRect().left
    const windowWidth = window.innerWidth
    const transfromProps = { x: 0, y: 0, z: 0 }

    switch (dir) {
      case 'prev':
        TweenMax.to(container, 1.0, {
          css: { left: containerPosition + windowWidth + this.dragOffset },
          ease: Power2.easeOut,
          immediateRender: true,
          onComplete: () => {
            SearchStore.paginate('prev')
            this.onPaginateEnd()
          }
        })
        break
      case 'next':
        TweenMax.to(container, 1.0, {
          css: { left: containerPosition - windowWidth + this.dragOffset },
          ease: Power2.easeOut,
          immediateRender: true,
          onComplete: () => {
            SearchStore.paginate('next')
            this.onPaginateEnd()
          }
        })
        break
    }
  }


  /**
   * Controla el fin de la animación para la paginación
   */
  onPaginateEnd () {
    this.dragOffset = 0
    this.scrolling = false
    document.addEventListener('keydown', this.onKeyDown, false)
  }
}


export default ShopBotLine
