
import React from 'react'

const mail = () => {
  return (
    <g>
      <path d="M17.519,63.827c21.86,15.724,67.782,49.622,68.242,49.964l0.862,0.724c1.78,1.656,7.796,6.05,13.716,6.05
  			c5.918,0,11.936-4.394,13.716-6.05l0.862-0.724c0.462-0.34,46.384-34.24,68.242-49.964c13.252-9.532,16.42-17.322,17.18-20.622
  			v-9.862c0-8.962-7.266-16.228-16.228-16.228H16.567c-8.962,0-16.228,7.264-16.228,16.228v9.862
  			C1.101,46.507,4.267,54.295,17.519,63.827z"/>
  		<path d="M193.585,78.319c-10.804,7.772-27.53,20.014-41.7,30.424l48.454,51.11v-86.87
  			C198.315,74.741,196.081,76.523,193.585,78.319z"/>
  		<path d="M137.393,119.399c-5.948,4.38-10.222,7.532-11.488,8.468c-2.476,2.228-12.596,10.548-25.564,10.548
  			c-12.97,0-23.088-8.32-25.564-10.548c-1.268-0.936-5.54-4.088-11.488-8.468L5.953,179.877c2.846,2.468,6.552,3.974,10.616,3.974
  			h167.546c4.064,0,7.77-1.504,10.618-3.974L137.393,119.399z"/>
  		<path d="M7.095,78.319c-2.496-1.796-4.732-3.576-6.756-5.334v86.868l48.454-51.11
  			C34.625,98.333,17.899,86.089,7.095,78.319z"/>
  	</g>
  )
}

export default mail
