
import config from '../../config'

/**
 * @func: getIntegrationsDIASSO
 * @return {[Object]} data
 *
 * @description:
 */

const getIntegrationsDIASSO = () => {
  
  return config.API.integrationsDiaSsoGet({}, {}, {})
	  .then(result => result.data)
	  .catch(error => error)
}


export default getIntegrationsDIASSO
