
import React, { Component } from 'react'
import { SwitchButton } from '../'


class Tabs extends Component {

  isActive = false

  render () {
    return (
      <nav className="tabs-wrapper">
        <ul className={ `tab-list ${ this.props.type }` }>
          <SwitchButton name="switch"
                        id="email"
                        isChecked={ this.props.active }
                        onSwitchChange={ this.handleChange } />
        </ul>
      </nav>
    )
  }


  handleChange = event => {
    if (this.isActive) {
      this.isActive = false
      this.props.onSwitch(0)
    } else {
      this.isActive = true
      this.props.onSwitch(1)
    }
  }
}


export default Tabs
