
import config from '../../config'
import checkApiResponse from './checkApiResponse'

/**
 * @func: postOrder
 * @return {[Object]} data
 *
 * @description:
 */

const postCheckoutMas= (id_address, delivery_date, delivery_start_time, delivery_end_time, payment_method, replacement_method, delivery_observations)=> {
  const params = { id_address : id_address, delivery_date: delivery_date, delivery_start_time:delivery_start_time, delivery_end_time:delivery_end_time, payment_method:payment_method, replacement_method:replacement_method, delivery_observations:delivery_observations}
  return config.API.integrationsMasCheckoutPost({}, params, {})
  .then(result => result.data)
  .catch(error => checkApiResponse(error))
}


export default postCheckoutMas
