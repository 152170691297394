
import React, { Component } from 'react'
import { IconButton } from '../'

const ShopLineControlls = (props) => <div className="shop-line_controlls">
  <IconButton onClick={ props.onClickNext } id="next" className="next" type="fab" color="grey" iconType="arrow_ahead" iconTitle="ALT_PARA_ICONO_AQUI" />
  <IconButton onClick={ props.onClickPrevious } id="prev" className="prev" type="fab" color="grey" iconType="arrow_back" iconTitle="ALT_PARA_ICONO_AQUI" />
</div>

export default ShopLineControlls
