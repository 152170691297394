
import React from 'react'

const config = () => {
  return (
    <g>
      <path d="M191.138,76.977h-12.604c-1.602-5.371-3.752-10.549-6.434-15.496l8.925-8.92
       c1.554-1.555,2.427-3.662,2.427-5.857s-0.873-4.305-2.427-5.857l-21.438-21.441c-1.553-1.553-3.662-2.428-5.857-2.428
       c-2.203,0-4.306,0.875-5.864,2.428l-8.921,8.922c-4.939-2.68-10.123-4.832-15.496-6.436V9.285c0-4.572-3.705-8.285-8.279-8.285
       H84.844c-4.574,0-8.285,3.713-8.285,8.285v12.605c-5.373,1.604-10.551,3.756-15.495,6.436l-8.921-8.922
       c-1.553-1.553-3.662-2.428-5.857-2.428c-2.197,0-4.307,0.875-5.859,2.428L18.986,40.846c-1.553,1.553-2.427,3.662-2.427,5.857
       s0.874,4.303,2.427,5.857l8.92,8.92c-2.682,4.947-4.832,10.125-6.428,15.496H8.873c-4.578,0-8.291,3.713-8.291,8.287v30.316
       c0,4.574,3.713,8.285,8.291,8.285h12.605c1.596,5.373,3.746,10.557,6.428,15.496l-8.92,8.928c-1.553,1.547-2.427,3.656-2.427,5.857
       c0,2.195,0.874,4.299,2.427,5.857l21.439,21.436c1.621,1.617,3.74,2.426,5.859,2.426c2.121,0,4.238-0.809,5.857-2.426l8.921-8.922
       c4.944,2.682,10.122,4.832,15.495,6.434v12.602c0,4.572,3.711,8.283,8.285,8.283h30.324c4.574,0,8.279-3.711,8.279-8.283v-12.602
       c5.373-1.602,10.557-3.752,15.496-6.434l8.921,8.922c1.619,1.617,3.745,2.426,5.864,2.426c2.12,0,4.24-0.809,5.857-2.426
       l21.438-21.436c1.554-1.559,2.427-3.662,2.427-5.857c0-2.201-0.873-4.311-2.427-5.857l-8.925-8.928
       c2.682-4.939,4.832-10.123,6.434-15.496h12.604c4.577,0,8.281-3.711,8.281-8.285V85.264
       C199.419,80.689,195.715,76.977,191.138,76.977z M100,129.352c-15.975,0-28.932-12.951-28.932-28.934
       c0-15.975,12.957-28.932,28.932-28.932c15.984,0,28.938,12.957,28.938,28.932C128.938,116.4,115.984,129.352,100,129.352z"/>
    </g>
  )
}

export default config
