import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { AuthStore , AppStore, UIStore} from '../../../../stores';
import { Icon, Button } from '../../../';
import { Notification } from '../../Notification/components';
import { ValidateForm } from '../../../../functions/ValidateForm';
import { postAddress } from '../../../../utils/api'

class AddAddress extends Component {
  state = {
    form: {
      alias:'',
      address_1:'',
      address_2:'',
      city:'',
      province:'',
      postcode:'',
      phone:''
    }
  }

  render(){
    return (
      <div className="address-modal">
        <div className="logotype">
          <div className="symbol">
            <Icon className="icon-symbol" type="symbol" width="35" height="35" fill="blue" viewport="0 0 200 200"></Icon>
          </div>
          <div className="logo">
            <Icon className="icon-logo" type="logo" width="70" height="26" fill="blue" viewport="0 0 200 70"></Icon>
          </div>
        </div>
        <h2 className="h2 light grey s20">Para poder tramitar tu pedido necesitamos la dirreción de entrega</h2>
        <h2 className="h2 bold grey s21">ALTA DIRECCIÓN DE ENTREGA</h2>
        <form onSubmit={ this.send }>
          <ul>
            <li>
              <label className="p light black s20 a40">Alias <small>*</small></label>
              <input onChange={ this.onChangeText } className="input" id="alias" type="text" readOnly={ false } required/>
            </li>
            <li>
              <label className="p light black s20 a40">Dirreción 1 <small>*</small></label>
              <input onChange={ this.onChangeText } className="input" id="address_1" type="text" readOnly={ false } required />
            </li>
            <li>
              <label className="p light black s20 a40">Dirreción 2<small>*</small></label>
              <input onChange={ this.onChangeText } className="input" id="address_2" type="text" readOnly={ false } />
            </li>
            <li>
              <label className="p light black s20 a40">Ciudad <small>*</small></label>
              <input onChange={ this.onChangeText } className="input" id="city" type="text" readOnly={ false } required />
            </li>
            <li>
              <label className="p light black s20 a40">Provincia <small>*</small></label>
              <input onChange={ this.onChangeText } className="input" id="province" type="text" readOnly={ false } required/>
            </li>
            <li>
              <label className="p light black s20 a40">Código postal <small>*</small> </label>
              <input onChange={ this.onChangeText } className="input" id="postcode" type="text"readOnly={ false } required />
            </li>
            <li>
              <label className="p light black s20 a40">Número de teléfono <small>*</small></label>
              <input onChange={ this.onChangeText } className="input" id="phone" type="text" readOnly={ false }  required />
            </li>
          </ul>
          <Button onClick={ this.send } type="outline" placeholder="Añadir dirección" color="blue" />
        </form>
      </div>
    )
  }

  onChangeText = (e) => {
    const { form }  = this.state
    this.setState({
        form: {
          ...form,
          [e.target.id] : e.target.value,
        }
    })
  }

  onPostNewAddress = async (body) => {
    // const result = await postAddress(body)
    postAddress(body)
    .then( result => {
      if(result.status === 200){
        AuthStore.refreshAddresses()
        .then(x => {
          console.log('Response of refreshAddresses', x);
          browserHistory.push(`/compra`)
        })
        .catch(e => console.log('Error of refreshAddresses', e))
      }else {
        UIStore.showNotification({ type: 'danger', content: () => <Notification message="Se ha producido un error, por favor, inténtalo de nuevo.." /> })
      }
    })
    .catch(x => console.log('In catchhh'))
  } 
  emptyForm = () => {
    const _newForm = {
      alias:'',
      address_1:'',
      address_2:'',
      city:'',
      province:'',
      postcode:'',
      phone:''
    }
    this.setState({form:_newForm})
  }

  send = (event) => {
    event.preventDefault()
    const { form }  = this.state
    if(ValidateForm(form)){
      const { user } = AuthStore
      const obj ={
        address_1: form.address_1,
        address_2: form.address_2,
        address_3:'',
        province:form.province,
        firstname: user.firstname,
        lastname: user.lastname,
        phone: form.phone,
        city: form.city,
        postcode: form.postcode,
        alias: form.alias
      }
      this.emptyForm()
      UIStore.hideModal()
      this.onPostNewAddress(obj)
    }else{
      UIStore.showNotification({ type: 'danger', content: () => <Notification message="Todos los campos son obligatorios" /> })
    }
  }
}

export default AddAddress;