
import React from 'react'

/**
 * @func: ListItem
 * @param: {[Object]} props
 *
 * @description: Elemento de la lista del componete ListDropdown.
 *
 * props.text {[String]}: @default: ''. NOTE: Se le puede pasar el key de un literal de los locales!
 * props.active {[Boolean]}: @default: false.
 * props.action {[Function]}: @default: null.
 */
const ListItem = (props) => <div className="dropdown-list_item" id={ props.id }>
  <button className={ `dropdown-list_item-button ${(props.active) ? 'active' : ''}` }
          role="option"
          tabIndex="-1"
          onClick={ props.action }
          form = {props.form}
          >

    { props.text }
  </button>
</div>

export default ListItem
