
import config from '../config'
import { AppStore } from '../stores'

/**
 * Calcula las vistas para una categoria con sus respectivas baldas y
 * productos teniendo en cuenta el tamaño del navegador, el ancho de la
 * imagen del producto y los margenes.
 */
const calcCategorieViews = (products, fromShop) => {
  let views = []
  let view = []
  let len = products.length
  let percentage_width = 16

  // Fix para que no vuelen productos fuera de las baldas.
  let maxWidth = (window.innerWidth - config.SHELF_MARGIN) - 100

  if (AppStore.windowWidth <= 750){
    maxWidth = AppStore.windowWidth - 150;
    percentage_width = 30
  }

  if (AppStore.windowWidth <= 400){
    maxWidth = AppStore.windowWidth - 140;
    percentage_width = 12
  }

  if (AppStore.windowWidth <= 300){
    maxWidth = AppStore.windowWidth - 200;
    percentage_width = 12
  }

  let w = 0
  let that = []
  let ind = 0
  let maxProductWidth = 150;

  for (let i = 0; i < len; i++) {
    const product = products[i]
    if (product === undefined || product === null) {
      let index = products.indexOf(product)
      products.splice(index, 1)
      continue
    }

    // w += ((( percentage_width / 100) * Math.min(maxProductWidth, product.product.width) || config.FAKE_BAGS_SIZES[0]) + config.PRODUCTS_GAP)
    w += (130 + config.PRODUCTS_GAP)


    if (w >= maxWidth) {
      if (that.length){
        view.push(that)
      }

      // Reseteo variables
      w = 0
      that = []

      const MAX_SELVES = (fromShop) ? config.MAX_SELVES : config.MAX_SELVES_SHOP_BOT
      if (view.length >= MAX_SELVES) {
        views.push(view)
        view = []
      }
    }

    that.push(product)
  }

  // Si quedan productos de escaqueo
  if (that.length) {
    view.push(that)
    views.push(view)
  }

  return views
}

export default calcCategorieViews
