
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { AppStore, ShopStore, UIStore } from '../../stores'
import Shelf from './Shelf'
import ShopProduct from '../Products/ShopProduct'

@observer
class Rack extends Component {

  render() {
    let layout = AppStore.layouts.get(this.props.layout || 55)
    if (!layout) layout = AppStore.layouts.get(55)
    console.log("Este es el layout")
    console.log(layout)
    const shadow = (layout.shadow === 1) ? true : false
    const font = layout.font_name.toLowerCase().replace(/\-/g, '')

    const products = this.props.products.map(product => {

      var withAllergen = false
      var withWelfares = false
      /*
      product.product.welfares.forEach(function(element) {
        console.log(product.product.id_product_eva + " --- " + product.product.description +"----"+element);
      });*/
      var filter = 1
      // Check if product is food to apply filters
      if (product.product.is_food == 1) {
        for (var aller in AppStore.allergensEnable) {
          var idal = AppStore.allergensEnable[aller].toString();
          if (product.product.allergens.includes(idal)) {
            withAllergen = true
            //console.log(product.product.id_product_eva + "--" + aller)
          }
        }


        for (var wel in AppStore.welfaresEnable) {
          var idwel = AppStore.welfaresEnable[wel].toString();
          //console.log("Este welfare " + product.product.welfares.includes(idwel))
          //console.log(product.product.welfares.includes(idwel))
          if (product.product.welfares.includes(idwel)) {
            withWelfares = true
            // console.log(product.product.allergens)
            //console.log(product.product.id_product_eva + "--" + wel)
          } else withWelfares = false
        }

      } else if (product.product.is_food == 2) filter = 0
      //console.log("Estado de allergen "  + AppStore.allergensEnable.length + "  " +withAllergen)
      //console.log("Estado de welfares "  + AppStore.welfaresEnable.length + "  " +withWelfares)

      if (AppStore.allergensEnable.length < 1 && AppStore.welfaresEnable.length < 1) {
        filter = 0

      } else if (AppStore.allergensEnable.length > 0 && AppStore.welfaresEnable.length < 1) {
        if (!withAllergen && product.product.is_food == 1) {
          // console.log ("alergenos activados, welfares sin activar y alergenos no coinciden")

          filter = 0
        }

      } else if (AppStore.allergensEnable.length < 1 && AppStore.welfaresEnable.length > 0) {
        if (withWelfares) {
          //console.log("welfares - " +product.product.id_product_eva + "--" + wel)

          filter = 0
        }
      } else { //if (AppStore.allergensEnable.length > 0 && AppStore.welfaresEnable.length  > 0)
        if (!withAllergen && withWelfares) {
          // console.log ("alergenos y welfares activados, pero alergeno no coincide y welfare si")
          if (product.product.is_food == 1) filter = 0
        }
      }
      return <ShopProduct onClick={(event) => this.handleClick(product.product.id_product_eva, product.units, event)}
        onMouseEnter={(event) => this.handleMouseEnter({
          description: product.product.description || '',
          brand: product.product.brand || ''
        })}
        classnameProduct="rack-product"
        filter={filter}
        onMouseLeave={(event) => this.handleMouseLeave()}
        product={product}
        key={product.product.id_product_eva + Math.random()}
        font={font}
        shadow={shadow}
        color={layout.colour}
        tag={layout.tag_price} />


    })
    const sc = layout.shelf_c
    const sl = layout.shelf_l
    const sr = layout.shelf_r
    const left = { backgroundImage: `url(https://images.ilusia.com/post_masters/${sl})` }
    const center = { backgroundImage: `url(https://images.ilusia.com/post_masters/${sc})` }
    const right = { backgroundImage: `url(https://images.ilusia.com/post_masters/${sr})` }

    return <Shelf products={products}
      left={left}
      center={center}
      right={right} />
  }


  handleMouseEnter = (product, event) => UIStore.showProductTooltip(product)
  handleMouseLeave = (event) => UIStore.hideProductTooltip()


  handleClick = (id, units, event) => {
    if (event.target.getBoundingClientRect().width != event.target.offsetWidth){
      return;
    }

    const img = event.target.parentNode.querySelector('img.prod')
    const product = {
      src: img.src,
      width: img.getBoundingClientRect().width,
      height: img.getBoundingClientRect().height,
      top: img.getBoundingClientRect().top,
      left: img.getBoundingClientRect().left,
      units: units
    }
    UIStore.addAnimatedProduct(product)
    ShopStore.add(id, 1)
  }
}


export default Rack
