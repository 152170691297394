import React from 'react';

const one = () => {
  return (
    <g>
      <path
        d="m376 512v-512h-99.902l-3.56 9.932c-9.419 26.294-27.202 49.746-52.837 69.697-26.528 20.684-51.211 34.849-73.359 42.1l-10.342 3.383v114.771l19.702-6.519c36.387-12.012 69.448-29.268 98.672-51.445v330.081z" />
    </g>
  )
}

export default one;