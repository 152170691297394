
import React from 'react'
import { capitalize } from '../../utils'

const OrderDetailTicketProduct = (props) => <tr key={ props.product.id_product_eva }>
  <td>{ props.product.quantity }</td>
  <td className="product-presentation-name">{ capitalize(props.product.product_description) }</td>
  <td>{ (props.product.unit_price).toFixed(2) + ' €' }</td>
  <td>{ parseFloat(props.product.total_price).toFixed(2) + ' €' }</td>
</tr>

export default OrderDetailTicketProduct
