
import React, { Component } from 'react'
import { IconButton, Icon } from '../../../'

class AccordionButton extends Component {

  render () {
    return (
      <div className="accordion-button" onClick={ this.props.onClick } role="button">
        <IconButton type="fab"
                    style={ { backgroundColor: this.props.bubblecolor } }
                    size="46"
                    iconSize="36"
                    iconType={ this.props.icon }
                    iconTitle="AL TEXT GOES HERE"
                    onClick={ null } />
        <h3 className="h3 black bold s16">{ this.props.name }</h3>
        { this.renderStatus() }
      </div>
    )
  }


  /**
   * Renderiza la etiqueta de estado para el botón
   */
  renderStatus () {
    if (this.props.status === 1) {
      return (
        <div className="status">
          <span className="status-icon">
            <Icon type="ok" size="26" fill="white" />
          </span>
          <p className="verify p regular black s16 a60">Verificada</p>
        </div>
      )
    } else {
      return (
        <div className="status">
          <p className="p regular blue s16">Vincular</p>
        </div>
      )
    }
  }
}

export default AccordionButton
