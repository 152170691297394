
import React from 'react'
import { translate } from 'react-i18next'
import { AppStore, UIStore } from '../../../../stores'
import { Button } from '../../../'
import { capitalize } from '../../../../utils'

/**
 *
 */
const OrderCheckout = ({ t, ...props }) => <div className="empty-cart-alert">
  <div className="container">
    <div className="row">
      <p className="p light white s12">Mensaje por definir</p>
    </div>
    <div className="row">
      <p className="p regular white s14">Mensaje por definir</p>
      <ul>
        <li><Button placeholder="Cancelar" type="flat" color="dark-white" onClick={ props.onCancel } /></li>
        <li><Button placeholder="Ir" type="flat" color="blue" onClick={ props.onConfirm } /></li>
      </ul>
    </div>
  </div>
</div>

export default translate(['common'], { wait: false })(OrderCheckout)
