
import React from 'react'
import { Link } from 'react-router'
import { Icon } from '../'

const LinkIconButton = (props) => <Link className={ `btn icon ${ props.type || '' } ${ props.size || '' } ${ props.color || '' } ${ props.className || '' }` }
                                        id={ props.id || null }
                                        onMouseOver={ props.onMouseOver || null }
                                        onMouseOut={ props.onMouseOut || null }
                                        onMouseEnter={ props.onMouseEnter || null }
                                        onMouseLeave={ props.onMouseLeave || null }
                                        target={ props.target || null }
                                        to={ props.onClick || null }
                                        target={ props.target || null } >
  <Icon type={ props.iconType }
        title={ props.iconTitle }
        className={ props.iconColor || '' }
        size={ props.iconSize }
        fill={ props.iconFill || '' } />
</Link>

export default LinkIconButton
