import React, { Component, useState } from "react";
import { translate } from "react-i18next";
import Icon from "../../../Icons/Icon";
import { ValidateForm } from "../../../../functions/ValidateForm";
import { getAddresses, postAddress, putAddress } from "../../../../utils/api";
import { AppStore, AuthStore, UIStore, SyncStore } from "../../../../stores";
import { browserHistory } from "react-router";
import { IconButton } from "../../../";
import { AutocompletePlace } from "../AutocompletePlace";

@translate(["common"], { wait: false })
class AddressesAcordion extends Component {
  state = {
    current: undefined,
    email: "",
    password: "",
    addresses: AuthStore.user.addresses,
    max_addresses: AuthStore.user.addresses
      ? AuthStore.user.addresses.length > 0
      : true,
    form: {
      alias: "",
      address1: "",
      address2: "",
      city: "",
      province: "",
      postcode: "",
      // nif:'',
      phone: "",
    },
    forms: [
      { name: "alias", field: "alias", Component: false },
      {
        name: "dirección 1",
        field: "address1",
        Component: (props = {}) => <AutocompletePlace {...props} />,
      },
      { name: "dirección 2", field: "address2", Component: false },
      { name: "ciudad", field: "city", Component: false },
      { name: "provincia", field: "province", Component: false },
      { name: "código postal", field: "postcode", Component: false },
      // {name: 'número de identificación', field:'nif'},
      { name: "número de teléfono", field: "phone", Component: false },
    ],
    current_addresss_index: null,
    status: "list",
    readOnly: true,
    locationMetadata: {
      latitude: 0.0,
      longitude: 0.0,
      globalcode: "",
    },
  };

  componentDidMount() {
    AuthStore.setUser();
  }

  onSetAsDefault = (id) => {
    const { local_addresses } = this.state;
    const newItems = local_addresses.map((value) => {
      if (value.id === id) return { ...value, is_default: true };
      return { ...value, is_default: false };
    });
    this.setState({ local_addresses: newItems });
  };

  onFormChange = (field, text) => {
    const { form } = this.state;
    this.setState({ form: { ...form, [field]: text } });
    // console.log('in fromCh -->', text, field, form);
  };

  onPostNewAddress = (body) => {
    const result = postAddress(body).then(async (res) => {
      getAddresses().then((data) => {
        this.setState({ addresses: data });
        AuthStore.setUser();
        UIStore.hideLoading();
        this.setState({ status: "list", readOnly: true });
        browserHistory.push("/zona-usuario");
        this.emptyForm();
      });
    }).catch((err) => {
      getAddresses().then((data) => {
        this.setState({ addresses: data });
        AuthStore.setUser();
        UIStore.hideLoading();
        this.setState({ status: "list", readOnly: true });
        browserHistory.push("/zona-usuario");
        this.emptyForm();
      });
    });
  };

  onPutNewAddress = async (body) => {
    const result = await putAddress(body);
    console.log(result);
  };

  onAddPress = () => {
    this.setState({ status: "add" });
  };

  emptyForm = () => {
    const _newForm = {
      alias: "",
      address1: "",
      address2: "",
      city: "",
      province: "",
      postcode: "",
      // nif:'',
      phone: "",
    };
    this.setState({ form: _newForm });
  };

  onSaveEditChanges = () => {
    const {form} = this.state;
    const invalidAddresses = form.address1 == "Dirección invalida" || form.ad == "Dirección invalida"
    if (invalidAddresses){
      alert(
        "Verifica las direcciones"
      )
      return; 
    }

    const {
      addresses,
      current_addresss_index,
      locationMetadata: { latitude, longitude, globalcode },
    } = this.state;
    const address = addresses[current_addresss_index];

    const { user } = AuthStore;
    if (addresses[current_addresss_index]) {
      const obj = {
        address_1: form.address1 || address.address1,
        address_2: form.address2 || address.address2,
        // address_3:'',
        province: form.province || address.province,
        firstname: user.firstname,
        lastname: user.lastname,
        phone: form.phone,
        // nif: form.nif,
        city: form.city || address.city,
        postcode: form.postcode || address.postcode,
        alias: form.alias || address.alias,
        id_address: addresses[current_addresss_index].id, //.toString()
        latitude,
        longitude,
        globalcode,
      };
      UIStore.showLoading();

      putAddress(obj)
        .then(async (res) => {
          getAddresses().then((data) => {
            this.setState({ addresses: data });
            AuthStore.setUser();
            UIStore.hideLoading();
            this.setState({ status: "list", readOnly: true });
            browserHistory.push("/zona-usuario");
            this.emptyForm();
          });
        })
        .catch((err) => {
          getAddresses().then((data) => {
            this.setState({ addresses: data });
            UIStore.hideLoading();
            this.setState({ status: "list", readOnly: true });
            browserHistory.push("/zona-usuario");
            this.emptyForm();
          });
        });
    }
  };

  onSaveNewAddress = () => {
    const { form } = this.state;
    if (ValidateForm(form, ["address2"])) {
      const { user } = AuthStore;
      const obj = {
        address_1: form.address1,
        address_2: form.address2,
        address_3: "",
        alias: form.alias,
        city: form.city,
        firstname: user.firstname,
        globalCode: this.state.locationMetadata.globalcode,
        lastname: user.lastname,
        latitude: this.state.locationMetadata.latitude,
        longitude: this.state.locationMetadata.longitude,
        phone: form.phone,
        postcode: form.postcode,
        province: form.city,
        nif: form.nif,
      };
      UIStore.showLoading();
      this.onPostNewAddress(obj);
    } else {
      alert(
        "Todos los campos son obligatorios, excepto la dirección secundaria."
      );
    }
  };

  onEditPress = (index) => {
    const { addresses, forms } = this.state;
    const currentAddres = addresses[index];
    let _newForm = {};
    forms.map((value) => {
      const placeholder = currentAddres[value.field]
        ? currentAddres[value.field]
        : "";
      _newForm = { ..._newForm, [value.field]: placeholder };
    });
    this.setState({ form: _newForm });
    this.setState({ current_addresss_index: index });
    this.setState({ status: "edit" });
  };

  setLocationMetadata = (data = {}) => {
    this.onFormChange("city", data.city)
    this.onFormChange("postcode", data.postalCode)
    this.onFormChange("province", data.province)
    this.setState({ locationMetadata: data });
  };

  render() {
    const {
      forms,
      current_addresss_index,
      form,
      status,
      addresses,
      max_addresses,
    } = this.state;
    const bgColor = max_addresses ? "inactive" : "active";
    if (status === "list") {
      return (
        <div className="user-zone-page adresses-container">
          <div
            className="adresses add-adresses"
            onClick={() => {
              !max_addresses && this.onAddPress();
            }}
          >
            <button
              disabled={max_addresses}
              className={`icon-add ${bgColor}`}
              onClick={() => {
                !max_addresses && this.onAddPress();
              }}
            >
              <Icon type="add" />
            </button>
          </div>
          {addresses &&
            addresses.length > 0 &&
            addresses.map((value, index) => {
              return (
                <AddressCard
                  name={value.alias}
                  description={value.address1}
                  neighbor={value.city}
                  phone={value.phone}
                  province={value.province}
                  postCode={value.postcode}
                  is_default={/* value.is_default */ true}
                  key={index.toString()}
                  id={value.nif}
                  onSetAsDefault={this.onSetAsDefault}
                  onEditPress={this.onEditPress}
                  index={index}
                />
              );
            })}
          {addresses.length === 0 && (
            <h1 className="h1 light" style={{ margin: "auto" }}>
              Aún no tienes direcciones
            </h1>
          )}
        </div>
      );
    }
    if (status === "edit") {
      return (
        <div className="edit-adresses-container">
          <div className="d-flex">
            <button
              className="btn-back"
              onClick={() => this.setState({ status: "list", readOnly: true })}
            >
              <Icon
                type="arrow_go_back"
                fill="#97a7b3"
                width="40"
                height="40"
              />
            </button>
            <h1>EDITAR DIRECCIÓN</h1>
          </div>
          {this.state.readOnly ? (
            <IconButton
              type="fab"
              className="edit"
              color="blue"
              size="60"
              iconSize="26"
              iconType="edit"
              iconTitle="AL TEXT GOES HERE"
              onClick={() => this.setState({ readOnly: false })}
            />
          ) : (
            <IconButton
              type="fab"
              className="edit"
              color="green"
              size="60"
              iconSize="32"
              iconType="ok"
              iconTitle="AL TEXT GOES HERE"
              style={{ border: "none" }}
              o
              onClick={this.onSaveEditChanges}
            />
          )}
          <ul>
            {forms.map((value, index) => {
              const placeholder =
                addresses.length > 0
                  ? addresses[current_addresss_index][value.field]
                    ? addresses[current_addresss_index][value.field]
                    : "  "
                  : "   ";
              if (form[value.field].lenght === 0) {
                this.onFormChange(value.field, placeholder);
              }
              return (
                <CustomInput
                  title={`${
                    value.name.charAt(0).toUpperCase() + value.name.slice(1)
                  }`}
                  field={value.field}
                  onFormChange={this.onFormChange}
                  key={index.toString()}
                  form={form}
                  value={form[value.field]}
                  readOnly={this.state.readOnly}
                  CustomComponent={value.Component}
                  onChange={this.setLocationMetadata}
                />
              );
            })}
          </ul>
        </div>
      );
    }
    if (status === "add") {
      return (
        <div className="edit-adresses-container">
          <div className="d-flex">
            <button
              className="btn-back"
              onClick={() => this.setState({ status: "list", readOnly: true })}
            >
              <Icon
                type="arrow_go_back"
                fill="#97a7b3"
                width="40"
                height="40"
              />
            </button>
            <h1 className="h1-d">AÑADIR DIRECCIÓN</h1>
          </div>
          <IconButton
            type="fab"
            className="edit"
            color="green"
            size="60"
            iconSize="32"
            iconType="ok"
            iconTitle="AL TEXT GOES HERE"
            style={{ border: "none" }}
            onClick={this.onSaveNewAddress}
          />
          <ul>
            {forms.map((value, index) => {
              const placeholder = "";
              return (
                <CustomInput
                  title={`${
                    value.name.charAt(0).toUpperCase() + value.name.slice(1)
                  }`}
                  field={value.field}
                  onFormChange={this.onFormChange}
                  key={index.toString()}
                  placeholder={placeholder}
                  form={form}
                  value={form[value.field]}
                  hasEmptyFields
                  readOnly={false}
                  add={true}
                  CustomComponent={value.Component}
                  onChange={this.setLocationMetadata}
                />
              );
            })}
          </ul>
          {/* <button onClick={()=>this.setState({status:'list'})} >  
                CANCELAR
            </button> */}
          {/* <button onClick={()=>this.onSaveNewAddress()} >  
                GUARDAR DIRECIÓN
            </button> */}
        </div>
      );
    }

    /**
     * Maneja el click de los elementos del acordeón.
     */
    onAccordionItemClick = (i, event) => {
      if (i === this.state.current) i = undefined;
      this.setState({ current: i });
    };
  }
}

const CustomInput = ({
  title,
  field = "",
  onFormChange = () => {},
  value,
  readOnly = true,
  add = false,
  CustomComponent = null,
  onChange = () => {},
}) => {
  const addL = add ? " " : "edit";
  const edit = readOnly ? " " : addL;
  return (
    <li>
      <label className="p light black s16 a40">{title}</label>
      {CustomComponent ? (
        CustomComponent({
          onSelectAddress: (value, metadata) => {
            if (!field || !value) {
              onFormChange(field, null);
              console.log('nadaaaa');
              return; 
            }
            onFormChange(field, value);
            onChange(metadata);
          },
          onChange: (value) => {
            if (!value) return;
            if (!value.target) return;
            onFormChange(field, value.target.value);
          },
          value: value,
          readOnly: readOnly,
          className: `user-zone_input ${edit}`,
        })
      ) : (
        <input
          id={field}
          type="text"
          className={`user-zone_input ${edit}`}
          value={value}
          onChange={(e) => {
            onFormChange(field, e.target.value);
          }}
          readOnly={readOnly}
        />
      )}
    </li>
  );
};

const AddressCard = ({
  is_default = false,
  name,
  description,
  neighbor,
  id,
  province,
  postCode,
  phone,
  onSetAsDefault = () => {},
  onEditPress = () => {},
  index,
}) => {
  return (
    <div className="adresses">
      <div className="title-container">
        <div>
          {is_default && <p className="container-default">Predeterminada</p>}
        </div>
        <IconButton
          type="fab"
          className="edit-icon"
          color="blue"
          size="30"
          iconSize="20"
          iconType="edit"
          iconTitle="AL TEXT GOES HERE"
          onClick={() => onEditPress(index)}
        />
      </div>
      <div className="body-container">
        <h1 className="h1">{name.toUpperCase()}</h1>
        <p className="p">{description}</p>
        <p className="p">
          {postCode} {neighbor}, {province}
        </p>
        <p className="p">{phone}</p>
      </div>
      {!is_default && (
        <div className="btn-container-card">
          <button onClick={() => onSetAsDefault(id)}>
            MARCA COMO PREDETERMINADA
          </button>
        </div>
      )}
    </div>
  );
};

export default AddressesAcordion;
