
import config from '../../config'

/**
 * @func: getCountries
 * @return {[Object]} data
 *
 * @description:
 */

const getCountries = () => {
  return config.API.countriesGet()
  .then(result => result.data)
  .catch(error => error)
}


export default getCountries
