import React from 'react'

const allergen_1 = () => {
  return (
    <g>
            <path d="M100.001,0C44.772,0,0,44.772,0,100c0,55.23,44.772,100,100.001,100C155.229,200,200,155.23,200,100
                C200,44.772,155.229,0,100.001,0z M112.809,127.998c0,0-11.816,10.221-12.113,10.624c-0.593,0.594-0.693,1.088-0.693,1.088
                s-0.2-0.594-0.497-0.991c-0.297-0.297-12.212-10.621-12.212-10.621c-3.272-3.277-3.272-3.277-3.272-16.183l12.906,11.816
                c2.08,2.085,2.875,2.876,3.075,6.847c0.199-4.068,0.99-4.762,3.173-6.947l12.91-11.812
                C116.086,124.821,116.086,124.821,112.809,127.998z M113.004,110.72c0,0-11.815,10.327-12.109,10.624
                c-0.596,0.597-0.693,1.091-0.693,1.091s-0.199-0.494-0.598-1.091c-0.295-0.297-12.213-10.624-12.213-10.624
                c-3.276-3.277-3.276-3.277-3.276-16.18l12.908,11.816c2.088,2.082,2.88,2.877,3.08,6.851c0.197-3.974,0.89-4.668,3.073-6.851
                l12.91-11.816C116.086,107.546,116.086,107.546,113.004,110.72z M112.809,93.748c0,0-11.816,10.221-11.914,10.621
                c-0.596,0.594-0.693,1.091-0.693,1.091s-0.199-0.497-0.598-1.091c-0.295-0.3-12.213-10.621-12.213-10.621
                c-3.276-3.277-3.276-3.277-3.276-16.187l12.908,11.816c2.088,2.085,2.88,2.88,3.08,6.85c0.197-3.971,0.89-4.665,3.073-6.85
                l12.91-11.816C116.086,90.472,116.086,90.472,112.809,93.748z M112.809,76.471c0,0-11.816,10.327-12.113,10.621
                c-0.593,0.597-0.693,1.098-0.693,1.098s-0.2-0.5-0.6-1.098c-0.293-0.294-12.209-10.621-12.209-10.621
                c-3.28-3.277-3.28-3.277-3.28-16.18l13.109,11.816c2.088,2.082,2.88,2.873,3.08,6.847c0.197-3.974,0.89-4.665,3.073-6.847
                l12.91-11.816C116.086,73.194,116.086,73.194,112.809,76.471z"/>
        </g>
  )
}

export default allergen_1