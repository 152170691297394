
/**
 * @func: checkCookiesDialogStatus
 * @param {[Object]} nextState
 * @param {[Function]} replaceState
 */

import React from 'react'
import { UIStore } from '../../stores'

const checkCookiesDialogStatus = (nextState, replaceState) => {
  if (UIStore.isCookiesOpen === true) {
    UIStore.hideCookiesDialog()
  }
}

export default checkCookiesDialogStatus
