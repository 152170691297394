
import React from 'react'
import { translate } from 'react-i18next'
import { UIStore } from '../../../../stores/UIStore'
import { Button } from '../../../'

/**
 *
 */
const ToEmptyCarAlert = ({ t, ...props }) => <div className="empty-cart-alert">
  <div className="container">
    <div className="row">
      <p className="p light white s12">¿Estás seguro de que quieres vaciar el carrito? Si pulsas en Aceptar todos los productos desaparecerán en todos tus dispositivos.</p>
    </div>
    <div className="row">
      <p className="p regular white s14">Su carrito va a ser eliminado</p>
      <ul>
        <li><Button placeholder="Cancelar" type="flat" color="dark-white" onClick={ props.onCancel } /></li>
        <li><Button placeholder="Vaciar" type="flat" color="blue" onClick={ props.onConfirm } /></li>
      </ul>
    </div>
  </div>
</div>

export default translate(['common'], { wait: false })(ToEmptyCarAlert)
