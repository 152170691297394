import React from "react";
import { Icon } from "../";

/**
 * @func: ProviderSelectionButtonBasic
 * @param: {[Object]} props
 *
 *
 * @description:
 */

const ProviderSelectionButtonBasic = (props) => (
  <button
    className="btn provider-selection_basic"
    onClick={props.onClick}
    id={props.id}
    name={props.name}
    style={props.style}
  >
    <Icon
      type={props.icon}
      fill="white"
      width="130"
      height="40"
      viewport="0 0 190 230"
    />
  </button>
);

export default ProviderSelectionButtonBasic;
