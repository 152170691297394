/**
 * Testing project
 */
import React, { Component } from "react";
import { translate } from "react-i18next";
import { observer } from "mobx-react";
import { SyncStore } from "../stores";
import { ShopLine } from "../components";

@translate(["common"], { wait: false })
@observer
class Shop extends Component {
  componentWillUnmount() {
    SyncStore.forceCartSync();
  }
  render() {
    console.info(
      `%cRenderizado de la tienda!`,
      "color: green; font-weight: bold"
    );
    return (
      <div className="section shop">
        <ShopLine />
      </div>
    );
  }
}

export default Shop;
