
import React, { Component } from 'react'


class MobileBlocked extends Component {

  componentDidMount () {
    
  }

   constructor(props) {
    super(props);

  } 


  render () {
    return (
      <div className="mobile-view">
        <div className="vertical-center-mobile">
          <span className="bar"></span>
          <p className="p light black s14">Bienvenido a ilusia®.</p>
          <p className="p light black s14">Descubre nuestra app disponible para iOS y Android y disfruta comprando con tu dispositivo móvil o tablet.</p>
          <a href="https://play.google.com/store/apps/details?id=com.ilusia.ilusia" target="_blank" className="android">
            <img src="/img/download_android.png" />
          </a>

          <a href="https://itunes.apple.com/app/id1250011897" target="_blank" className="ios">
            <img src="/img/download_ios.png" />
          </a>
          <p className="p light black s14">Redescubre la forma de hacer la compra.</p>
          <br />
          <img src="/img/corazon-filtros.png" />
        </div>
      </div>
    )
  }


}

export default MobileBlocked
