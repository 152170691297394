/**
 * Testing project
 */
import React, { Component } from 'react'
import { Link } from 'react-router'
import { observer } from 'mobx-react'

// NOTE: importamos los stores para instanciarlos ya que son pseudo-singletons
import { TrackingApartments, AppStore, UIStore, AuthStore, WebPushNotificationStore } from './stores'

// Components
import { AsyncRouteManager,
         BackgroundTransitionManager,
         Preloader,
         Loading,
         Toolbar,
         Modal,
         Notification,
         Alert,
         ProductDetailFullImage,
         Cookies,
         ListDropdown,
         Tutorial,
         TutorialUser } from './components'




@observer
class App extends Component {

  constructor (...args) {
    super(...args)
  }


  render () {
    return (
      <div className="container-full">

        <Loading isOpen={ UIStore.isLoadingOpen }
                 transitionName="modal-anim" />

        <Modal isOpen={ UIStore.isModalOpen }
               toogle={ UIStore.toggleModal }
               config={ UIStore.modalConfig }
               transitionName="modal-anim" />

        <Tutorial isOpen={ UIStore.isTutorialOpen }
                  toogle={ UIStore.toggleTutorial }
                  transitionName="modal-anim" />

        <TutorialUser isOpen={ UIStore.isTutorialuserOpen }
                  toogle={ UIStore.toggleTutorialuser }
                  transitionName="modal-anim" />

        <Notification isOpen={ UIStore.isNotificationOpen }
                      show={ UIStore.showNotification }
                      hide={ UIStore.hideNotification }
                      config={ UIStore.notificationConfig }
                      timeOut={ 3000 }
                      transitionName="notification-anim"/>

        <Alert isOpen={ UIStore.isAlertOpen }
               config={ UIStore.alertConfig }
               transitionName="alert-anim"/>

        <ProductDetailFullImage isOpen={ UIStore.isProductDetailFullImageVisible }
                                src={ UIStore.productDetailFullImage }
                                transitionName="modal-anim" />

        <Cookies isOpen={ UIStore.isCookiesOpen }
                 onClick={ UIStore.hideCookiesDialog }
                 transitionName="alert-anim"/>

        <Toolbar />
        <BackgroundTransitionManager source={ UIStore.backgroundSource }
                                     type={ UIStore.backgroundType }
                                     transitionName="background" />

        <AsyncRouteManager componentDataHandlerMethod={ AppStore.componentDataHandler }
                           resetStoreDataHandlerMethod={ AppStore.resetStoreData }
                           resetConfigDataHandlerMethod={ AppStore.resetConfigData }
                           initialPreloader={ Preloader }
                           transitionPreloader={ Preloader }
                           transition={ true }
                           transitionTimeOut={ 200 } >
          { this.props.children }
        </AsyncRouteManager>
      </div>
    )
  }
}

export default App
