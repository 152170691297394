
import React from 'react'

const shopline = () => {
  return (
    <g>
      <g>
       <g>
         <path d="M33.144,22.131V0H20.535v22.131c-5.08,2.362-8.737,7.177-8.737,13.148V64.13
           c0,3.457,2.803,6.261,6.259,6.261h17.56c3.457,0,6.261-2.804,6.261-6.261V35.279C41.878,29.308,38.221,24.493,33.144,22.131z"/>
       </g>
       <g>
         <path d="M59.612,70.391h48.406c3.454,0,6.258-2.802,6.258-6.259V28.483c0-3.457-2.804-6.261-6.258-6.261H59.612
           c-3.458,0-6.258,2.804-6.258,6.261v35.648C53.354,67.589,56.154,70.391,59.612,70.391z M69.507,37.578
           c0-0.908,0.737-1.645,1.646-1.645h25.324c0.909,0,1.645,0.737,1.645,1.645v16.079c0,0.911-0.736,1.648-1.645,1.648H71.153
           c-0.909,0-1.646-0.737-1.646-1.648V37.578z"/>
       </g>
       <g>
         <path d="M133.538,70.391h48.406c3.454,0,6.258-2.802,6.258-6.259V28.483c0-3.457-2.804-6.261-6.258-6.261h-48.406
           c-3.458,0-6.259,2.804-6.259,6.261v35.648C127.278,67.589,130.08,70.391,133.538,70.391z M143.432,37.578
           c0-0.908,0.737-1.645,1.646-1.645h25.323c0.911,0,1.645,0.737,1.645,1.645v16.079c0,0.911-0.734,1.648-1.645,1.648h-25.323
           c-0.909,0-1.646-0.737-1.646-1.648V37.578z"/>
       </g>
     </g>
     <g>
       <g>
         <path d="M33.144,126.867v-22.131H20.535v22.131c-5.08,2.362-8.737,7.177-8.737,13.148v28.851
           c0,3.457,2.803,6.261,6.259,6.261h17.56c3.457,0,6.261-2.804,6.261-6.261v-28.851C41.878,134.044,38.221,129.229,33.144,126.867z
           "/>
       </g>
       <g>
         <path d="M74.725,126.867v-22.131H62.116v22.131c-5.08,2.362-8.735,7.177-8.735,13.148v28.851
           c0,3.457,2.802,6.261,6.258,6.261H77.2c3.455,0,6.259-2.804,6.259-6.261v-28.851C83.46,134.044,79.804,129.229,74.725,126.867z"
           />
       </g>
       <g>
         <path d="M116.307,126.867v-22.131h-12.609v22.131c-5.079,2.362-8.735,7.177-8.735,13.148v28.851
           c0,3.457,2.803,6.261,6.258,6.261h17.561c3.457,0,6.261-2.804,6.261-6.261v-28.851
           C125.043,134.044,121.386,129.229,116.307,126.867z"/>
       </g>
       <g>
         <path d="M144.514,175.127h37.43c3.454,0,6.258-2.802,6.258-6.259v-46.76c0-3.457-2.804-6.261-6.258-6.261h-37.43
           c-3.458,0-6.259,2.804-6.259,6.261v46.76C138.254,172.325,141.056,175.127,144.514,175.127z M154.409,142.314
           c0-0.908,0.737-1.646,1.646-1.646h14.347c0.911,0,1.645,0.738,1.645,1.646v16.079c0,0.909-0.734,1.648-1.645,1.648h-14.347
           c-0.909,0-1.646-0.738-1.646-1.648V142.314z"/>
       </g>
     </g>
     <g>
       <rect y="77.004" width="200" height="17.757"/>
       <rect y="182.243" width="200" height="17.757"/>
     </g>
    </g>
  )
}

export default shopline
