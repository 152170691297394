
import React from 'react'
import { translate } from 'react-i18next'
import { AppStore, UIStore } from '../../../../stores'
import { Button } from '../../../'
import { capitalize } from '../../../../utils'

/**
 *
 */
const OrderSuccessfullySentAlert = ({ t, ...props }) => <div className="empty-cart-alert">
  <div className="container">
    <div className="row">
      <p className="p light white s12">Tu pedido se ha enviado correctamente a { capitalize(AppStore.providername) }. Puedes seguir las instructiones...</p>
    </div>
    <div className="row">
      <p className="p regular white s14">Ir a la web de { capitalize(AppStore.providername) }</p>
      <ul>
        <li><Button placeholder="Seguir comprando" type="flat" color="dark-white" onClick={ props.onCancel } /></li>
        <li><Button placeholder={ `Ir a ${ capitalize(AppStore.providername) }` } type="flat" color="blue" onClick={ props.onConfirm } /></li>
      </ul>
    </div>
  </div>
</div>

export default translate(['common'], { wait: false })(OrderSuccessfullySentAlert)
