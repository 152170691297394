import React from 'react'

const welfare_7 = () => {
  return (
        <g>
            <path d="M195.48,70.678c-0.173,0.077-0.347,0.162-0.52,0.24c-1.014-3.38-2.189-6.688-3.542-9.906
                c0.128-0.058,0.257-0.121,0.385-0.177c-5.018-11.57-12.179-21.991-20.964-30.787C152.755,11.519,127.52,0.001,99.585,0.001
                C44.586,0.001,0,44.587,0,99.586c0,27.719,11.333,52.783,29.608,70.838c18.051,18.255,43.103,29.575,70.807,29.575
                c55,0,99.585-44.585,99.585-99.582C200,90.058,198.415,80.069,195.48,70.678z M100.415,43.687c15.64,0,28.363,12.724,28.363,28.363
                c0,15.641-12.724,28.365-28.363,28.365c-15.641,0-28.364-12.724-28.364-28.365C72.051,56.411,84.774,43.687,100.415,43.687z
                 M179.848,78.492c-7.666,4.258-15.323,9.177-22.783,14.904c-0.144,0.117-23.507,18.975-44.774,44.408
                c-1.546,2.042-17.119,21.792-40.015,21.648c-9.842-0.06-22.728-3.984-29.572-8.613c-10.024-6.772-13.179-12.223-13.612-14.304
                c-0.002-0.008,0.012-0.036,0.019-0.057c-0.028-0.101-0.083-0.18-0.105-0.281c-0.503-2.255,0.279-4.602,2.033-6.104l32.479-27.791
                c1.169-1,2.614-1.499,4.059-1.499c1.446,0,2.89,0.499,4.059,1.499l33.1,28.324c21.832-25.99,44.928-44.629,45.909-45.418
                c8.493-6.52,17.179-12.069,25.831-16.795C177.803,71.678,178.939,75.038,179.848,78.492z"/>
            <path d="M100.415,94.725c12.502,0,22.673-10.169,22.673-22.674c0-12.5-10.172-22.673-22.673-22.673
                c-12.502,0-22.674,10.172-22.674,22.673C77.741,84.555,87.913,94.725,100.415,94.725z M89.834,68.05h21.162
                c2.307,0,4.179,1.872,4.179,4.179c0,2.31-1.871,4.179-4.179,4.179H89.834c-2.308,0-4.179-1.869-4.179-4.179
                C85.655,69.922,87.526,68.05,89.834,68.05z"/>
            <path d="M35.859,134.184c16.366-0.013,52.959-0.123,63.253-0.156l-31.535-26.984L35.859,134.184z"/>
        </g>
  )
}

export default welfare_7