
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { browserHistory } from 'react-router'
import { AppStore, AuthStore, TrackingApartments } from '../stores'
import { ProductInfoContainer, ProductInfoNutriContainer, SuggestedProducts } from '../components'
import { SuggestedProduct } from '../components'

@observer
class ProductDetail extends Component {

  componentDidMount() {
    TrackingApartments.view_product_details(AuthStore.apartmentId);
  }
  
  // NOTE: el control del bg se realiza desde AsyncRouteManager
  render () {
    const suggested = AppStore.suggested.map(product => <SuggestedProduct product={ product } key={ product.id_product_eva } />)
    return (
      <div className="section productdetail">
        <ProductInfoContainer product={ AppStore.detail } />
        <ProductInfoNutriContainer nutritional={ AppStore.nutritional } />
        <SuggestedProducts products={ suggested } />
      </div>
    )
  }
}

export default ProductDetail
