
import React from 'react'

const see = () => {
  return (
    <g>
      <path d="M101,32.218c-45.187,0-83.78,28.107-99.419,67.783C17.22,139.678,55.813,167.783,101,167.783
       c45.196,0,83.78-28.105,99.419-67.782C184.78,60.326,146.196,32.218,101,32.218z M101,145.191c-24.946,0-45.187-20.244-45.187-45.19
       c0-24.947,20.24-45.188,45.187-45.188s45.196,20.24,45.196,45.188C146.196,124.947,125.946,145.191,101,145.191z M101,72.886
       c-14.999,0-27.114,12.115-27.114,27.115c0,15.004,12.115,27.114,27.114,27.114c15.005,0,27.114-12.109,27.114-27.114
       C128.114,85.001,116.005,72.886,101,72.886z"/>
    </g>
  )
}

export default see
