
import config from '../../config'

/**
 * @func: getSuggested
 * @return {[Object]} data
 *
 * @description:
 */

const getSuggested = (idproduct, idzone, idprovider, idcategory, allergens, welfares, acronym) => {
  const params = { id_product_eva: idproduct, id_zone: idzone, id_provider: idprovider, id_category: idcategory, allergens: allergens, welfares: welfares, acronym: acronym }
  return config.API.suggestedGet(params, {}, {})
  .then(result => result.data.products)
  .catch(error => error)
}


export default getSuggested
