
import React from 'react'

const logo_symbol = () => {
 return (
   <g transform="scale(0.55) translate(0, -65)">
   {/* <g transform="translate(0, -65)"> */}
     <path d="M194.793,114.759c0-0.376,0.094-0.72,0.274-1.043c0.183-0.319,0.441-0.576,0.768-0.765
				c0.332-0.19,0.686-0.283,1.06-0.283c0.389,0,0.746,0.091,1.065,0.281c0.323,0.189,0.575,0.444,0.765,0.767
				c0.183,0.324,0.275,0.673,0.275,1.043c0,0.385-0.096,0.739-0.285,1.062c-0.19,0.323-0.446,0.574-0.769,0.762
				c-0.326,0.184-0.675,0.273-1.052,0.273c-0.374,0-0.728-0.094-1.06-0.287c-0.326-0.189-0.585-0.447-0.768-0.766
				C194.887,115.487,194.793,115.137,194.793,114.759z M195.176,114.759c0,0.31,0.083,0.599,0.238,0.864
				c0.154,0.266,0.363,0.471,0.626,0.619c0.259,0.152,0.546,0.227,0.85,0.227c0.314,0,0.601-0.078,0.869-0.23
				c0.267-0.154,0.479-0.361,0.632-0.623c0.151-0.266,0.228-0.547,0.228-0.856c0-0.306-0.076-0.592-0.229-0.855
				c-0.158-0.263-0.37-0.47-0.635-0.626c-0.265-0.151-0.551-0.229-0.865-0.229c-0.304,0-0.591,0.078-0.854,0.228
				c-0.262,0.155-0.474,0.36-0.628,0.628C195.255,114.172,195.176,114.456,195.176,114.759z M197.097,113.657
				c0.206,0,0.371,0.059,0.505,0.171c0.128,0.111,0.191,0.256,0.191,0.438c0,0.175-0.061,0.321-0.169,0.44
				c-0.114,0.115-0.265,0.182-0.452,0.197v0.012c0.082,0.025,0.158,0.072,0.224,0.136c0.069,0.066,0.16,0.2,0.276,0.4l0.27,0.444
				h-0.513l-0.103-0.196c-0.092-0.185-0.175-0.332-0.255-0.446s-0.15-0.186-0.208-0.23c-0.061-0.037-0.144-0.059-0.246-0.059h-0.19
				v0.932h-0.411v-2.239H197.097z M196.936,114.63c0.136,0,0.236-0.03,0.309-0.083c0.074-0.061,0.11-0.139,0.11-0.238
				c0-0.104-0.042-0.184-0.114-0.236c-0.078-0.055-0.178-0.081-0.305-0.081h-0.509v0.639H196.936z M76.3,82.495
				c-0.888,1.468-2.112,2.205-3.675,2.205c-0.673,0-1.305-0.146-1.896-0.441c-0.631-0.376-1.115-0.867-1.456-1.479
				c-0.334-0.608-0.505-1.271-0.505-1.984c0-0.8,0.189-1.514,0.571-2.144c0.378-0.712,0.904-1.268,1.581-1.669
				c0.673-0.399,1.368-0.595,2.085-0.595c0.635,0,1.245,0.167,1.834,0.5c0.675,0.34,1.181,0.82,1.518,1.449
				c0.342,0.63,0.51,1.302,0.51,2.017C76.867,81.112,76.677,81.823,76.3,82.495z M81.229,110.496
				c-0.212,0.295-0.567,0.538-1.071,0.725c-0.509,0.185-1.015,0.242-1.52,0.156c-0.512-0.084-0.953-0.343-1.331-0.787
				c-0.377-0.441-0.567-1.167-0.567-2.173V93.361c0-1.257,0.011-2.278,0.028-3.053c0.021-0.776,0.055-1.396,0.1-1.859
				c0.039-0.504,0.084-0.9,0.123-1.198c-2.616,0-4.595,0.548-5.943,1.641c-1.352,1.091-2.023,2.898-2.023,5.415l-0.065,14.867
				c0,1.051,0.161,2.056,0.477,3.021c0.315,0.965,0.8,1.803,1.453,2.521c0.653,0.714,1.468,1.269,2.435,1.669
				c0.97,0.398,2.108,0.535,3.417,0.406c1.349-0.082,2.34-0.471,2.973-1.164c0.629-0.693,1.054-1.418,1.265-2.168
				C81.229,112.574,81.313,111.586,81.229,110.496z M96.088,110.496c0.087,1.09,0,2.078-0.249,2.963
				c-0.213,0.75-0.632,1.475-1.267,2.168c-0.631,0.693-1.623,1.082-2.972,1.164c-1.306,0.129-2.444-0.008-3.415-0.406
				c-0.97-0.4-1.783-0.955-2.435-1.669c-0.653-0.718-1.137-1.556-1.454-2.521c-0.316-0.966-0.476-1.971-0.476-3.021V83
				c-0.04-1.261-0.072-2.28-0.095-3.055c-0.024-0.777-0.055-1.396-0.097-1.859c-0.04-0.503-0.081-0.899-0.123-1.197
				c2.612,0,4.616,0.548,6.008,1.639c1.389,1.095,2.086,2.877,2.086,5.355v24.535c0,1.006,0.188,1.731,0.572,2.173
				c0.376,0.444,0.816,0.703,1.324,0.787c0.506,0.086,1.016,0.028,1.519-0.156C95.521,111.034,95.878,110.791,96.088,110.496z
				 M126.95,103.19c0,2.812-0.417,5.101-1.262,6.864c-0.847,1.764-1.93,3.148-3.257,4.154c-1.33,1.012-2.816,1.705-4.458,2.08
				c-1.645,0.377-3.271,0.566-4.87,0.566c-1.604,0-3.229-0.189-4.871-0.566c-1.646-0.375-3.126-1.068-4.46-2.08
				c-1.327-1.006-2.401-2.391-3.225-4.154c-0.82-1.764-1.231-4.053-1.231-6.864l0.065-8.883c0-2.516,0.684-4.314,2.053-5.383
				c1.369-1.072,3.36-1.607,5.975-1.607c-0.041,0.253-0.083,0.628-0.124,1.133c-0.043,0.463-0.074,1.095-0.098,1.889
				c-0.02,0.798-0.034,1.825-0.034,3.086l0.067,9.766c0,1.763,0.17,3.177,0.504,4.248c0.336,1.072,0.793,1.899,1.363,2.489
				c0.568,0.587,1.201,0.986,1.895,1.197c0.696,0.209,1.401,0.315,2.121,0.315c0.716,0,1.423-0.106,2.116-0.315
				c0.697-0.211,1.33-0.61,1.897-1.197c0.572-0.59,1.023-1.417,1.361-2.489c0.336-1.071,0.505-2.485,0.505-4.248l0.065-8.883
				c0-2.516,0.694-4.314,2.085-5.383c1.391-1.072,3.395-1.607,6.008-1.607c-0.039,0.253-0.084,0.628-0.126,1.133
				c-0.041,0.463-0.072,1.095-0.093,1.889c-0.023,0.798-0.033,1.825-0.033,3.086L126.95,103.19z M145.673,108.793
				c0.082-0.713-0.116-1.311-0.604-1.793c-0.485-0.484-1.18-0.947-2.087-1.385c-0.905-0.44-1.972-0.916-3.192-1.418
				c-1.224-0.506-2.51-1.175-3.856-2.018c-1.013-0.586-1.844-1.293-2.498-2.108c-0.655-0.818-1.13-1.7-1.424-2.647
				c-0.295-0.942-0.424-1.907-0.381-2.893c0.041-0.989,0.276-1.967,0.695-2.935c0.422-0.921,0.972-1.699,1.646-2.328
				c0.676-0.631,1.425-1.144,2.245-1.544c0.821-0.396,1.653-0.682,2.5-0.85c0.841-0.166,1.62-0.251,2.34-0.251h0.758
				c2.231,0.125,4.279,0.672,6.133,1.636c1.855,0.968,3.44,2.249,4.745,3.844l0.19,0.185l-0.19,0.255
				c-0.423,0.587-0.884,1.027-1.391,1.321c-0.464,0.293-0.99,0.442-1.581,0.442c-0.593,0-1.163-0.113-1.707-0.345
				c-0.551-0.229-1.137-0.473-1.771-0.726c-0.802-0.333-1.551-0.615-2.247-0.849c-0.692-0.231-1.403-0.346-2.118-0.346
				c-0.928,0-1.624,0.2-2.087,0.598c-0.462,0.397-0.74,0.874-0.823,1.418c-0.084,0.796,0.168,1.461,0.761,1.984
				c0.588,0.524,1.456,1.016,2.595,1.479c1.304,0.547,2.694,1.143,4.17,1.797c1.478,0.65,2.782,1.446,3.924,2.393
				c1.134,0.946,2.003,2.077,2.592,3.402c0.591,1.321,0.654,2.929,0.189,4.816c-0.21,0.754-0.569,1.543-1.075,2.361
				c-0.504,0.817-1.212,1.568-2.117,2.236c-0.911,0.673-2.005,1.229-3.29,1.67c-1.283,0.444-2.791,0.66-4.52,0.66
				c-0.341,0-0.616-0.02-0.824-0.064c-2.024-0.082-4.017-0.607-5.975-1.57c-1.961-0.967-3.594-2.246-4.903-3.844l-0.188-0.188
				l0.188-0.254c0.213-0.334,0.442-0.608,0.693-0.82c0.257-0.205,0.485-0.396,0.699-0.565c0.505-0.253,1.011-0.376,1.516-0.376
				c0.592,0,1.164,0.113,1.71,0.344c0.547,0.234,1.141,0.475,1.77,0.727c0.592,0.295,1.257,0.547,1.991,0.756
				c0.741,0.209,1.552,0.359,2.438,0.44c1.517,0,2.602-0.294,3.257-0.882C145.219,109.969,145.588,109.383,145.673,108.793z
				 M164.516,82.495c-0.884,1.468-2.107,2.205-3.664,2.205c-0.676,0-1.312-0.146-1.897-0.441c-0.636-0.376-1.118-0.867-1.454-1.479
				c-0.34-0.608-0.509-1.271-0.509-1.984c0-0.8,0.191-1.514,0.572-2.144c0.378-0.712,0.902-1.268,1.579-1.669
				c0.676-0.399,1.367-0.595,2.085-0.595c0.638,0,1.245,0.167,1.837,0.5c0.673,0.34,1.18,0.82,1.517,1.449
				c0.336,0.63,0.506,1.302,0.506,2.017C165.087,81.112,164.895,81.823,164.516,82.495z M169.452,110.496
				c-0.217,0.295-0.569,0.538-1.075,0.725c-0.506,0.185-1.014,0.242-1.521,0.156c-0.503-0.084-0.945-0.343-1.326-0.787
				c-0.377-0.441-0.569-1.167-0.569-2.173V93.361c0-1.257,0.011-2.278,0.029-3.053c0.024-0.776,0.052-1.396,0.097-1.859
				c0.042-0.504,0.086-0.9,0.126-1.198c-2.614,0-4.598,0.548-5.945,1.641c-1.351,1.091-2.021,2.898-2.021,5.415l-0.064,14.867
				c0,1.051,0.158,2.056,0.476,3.021c0.312,0.965,0.797,1.803,1.453,2.521c0.653,0.714,1.463,1.269,2.436,1.669
				c0.972,0.398,2.106,0.535,3.414,0.406c1.35-0.082,2.339-0.471,2.972-1.164c0.634-0.693,1.053-1.418,1.265-2.168
				C169.452,112.574,169.538,111.586,169.452,110.496z M174.825,100.105c1.138-0.717,2.424-1.25,3.86-1.61
				c1.43-0.354,2.928-0.535,4.486-0.535c2.19,0,4.284,0.312,6.264,0.947c-0.127-1.304-0.402-2.458-0.822-3.465
				c-0.547-1.299-1.369-2.246-2.469-2.835c-0.424-0.212-0.872-0.366-1.358-0.472c-0.482-0.106-1.043-0.158-1.675-0.158h-0.696
				c-0.589,0.042-1.183,0.137-1.768,0.286c-0.593,0.149-1.181,0.323-1.773,0.532c-0.59,0.213-1.184,0.39-1.771,0.539
				c-0.589,0.146-1.16,0.219-1.707,0.219c-0.592,0-1.161-0.128-1.71-0.378c-0.503-0.294-0.947-0.779-1.326-1.451l-0.124-0.25
				l0.124-0.19c1.267-1.34,2.751-2.423,4.461-3.244c1.705-0.818,3.569-1.291,5.595-1.416c0.209-0.043,0.389-0.064,0.538-0.064
				c0.146,0,0.326,0,0.537,0c1.938,0,3.71,0.346,5.312,1.04c1.604,0.692,2.994,1.648,4.174,2.865
				c2.404,2.606,3.647,6.024,3.732,10.264v0.506c0,4.369-1.119,7.917-3.354,10.645c-1.096,1.346-2.412,2.436-3.953,3.278
				c-1.539,0.839-3.258,1.36-5.153,1.573c-0.253,0.039-0.506,0.061-0.759,0.061c-0.248,0-0.528,0-0.819,0
				c-0.889,0-1.817-0.071-2.786-0.219c-0.968-0.149-1.918-0.43-2.848-0.85c-0.967-0.377-1.853-0.872-2.656-1.481
				c-0.799-0.606-1.476-1.394-2.021-2.362c-0.802-1.216-1.226-2.705-1.264-4.472v-0.376c0-1.637,0.339-3.004,1.01-4.098
				C172.698,101.847,173.604,100.901,174.825,100.105z M187.92,103.127c-0.551-0.086-1.171-0.166-1.871-0.254
				c-0.691-0.084-1.401-0.125-2.118-0.125c-0.589,0-1.158,0.03-1.703,0.098c-0.55,0.058-1.054,0.177-1.519,0.345
				c-1.057,0.338-1.856,0.858-2.402,1.573c-0.216,0.252-0.383,0.537-0.507,0.852c-0.127,0.311-0.212,0.661-0.255,1.037
				c-0.042,0.125-0.062,0.314-0.062,0.568c0,0.416,0.062,0.871,0.189,1.353c0.127,0.481,0.377,0.937,0.759,1.354
				c0.338,0.42,0.832,0.777,1.485,1.072c0.655,0.293,1.526,0.44,2.623,0.44c0.341,0,0.589-0.022,0.759-0.063
				c1.181-0.043,2.214-0.377,3.103-1.008c0.417-0.254,0.778-0.555,1.075-0.914c0.291-0.354,0.545-0.744,0.758-1.164
				c0.461-0.797,0.776-1.617,0.95-2.458c0.165-0.839,0.251-1.616,0.251-2.329c-0.043,0-0.073-0.01-0.096-0.031
				c-0.02-0.022-0.051-0.035-0.095-0.035c-0.169-0.04-0.36-0.08-0.571-0.121C188.463,103.273,188.211,103.211,187.92,103.127z"/>
			<path d="M54.853,99.163c0,14.949-12.171,27.069-27.182,27.069S0.49,114.112,0.49,99.163
				c0-14.952,12.17-27.07,27.181-27.07S54.853,84.211,54.853,99.163z M24.986,85.441c0-1.583-1.195-2.862-2.668-2.862
				c-1.467,0-2.657,1.279-2.657,2.862V103.6l-3.962-4.26c-1.039-1.114-2.724-1.114-3.77,0c-1.037,1.123-1.037,2.936,0,4.053
				l8.509,9.139c0.123,0.138,0.258,0.254,0.408,0.361c0.036,0.027,0.073,0.039,0.106,0.064c0.116,0.073,0.233,0.146,0.354,0.201
				c0.039,0.013,0.078,0.022,0.117,0.036c0.122,0.048,0.251,0.096,0.388,0.122c0.004,0.002,0.008,0.002,0.013,0.004
				c0.159,0.029,0.323,0.053,0.493,0.053c0.336,0,0.65-0.074,0.947-0.192c0.018-0.011,0.04-0.012,0.061-0.022
				c0.153-0.066,0.302-0.15,0.439-0.247c0.005-0.004,0.016-0.01,0.02-0.015c0.3-0.211,0.556-0.485,0.753-0.806
				c0.029-0.042,0.042-0.084,0.066-0.126c0.065-0.122,0.133-0.237,0.177-0.364c0.033-0.079,0.049-0.163,0.068-0.241
				c0.029-0.096,0.066-0.191,0.082-0.293c0.031-0.154,0.04-0.311,0.049-0.465c0-0.035,0.006-0.062,0.006-0.092V85.441z
				 M33.478,97.584c0-1.583-1.193-2.866-2.665-2.866c-1.466,0-2.658,1.283-2.658,2.86v12.932c0,1.581,1.192,2.863,2.658,2.863
				c1.472,0,2.665-1.282,2.665-2.863V97.584z M42.097,101.931c0-1.582-1.187-2.865-2.655-2.863
				c-1.473-0.001-2.671,1.281-2.671,2.863v8.579c0,1.581,1.198,2.863,2.671,2.863c1.469,0,2.655-1.282,2.655-2.863V101.931z"/>
   </g>
  )
}

export default logo_symbol
