
import React, { Component } from 'react'
import config from '../../../config'
import { capitalize } from '../../../utils'
import ProductDeals_1 from '../components/ProductDeals_1'
import ProductDeals_2 from '../components/ProductDeals_2'
import { Icon } from "../../Icons"

class ProductImage extends Component {
  render () {
    // NOTE: esto es un apaño mientras tenemos productos sin imagen.
    return (this.props.src === config.FAKE_BAGS[0] || this.props.src === config.FAKE_BAGS[1]) ? this.renderBag() : this.renderProduct()
  }

  /**
   * Renderiza un producto 'normal'
   */
  renderProduct () {
    var className = null
    var DivImage = null
    var product = this.props.product
    var DEAL =  null
    var text_deal = null
    if (typeof product !== "undefined"){
      if(typeof product.deal !== "undefined") {
        if (product.deal.id_type == 1){
              text_deal = product.deal.m +'x'+product.deal.n
              DEAL = <ProductDeals_1 deal={text_deal} type={product.deal.id_type} class1="prod_deal1"/>
        } else if (product.deal.id_type == 2){
              text_deal = "-"+product.deal.percent
              DEAL = <ProductDeals_2 deal={text_deal} type={product.deal.id_type} class_top="prod_deal2_top" class_down="prod_deal2_down"/>
        }
        else if (product.deal.id_type == 4){
              text_deal = product.deal.percent
              DEAL = <ProductDeals_1 deal={text_deal} type={product.deal.id_type} class1="prod_deal1"/>
        }

      }
    }
    if (this.props.filter === 1){
          className = "prod_filter"
            DivImage =
                <div className={ `product-image ${ this.props.className }` }>
                  { DEAL }
                  {  this.renderShadow() }
                  <img src={ `https://${ config.S3_IMAGES }/images/${ config.SHELF_IMAGE_SIZE }/${ this.props.src }` }
                       className= { className }
                       alt={ this.props.description || 'No description' }/>
                </div>
    }else {
          className = "prod"
          DivImage =
            <div className={ `product-image ${ this.props.className }` }>
              { DEAL }
              { (this.props.shadow === true) ? this.renderShadow() : null }
              { (this.props.product && this.props.product.ilusia === 1) ? this.renderIlusiaIcon() : null }
              <img src={ `https://${ config.S3_IMAGES }/images/${ config.SHELF_IMAGE_SIZE }/${ this.props.src }` }
                   className= { className }
                   alt={ this.props.description || 'No description' }/>
            </div>
    }
    return (DivImage)
  }

  /**
   * Renderiza un producto con bolsa de papas
   */
  renderBag () {
        var DEAL =  null
        var product = this.props.product
        var text_deal = null
        if (typeof product !== "undefined"){
          if(typeof product.deal !== "undefined") {
            if (product.deal.id_type == 1){
                  text_deal = product.deal.m +'x'+product.deal.n
                  DEAL = <ProductDeals_1 deal={text_deal} type={product.deal.id_type} class1="prod_deal1"/>
            } else if (product.deal.id_type == 2){
                text_deal = "-"+product.deal.percent
                  DEAL = <ProductDeals_2 deal={text_deal} type={product.deal.id_type} class_top="prod_deal2_top" class_down="prod_deal2_down"/>
            }
            else if (product.deal.id_type == 4){
                  text_deal = product.deal.percent
                  DEAL = <ProductDeals_1 deal={text_deal} type={product.deal.id_type} class1="prod_deal1"/>
            }

          }
        }
        var className = null
        var DivImage = null
        if (this.props.filter === 1){
              className = "prod_filter"
                DivImage =
                    <div className={ `product-image ${ this.props.className }` }>
                      { DEAL }
                      {  this.renderShadow() }
                      <img src={ `https://${ config.S3_IMAGES }/images/${ config.SHELF_IMAGE_SIZE }/${ this.props.src }` }
                           className= { className }
                           alt={ this.props.description || 'No description' }/>
                    </div>
        }else {
              const description = this.props.description.length > 40 ? this.props.description.substring(0, 40 - 3) + "..." : this.props.description
              const brand = this.props.brand.length > 12 ? this.props.brand.substring(0, 12 - 3) + "..." : this.props.brand

              DivImage =
                <div className={ this.props.calassName || 'product-image' }>
                  <p className="p regular s14 grey description">{ capitalize(description) }</p>
                  <p className="p bold s14 dark-grey brand">{ capitalize(brand) }</p>
                  { DEAL }
                  <img src={ `https://${ config.S3_IMAGES }/images/${ config.SHELF_IMAGE_SIZE }/${ this.props.src }` }
                       className="prod"
                       alt={ this.props.description || 'No description' }/>
                </div> 
         }
         return DivImage
  }


  renderShadow () {
    return <div className="shadow">
      {/* <svg preserveAspectRatio="xMinYMid">
        <defs>
          <filter id="reduce">
            <feComponentTransfer>
              <feFuncR type="linear" slope="0"/>
              <feFuncG type="linear" slope="0"/>
              <feFuncB type="linear" slope="0"/>
            </feComponentTransfer>
          </filter>
        </defs>
        <image className="product-shadow"
               filter="url(#reduce)"
               xmlnsXlink="http://www.w3.org/1999/xlink"
               xlinkHref={ `https://${ config.S3_IMAGES }/images/${ config.SHELF_IMAGE_SIZE }/${ this.props.src }` } />
      </svg> */}
      <img src={ `https://${ config.S3_IMAGES }/images/${ config.SHELF_IMAGE_SIZE }/${ this.props.src }` }
           className="product-shadow"
           alt="Sombra del producto" />
    </div>
  }

  renderIlusiaIcon () {
    const iconColor = config.COLORS.BLUE;
    return (
      <div className='product-image-ilusia'>
        <div className='ilusia-symbol-product'>
          <Icon
            type="logo_symbol_hand_complete"
            width="30"
            height="32"
            fill={ iconColor }
            viewport="0 0 60 60"></Icon>
        </div>
      </div>
    )
  }
}


export default ProductImage
