import React from 'react'

const allergen_4 = () => {
  return (
        <g>
           <path d="M129.845,92.245c-1.414,0.051-2.641,1.327-2.638,2.742c0.003,1.503,1.146,2.619,2.676,2.608
                c1.579-0.01,2.763-1.194,2.725-2.723C132.573,93.501,131.201,92.197,129.845,92.245z"/>
            <path d="M100.001,0c-55.229,0-100,44.771-100,99.999c0,55.229,44.771,100,100,100
                c55.228,0,99.998-44.771,99.998-100C199.999,44.772,155.229,0,100.001,0z M143.424,103.087c-7.28,9.954-17.052,15.772-29.239,17.55
                c-0.868,0.127-1.746,0.216-2.621,0.253c-0.996,0.044-1.995,0.011-2.993,0.011c-11.206-0.111-20.988-3.875-29.23-11.517
                c-2.248-2.085-3.456-2.126-5.77-0.103c-4.308,3.767-9.221,6.405-14.728,7.958c-1.073,0.303-2.069,0.371-2.857-0.546
                c-0.785-0.914-0.612-1.918-0.132-2.916c1.524-3.164,3.023-6.34,4.58-9.49c1.428-2.89,1.426-5.717-0.03-8.613
                c-1.577-3.136-3.039-6.332-4.557-9.499c-0.458-0.955-0.665-1.904,0.093-2.799c0.776-0.916,1.742-0.941,2.835-0.63
                c5.499,1.566,10.422,4.181,14.733,7.947c2.361,2.062,3.648,1.976,5.982-0.183c6.935-6.412,15.089-10.205,24.498-11.167
                c11.987-1.226,22.676,1.972,32.028,9.576c2.834,2.303,5.281,4.983,7.383,7.968C144.944,99.074,144.947,101.005,143.424,103.087z"/>
        </g>
  )
}

export default allergen_4