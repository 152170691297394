
import React, { Component } from 'react'

class ProductDeals extends Component {

  render () {

  	var text = null
  	var text2 = null
  	var classname = null
  	if (this.props.type == 3){
  		text = <p className="p bold white s20">{ this.props.deal }<span className="p regular white s12">%</span></p>
  		classname = "prod_deal1"
  	}else if (this.props.type == 1){
  		text = <p className="p bold yellow_deal s20">{ this.props.deal }</p>
  		classname = "prod_deal1"
  	}else if (this.props.type == 2){
  		text = <p className="p bold yellow_deal s20">{ this.props.deal } </p>
  		text2 = <p className="p bold white s10">{ this.props.deal } </p>
  		classname = "prod_deal2_top"
  	}

		return (
			<div className={classname}>
				{text}
				<div className="prod_deal2_down">
				{text2}
				</div>
			</div>
		)
	}
}

export default ProductDeals
