
import React from 'react'

const arrow_dropdown = () => {
  return (
  	<g>
     <polygon points="100.012,154.104 0,61.266 14.264,45.896 100.012,125.453 185.736,45.896 200,61.266"/>
  	</g>
  )
}

export default arrow_dropdown
