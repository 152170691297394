
import React from 'react'

const credit_card = () => {
  return (
    <g>
      <path d="M199.838,55.516V44.582c0-9.881-8.013-17.895-17.893-17.895H18.898C9.018,26.688,1,34.701,1,44.582v10.934
  			H199.838z"/>
  		<path d="M1,88.326v67.934c0,9.887,8.018,17.896,17.898,17.896h163.047c9.88,0,17.893-8.01,17.893-17.896V88.326H1z
  			 M96.529,150.295H28.182c-4.305,0-7.79-3.49-7.79-7.789s3.485-7.783,7.79-7.783h68.348c4.299,0,7.785,3.484,7.785,7.783
  			S100.828,150.295,96.529,150.295z M172.662,123.121H28.182c-4.305,0-7.79-3.49-7.79-7.789s3.485-7.783,7.79-7.783h144.48
  			c4.301,0,7.789,3.484,7.789,7.783S176.963,123.121,172.662,123.121z"/>
    </g>
  )
}

export default credit_card
