
import config from '../../config'

/**
 * @func: getExternalConfig
 * @return {[Promise]} resolve | reject
 *
 */

const getExternalConfig = () => {
  return new Promise((resolve, reject) => {
    config.API.externalConfigGet({}, {}, {})
      .then(result => resolve(result.data))
      .catch(error => reject(error) )
  })
}


export default getExternalConfig
