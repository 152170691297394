
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { AppStore, AuthStore } from '../../stores'
import { MyAccount, Preferences, LinkedAccounts, FinishedCarts, MyAddresses } from './views'

@observer
class ViewsWrapper extends Component {

  // componentDidMount(){
  //   AuthStore.getProcessedOrders();
  // }

  render () {
    return (
      <div className="wiews-wrapper box-md-9">
        <ReactCSSTransitionGroup transitionName="userzone"
                                 transitionEnterTimeout={ 600 }
                                 transitionLeaveTimeout={ 600 }>
          { this.renderContent() }
        </ReactCSSTransitionGroup>
      </div>
    )
  }


  renderContent () {
    switch (AppStore.userZoneView) {
      case 0:
        return <MyAccount key="0" />
        break;
      case 1:
        return <FinishedCarts key="1" />
        break;
      case 2:
        return <Preferences key="3" />
        break;
    }
  }
}

export default ViewsWrapper
