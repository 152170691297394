
import React, { Component } from 'react'
import config from '../../config'
import { UIStore } from '../../stores'
import { debounce } from '../../utils'
import { IconButton } from '../../components'
import QuantityBadge from '../Products/components/QuantityBadge'


class ProductDetailImage extends Component {

  componentDidMount () {
    window.addEventListener('resize', debounce(this.onResizeHandler, 250, true), false)
    this.onResizeHandler()

  }

  render () {
    return (this.props.src === config.FAKE_BAGS[0] || this.props.src === config.FAKE_BAGS[1]) ? this.renderBag() : this.renderProduct()
  }


  renderProduct () {
    if (this.props.hr === true) {
      return (
        <div ref="container" className={ this.props.className || 'product-detail-image' }>
          <div className="content-wrapper">
            <IconButton type="fab"
                        size="60"
                        color="grey"
                        iconSize="25"
                        iconType="zoom_plus"
                        onClick={ () => UIStore.showProductDetailFullImage(this.props.src) } />
            <img src={ `https://${ config.S3_IMAGES }/images/${ config.DETAIL_IMAGE_SIZE }/${ this.props.src }` }
            alt={ this.props.description || 'No description' } />
            <QuantityBadge units={ this.props.units } />
          </div>
        </div>
      )
    } else {
      return (
        <div ref="container" className={ this.props.calassName || 'product-detail-image' }>
          <div className="content-wrapper">
            <img src={ `https://${ config.S3_IMAGES }/images/${ config.DETAIL_IMAGE_SIZE }/${ this.props.src }` }
            alt={ this.props.description || 'No description' } />
            <QuantityBadge units={ this.props.units } />
          </div>
        </div>
      )
    }
  }


  /**
   * Renderiza un producto con bolsa de papas
   */
  renderBag () {
    return (
      <div ref="container" className={ this.props.calassName || 'product-detail-image' }>
        <img src={ `https://${ config.S3_IMAGES }/images/${ config.DETAIL_FAKE_BAG_IMAGE_SIZE }/${ this.props.src }` }
             className="prod"
             alt={ this.props.description || 'No description' }/>
        <QuantityBadge units={ this.props.units } />
      </div>
    )
  }


  onResizeHandler = (event) => {
    const width = window.innerWidth
    this.container = this.refs.container || this.container

    if (width >= 1200)
      this.container.style.width = ((width * 0.5) - ((width - 1200) * 0.5)) - 60 + 'px'

    if (width >= 1024 && width < 1200)
      this.container.style.width = ((width * 0.5) - ((width - 980) * 0.5)) - 60 + 'px'

    if (width >= 768 && width < 1024)
      this.container.style.width = ((width * 0.5) - ((width - 760) * 0.5)) - 60 + 'px'

    if (width < 768)
      this.container.style.width = '100%'
  }
}

export default ProductDetailImage
