import React, { Component } from "react";
import { observer } from "mobx-react";
import { AppStore, ShopStore, UIStore } from "../stores";
import {
  ShoppingCartHeader,
  ShoppingCartGrid,
  ShoppingCartTicket,
  EmptyCart,
  OrderInProgress,
} from "../components";
import {
  ShoppingCartConflictsAlert,
} from "../components/Dialogs/Alert/components";

@observer
class ShoppingCart extends Component {
  state = {
    products: []
  }

  componentDidMount() {
    console.log("Did mount------------------------------");
    this.setState({
      products: ShopStore.products
    })
    const filteredProducts = ShopStore.allProducts
    const productsArray = Array.from(ShopStore.products);
    if (filteredProducts.length != productsArray.length && !UIStore.isAlertOpen) {
      UIStore.toggleAlert({
        type: 'default',
        content: () => <ShoppingCartConflictsAlert onConfirm={() => {
          UIStore.toggleAlert()
          UIStore.showLoading()
          ShopStore.clearCartConflicts(() => { 
            this.setState({
              products: ShopStore.products
            })
            UIStore.hideLoading()
          })
        }} />
      })
    }
  }

  render() {
    // NOTE: esto es un apaño para forzar el renderizado cuando borramos un producto!
    // TODO: La idea es que pasemos las funciones de las opciones del producto desde
    // este componente y no desde CartProduct. De este modo podemos forzar el render.
    this.units = ShopStore.units;
    // NOTE: Control del tipo de vista
    this.isGrid = ShopStore.isGrid;
    this.isEmptyCart = ShopStore.isEmptyCart;
    this.isInProgress = ShopStore.isInProgress;
    this.idProvider = AppStore.idProvider;


    return (
      <div className="section shopping-cart">
        <div className="container">
          <ShoppingCartHeader />
          {this.units === 0 ? (
            <EmptyCart />
          ) : this.idProvider == 6 && this.idProvider == 5 ? (
            <EmptyCart />
          ) : !this.isInProgress ? (
            !this.isGrid ? (
              <ShoppingCartTicket
                products={this.state.products}
                units={this.units}
                provider={AppStore.provider}
              />
            ) : (
              <ShoppingCartGrid products={this.state.products} units={this.units} />
            )
          ) : (
            <OrderInProgress />
          )}
        </div>
      </div>
    );
  }
}

export default ShoppingCart;

// (!ShopStore.isEmptyCart)
// ? (this.isGrid)
//   ? <ShoppingCartGrid products={ ShopStore.products } />
//   : <ShoppingCartTicket products={ ShopStore.products } />
// : <EmptyCart />
