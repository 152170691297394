
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { AccordionButton } from './'
import { Button, LinkButton } from '../../../'
import { UIStore, AuthStore, Tracking } from '../../../../stores'
import config from '../../../../config'
import { postProviderCredentials, deleteProviderCredentials } from '../../../../utils/api'
import { Notification } from '../../../Dialogs/Notification/components'
import SignupDia from '../../../../components/Dialogs/Modal/modals/SignupDia'
import SignupMas from '../../../../components/Dialogs/Modal/modals/SignupMas'
import FinisOrderDIAModal from '../../../../components/Dialogs/Modal/modals/FinisOrderDIAModal'
import SuccessMas from '../../../../components/Dialogs/Modal/modals/SuccessMas'


@observer
class AccordionItem extends Component {

  state = {
    email: (this.props.provider.status === 1) ? this.props.provider.login : '',
    password: (this.props.provider.status === 1) ? this.props.provider.pass : '',
    status: this.props.provider.status
  }

  link = undefined
  unlink = undefined
  not = undefined


  componentDidMount () {
    this.link = document.querySelector(`#link_${ this.props.provider.id }`)
    this.unlink = document.querySelector(`#unlink_${ this.props.provider.id }`)
    this.not = document.querySelector(`#not_${ this.props.provider.id }`)
    window.setTimeout(() => {
      this.switchButtons(this.state)
      // if (this.state.status === 1) {
      //   this.link.style.display = 'none'
      //   this.unlink.style.display = 'inline-block'
      //   this.not.style.display = 'none'
      // } else {
      //   this.link.style.display = 'inline-block'
      //   this.unlink.style.display = 'none'
      //   this.not.style.display = 'inline-block'
      // }
      window.clearTimeout(this)
    })
  }

  componentWillUpdate(nextProps, nextState) {
    this.switchButtons(nextState)
  }


  render () {
    const link = config.AVAILABLE_PROVIDERS.filter(provider => provider.id === this.props.provider.id)[0].URL || 'https://market.ilusia.com'
    var button = null
    if (parseInt(this.props.provider.id) == 5 || parseInt(this.props.provider.id) == 6){
      button = <Button placeholder={ `Registrar en ${ this.props.provider.title }` }
                        type="flat"
                        color="blue"
                        onClick={ () => this.openRegisterForm(this.props.provider.id) } />
    } else {
      button = <LinkButton placeholder={ `Ir a ${ this.props.provider.title }` }
                        type="flat"
                        color="blue"
                        target="blank"
                        onClick={ link } />
    }


    return (
      <div className={ `accordion-item ${ (this.props.current === this.props.id) ? 'active' : '' }` }>
        <AccordionButton name={ this.props.provider.title }
                         icon={ this.props.provider.icon }
                         bubblecolor={ this.props.provider.bubblecolor }
                         status={ this.state.status }
                         onClick={ this.props.onClick.bind(this, this.props.id) } />
        <div className="accordion-item-content">
          <input type="email"
                 ref="email"
                 placeholder="Introduce tu correo electrónico"
                 className="user-zone_input"
                 value={ this.state.email }
                 onChange={ this.onInputChange } />
          <input type="password"
                 ref="password"
                 placeholder="Introduce tu contraseña"
                 className="user-zone_input"
                 value={ this.state.password }
                 onChange={ this.onInputChange } />
          <Button placeholder="Vincular cuenta"
                  id={ `link_${ this.props.provider.id }` }
                  color="blue"
                  type="outline"
                  onClick={ this.linkAccount } />
          <Button placeholder="Desvincular"
                  id={ `unlink_${ this.props.provider.id }` }
                  color="grey"
                  type="outline"
                  onClick={ this.unlinkAccount } />
          <div className="not-account" id={ `not_${ this.props.provider.id }` } >
            <p className="p regular grey s16">No dispongo de cuenta</p>
            { button }
          </div>
        </div>
      </div>
    )
  }

/**
* Abre IFrame para el registro
*/

openRegisterForm(idprovider){
    if (idprovider == 5){
     UIStore.toggleModal({
              content: () => <SignupDia onSubmit={ AuthStore.singUpDia }/>
      })
      /*UIStore.toggleModal({ clickOnBackgroundClose: false,
              content: () => <SuccessMas />
      })*/

      
    }else if (idprovider == 6){
     UIStore.toggleModal({
              content: () => <SignupMas onSubmit={ AuthStore.singUpMas }/>
      })
     
      
    }
}




  switchButtons (state) {
    if (state.status === 1) {
      this.link.style.display = 'none'
      this.unlink.style.display = 'inline-block'
      this.not.style.display = 'none'
    } else {
      this.link.style.display = 'inline-block'
      this.unlink.style.display = 'none'
      this.not.style.display = 'inline-block'
    }
  }


  /**
   * Maneja el evento input
   */
  onInputChange = (event) => {
    this.setState({
      email: this.refs.email.value,
      password: this.refs.password.value
    })
  }

  /**
   * Vincula la cuenta de usuario con su cuenta en un proveedor
   */
  linkAccount = (event) => {
    UIStore.showLoading()
    postProviderCredentials(this.props.id, this.state.email, this.state.password)
    .then(result => {
      UIStore.hideLoading()
      if (result.result === 0) {
        UIStore.showNotification({ type: 'danger', content: () => <Notification message="Error al vincular la cuenta, inténtalo de nuevo." /> })
      } else {
        UIStore.showNotification({ type: 'success', content: () => <Notification message="Tu cuenta ha sido vinculada con éxito." /> })
        this.props.provider.status = 1
        this.setState({ status: 1 })
        // Tracking.provider_account_linked(AuthStore.user.email, this.props.provider.title)

      }
    })
  }


  /**
   * Desvincula la cuenta de usuario con su cuenta en un proveedor
   */
  unlinkAccount = () => {
    UIStore.showLoading()
    deleteProviderCredentials(this.props.id, this.state.email, this.state.password)
    .then(result => {
      UIStore.hideLoading()
      if (result.result === 0) {
        UIStore.showNotification({ type: 'danger', content: () => <Notification message="Error al desvincular la cuenta, inténtalo de nuevo." /> })
      } else {
        UIStore.showNotification({ type: 'success', content: () => <Notification message="Tu cuenta ha sido desvinculada con éxito." /> })
        this.props.provider.status = 0
        this.setState({
          email: '',
          password: '',
          status: 0
        })
      }
    })
  }
}

export default AccordionItem
