
import React, { Component } from 'react'
import { Link } from 'react-router'
import config from '../../config'
import { AppStore } from '../../stores'
import { urliFy, capitalize, truncate } from '../../utils'
import ProductDeals_1 from '../Products/components/ProductDeals_1'
import ProductDeals_2 from '../Products/components/ProductDeals_2'

class SuggestedProduct extends Component {

  render () {
    const { description, price, type_price, id_product_eva, brand } = this.props.product
    const currency = (type_price === 1) ? ' €' : ' $'
    let { image } = this.props.product
    if (image === null) image = config.FAKE_BAGS[(Math.floor(Math.random() * 9) % 2)]
    var DEAL =  null
    var text_deal = null
    var product = this.props.product
    if (typeof product !== "undefined"){
      if(typeof product.deal !== "undefined") {
        if (product.deal.id_type == 1){
              text_deal = product.deal.m +'x'+product.deal.n
              DEAL = <ProductDeals_1 deal={text_deal} type={product.deal.id_type} class1="prod_deal1_suggested"/>
        } else if (product.deal.id_type == 2){
            text_deal = "-50"
              DEAL = <ProductDeals_2 deal={text_deal} type={product.deal.id_type} class_top="prod_deal2_top_suggested" class_down="prod_deal2_down_suggested" />
        }
        else if (product.deal.id_type == 4){
              text_deal = product.deal.percent
              DEAL = <ProductDeals_1 deal={text_deal} type={product.deal.id_type} class1="prod_deal1_suggested"/>
        }

      }
    }
    return (
      <li className="suggested-product">
       
        <Link to={ `/producto/${ id_product_eva }/${ urliFy(description) }` }>

          <img src={ `https://${ config.S3_IMAGES }/images/${ config.SHELF_IMAGE_SIZE }/${ image }` }
               alt={ description || 'No description' }/>
          { DEAL }
          <p className="description p light black s16">{ truncate(capitalize(description), 50) }
            <br/>
            <span className="p light grey s14">{ brand }</span>
            <br/>
            <span className="p regular black s16">{ price.toFixed(2) } { currency }</span>
          </p>
        </Link>
      </li>
    )
  }
}

export default SuggestedProduct
