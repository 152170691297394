
/**
 * @func: onLeaveOrderDetail
 * @param {[Object]} nextState
 * @param {[Function]} replaceState
 *
 * @description:
 */

import React from 'react'
import { OrderStore } from '../../stores'

const onLeaveOrderDetail = (nextState, replaceState) => {
  OrderStore.reset()
}

export default onLeaveOrderDetail
