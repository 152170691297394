
import React from 'react'

const edit = () => {
  return (
    <g>
      <path d="M6.106,168.467v25.846H31.95L158.563,67.705L132.72,41.854L6.106,168.467z M190.658,35.613
       c4.315-4.314,4.315-11.289,0-15.604L180.41,9.76c-4.315-4.314-11.284-4.314-15.604,0l-20.248,20.254l25.846,25.852L190.658,35.613z
       "/>
       <path fill="none" d="M0.581,1h198.838v198.836H0.581V1z"/>
    </g>
  )
}

export default edit
