
import React, { Component } from 'react'
import { browserHistory } from 'react-router'
import { UIStore, AppStore, Tracking, AuthStore } from '../../../../stores'
import { Button, IconButton } from '../../../'
import config from '../../../../config'

/**
 *
 */

class SuccessMas extends Component {


  render () {
    return (
      <div className="MasSuccess">
        <div className="header">
          <img className="IMGcheck" src='/img/checkicon.png' alt="ALT_TEXT_GOES_HERE"/>
          <img className="IMGcart" src={ (AppStore.providername) ? `/img/carts/${ AppStore.providername.toLowerCase() }.png` : null } alt="ALT_TEXT_GOES_HERE"/>
        </div>
        <p className="p bold black s21"> !Pedido realizado correctamente! </p>
        <p className="p bold black s17"> Número de pedido de MAS: { this.props.OrderID } </p>
        <div className="container">
          <p className="p dark s13"> Recuerda que podrás comprobar el estado de tu pedido en "Mis Pedidos" dentro de la web de supermercados MAS.</p>
        </div>
        <Button placeholder="Volver a la tienda"
                color="blue"
                onClick={this.finish} />
        </div>
    )
  }

  finish = (event) =>{
    UIStore.hideModal()

    // Tracking.order_finished(AuthStore.user.email, AppStore.lastOrderID, AppStore.providername, AppStore.CatInformationMas.prices.shopping_price.toFixed(2), AppStore.ccid)

    browserHistory.push(`/${ AppStore.countryname }/${ AppStore.postalcode }/${ AppStore.providername }`)
  }

}
export default SuccessMas
