
import React, { Component } from 'react'
import { browserHistory } from 'react-router'
import { translate } from 'react-i18next'
import config from '../config'
import { AppStore, UIStore } from '../stores'
import { getPostalCode } from '../utils'
import { Button, Icon } from '../components'
import { Notification } from '../components/Dialogs/Notification/components'


@translate(['common'], { wait: false })
class ZoneSelection extends Component {

  state = {
    cp: '',
    geolocating: 'location'
  }

  componentDidMount () {
    window.setTimeout(() => {
      this.refs.email.focus()
      window.clearTimeout(this)
    })
  }

  render () {
    return (
      <div className="section zoneselection">
        <div className="absolute-center">
          <Icon className="logo" type="logo_symbol_basic" width="200" height="70" fill="white" viewport="0 0 200 70"></Icon>
          <h1 className="h2 s26 light white">The Smart Shopping Experience</h1>

          <form>
            {/* http://html5pattern.com/Postal_Codes */}
            <input ref="email"
                   className="input dark"
                   type="text"
                   autoFocus
                   placeholder="Código postal"
                   value={ this.state.cp }
                   pattern="((0[1-9]|5[0-2])|[1-4][0-9])[0-9]{3}"
                   onChange={ this.handleInputChange } />
            <Button placeholder="Ver tiendas" color="green" onClick={ this.checkSelectedOptions } />

            <button className={ `btn-location ${ this.state.geolocating }` } onClick={ this.getLocation }>
              <Icon type={ (this.state.geolocating === 'location') ? 'location' : 'compass' } fill="dark-white" size="24"></Icon>
            </button>
          </form>
        </div>
      </div>
    )
  }


  /**
   * @description Controla el evento 'change' del input
   */
  handleInputChange = (event) => {
    this.setState({ cp: event.target.value })
  }


  /**
   * @description Comprueba el codigo postal introducito
   */
  checkSelectedOptions = (event) => {
    event.preventDefault()

    const cpRegExp = /(^([0-9]{5,5})|^)$/

    if (!cpRegExp.test(this.state.cp) || /\s/g.test(this.state.cp) || this.state.cp === '') {
      // TODO: crear esta notificación
      UIStore.showNotification({ type: 'danger', content: () => <Notification message="Lo sentimos pero el código postal introducido no existe." /> })
      this.setState({ cp: '' })
    } else {
      AppStore.idcountry = 1  // FIX
      AppStore.countryname = 'España'  // FIX
      AppStore.postalcode = this.state.cp
      browserHistory.push(`${ AppStore.countryname }/${ AppStore.postalcode }`)
    }
  }


  getLocation = (event) => {
    event.preventDefault()
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.getPosition.bind(this))
      this.setState({ geolocating: 'loading' })
    } else {
      /* TODO: lanzamos la alerta */
    }
  }


  /**
   * Obtiene el CP del usuario
   *
   * TODO: parsear datos antes de que lleguen aquí
   * @param  {Object} position
   */
  getPosition (position) {
    let lat = position.coords.latitude
    let lon = position.coords.longitude

    getPostalCode(lat, lon).then(data => {
      this.setState({
        cp: data.address_components[0].long_name,
        geolocating: 'location'
      })
    }).catch((error) => {
      // console.error(error)
    })
  }
}

export default ZoneSelection
