
import React from 'react'

const symbol = () => {
 return (
   <g>
     <path d="M100,0.583c-54.908,0-99.418,44.506-99.418,99.42c0,54.902,44.51,99.416,99.418,99.416
     	s99.418-44.514,99.418-99.416C199.418,45.088,154.908,0.583,100,0.583z M82.941,146.141c0,0.111-0.062,0.203-0.066,0.316
     	c-0.023,0.684-0.231,1.328-0.404,1.996c-0.135,0.529-0.195,1.072-0.414,1.559c-0.229,0.498-0.605,0.9-0.918,1.359
     	c-0.391,0.555-0.734,1.133-1.229,1.592c-0.084,0.08-0.115,0.189-0.201,0.264c-0.457,0.398-1.002,0.598-1.512,0.895
     	c-0.407,0.238-0.764,0.535-1.207,0.713c-1.111,0.441-2.264,0.697-3.436,0.697h-0.004l0,0c-1.334,0-2.592-0.293-3.737-0.801
     	c-0.265-0.111-0.472-0.344-0.726-0.484c-0.879-0.484-1.697-1.035-2.379-1.758c-0.068-0.086-0.178-0.107-0.246-0.189l-32.311-37.164
     	c-3.404-3.91-2.985-9.844,0.928-13.246c3.912-3.398,9.852-2.982,13.248,0.928l15.83,18.209V53.856c0-5.182,4.209-9.389,9.393-9.389
     	c5.182,0,9.391,4.207,9.391,9.389V146.141z M115.244,146.141c0,5.182-4.209,9.391-9.391,9.391c-5.184,0-9.393-4.209-9.393-9.391
     	V86.997c0-5.182,4.209-9.389,9.393-9.389c5.182,0,9.391,4.207,9.391,9.389V146.141z M147.547,146.141
     	c0,5.182-4.209,9.391-9.391,9.391c-5.184,0-9.393-4.209-9.393-9.391V109.09c0-5.184,4.209-9.39,9.393-9.39
     	c5.182,0,9.391,4.207,9.391,9.39V146.141z"/>
   	</g>
  )
}

export default symbol
