import config from '../../config'

/**
 * @func: postAddress
 * @return {[Object]} data
 *
 * @description: Crear dirreccion del usuario logueado
 */

const postAddress = (address) => {
  const params = {
    firstname: 'Daniel',
    lastname: 'Romero',
    address_1: 'Calle ABD',
    address_2: 'Primero IZQ',
    address_3: 'PPPP',
    city: 'Sevilla',
    postcode: '41009',
    alias: 'DANI5',
    nif: '28935856L',
    province: 'Seville',
    phone: '954000000'
  }
  return config.API.addressesPost({},address, {})
  .then(result => result)
  .catch(error => error)
}


export default postAddress
