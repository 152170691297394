import React, { Component } from 'react'
import { translate } from 'react-i18next'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { LinkIconButton, Icon } from '../'
import { AppStore } from '../../stores'
import config from '../../config'
import { capitalize } from '../../utils'
import { Link } from 'react-router'

@translate(['common'], { wait: false })
class FiltersButton extends Component {

      state = {
            isOver: false
      }

      render () {
          return (
              <div className="tooltip customer-support">
                  { this.renderFiltersButton() }
              </div>
          )
      }

      renderFiltersButton () {
          if (AppStore.provider != null){
              if (AppStore.provider.allergen == 1) {
                  return (

                        <LinkIconButton type="fab" color="blue" iconType="allergens" iconTitle="ALT_ICONO_AQUI" iconSize="20"
                                              onMouseEnter={ this.onMouseEnterHandler }
                                              onMouseLeave={ this.onMouseLeaveHandler }
                                              onClick= {`/filtros`}
                                              />
                  )
              }
          }
      }

}

export default FiltersButton
