
import React from 'react'

const cart_dia = () => {
  return (
    <g>
      <path d="M162.976,37.116v93.975c0,17.549-14.247,31.789-31.837,31.789H37.024V68.908
        c0-17.547,14.25-31.792,31.833-31.792L162.976,37.116 M162.976,20.126H68.858C41.917,20.126,20,42.007,20,68.908v93.972
        c0,9.38,7.621,16.994,17.024,16.994h94.115c26.942,0,48.861-21.882,48.861-48.783V37.116C180,27.734,172.38,20.126,162.976,20.126
        L162.976,20.126z M71.597,52.703c-9.699,0-17.551,7.838-17.551,17.525c0,9.679,7.852,17.523,17.551,17.523
        c9.689,0,17.545-7.844,17.545-17.523C89.142,60.54,81.286,52.703,71.597,52.703z M128.762,111.909
        c-9.69,0-17.548,7.845-17.548,17.52c0,9.679,7.858,17.533,17.548,17.533c9.696,0,17.55-7.854,17.55-17.533
        C146.312,119.754,138.458,111.909,128.762,111.909z M55.103,145.461h31.559l59.929-91.346h-31.562L55.103,145.461z"/>
    </g>
  )
}

export default cart_dia
