import React from 'react'

const welfare_4 = () => {
  return (
    <g>
    <path d="M100,0C44.769,0,0,44.767,0,100.002C0,155.233,44.769,200,100,200c55.229,0,100-44.766,100-99.998
    C200,44.767,155.229,0,100,0z M142.015,106.882c-11.797,14.104-28.405,30.243-42.015,40.773
    c-13.61-10.53-30.22-26.669-42.014-40.773c-8.072-9.995-8.782-25.698,0.822-36.014c10.359-9.67,26.178-8.979,35.872-0.823
    c1.735,1.52,3.512,3.096,5.321,4.729c1.808-1.632,3.586-3.209,5.317-4.729c9.694-8.153,25.519-8.845,35.874,0.823
    C150.796,81.19,150.086,96.887,142.015,106.882z"/>
</g>
  )
}

export default welfare_4