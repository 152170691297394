import React, { Component } from 'react';
import { observer } from "mobx-react";
import { translate } from "react-i18next";
import { ShopStore, UIStore, TrackingApartments, AuthStore } from '../stores';


class OrderPayment extends Component{

  componentDidMount(){
    window.addEventListener("message", this.handleOrderPaymentStatus);
  }

  handleOrderPaymentStatus(event){
    if (event.data === 'order_payment_success'){
      TrackingApartments.order_complete(AuthStore.apartmentId);
    }

    if (event.data === 'order_payment_error'){
      TrackingApartments.order_error_payment(AuthStore.apartmentId);
    }
    
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handleOrderPaymentStatus);
  }

  render(){
    return(
      <div className="section order-payment">
        <div className="container-payment">
          <iframe 
            src={ShopStore.url}
            className="iframe"
          />
        </div>
      </div>
    )
  }
}

export default OrderPayment;