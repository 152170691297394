
/*****************************************************************************
 * @class: Tracking
 *
 * @description: Class for tracking
 *
 *****************************************************************************/

import React from 'react'
import { browserHistory } from 'react-router'
import ReactGA from 'react-ga'
import { AppStore } from './'
import md5 from 'crypto-js/md5';
import config from '../config'


class Tracking {

	constructor () {
    
  	}

  	C4M_URL = "https://r.crazy4mediamobile.com/il/notif.axd"


  	Lead (id, ccid) {
  		const id_user = md5(id)
  		const url = this.C4M_URL+'?type=reg&status=pending&provider=&ccid='+ccid+'&id='+id_user
  		if (config.C4M_ENABLE && ccid !=null){
  			this.HTTP_send_value('GET',url,'C4M_Lead')
  		}
	}
	access_market_mobile (device) {
  		const category ='ilusia'
	    const action = 'access_market_mobile'
	    const label = 'Accedido a market.ilusia.com desde movil ' +device +', redirigido'
	    this.create_GA_event(category, action, label)
	    console.info(`%cSe ha accedido al market desde el movil ` + device, 'color: blue; font-weight: bold')
  	}

	order_without_login (provider) {
  		const category ='ilusia'
	    const action = 'order_without_login'
	    const label = 'Finalización de pedido sin login en ilusia: ' + provider
	    this.create_GA_event(category, action, label)
	    console.info(`%cAnalytics finalización de pedido sin login en ` + provider, 'color: blue; font-weight: bold')
  	}

  	order_without_provider_account (id, provider) {
  		const id_user = md5(id)
  		const category ='ilusia'
	    const action = 'order_without_provider_account'
	    const label = 'Finalización de pedido sin cuenta vinculada en ' + provider + ', usuario: ' +id_user
	    this.create_GA_event(category, action, label)
	    console.info(`%cAnalytics pedido sin cuenta vinculada en ` + provider, 'color: blue; font-weight: bold')
  	}

  	provider_account_linked (id, provider) {
  		const id_user = md5(id)
  		const category ='ilusia'
	    const action = 'provider_account_linked'
	    const label = 'Vinculación de cuenta en ' + provider + ', usuario: ' +id_user
	    this.create_GA_event(category, action, label)
	    console.info(`%cAnalytics Vinculación de cuenta en ` + provider, 'color: blue; font-weight: bold')
  	}

  	ilusia_register (id, ccid) {
  		const id_user = md5(id)
  		const category ='ilusia'
	    const action = 'ilusia_register'
	    const label = 'Usuario registrado en ilusia transacción: ' + ccid + ', usuario: ' +id_user
	    this.create_GA_event(category, action, label)
	    console.info(`%cAnalytics ilusia_register added`, 'color: blue; font-weight: bold')
  		if (config.C4M_ENABLE && ccid !=null){
  			const category ='C4M'
	    	const action = 'C4M_ilusia_register'
	    	const label = 'Usuario registrado en ilusia transacción: ' + ccid + ', usuario: ' +id_user
	    	this.create_GA_event(category, action, label)
	    	console.info(`%cAnalytics C4M_ilusia_register added`, 'color: blue; font-weight: bold')
  			const url = this.C4M_URL+'?type=reg&status=success&provider=&ccid='+ccid+'&id='+id_user
  			this.HTTP_send_value('GET',url,'C4M_ilusia_register')
  		}
  	}

  	provider_register (id, provider, ccid) {
  		const id_user = md5(id)
  		const category ='ilusia'
	    const action = 'provider_register'
	    const label = 'Usuario registrado en ' +provider+ ', transacción: ' + ccid + ', usuario: ' +id_user
	    this.create_GA_event(category, action, label)
	    console.info(`%cAnalytics provider_register added`, 'color: blue; font-weight: bold')
  		if (config.C4M_ENABLE && ccid !=null){
  			const category ='C4M'
	    	const action = 'C4M_provider_register'
	    	const label = 'Usuario registrado en ' +provider+ ', transacción: ' + ccid + ', usuario: ' +id_user
	    	this.create_GA_event(category, action, label)
  			const url = this.C4M_URL+'?type=reg&status=success&provider='+provider+'&ccid='+ccid+'&id='+id_user
  			this.HTTP_send_value('GET',url,'C4M_provider_register')
  		}
  	}

  	order_exported (id, orderId, provider, total, ccid) {
  		const id_user = md5(id)
  		const category ='ilusia'
	    const action = 'order_exported'
	    const label = 'Carrito exportado en '+provider+', OrderId: ' + orderId + ', total: ' + total +', transacción: ' + ccid + ', usuario: ' +id_user
	    this.create_GA_event(category, action, label)
	    console.info(`%cAnalytics order_exported added`, 'color: blue; font-weight: bold')
  		if (config.C4M_ENABLE && ccid !=null){
  			const category ='C4M'
	    	const action = 'C4M_order_exported'
	    	const label = 'Carrito exportado en '+provider+', OrderId: ' + orderId + ', total: ' + total +', transacción: ' + ccid + ', usuario: ' +id_user
	    	this.create_GA_event(category, action, label)
  			const url = this.C4M_URL+'?type=order&status=pending&provider='+provider+'&ccid='+ccid+'&id='+id_user+'&orderId='+orderId+'&total='+total.toString()
  			this.HTTP_send_value('GET',url,'C4M_order_exported')
  		}

  	}

  	order_finished (id, orderId, provider, total, ccid) {
  		const id_user = md5(id)
	    const category ='ilusia'
	    const action = 'order_finished'
	    const label = 'Pedido finalizado en '+provider+', OrderId: ' + orderId + ', total: ' + total +', transacción: ' + ccid + ', usuario: ' +id_user
	    this.create_GA_event(category, action, label)
	    console.info(`%cAnalytics order_finished added`, 'color: blue; font-weight: bold')
  		if (config.C4M_ENABLE && ccid !=null){
  			const category ='C4M'
	    	const action = 'C4M_order_finished'
	    	const label = 'Pedido finalizado en '+provider+', OrderId: ' + orderId + ', total: ' + total +', transacción: ' + ccid + ', usuario: ' +id_user
	    	this.create_GA_event(category, action, label)
  			const url = this.C4M_URL+'?type=order&status=success&provider='+provider+'&ccid='+ccid+'&id='+id_user+'&orderId='+orderId+'&total='+total.toString()
  			this.HTTP_send_value('GET',url,'C4M_order_finished')
  		}
  	}

  	create_GA_event(str_category, str_action, str_label){
  		ReactGA.event({
              category: str_category,
              action: str_action,
              label: str_label,
              nonInteraction: true
  		})
  	}

  	HTTP_send_value(method, url, classname){
  		var req = new XMLHttpRequest();
  		req.open(method, url, true);
  		req.onreadystatechange = function (aEvt) {
	  		if (req.readyState == 4) {
	     		if(req.status == 200)
	      			console.log(classname + " was sended with the url "+ url);
	     		else
	      			console.log(classname + " error loading page " + url);
	  		}
		};
		req.send(null);
  	}


}

export default new Tracking()