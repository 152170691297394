import config from '../../config'

/**
 * @func: getAddresses
 * @return {[Object]} data
 *
 * @description: Obtiene las dirrecciones del usuario en su cuenta Ilusa 
 */

const getAddresses = () => {
  const params={}
  return config.API.addressesGet({},{}, {})
  .then(result => result.data)
  .catch(error => error)
}

export default getAddresses