
import config from '../config'


/**
 * @func: formatDate
 *
 * @param {String}
 */

const formatDate = (source) => {
  // console.log(source)
  // source = 'Thu Jan 8 2017 08:39:51 GMT+0200 (CEST)'
  const date = new Date(source).toLocaleDateString('es-ES')
  const now = new Date().toLocaleDateString('es-ES')
  let days = calcDaysBetween(date, now)

  let string = ''
  if (days <= 0) {
    string = `Hoy`
  } else if (days === 1) {
    string = `Ayer`
  } else {
    string = `Hace ${ days } días`
  }
  return string
}

const calcDaysBetween = (date, now) => {
  const dateSplitted = date.split('/')
  const nowSplitted = now.split('/')
  
  const dateToUTC = Date.UTC(dateSplitted[2], dateSplitted[1]-1, dateSplitted[0])
  const nowToUTC = Date.UTC(nowSplitted[2], nowSplitted[1]-1, nowSplitted[0])
  const diff = nowToUTC - dateToUTC
  const days = Math.floor(diff / (1000 * 60 * 60 * 24))
  return days
}

export default formatDate
