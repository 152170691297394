
import config from '../../config'

/**
 * @func: getProviders
 * @return {[Object]} data
 *
 * @description:
 */

const getProviders = (postalcode, idcountry, idprovider = '') => {
  const params = { postal_code: postalcode, id_country: idcountry, id_provider: idprovider }
  return config.API.providersGet(params, {}, {})
  .then(result => result.data)
  .catch(error => error)
}


export default getProviders
