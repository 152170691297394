import React, { Component } from "react";
import { Link, browserHistory } from "react-router";
import { translate } from "react-i18next";
import { Icon, Button, LinkButton, UserIcon } from "../../";
import { AppStore, AuthStore, SyncStore } from "../../../stores";
import OrderButton from "./components/OrderButton";

@translate(["common"], { wait: false })
class UserLogged extends Component {
  render() {
    const conected = AppStore.postalcode
      ? `Conectado para ${AppStore.postalcode}`
      : "";

    return (
      <div className="user-logged">
        {this.renderOrdersList()}

        <div className="userdata-wrapper">
          <ul>
            <li>
              <UserIcon
                letter={AuthStore.user.firstname.charAt(0)}
                color="#7B529C"
              />
            </li>
            <li>
              <h3 className="h3 bold black s16">
                {AuthStore.user.firstname} {AuthStore.user.lastname}
              </h3>
              <Link className="btn-cp" to={AppStore.postalcode ? "/" : null}>
                <p className="p regular grey s14">{conected}</p>
              </Link>
              <LinkButton
                placeholder="Mi cuenta"
                type="flat"
                color="blue"
                onClick="/zona-usuario"
              />
              <Button
                placeholder="Cerrar sesión"
                type="flat"
                color="blue"
                onClick={AuthStore.logOut}
              />
            </li>
          </ul>
        </div>
      </div>
    );
  }

  /**
   * Renderiza el listado de carritos abiertos para un usuario logueado.
   *
   * TODO: terminar esto cuando sincronizemos con AWS Cognito
   */
  renderOrdersList() {
    const carts = SyncStore.openCarts.map((cart) => (
      <OrderButton
        id={cart.id}
        icon={cart.icon}
        bubblecolor={cart.color}
        units={cart.units}
        date={cart.date}
        link={cart.link}
        onClick={() => this.goToOpenCart(cart.link)}
        key={"key" + cart.id}
      />
    ));
    console.log("carts", SyncStore.openCarts);
    return (
      <div className="orders-wrapper">
        {carts}
        <Button
          className="new"
          placeholder="+ Comenzar nueva compra"
          type="flat"
          color="blue"
          onClick={null}
        />
      </div>
    );
  }

  goToOpenCart = (url) => {
    AppStore.resetStoreData([
      "postalcode",
      "providers",
      "categories",
      "layouts",
      "shop",
      "detail",
    ]);
    AppStore.resetConfigData([
      "SHOP_CONFIG",
      "PROVIDER_SELECTION_CONFIG",
      "SHOPPINGCART_CONFIG",
      "PRODUCT_DETAIL_CONFIG",
    ]);
    let sto = window.setTimeout(() => {
      browserHistory.push(url);
      window.clearTimeout(sto);
    }, 200);
  };
}

export default UserLogged;
