import React from 'react'

const allergen_13 = () => {
  return (
        <g>
        <path d="M100,0C44.772,0,0,44.772,0,100c0,55.23,44.772,100,100,100c55.23,0,100-44.77,100-100
    C200,44.772,155.23,0,100,0z M72.14,109.88c-1.164,2.678-2.562,5.24-4.11,7.717c-0.184,0.291-0.349,0.471-0.526,0.471
    c-0.145,0-0.297-0.124-0.478-0.42c-2.783-4.602-5.354-9.291-6.32-14.669c-0.741-4.108,1.311-7.645,5.038-8.608
    c0.626-0.163,1.241-0.239,1.839-0.239c3.979,0,7.133,3.341,6.9,6.537C74.341,104.412,73.32,107.175,72.14,109.88z M95.4,115.462
    c-0.626,0.16-1.24,0.236-1.835,0.236c-3.977,0-7.136-3.344-6.9-6.536c0.139-3.743,1.165-6.507,2.339-9.214
    c1.17-2.683,2.562-5.238,4.113-7.712c0.181-0.292,0.345-0.475,0.523-0.475c0.147,0,0.303,0.126,0.481,0.423
    c2.781,4.597,5.352,9.289,6.326,14.665C101.181,110.959,99.128,114.489,95.4,115.462z M107.782,84.672
    c-1.433,2.433-3.906,3.558-6.213,3.558c-1.739,0-3.385-0.639-4.42-1.837c-2.547-2.744-3.779-5.42-4.862-8.168
    c-1.067-2.718-1.898-5.516-2.547-8.368c-0.116-0.492-0.089-0.793,0.321-0.793c0.095,0,0.213,0.018,0.355,0.05
    c5.215,1.285,10.35,2.786,14.839,5.901C108.684,77.399,109.73,81.347,107.782,84.672z M133.781,124.464
    c-4.602,2.78-9.294,5.353-14.674,6.321c-0.558,0.102-1.107,0.15-1.64,0.15c-3.382,0-6.132-1.971-6.965-5.191
    c-1.209-4.65,2.392-8.75,5.963-8.75c0.111,0,0.223,0.003,0.333,0.013c3.742,0.14,6.508,1.159,9.214,2.34
    c2.679,1.164,5.241,2.559,7.716,4.115C134.26,123.793,134.43,124.067,133.781,124.464z M135.35,90.333
    c-1.28,5.217-2.781,10.356-5.9,14.842c-1.58,2.274-3.845,3.501-6.163,3.501c-1.178,0-2.371-0.321-3.491-0.977
    c-4.268-2.513-4.508-8.222-1.721-10.629c2.754-2.554,5.422-3.782,8.176-4.86c2.712-1.073,5.506-1.897,8.353-2.55
    c0.158-0.037,0.297-0.058,0.412-0.058C135.348,89.602,135.489,89.784,135.35,90.333z"/>
    </g>
  )
}

export default allergen_13