
/**
 * @func: isValidHEXColor
 * @param: {[String]} color
 * @return: {[Boolean]}
 *
 * @description: devuelve 'true' o 'false' dependiendo de si el valor
 * que se le pasa es un valor hexadecimal valido.
 */

const isValidHEXColor = (color) => /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(color)
export default isValidHEXColor
