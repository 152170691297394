
import config from '../../config'
import checkApiResponse from './checkApiResponse'

/**
 * @func: getAllergens
 * @return {[Object]} data
 *
 * @description:
 */

const checkCart = (id) => {
  const params = { id_provider: id }
  return config.API.checkCartGet(params, {}, {})
  .then(result => result.data)
  .catch(error => checkApiResponse(error))
}


export default checkCart
