import React from 'react'

const allergen_14 = () => {
  return (
        <g>
        <polygon points="80.312,103.894 103.325,108.812 103.325,87.564 80.312,82.341    "/>
        <polygon points="80.312,137.307 103.325,142.682 103.325,127.892 80.312,122.977  "/>
        <polygon points="111.385,62.327 90.385,57.328 90.385,62.043 111.286,67.356  "/>
        <polygon points="118.543,79.606 113.407,73.016 108.594,83.06    "/>
        <polygon points="89.439,65.471 81.323,78.925 104.113,84.098 110.481,70.817  "/>
        <path d="M100,0C44.772,0,0.001,44.772,0.001,99.999C0.001,155.229,44.772,200,100,200
            c55.229,0,99.999-44.771,99.999-100.001C199.999,44.772,155.229,0,100,0z M123.284,139.247c0,0.754-0.483,1.422-1.199,1.672
            l-16.361,5.685c-0.191,0.066-0.399,0.099-0.599,0.099c-0.136,0-0.276-0.018-0.41-0.048l-26.614-6.214
            c-0.812-0.187-1.383-0.907-1.383-1.73V80.111c0-0.318,0.086-0.633,0.255-0.907l9.814-16.272v-7.858c0-0.544,0.245-1.06,0.683-1.395
            c0.438-0.337,0.998-0.463,1.537-0.329l24.633,5.856c0.82,0.197,1.391,0.93,1.372,1.763l-0.163,8.074l8.061,10.34
            c0.247,0.312,0.375,0.691,0.375,1.085V139.247z"/>
        <polygon points="106.925,142.419 119.688,137.986 119.688,82.973 106.925,87.407  "/>
    </g>
  )
}

export default allergen_14