
import config from '../../config'

/**
 * @func: getProductInfo
 * @return {[Object]} data
 *
 * @description:
 */

const getProductInfo = (idproduct, idzone, idprovider, lan) => {
  const params = { id_product_eva: idproduct, id_zone: idzone, id_provider: idprovider, acronym: lan }
  return config.API.productsInfoGet(params, {}, {})
  .then(result => result.data)
  .catch(error => error)
}


export default getProductInfo
