

/*****************************************************************************
 * @class: SearchStore
 *
 * @description:
 *
 *****************************************************************************/

import { observable, computed, action, reaction, autorun } from 'mobx'
import config from '../config'
import { AppStore, UIStore, TrackingApartments, AuthStore } from '../stores'
import { ProductModel } from '../models'
import { getDataAsync, calcCategorieViews } from '../utils'
import { getSearch } from '../utils/api'

class SearchStore {

  // Resultado de la busqueda
  result = null
  // Cadena a buscar
  @observable string = ''
  // Pagina de resultados
  currentPage = 1
  // Cadena a buscar
  @observable matches = null
  // Ordenado de la respuesta
  // 1 - id_product_eva
  // 2 - precio ascendente
  // 3 - precio descendente
  order = 1
  // Los productos!
  products = null
  models = []
  // Las vistas
  views = undefined
  // Indice para la vista actual del lineal
  view = 0
  maxViewIndex = 0
  downloadedProducts = 0
  // Las vistas para pintar los componentes 'ShopLineView'
  @observable previousView = undefined
  @observable currentView = undefined
  @observable nextView = undefined

  // La utilizamos para controlar que se está realizando una
  // búsqueda y mostrar el mensaje oportuno
  @observable isSearching = undefined


  /**
   * Busca productos.
   *
   * TODO: mostrar el precargador en este punto y controlar
   * las respuestas con 0 productos.
   *
   * TODO: que pasa fon los filtros? Los aplicamos nosotros?
   */
  async search (string) {
    if (!string || string === '') return
    if (string.length <= 1) {
      this.result = null
      this.matches = 0
      this.string = ''
      return
    }

    TrackingApartments.search_product(AuthStore.apartmentId, string);
    UIStore.showLoading()
    this.isSearching = true

    // Reseteamos las vistas cada nueva busqueda
    this.reset()
    this.string = '¡Estamos repasando los estantes para encontrar lo que buscas!'

    // @params: idzone, idprovider, keyword, page, order
    const result = await getDataAsync(getSearch, AppStore.idzone, AppStore.idprovider, string, this.currentPage, this.order, config.LANGUAGE)
    // NOTE: necesitamos tener guardado el resultado para consultar,
    // por ejemplo, si tenemos que pedir mas 'páginas' o no.
    this.result = result
    this.matches = result.matches
    this.string = string

    if (result.matches === 0) {
      UIStore.hideLoading()
      this.isSearching = false
      return
    }

    this.configViews(result)
    this.getNextResultPage()  // NOTE: desde aqui nos aseguramos que no se nos queda frito al ir paginando
    this.isSearching = false
  }


  /**
   * Pedimos la siguiente 'página' de productos.
   */
  getNextResultPage = async () => {
    // NOTE: controlamos que nos quedan mas productos por pedir
    if (this.views && this.matches <= this.downloadedProducts) {
      this.maxViewIndex = this.views.length
      return
    }

    this.currentPage ++
    const result = await getDataAsync(getSearch, AppStore.idzone, AppStore.idprovider, this.string, this.currentPage, this.order, config.LANGUAGE)
    await this.configViews(result)
    this.getNextResultPage()  // NOTE: desde aqui nos aseguramos que no se nos queda frito
  }


  /**
   * Configuramos todas las vistas del lienal
   */
  async configViews (result) {
    if (result.products.length > 0) {
      this.products = result.products
      this.downloadedProducts += result.products.length
      // NOTE: creamos los modelos de los productos
      const models = await this.mapProducts(this.products)
      // NOTE: creamos todas las vistas con los primeros 50 productos
      // que nos devuelve la llamada a la API
      // TODO: recalcular las vistas para que solo salga una balda
      // en la ultima vista.
      let views = await calcCategorieViews(models, false)
      this.views = (this.views && this.views.length) ? this.views.concat(views) : views
      this.setViews()
    }

    UIStore.hideLoading()
  }


  /**
   * Controla el resize del buscador.
   */
  async resizeHandler (models) {
    this.view = 0
    let views = await calcCategorieViews(models, false)
    this.views =views
    this.setViews()
  }


  /**
   * Seteamos las vistas para los componentes 'ShopLineView'
   */
  setViews () {
    // NOTE: previous view
    if (this.view === 0) {
      this.previousView = null
    } else {
      this.previousView = this.views[this.view - 1]
    }

    // NOTE: current view
    this.currentView = this.views[this.view]

    // NOTE: next view
    if (!this.maxViewIndex || this.view < this.maxViewIndex) {
      this.nextView = this.views[this.view + 1]
    } else {
      this.nextView = null
    }
  }


  /**
   * Pues eso, paginando...
   */
  paginate (dir) {
    switch (dir) {
      case 'prev':
        this.view --
        break
      case 'next':
        this.view ++
        break
    }

    // NOTE: desde aqui hay veces que no le ha dado tiempo a cargar
    // y se queda frito el carrusel.
    // this.getNextResultPage()
    this.setViews()
  }


  /**
   * Crea los modelos de los productos si aún no los tenemos
   */
  async mapProducts (products) {
    let index = 0
    let models = []
    for (let product of products) {
      let model = AppStore.products.get(product.id_product_eva)
      if (!model) {
        if (product.image === null) product.image = config.FAKE_BAGS[Math.round(Math.random())]
        model = new ProductModel(product, index, null, 0)
        AppStore.products.set(product.id_product_eva, model)
      }
      this.models.push(model)
      models.push(model)
      index ++
    }
    return models
  }


  /**
   *
   */
  @action sortSearch (order) {
    this.order = order
    this.currentPage = 1
    this.search(this.string)
  }


  /**
   * Reseteo
   */
  reset () {
    this.result = null
    this.matches = null
    this.string = null
    this.products = null
    this.models = []
    this.views = null
    this.currentPage = 1
    this.view = 0
    this.previousView = null
    this.currentView = null
    this.nextView = null
  }

}


export default new SearchStore()
