
import React, { Component } from 'react'
import { translate } from 'react-i18next'
import config from '../../config'



class SuggestedProducts extends Component {

  render () {

		return (
			 <div className="container-full suggested-wrapper">
          <div className="container suggested">
              <h4 className="h4 bold grey s16 upper">También te puede interesar</h4>
                <ul className="grid">
                      {this.props.products}
                </ul>
              </div>
          </div>
		)
	}
}

export default SuggestedProducts
