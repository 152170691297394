
import React, { Component } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { IconButton } from '../../'

/**
 * @class: Modal
 * @author: Edgar Bermejo
 * @description: Proporciona detalles y/o acciones soobre algún flujo.
 * En la mayoría de los casos requiere que el usuario confirme explícitamente
 * una acción.
 *
 * TODO:
 * - Parametrizar los tiempos de las transiciones
 * - Parametrizar los tiempos de las transiciones
 *
 * Parámetros de configuración:
 * @Config.blockClose {[Boolean]} @default: true
 * @Config.clickOnBackgroundClose {[Boolean]} @default: true
 * @Config.content {[Component]}: <Component /> | @required
 *
 *
 * @usage:
 *
 * // HoC
 * const ModalTemplate = (props) => <div className="contenti">
 *   <h1>Hola tron!</h1>
 *   <p>Soy un HoC Modal y tengo un botón para cerrarme.</p>
 *   <p>Además puedes elegir si quieres que me cierre haciendo 'click' en el fondo o no.</p>
 *   <button onClick={ props.clickEvent }>Close</button>
 * </div>
 *
 * // Or React.Component
 *
 * UIStore.toogleNotification({
 *   clickOnBackgroundClose: true,
 *   content: () => <ModalTemplate clickEvent={ testClick } />
 * })
 *
 * <Modal isOpen={ UIStore.isModalOpen }
 *        toogle={ UIStore.toggleModal }
 *        transitionName="modal-anim" />
 */

class Modal extends Component {

  static propTypes = {
    isOpen: React.PropTypes.bool,
    toogle: React.PropTypes.func,
    transitionName: React.PropTypes.string
  }

  static defaultProps = {
    isOpen: false
  }

  constructor (...args) {
    super(...args)
  }

  close = null

  render () {
    return (
      <ReactCSSTransitionGroup transitionName={ this.props.transitionName }
                               transitionEnterTimeout={ 500 }
                               transitionLeaveTimeout={ 300 }>
        { (this.props.isOpen) ? this.renderContent() : null }
      </ReactCSSTransitionGroup>
    )
  }


  renderContent () {
    const { customClassName } = this.props
    const closeProp = this.props.config.clickOnBackgroundClose
    this.close = (closeProp === undefined || closeProp === true) ? this.props.toogle : null
    return (
      <div className={ `modal-wrapper ${ customClassName }` } ref="modal" role="dialog">
        <div className="modal-content">
          { (this.close) ? this.renderCloseButton() : null }
          { this.props.config.content() }
        </div>
        <div className="bg-close" onClick={ this.close }></div>
      </div>
    )
  }


  renderCloseButton = () => <IconButton iconType="close"
                                        iconTitle="ALT_BOTON_CERRAR_AQUI"
                                        iconFill="grey"
                                        className="btn-close"
                                        onClick={ this.close }/>
}


export default Modal
