
/**
 * @func: synOnEnter
 * @param {[Object]} nextState
 * @param {[Function]} replaceState
 *
 * @description:
 */

import React from 'react'
import { AppStore, ShopStore, SyncStore, UIStore, AuthStore } from '../../stores'
import { browserHistory } from "react-router";

const synOnEnter = (nextState, replaceState) => {
  UIStore.showLoading()
  SyncStore.openOrCreateProviderCartDataset(AuthStore.bookingId, (records, products) => {
    const _products = JSON.parse(sessionStorage.getItem('ProductsWithoutAuthenticated'));
    if (products.length <= 0) {
      if(_products){
        _products.forEach((d) => {
          let key = d.product.id_product_eva.toString();
          ShopStore.add(key, d.units);
          ShopStore.isEmptyCart = false
          SyncStore.forceCartSync()
        })
        ShopStore.clearCartConflicts();
      }else {
        ShopStore.isEmptyCart = true
        ShopStore.empty(() => {
          SyncStore.forceCartSync()
        })
      }
    } else {
      if(_products){
        [..._products, ...products].forEach((d) => {
          let key = d.product.id_product_eva.toString();
          ShopStore.add(key, d.units);
          ShopStore.isEmptyCart = false;
          SyncStore.forceCartSync();
        })
        ShopStore.clearCartConflicts();
      }else{
        ShopStore.isEmptyCart = false;
        SyncStore.forceCartSync();
      }
    }
    if(!browserHistory.getCurrentLocation().pathname.includes("compra")){
        UIStore.hideLoading();
    }
    //UIStore.hideLoading()
  })

  if (UIStore.isCategoriesOpen) UIStore.isCategoriesOpen = false
}

export default synOnEnter
