
import React, { Component } from 'react'
import { UIStore, AuthStore } from '../../../../stores'
import LoginModal from './LoginModal'
import { Icon, Button, IconButton, SwitchButton, LinkButton } from '../../../'

/**
 *
 */

class SignupModal extends Component {

state = { terms: false }

constructor(props) {
    super(props);
    this.send = this.send.bind(this);
  } 

  componentDidMount () {
    window.setTimeout(() => {
      this.refs.email.focus()
      window.clearTimeout(this)
    })
  }


  render () {
    return (
      <div className="signup-modal">
        <div className="logotype">
          <div className="symbol">
            <Icon className="icon-symbol" type="symbol" width="55" height="55" fill="blue" viewport="0 0 200 200"></Icon>
          </div>
          <div className="logo">
            <Icon className="icon-logo" type="logo" width="110" height="36" fill="grey" viewport="0 0 200 70"></Icon>
          </div>
        </div>
        <h2 className="h2 light grey s32">Crear cuenta</h2>
        <form onSubmit={ this.send }>
          <input ref="email" className="input" id="email" type="text" placeholder="Correo electrónico" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" />
          <input className="input" id="password" type="password" placeholder="Contraseña" readOnly={ false } />
          <input className="input" id="name" type="text" placeholder="Nombre" readOnly={ false } />
          <input className="input" id="lastname" type="text" placeholder="Apellidos" readOnly={ false } />
          <button type="submit"></button>
          <Button onClick={ this.send } type="outline" placeholder="Registrarme" color="blue" />
          <p className="p light grey s14">
            <span>¿Ya tienes cuenta? </span><Button onClick={ this.openLoginModal } type="flat" placeholder="Inicia sesión" color="blue" />
          </p>
          </form>
          <div className="signup-page-footer-modal">
          <p className="p light grey s12 footer">
            <span>Al registrarte aceptas los</span> <LinkButton type="flat" target= "blank" onClick="https://www.ilusia.com/legales/#aviso-legal" placeholder="Términos y condiciones de uso" color="grey" /><span> y la </span><LinkButton type="flat" target="blank" onClick="https://www.ilusia.com/privacidad/" placeholder="política de Privacidad." color="grey" />
          </p>
          <SwitchButton name="terminos"
                            id="terminos"
                            isChecked={ this.state.terms }
                            onSwitchChange={ this.TerminosSwichButtonToogle }
                          />
        </div>
      </div>
    )
  }

TerminosSwichButtonToogle = (event) => this.setState({ terms: !this.state.terms })

  openLoginModal = (event) => {
    event.preventDefault()

    UIStore.toggleModal()
    window.setTimeout(() => {
      UIStore.toggleModal({ content: () => <LoginModal onSubmit={ AuthStore.logIn } /> })
      window.clearTimeout(this)
    }, 400)
  }


  /**
   * Envia los datos del formulario.
   *
   * TODO: cambiar las referecias DOM a React.refs
   */
  send = (event) => {
    event.preventDefault()
    const terms = this.state.terms
    const email = document.querySelector('form input#email').value
    const password = document.querySelector('form input#password').value
    const name = document.querySelector('form input#name').value
    const lastname = document.querySelector('form input#lastname').value
    this.props.onSubmit(email, password, name, lastname, terms)
  }
}


export default SignupModal
