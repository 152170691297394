
import config from '../../config'
import checkApiResponse from './checkApiResponse'

/**
 * @func: getUser
 * @return {[Object]} data
 *
 * @description:
 */

 const getUser = () => {
   console.log('In get User ------> config:', config, '\nCurrent Api', config.API);
   return config.API.usersGet({}, {}, {})
   .then(result => result.data)
   .catch(error => checkApiResponse(error))
 }


export default getUser
