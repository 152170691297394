
import React, { Component } from 'react'
import { translate } from 'react-i18next'
import { AuthStore } from '../../../stores'

@translate(['common'], { wait: false })
class MyAccount extends Component {
  
  render () {
    return (
      <div className="user-zone-view my-account">
        <header>
          <img className="background" src="/img/userzonebannerweb.png" alt=""/>
          <h2 className="h2 regular blue s30">Mi cuenta</h2>
        </header>
        <article>
          <h3 className="h3 regular blue s22">Perfil</h3>
          <ul>
            <li>
              <label className="p light black s16 a40">Correo electrónico</label>
              <p className="email p regular black s16">{ (AuthStore.user || {}).email }</p>
            </li>

            <li>
              <label className="p light black s16 a40">Nombre</label>
              <input type="text"
                     className="user-zone_input"
                     value={ (AuthStore.user || {firstname:''}).firstname }
                     ref="firstname"
                     readOnly={ true } />
            </li>
            <li>
              <label className="p light black s16 a40">Apellidos</label>
              <input type="text"
                     className="user-zone_input"
                     value={ (AuthStore.user || {lastname:''}).lastname }
                     ref="lastname"
                     readOnly={ true } />
            </li>
            <li>
              <label className="p light black s16 a40">Teléfono</label>
              <input type="text"
                     className="user-zone_input"
                     value={ (AuthStore.user || {phone1:''}).phone1 || '' }
                     ref="phone1"
                     readOnly={ true } />
            </li>
          </ul>
        </article>
      </div>
    )
  }

}

export default MyAccount
