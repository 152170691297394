
import React, { Component } from 'react'
import { TweenMax, Back } from 'gsap'
import { UIStore } from '../../stores'


class AnimatedProduct extends Component {

  componentDidMount () {
    const rotation = Math.floor(Math.random() * -35) + 35
    TweenMax.to(this.refs.product, 1.2, { top: (window.innerHeight - 200) + 'px',
                                          left: (window.innerWidth * .5) + 'px',
                                          opacity: 0,
                                          scale: 1.1,
                                          rotation: rotation + 'deg',
                                          ease: Back.easeInOut.config(1),
                                          onComplete: () => UIStore.removeAnimatedProduct(this.props.index) })
  }

  render () {
    const { src, width, height, top, left } = this.props.product
    const styler = {
      width: width,
      height: height,
      top: top,
      left: left,
    }

    return (
      <div ref="product" className="animated-product" style={ styler }>
        <img src={ src } />
      </div>
    )
  }
}

export default AnimatedProduct
