
/**
 * @func: onLeaveShoppingCart
 * @param {[Object]} nextState
 * @param {[Function]} replaceState
 *
 * @description:
 */

import React from 'react'
import { ShopStore } from '../../stores'
import { synOnEnter } from './'

const onLeaveShoppingCart = (nextState, replaceState) => {
  if (ShopStore.conflicts.length) ShopStore.clearCartConflicts()
  ShopStore.clearProductConflictStatuss()

  synOnEnter()
}

export default onLeaveShoppingCart
