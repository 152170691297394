
/**
 * expires (GMT)
 */

const getCookie = (key) => {
  const name = `${ key }=`
  const ca = document.cookie.split(';')
  const len = ca.length

  for (let i = 0; i < len; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(name) === 0) return decodeURIComponent(c.substring(name.length, c.length))
  }

  return null
}

export default getCookie
