
import React from 'react'

const ticket_view = () => {
  return (
  	<g>
     <path d="M173.162,8.411l-1.079-0.123l-0.122,0.013c-0.87-0.08-1.703-0.141-2.499-0.141H59.259
       c-16.282,0-29.528,13.246-29.528,29.528v49.908c-0.025,0.19-0.025,0.392-0.025,0.601v56.935H1.249v26.49
       c0,10.302,6.625,19.401,17.067,20.123c0.109,0.024,0.202,0.061,0.318,0.074c0.379,0.035,0.716,0.061,1.065,0.098v0.085h1.169
       c0.509,0.025,1.005,0.074,1.464,0.074h110.193c16.301,0,29.54-13.265,29.54-29.541v-49.907c0.013-0.196,0.025-0.393,0.025-0.589
       V63.524h28.45h8.426v-8.433V37.688C198.967,22.85,187.883,10.254,173.162,8.411z M153.651,112.038h-0.024v50.496
       c0,10.741-8.033,19.597-18.432,20.907c-10.387-1.311-18.433-10.166-18.433-20.907v-17.403H38.138V88.196h0.012V37.688
       c0-11.641,9.455-21.078,21.109-21.078h94.847h15.356c0.882,0,1.765,0.068,2.621,0.178c-10.373,1.299-18.432,10.141-18.432,20.9
       v25.836V112.038z"/>
     <path d="M56.086,48.87c0,3.705,3.013,6.711,6.7,6.711h66.041c3.698,0,6.71-3.006,6.71-6.711l0,0
       c0-3.699-3.012-6.718-6.71-6.718H62.786C59.1,42.152,56.086,45.171,56.086,48.87L56.086,48.87z"/>
     <path d="M56.086,80.143c0,3.699,3.013,6.7,6.7,6.7h66.041c3.698,0,6.71-3.001,6.71-6.7l0,0
       c0-3.698-3.012-6.699-6.71-6.699H62.786C59.1,73.444,56.086,76.445,56.086,80.143L56.086,80.143z"/>
     <path d="M56.086,111.414c0,3.711,3.013,6.699,6.7,6.699h66.041c3.698,0,6.71-2.988,6.71-6.699l0,0
       c0-3.698-3.012-6.699-6.71-6.699H62.786C59.1,104.715,56.086,107.716,56.086,111.414L56.086,111.414z"/>
  	</g>
  )
}

export default ticket_view
