
import config from '../../config'
import checkApiResponse from './checkApiResponse'

/**
 * @func: deleteProviderCredentials
 * @return {[Object]} data
 *
 * @description:
 */

const deleteProviderCredentials = (id) => {
  const params = { id_provider: id }
  return config.API.providerCredentialsDelete({}, params, {})
  .then(result => result.data)
  .catch(error => checkApiResponse(error))
}


export default deleteProviderCredentials
