
import React from 'react'
import { Link } from 'react-router'

const LinkButton = (props) => <Link className={ `link ${ props.type || 'normal' } ${ props.align || '' } ${ props.color || '' } ${ props.className || '' }` }
                                    id={ props.id || null }
                                    target={ props.target || null }
                                    to={ props.onClick || null }>
  { props.placeholder }
</Link>

export default LinkButton
