import React from 'react'

const allergen_12 = () => {
  return (
        <g>
        <path d="M100,0C44.772,0,0,44.772,0,100c0,55.23,44.772,100,100,100c55.23,0,100-44.77,100-100
    C200,44.772,155.23,0,100,0z M88.533,61.356c1.407-0.211,3.112-1.148,3.761,0.959c0.166,0.547-0.61,1.714-1.253,2.097
    c-0.831,0.497-1.961,0.491-3.485,0.818c-0.391-0.428-1.351-0.989-1.459-1.685C85.842,61.867,87.216,61.557,88.533,61.356z
     M86.77,67.73c2.145-0.754,4.445-1.248,6.71-1.422c0.773-0.064,2.14,0.993,2.328,1.758c0.184,0.765-0.56,2.373-1.257,2.625
    c-2.211,0.817-4.594,1.175-7.451,1.834c-0.586-0.662-1.755-1.409-1.882-2.3C85.113,69.444,86.012,67.993,86.77,67.73z
     M84.661,75.202c4.294-1.033,8.607-1.973,12.942-2.828c1.692-0.339,2.978,0.512,3.433,2.182c0.455,1.692-0.253,3.122-1.918,3.545
    c-4.429,1.123-8.909,2.042-14.038,3.19c-0.709-0.744-1.971-1.527-2.382-2.621C82.114,77.095,82.945,75.621,84.661,75.202z
     M83.49,83.77c5.835-1.377,11.696-2.691,17.578-3.866c2.423-0.479,4.36,1.23,4.399,4.431c-0.639,0.599-1.6,2.239-2.896,2.579
    c-5.8,1.524-11.688,2.733-17.56,3.929c-2.05,0.415-3.829-0.973-4.208-2.933C80.454,86.084,81.481,84.244,83.49,83.77z
     M82.509,93.795c6.155-1.504,12.332-2.886,18.508-4.303c1.69-0.387,3.39-0.865,5.114-1.059c2.684-0.292,4.668,1.561,4.679,4.515
    c-0.079,1.69-1.012,3.235-3.012,3.713c-7.932,1.897-15.874,3.734-23.844,5.461c-2.102,0.455-4.142-1.181-4.597-3.248
    C78.838,96.575,80.077,94.386,82.509,93.795z M122.569,128.935c-3.32,7.143-10.211,10.92-17.827,9.919
    c-1.767-0.234-3.619-0.174-5.404,0.01c-5.281,0.547-10.277-0.178-14.894-2.923c-11.291-6.715-10.079-23.099-3.943-29.991
    c0.531-0.596,1.414-1.019,2.215-1.211c8.697-2.126,17.396-4.216,26.119-6.242c4.101-0.951,9.591,4.596,8.518,8.573
    c-0.165,0.599-0.793,1.144-1.33,1.562c-3.325,2.573-6.699,5.078-10.025,7.648c-3.361,2.597-6.101,5.683-6.423,10.127
    c-0.128,1.835,0.168,3.797,0.72,5.559c0.773,2.497,2.647,3.662,5.386,3.808c5.651,0.3,10.103-1.548,13.118-6.453
    c3.136-5.109,2.939-10.219-0.078-15.291c-0.641-1.083-1.433-2.073-2.294-3.301c0.823-0.639,1.5-1.172,2.156-1.696
    C123.977,113.811,125.646,122.295,122.569,128.935z"/>
    </g>
  )
}

export default allergen_12