
import React from 'react'

const logo_symbol_hand = () => {
 return (
    <g>
      <path d="M54.93,0a54.93,54.93,0,1,0,54.92,54.93A54.93,54.93,0,0,0,54.93,0ZM47.61,80.79c0,.07,0,.13,0,.17V81a7.89,7.89,0,0,1-.1,1.07,5.07,5.07,0,0,1-.14.5,1.54,1.54,0,0,1-.05.17l0,.1a3,3,0,0,1-.13.47,5.36,5.36,0,0,1-.32.68v0s-.05.1-.08.14v0a.15.15,0,0,0,0,.08l-.12.22a6.51,6.51,0,0,1-1.75,1.87l-.08.06h0a6.65,6.65,0,0,1-1,.56l-.14,0h0a6,6,0,0,1-2.23.45,6.25,6.25,0,0,1-1.15-.12l-.12,0a7.12,7.12,0,0,1-.85-.27l-.06,0h0L39,87h0a7.73,7.73,0,0,1-.82-.47L38,86.49l-.16-.1a5.92,5.92,0,0,1-1-.87L18.57,65.77a7.13,7.13,0,0,1,0-9.49,6,6,0,0,1,8.9,0l5.61,6.06.23.25,0-.06a1.16,1.16,0,0,0,1.75-1,.06.06,0,0,0,0,0V26.58a6.51,6.51,0,0,1,6.26-6.72,6.52,6.52,0,0,1,6.28,6.72Zm18.56,0a6.28,6.28,0,1,1-12.53,0v-28a6.28,6.28,0,1,1,12.53,0Zm18.57,0a6.29,6.29,0,1,1-12.54,0V62.24a6.52,6.52,0,0,1,6.28-6.72h0a6.5,6.5,0,0,1,6.24,6.72Z"/>
    </g>
  )
}

export default logo_symbol_hand
