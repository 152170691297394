import React, { Component } from "react";
import {
  OrderConfirmationAddress,
  OrderConfirmationHorario,
  OrderConfirmationPayment,
  OrderConfirmationReview,
} from "../components/OrderConfirmationComponents";
import { AppStore, UIStore } from "../stores";

class OrderConfirmation extends Component {
  state = {
    deliveryPrice: AppStore.pricedelivery,
    loadingComponents: ["schedule"],
  };
  setPriceUI = (price) => {
    this.setState({ deliveryPrice: price });
  };
  setLoaded = (name = "") => {
    // const {loadingComponents} = this.state
    // updatedLoading=loadingComponents.filter(loadingComp => loadingComp !=name)
    // this.setState({loadingComponents:updatedLoading})
    // if(updatedLoading.length === 0){
    //   UIStore.hideLoading()
    // }else{
    //   if (UIStore.isLoadingOpen)return false;
    //   UIStore.showLoading();
    // }
  };

  componentDidMount(){
    UIStore.showLoading();
  }

  render() {
    return (
      <div className="section order-confirmation-page">
        <h1 className="h1 grey s30">Confirmación del pedido</h1>
        <OrderConfirmationHorario
          hasLoadedData={() => this.setLoaded("schedule")}
          setPriceUI={this.setPriceUI}
        />
        <OrderConfirmationAddress />
        <OrderConfirmationReview />
        <OrderConfirmationPayment deliveryPrice={this.state.deliveryPrice} />
      </div>
    );
  }
}

export default OrderConfirmation;
