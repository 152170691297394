
import React from 'react'
import config from '../../../config'

/**
 *
 */
const PriceTag = (props) => <div className={ `price-tag ${ props.type }` }>
  <p className="p s14" style={ { fontFamily: props.font, color: props.color } }>{ props.price }</p>
  <p className="p s14 currency" style={ { fontFamily: props.font, color: props.color } }>{ props.currency }</p>
  <img src={ `https://${ config.S3_IMAGES }/images/${ config.PRICE_TAG_IMAGE_SIZE }/${ props.tag }` }
       alt={ props.price + '' + props.currency } />
</div>

export default PriceTag
