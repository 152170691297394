
import React from 'react'

const zoom_plus = () => {
  return (
    <g>
      <rect x="1846.309" y="994.559" width="0" height="4.601"/>
      <path d="M196.504,179.354l-54.201-55.422c9.773-13.383,14.926-29.176,14.926-45.846C157.229,35.027,122.184,0,79.103,0
      	C36.041,0,1,35.027,1,78.086s35.041,78.09,78.103,78.09c17.341,0,33.614-5.496,47.25-15.918l54.024,55.238
      	c2.168,2.152,5.031,3.34,8.057,3.34c3.037,0,5.908-1.188,8.076-3.355C200.947,191.02,200.947,183.787,196.504,179.354z
      	 M134.406,78.086c0,14.611-5.638,28.369-15.877,38.742c-10.479,10.658-24.48,16.527-39.427,16.527
      	c-30.474,0-55.269-24.795-55.269-55.27c0-30.469,24.795-55.254,55.269-55.254C109.6,22.832,134.406,47.617,134.406,78.086z
      	 M107.145,78.113c0,4.26-2.83,7.104-7.08,7.104h-14.15v14.201c0,4.262-2.83,7.098-7.076,7.098c-4.244,0-7.074-2.836-7.074-7.098
      	V85.217H57.607c-4.244,0-7.075-2.844-7.075-7.104c0-4.262,2.831-7.1,7.075-7.1h14.156V56.812c0-4.268,2.83-7.105,7.074-7.105
      	c4.246,0,7.076,2.838,7.076,7.105v14.201h14.15C104.314,71.014,107.145,73.852,107.145,78.113z"/>
    </g>
  )
}

export default zoom_plus
