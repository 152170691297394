
import React from 'react'

const start_user = () => {
 return (
   <g>
     <path d="M100,0.5C45.139,0.5,0.5,45.138,0.5,100s44.639,99.5,99.5,99.5s99.5-44.639,99.5-99.5S154.861,0.5,100,0.5z
     	 M100,45.734c17.756,0,32.2,14.446,32.2,32.202c0,17.762-14.444,32.208-32.2,32.208c-17.763,0-32.207-14.446-32.207-32.208
     	C67.793,60.18,82.237,45.734,100,45.734z M100,193.611c-26.308,0-50.067-10.939-67.092-28.47
     	c5.06-21.507,33.174-38.031,67.092-38.031s62.032,16.532,67.085,38.038C150.061,182.671,126.301,193.611,100,193.611z"/>
   </g>
 )
}

export default start_user
