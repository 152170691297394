import React from 'react'

const allergen_5 = () => {
  return (
        <g>
         <path d="M100,0C44.772,0,0,44.772,0,100c0,55.23,44.772,100,100,100c55.23,0,100-44.77,100-100
        C200,44.772,155.23,0,100,0z M87.689,80.748h5.713l4.245-19.781h4.704l4.24,19.781h5.722v5.525H87.689V80.748z M118.087,136.015
        c0,1.671-1.349,3.019-3.015,3.019H84.924c-1.663,0-3.012-1.348-3.012-3.019V92.304c0-1.663,1.349-3.014,3.012-3.014h30.147
        c1.666,0,3.015,1.351,3.015,3.014V136.015z"/>
        </g>
  )
}

export default allergen_5