
import React from 'react'


const renderIcon = (placeholder, icon, hicon) => <img src={ `https://images.ilusia.com/images/50/${ hicon }_${ icon }.png` } alt={ placeholder } className="parent-button_icon"  />

const ParentButton = (props) => <button className={ `parent-button level_${ props.level }` }
                                        id={ props.id }
                                        onClick={ props.onClick }
                                        style={ props.colorStyle }>
  { (props.icon) ? renderIcon(props.placeholder, props.icon, props.hicon) : null }
  <p className="p regular s16 white">{ props.placeholder }</p>
</button>


export default ParentButton
