import React from 'react'

const allergen_9 = () => {
  return (
    <g>
    <path d="M112.493,85.896c-0.93-1.043-2.049-1.851-3.358-2.42c-1.31-0.568-2.762-0.853-4.356-0.853
        c-1.594,0-3.036,0.276-4.327,0.825c-1.291,0.551-2.4,1.338-3.33,2.363c-0.93,1.024-1.651,2.258-2.163,3.7
        c-0.512,1.444-0.769,3.056-0.769,4.84c0,1.746,0.257,3.34,0.769,4.782c0.512,1.442,1.242,2.676,2.191,3.701
        c0.948,1.024,2.069,1.821,3.359,2.391c1.29,0.57,2.732,0.854,4.327,0.854c1.593,0,3.036-0.274,4.327-0.826
        c1.289-0.55,2.409-1.337,3.358-2.362c0.949-1.025,1.68-2.258,2.193-3.7c0.513-1.441,0.768-3.036,0.768-4.782
        c0-1.745-0.266-3.34-0.798-4.782C114.153,88.182,113.422,86.94,112.493,85.896z"/>
    <path d="M100.001,0C44.772,0,0,44.772,0,100c0,55.229,44.772,100,100.001,100C155.229,200,200,155.229,200,100
        C200,44.772,155.229,0,100.001,0z M78.366,110.46c-2.582,2.049-6.32,3.074-11.214,3.074c-2.43,0-4.546-0.228-6.348-0.684
        c-1.804-0.455-3.284-1.043-4.441-1.764c-1.159-0.721-2.021-1.517-2.591-2.391c-0.57-0.873-0.853-1.727-0.853-2.562
        c0-0.57,0.151-1.12,0.455-1.651c0.303-0.531,0.645-0.996,1.024-1.395c0.379-0.398,0.74-0.711,1.081-0.939
        c0.341-0.228,0.55-0.303,0.626-0.228c0.57,0.683,1.242,1.328,2.021,1.935c0.777,0.608,1.642,1.139,2.591,1.595
        c0.948,0.454,1.964,0.825,3.046,1.11c1.081,0.285,2.191,0.426,3.329,0.426c2.201,0,3.871-0.388,5.01-1.166
        c1.139-0.778,1.708-1.888,1.708-3.331c0-0.835-0.219-1.508-0.655-2.021c-0.436-0.512-1.015-0.93-1.736-1.253
        c-0.721-0.322-1.565-0.598-2.533-0.825c-0.968-0.228-2.002-0.456-3.102-0.684c-1.481-0.341-2.932-0.758-4.355-1.252
        c-1.424-0.492-2.704-1.157-3.843-1.992c-1.139-0.835-2.05-1.889-2.733-3.16c-0.683-1.271-1.025-2.837-1.025-4.696
        c0-1.708,0.332-3.264,0.997-4.668c0.663-1.404,1.622-2.6,2.874-3.587c1.253-0.986,2.762-1.755,4.527-2.306
        c1.764-0.549,3.747-0.825,5.949-0.825c2.239,0,4.184,0.199,5.835,0.598c1.651,0.399,3.027,0.921,4.127,1.565
        c1.1,0.646,1.926,1.367,2.476,2.164c0.55,0.797,0.826,1.593,0.826,2.391c0,0.646-0.171,1.244-0.512,1.792
        c-0.342,0.551-0.712,1.025-1.111,1.423c-0.398,0.4-0.778,0.704-1.138,0.911c-0.361,0.21-0.56,0.295-0.598,0.257
        c-1.102-1.29-2.515-2.372-4.241-3.245c-1.727-0.872-3.577-1.31-5.55-1.31c-2.049,0-3.625,0.399-4.725,1.196
        c-1.101,0.797-1.65,1.86-1.65,3.188c0,0.797,0.199,1.433,0.598,1.907c0.398,0.475,0.939,0.864,1.622,1.168
        c0.683,0.304,1.508,0.55,2.476,0.739c0.968,0.191,2.021,0.418,3.159,0.684c1.67,0.379,3.255,0.844,4.754,1.395
        c1.5,0.55,2.818,1.272,3.957,2.163c1.138,0.893,2.049,1.955,2.732,3.188c0.683,1.233,1.025,2.724,1.025,4.469
        C82.237,105.545,80.946,108.411,78.366,110.46z M122.399,102.092c-0.931,2.354-2.24,4.374-3.928,6.063
        c-1.689,1.689-3.692,3.007-6.006,3.956c-2.316,0.948-4.876,1.423-7.685,1.423c-2.771,0-5.323-0.465-7.657-1.395
        c-2.334-0.929-4.336-2.239-6.006-3.928c-1.671-1.688-2.97-3.709-3.899-6.063c-0.931-2.353-1.395-4.934-1.395-7.742
        c0-2.808,0.474-5.389,1.423-7.742c0.947-2.353,2.267-4.374,3.956-6.063c1.688-1.688,3.691-3.008,6.005-3.957
        c2.316-0.947,4.859-1.423,7.629-1.423c2.808,0,5.37,0.475,7.684,1.423c2.315,0.949,4.307,2.269,5.978,3.957
        c1.67,1.689,2.969,3.7,3.9,6.035c0.929,2.334,1.395,4.905,1.395,7.714C123.793,97.158,123.328,99.739,122.399,102.092z
         M147.08,122.421c0,0.773-0.213,1.358-0.639,1.759c-0.426,0.399-1.145,0.599-2.157,0.599H130.7c-1.332,0-2.257-0.2-2.776-0.599
        c-0.519-0.4-0.779-1.199-0.779-2.397v-1.239c0-1.224,0.239-2.31,0.719-3.256c0.479-0.945,1.105-1.778,1.877-2.497
        c0.772-0.718,1.631-1.352,2.577-1.898c0.945-0.546,1.871-1.045,2.777-1.499c0.798-0.372,1.558-0.725,2.276-1.058
        c0.72-0.332,1.345-0.679,1.879-1.039c0.531-0.36,0.959-0.759,1.278-1.199c0.32-0.439,0.48-0.939,0.48-1.498
        c0-1.119-0.374-1.977-1.119-2.577c-0.746-0.599-1.825-0.898-3.236-0.898c-0.826,0-1.552,0.127-2.178,0.379
        c-0.626,0.253-1.172,0.593-1.638,1.019c-0.467,0.426-0.839,0.913-1.118,1.458c-0.28,0.546-0.5,1.113-0.659,1.698
        c0,0.027-0.2,0.04-0.6,0.04c-0.399,0-0.839-0.08-1.318-0.24c-0.479-0.16-0.918-0.432-1.319-0.818
        c-0.4-0.386-0.599-0.952-0.599-1.697c0-0.799,0.213-1.592,0.639-2.377c0.425-0.785,1.052-1.492,1.877-2.118
        c0.826-0.625,1.845-1.132,3.056-1.519c1.213-0.385,2.617-0.58,4.216-0.58c3.116,0,5.512,0.713,7.191,2.138
        c1.677,1.425,2.517,3.39,2.517,5.893c0,1.199-0.207,2.218-0.62,3.056c-0.413,0.839-0.959,1.565-1.638,2.178
        c-0.68,0.613-1.459,1.139-2.337,1.578c-0.879,0.44-1.799,0.86-2.758,1.258c-0.905,0.374-1.744,0.746-2.516,1.119
        c-0.772,0.374-1.452,0.766-2.037,1.179c-0.586,0.412-1.079,0.879-1.479,1.398c-0.4,0.52-0.665,1.125-0.799,1.818
        c1.012,0,2.104-0.006,3.276-0.02c1.172-0.013,2.318-0.019,3.437-0.019c1.118,0,2.13,0,3.036,0c0.905,0,1.571,0,1.998,0
        c1.012,0,1.731,0.193,2.157,0.58c0.426,0.386,0.639,0.938,0.639,1.658V122.421z"/>
    </g>
  )
}

export default allergen_9