
import React from 'react'
import { observer } from 'mobx-react'
import { Router, Route, browserHistory, IndexRoute } from 'react-router'

import config from '../config'
import App from '../App'
import { AppStore } from '../stores'
import { SignUpPage, LogInPage, RecoverPass, ZoneSelection, ProviderSelection, Shop, ProductDetail, ShopBot, ShoppingCart, UserZone, NoProvidersForThatPostalCode,PotentialProvidersSelection, OrderConflictsReview, OrderDetail, Filters, NotFound, Tramitar, MobileBlocked, OrderConfirmation, OrderPayment, AuthPage, UserNotAuthenticated } from '../pages'
import { GenericPreloader, ShopPreloader, LoginPreloader } from '../components'
import { requireAuth, synOnEnter, onLeaveShop, shoppingCartAuth, onLeaveShoppingCart, onLeaveOrderConflictsReview, onLeaveOrderDetail, checkCookiesDialogStatus, onLeaveTramitar, authUser } from '../utils'
import Cookies from '../pages/Cookies'


const routes = (
  <Router history={ browserHistory } >
    <Route path="/" component={ App } onChange={ checkCookiesDialogStatus }>

       <IndexRoute title="Shop"
             onEnter={ synOnEnter }
             onLeave={ onLeaveShop }
             component={ Shop }
             config={ config.SHOP_CONFIG }
             preloader={ ShopPreloader } />

       
       <Route path="/cookies"
             title="Cookies"
       //       config={config.NO_DATA }
             component={ Cookies } />

       <Route path="/no-autenticado"
             title="Usuario no autenticado"
             component={ UserNotAuthenticated } />

       <Route path="/auth"
             title="Autenticar Usuario"
             config={ config.REGISTRATION }
             component={ AuthPage }
             preloader={ LoginPreloader }/>

       
      <Route path="/login"
             title="Login"
             config={ config.REGISTRATION }
             component={ LogInPage } />

             
      <Route path="/zona-usuario"
             title="Zona de usuario"
             onEnter={ requireAuth }
             config={ config.USER_ZONE_CONFIG }
             onLeave={ onLeaveOrderDetail }
             component={ UserZone } />

       <Route path="filtros"
             onEnter={ requireAuth }
             title="Filtros nutricionales"
             config={ config.SHOP_CONFIG }
             component={ Filters } 
             preloader={ ShopPreloader }
             />

      <Route path="/detalle-pedido/:idorder"
             title="Detalle de pedido"
             onEnter={ requireAuth }
             onLeave={ onLeaveOrderDetail }
             config={ config.ORDER_DETAIL_CONFIG }
             component={ OrderDetail } />
       {/*
      <Route path="/:countryname/:postalcode"
             title="Selección proveedor"
             component={ ProviderSelection }
             config={ config.PROVIDER_SELECTION_CONFIG } />
       */}
             
      <Route path="/producto/:idproduct/:productname"
             title="Detail"
             onEnter={ synOnEnter }
             onLeave={ synOnEnter }
             component={ ProductDetail }
             config={ config.PRODUCT_DETAIL_CONFIG } />
      <Route path="/buscar"
             title="Bot"
             onEnter={ synOnEnter }
             onLeave={ synOnEnter }
             component={ ShopBot }
             config={ config.SHOPBOT_CONFIG }
             preloader={ ShopPreloader } />
      <Route path="/carrito"
             title="Cart"
             onEnter={ shoppingCartAuth }
             onLeave={ onLeaveShoppingCart }
             component={ ShoppingCart }
             config={ config.SHOPPINGCART_CONFIG }
             preloader={ ShopPreloader } />
      <Route path="/compra"
             title="Compra"
             onEnter={ requireAuth } 
             component={ OrderConfirmation }
             config= { config.ORDER_CONFIRMATION_CONFIG}
              />
      <Route path="/pagar"
             title="Pagar"
             onEnter={ requireAuth }
             component={ OrderPayment }/>
       
       {/* 
      <Route path="/no-existen-proveedores"
             config={ config.NOT_PROVIDER_CONFIG }
             component={ NoProvidersForThatPostalCode } />
      <Route path="/revision-conflictos"
             config={ config.EXPORT_CART_CONFLICTS }
             onLeave={ onLeaveOrderConflictsReview }
             component={ OrderConflictsReview } />
      <Route path="/suscripcion-de-proveedores"
             config={ config.POTENTIAL_PROVIDER_CONFIG }
             component={ PotentialProvidersSelection } />
       */}

      <Route path="*" component={ NotFound } />

     
    </Route>
  </Router>
)

export default routes
