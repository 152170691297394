import React from 'react'

const welfare_2 = () => {
  return (
    <g>
    <path d="M68.431,149.761c1.216,1.777,8.604-7.949,14.682-22.162C70.956,134.705,66.842,147.518,68.431,149.761z"/>
    <path d="M58.05,71.957c-1.216-0.748-8.136,9.632-14.588,21.602c-0.842,1.496-1.589,2.994-2.245,4.49
        C54.029,87.669,59.265,72.705,58.05,71.957z"/>
    <path d="M100.002,0.002C44.771,0.002,0,44.772,0,100.001c0,55.226,44.771,99.997,100.002,99.997
        c55.229,0,99.998-44.77,99.998-99.997C200,44.772,155.231,0.002,100.002,0.002z M72.92,84.114c-1.216,1.309-3.835,1.03-5.237-1.404
        c-1.496-2.525-1.028-5.329,0.468-6.92c1.496-1.59,4.208-0.465,5.424,2.059C74.79,80.28,74.136,82.805,72.92,84.114z
         M170.646,83.271c-2.057-0.279-4.956,0-7.294,0.377c-3.648,6.264-8.978,15.334-12.531,23.189
        c-3.086,6.828-2.057,12.625,1.87,12.812c4.208,0.187,9.539-6.453,12.999-13.465c0.561-1.122,1.589-1.309,1.964,0
        c0.468,1.495,0,3.178-0.935,5.329c-1.216,2.713-7.388,10.943-14.869,10.662c-3.747-0.115-7.032-2.721-8.041-7.148
        c-2.97,4.082-7.627,7.24-12.161,7.24c-7.855,0-9.819-5.703-7.013-13.84c3.086-9.071,11.222-18.795,9.726-19.637
        c-1.31-0.748-11.502,5.611-16.926,14.494c-3.367,5.517-6.826,12.062-9.071,17.768c-0.374,0.84-1.029,1.027-1.589,0.374
        c-0.876-0.985-1.508-2.31-1.504-4.35c-3.704,3.178-8.555,5.586-13.927,7.528c-5.144,12.531-11.783,27.4-19.358,30.393
        c-1.87,0.748-3.74,0.469-5.424-1.964c-1.964-2.804-3.647-6.077-1.87-11.596c2.713-8.416,10.755-14.121,20.012-17.673
        c0.935-2.059,1.777-4.302,2.711-6.453c-2.711,2.712-5.985,4.676-9.444,4.676c-4.156,0-6.679-2.743-7.342-6.828
        c-3.107,4.275-7.926,7.015-12.297,7.015c-4.385,0-6.734-2.283-7.281-5.829c-2.973,2.886-7.306,5.757-11.89,5.642
        c-6.453-0.187-9.913-4.676-7.761-14.777c3.459-16.27,14.12-33.198,23.659-43.108c1.402-1.496,2.712-1.685,4.021-0.469
        c2.712,2.433,3.272,5.893,2.151,10.287c-2.899,11.502-13.373,22.723-21.696,28.147c-3.74,9.166-4.769,16.925-0.561,17.302
        c3.876,0.324,9.28-4.654,13.033-10.653c0.022-0.067,0.037-0.131,0.06-0.195c2.338-6.828,8.51-17.863,11.97-22.726
        c0.842-1.121,1.496-1.401,2.245-0.561c1.402,1.498,2.15,5.519-0.093,10.195c-1.965,4.113-5.892,10.471-7.856,15.148
        c-2.244,5.424-2.057,9.071,0.935,8.883c4.208-0.187,9.351-6.359,12.157-12.812c0.063-0.112,0.129-0.203,0.197-0.285
        c0.795-2.368,1.983-4.838,3.638-7.288c7.294-10.848,14.869-15.056,19.638-15.056c3.741,0,5.611,2.9,5.517,6.172
        c1.122-1.872,2.151-3.555,3.086-5.145c0.748-1.309,1.684-1.4,2.338-0.561c1.402,1.682,2.151,5.706-0.187,10.287
        c-2.805,5.611-8.696,16.925-12.625,25.996c6.025-3.01,10.447-6.741,13.871-10.549c4.375-10.759,22.54-41.367,29.8-50.421
        c0.842-1.121,1.777-1.03,2.245,0.095c1.215,2.712,0.934,7.385-2.619,13.278c-2.245,3.739-7.2,11.314-11.222,17.581
        c3.835-3.742,8.697-6.828,11.222-6.546c3.18,0.374,6.078,5.047,3.647,9.819c-2.057,4.113-6.545,10.941-8.697,15.804
        c-2.337,5.424-1.87,9.818,1.59,9.631c3.525-0.17,8.154-5.881,11.132-11.453c0.28-1.175,0.676-2.406,1.214-3.697
        c1.964-4.674,5.891-12.531,9.913-19.916c-3.18,0-5.33-1.404-6.078-2.433c-0.936-1.309-0.375-1.965,1.028-1.869
        c2.619,0.092,5.238-0.095,7.762-0.373c2.899-5.143,5.518-9.54,7.107-11.597c0.842-1.216,1.777-1.122,2.245,0
        c1.029,2.617,1.029,5.703-1.216,10.566c3.647-0.092,5.05,1.591,5.798,2.808C172.423,82.618,172.048,83.271,170.646,83.271z"/>
    <path d="M96.299,88.79c-2.899-0.187-9.725,5.797-14.869,13.934c-5.05,8.041-6.078,16.27-2.337,16.365
        c3.647,0.092,8.416-5.798,13.185-14.027C98.169,94.775,99.011,88.978,96.299,88.79z"/>
</g>
  )
}

export default welfare_2