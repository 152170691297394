
import React from 'react'

const close = () => {
  return (
    <g>
      <path d="M112.135,100.418l49.375-49.373c3.234-3.234,3.234-8.48,0-11.717c-3.232-3.236-8.484-3.236-11.717,0l-49.374,49.373
       L51.046,39.328c-3.231-3.236-8.485-3.236-11.717,0c-3.235,3.236-3.235,8.482,0,11.717l49.376,49.373l-49.376,49.375
       c-3.235,3.234-3.235,8.482,0,11.715c1.62,1.617,3.739,2.426,5.859,2.426s4.245-0.809,5.857-2.426l49.373-49.375l49.374,49.375
       c1.621,1.617,3.74,2.426,5.859,2.426c2.117,0,4.242-0.809,5.857-2.426c3.234-3.232,3.234-8.48,0-11.715L112.135,100.418z"/>
    </g>
  )
}

export default close
