
import React from 'react'

const logo_dia = () => {
  return (
  	<g transform="scale(1.8) translate(-95, 10)">
      <path fill="#FFFFFF" d="M68.868,49.979c0,21.723-17.639,39.339-39.403,39.339H0V10.636h29.466
      	C51.23,10.636,68.868,28.25,68.868,49.979z M28.562,29.627h-9.25v40.705h9.25c11.26,0,20.387-9.114,20.387-20.352
      	C48.949,38.738,39.822,29.627,28.562,29.627z M155.739,57.557c0,7.909-6.419,14.316-14.342,14.316
      	c-7.917,0-14.341-6.408-14.341-14.316c0-7.91,6.424-14.322,14.341-14.322C149.32,43.235,155.739,49.647,155.739,57.557z
      	 M174.378,55.174c-1.226-17.121-15.518-30.645-32.981-30.645c-18.263,0-33.074,14.792-33.074,33.027
      	c0,18.234,14.811,33.022,33.074,33.022c6.88,0,13.268-3.295,18.558-7.917l14.516,6.656v-34.13
      	C174.47,55.188,174.439,55.185,174.378,55.174z M98.557,22.384l-20.198,9.308v57.626h20.198V22.384z M299.932,10.636v58.827
      	c0,10.986-8.919,19.9-19.93,19.9h-58.914V30.537c0-10.984,8.92-19.901,19.927-19.901H299.932 M299.932,0h-58.917
      	c-16.864,0-30.583,13.698-30.583,30.537v58.825c0,5.872,4.77,10.638,10.656,10.638h58.914c16.865,0,30.586-13.698,30.586-30.537
      	V10.636C310.589,4.763,305.819,0,299.932,0L299.932,0z M242.731,20.393c-6.071,0-10.986,4.906-10.986,10.97
      	c0,6.059,4.915,10.969,10.986,10.969c6.065,0,10.982-4.91,10.982-10.969C253.713,25.299,248.796,20.393,242.731,20.393z
      	 M278.515,57.455c-6.065,0-10.985,4.911-10.985,10.968c0,6.059,4.919,10.975,10.985,10.975c6.069,0,10.986-4.916,10.986-10.975
      	C289.501,62.365,284.584,57.455,278.515,57.455z M232.405,78.458h19.755l37.515-57.181h-19.757L232.405,78.458z"/>
  	</g>
  )
}

export default logo_dia
