
import React from 'react'
import { browserHistory } from 'react-router'
import { UIStore } from '../../stores'
import { Notification } from '../../components/Dialogs/Notification/components'


const checkApiResponse = (error) => {
  if (error.status === 0 && error.data === '' && error.config && error.config.withCredentials === undefined) {
    UIStore.showNotification({
      type: 'danger',
      content: () => <Notification message="Tu sesión ha expirado, tienes que volver a conectarte." />
    })
    // browserHistory.push('/login')
    window.location.href = "/no-autenticado";
    throw error
  } else {
    return error
  }
}


export default checkApiResponse
