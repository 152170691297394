import React, { Component } from "react";
import { translate } from "react-i18next";
import Icon from "../Icons/Icon";
import { getDeliveryTime } from "../../utils/api";
import { Notification } from "../Dialogs/Notification/components";
import { AppStore, AuthStore, TrackingApartments, UIStore } from "../../stores";

const daysArr = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
];
const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];
@translate(["common"], { wait: false })
class OrderConfirmationHorario extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    selected_time_id: 0,
    selected_schedule_index: 0,
    schedules: [],
    days: [],
    allData: [],
    daysAndSheculdes: [],
    selectedDayAndSchedule: { day: null, schedule: null },
  };
  onDayPress = (day, schedule, price, id_delivery_time_slot) => {
    this.setState({ selectedDayAndSchedule: { day, schedule } });
    AppStore.iddeliverytimeslot = id_delivery_time_slot.toString();
    AppStore.pricedelivery = price;
    this.props.setPriceUI(price);
  };
  onTimZonePress = (id, price) => {
    this.setState({ selected_time_id: id });
    AppStore.iddeliverytimeslot = id.toString();
    AppStore.pricedelivery = price;
    this.props.setPriceUI(price);
  };
  componentWillMount() {
    UIStore.showLoading();
    getDeliveryTime(AuthStore.user.addresses[0].id || 0, AppStore.idprovider, AuthStore.bookingId)
      .then((x) => {
        console.log("then", x);
        if (x.code) {
          UIStore.showNotification({
            type: "danger",
            content: () => <Notification message={`${x.message}`} />,
          });
        }
        const days = x.filter((item, index, array) => {
          return array.findIndex((day) => day.date === item.date) === index;
        });
        const daysAndSheculdes = days.map((day) => {
          const schedules = x
            .filter((schedule) => schedule.date === day.date)
            .map((schedule, index) => {
              return { ...schedule, _id: index + "schedule" };
            });
          return {
            ...day,
            schedules,
          };
        });
        this.setState({ days: days });
        this.setState({ daysAndSheculdes });
        this.setState({ allData: x });
        AppStore.iddeliverytimeslot = "0";
        this.props.setPriceUI(0);
        UIStore.hideLoading();
      })
      .catch((x) => {
        UIStore.hideLoading();
        UIStore.showNotification({
          type: "danger",
          content: () => (
            <Notification message="¡Vaya! Parece que hay un problema, comunicate con atención al cliente para mas información." />
          ),
        });
        console.log("catch");
      });
  }
  render() {
    const {
      daysAndSheculdes,
      selectedDayAndSchedule: { day, schedule },
    } = this.state;
    return (
      <div>
        <div className="container-title">
          <Icon
            type="clock"
            viewport="0 0 512 512"
            className="icon"
            height="35"
            width="35"
          />
          <div className="container-title-info">
            <h2 className="h1 light grey s28">Franja horaria</h2>
          </div>
        </div>
        <div className="day-card-container">
          {daysAndSheculdes &&
            daysAndSheculdes.map((value, index) => {
              return (
                <DayCard
                  key={index + "DayCard"}
                  name={`${daysArr[new Date(value.date).getDay()]}`}
                  date={`${new Date(value.date).getUTCDate()}`}
                  onPress={this.onDayPress}
                  isTrueDay={day === value.id_delivery_time_slot}
                  scheduleID={schedule}
                  index={index}
                  day_id={value.id_delivery_time_slot}
                  schedules={value.schedules}
                  price={value.price}
                />
              );
            })}
        </div>
      </div>
    );
  }
}

const DayCard = ({
  name = "",
  date = "",
  isSlected = false,
  index,
  onPress = () => {},
  schedules = [],
  day_id = "",
  isTrueDay,
  scheduleID,
  price,
}) => {
  const bgColor = isSlected ? "active" : "inactive";
  const today = `${new Date().getUTCDate()} ${
    months[new Date().getUTCMonth()]
  }`;
  const ui_day = /* date === today?'HOY': */ name.toUpperCase().substr(0, 3);
  return (
    <div>
      <div className={`day-card inactive`} onClick={() => onPress(index)}>
        <p className="day-card-text">
          {ui_day} - {date}
        </p>
      </div>
      {Array.isArray(schedules) &&
        schedules.map((schedule, index) => {
          const is_selected = isTrueDay && schedule._id === scheduleID;
          return (
            <button
              disabled={!schedule.available}
              key={index + "SchedulesMap"}
              className={`time-interval-button ${is_selected ? "active" : " "}`}
              onClick={() => {
                TrackingApartments.select_delivery_slot(AuthStore.apartmentId);
                onPress(
                  day_id,
                  schedule._id,
                  schedule.price,
                  schedule.id_delivery_time_slot
                )
              }
              }
            >
              <p>
                {schedule.begin_time} - {schedule.end_time}
              </p>
              <p
                className={`time-interval-text ${
                  schedule.available ? "active-time" : "inactive-time"
                } ${is_selected ? "select-time" : " "}`}
              >
                {schedule.price === 0 ? "GRATIS" : `${schedule.price}€`}
              </p>
            </button>
          );
        })}
    </div>
  );
};

export default OrderConfirmationHorario;
