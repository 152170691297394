import config from "../../config";
import checkApiResponse from "./checkApiResponse";

/**
 * @func: checkVoucherValidation
 * @return {[Object]} data
 *
 * @description:
 */

const checkVoucherValidation = (voucher, id_provider, booking_id) => {
  return config.API.voucherPost({},{voucher, id_provider, booking_id},{})
    .then((result) => result.data)
    .catch((error) => checkApiResponse(error))
};

export default checkVoucherValidation;
