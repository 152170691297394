
import React, { Component } from 'react'
import config from '../../../../config'
import { capitalize } from '../../../../utils'
import { AppStore, UIStore } from '../../../../stores'
import { Icon, Button, LinkButton } from '../../../'

/**
 *
 */

class FinisOrderModal extends Component {
  render () {
    const link = config.AVAILABLE_PROVIDERS.filter(provider => provider.id === AppStore.idprovider)[0].URL || 'https://market.ilusia.com'
    const name = (AppStore.providername) ? capitalize(AppStore.providername) : ''
    var text
    if (AppStore.idprovider == 3){
        text = <p className="p light black s16">Ve a <span className="p regular black s16">listas</span>, revisa la lista ILUSIA y pulsa en incluir al ticket selección.</p>
    } else {
      text = <p className="p light black s16">Ve a <span className="p regular black s16">"Mi Cesta"</span>, revisa el pedido y pulsa en finalizar.</p>
    }
    return (
      <div className="finish-order-modal">
        <div className="header">
          <h2 className="h2 regular black s28">Para finalizar tu compra sigue estos pasos</h2>
        </div>

        <ul>
          <li>
            <div className="icon-wrapper">
              <Icon type="user" fill="white" size="30" />
            </div>
            <p className="p light black s16"><span className="p regular black s16">Inicia sesión</span> en la web del supermercado para que aparezca tu carrito.</p>
          </li>
          <li>
            <div className="icon-wrapper">
              <Icon type="cart" fill="white" size="30" />
            </div>
            { text }
          </li>
          <li>
            <div className="icon-wrapper">
              <Icon type="credit_card" fill="white" size="30" />
            </div>
            <p className="p light black s16">Rellena el formulario de entrega y pulsa en <span className="p regular black s16">finalizar</span>.</p>
          </li>
        </ul>
        <div className="footer">
          <div className="box-md-12">
            <LinkButton type="outline"
                        color="blue"
                        placeholder={ `Ir a ${ name }` }
                        target="_blank"
                        onClick={ link } />
          </div>
          <div className="box-md-12">
            <Button type="flat" placeholder="Entendido" color="grey" onClick={ () => UIStore.hideModal() } />
          </div>
        </div>
      </div>
    )
  }
}


export default FinisOrderModal
