
/**
 * @class: Cookies extends React.Component
 * @author: Edgar Bermejo
 *
 * NOTE: Al ser un requisito esencial la interacción de usuario es importante NO bloquear
 * totalmente las posibles acciones. Por esto, este tipo de dialogos
 * no disponen de la propiedad 'clickOnBackgroundClose' en su configuración.
 */

import React, { Component } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { LinkButton, IconButton } from '../../'
import { UIStore } from '../../../stores'
import ConfigCookie from "../Modal/modals/ConfigCookies";
import { setCookie, deleteCookie } from "../../../utils";

class Cookies extends Component {

  static propTypes = {
    isOpen: React.PropTypes.bool,
    transitionName: React.PropTypes.string
  }

  static defaultProps = {
    isOpen: false,
  }


  render () {
    return (
      <ReactCSSTransitionGroup transitionName={ this.props.transitionName }
                               transitionEnterTimeout={ 500 }
                               transitionLeaveTimeout={ 300 }>
          {(this.props.isOpen) ? this.renderContent() : null }
      </ReactCSSTransitionGroup>
    )
  }


  renderContent () {
    return (
      <div className={!UIStore.isModalOpen ? 'modal-wrapper' : ''}>
        <div className="cookies-wrapper" ref="cookies" role="dialog">
          <div className="cookies-content container">
            <p className="p light white s14">
              Le informamos que la web de la ILUSIA utiliza cookies propias y deterceros para gestionar la web y recabar información de la utilización de la misma confines analíticos y para mostrarle publicidad personalizada en base a un perfil elaboradoa partir de sus hábitos de navegación. Puede obtener más información sobre nuestra <LinkButton placeholder="Política de Cookies" type="flat" color="white" onClick="https://www.ilusia.com/cookies" target="_blank"/>. Puede Aceptar, Configurar o Rechazar el uso de Cookies pulsando en los botones quedisponemos a tal efecto a continuación.
            </p>
          </div>
          <div className="cookies-buttons--container">
            <button className="cookies-button" onClick={this.saveCookie}>
              Aceptar
            </button>
            <button className="cookies-button" onClick={this.renderModal}>
              Ver configuración
            </button>
            <button className="cookies-button" onClick={this.deleteAllCookies}>
              Rechazar
            </button>
          </div>
        </div>
      </div>
    )
  }

  deleteAllCookies = () => {
    setCookie("cookie-dialog", encodeURIComponent("yes"), null);
    setCookie('cookie-analytic', encodeURIComponent("no"), null);
    deleteCookie("_gat", "/", document.domain);
    deleteCookie("_gid", "/", document.domain);
    deleteCookie("_ga", "/", document.domain);
    UIStore.hideCookiesDialog();
  }

  saveCookie = () => {
    setCookie("cookie-dialog", encodeURIComponent("yes"), null);
    setCookie('cookie-analytic', encodeURIComponent("yes"), null);
    UIStore.hideCookiesDialog();
  }

  renderModal = () => {
    UIStore.toggleModal({ content: () => <ConfigCookie />})
  }
}


export default Cookies
