import React, {Component} from 'react'
import { translate } from 'react-i18next'
import {AddressesAcordion} from '../components'
// import { observable } from 'mobx'

// @observable
@translate(['common'], { wait: false })
class MyAddresses extends Component {
    render(){
        return(
            <div className="user-zone-view linked-accounts">
            <header>
              <h2 className="h2 s30 regular blue">Mis direcciones</h2>
            </header>
            <article>
              <AddressesAcordion />
              {/* <LinkedAccountsAccordion /> */}
            </article>
          </div>
        )
    }
}

export default MyAddresses