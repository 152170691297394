
/*****************************************************************************
 * @class: TrackingApartments
 *
 * @description: Class for tracking apartments events
 *
 *****************************************************************************/

import ReactGA from "react-ga4";

class TrackingApartments {

    constructor () {
    
    }
    
    login_with_qr(apartmentId){
        const category = 'ilusia';
        const action = 'login_with_qr';
        const label = 'Login - QR - Apartment ID: ' + apartmentId;
        this.create_GA_event(category, action, label);
    }

    login_with_booking(apartmentId){
        const category = 'ilusia';
        const action = 'login_with_booking';
        const label = 'Login - Booking - Apartment ID: ' + apartmentId;
        this.create_GA_event(category, action, label);
    }

    search_product(apartmentId, searchTerm) {
        const category = 'ilusia';
        const action = 'search_product';
        const label = 'Search - Product - Term: ' + searchTerm + ' - Apartment ID: ' + apartmentId;
        this.create_GA_event(category, action, label);
    }
    
    click_on_category(apartmentId, category) {
        const categoryGA = 'ilusia';
        const action = 'click_on_category';
        const label = 'Category - Access - Apartment ID: ' + apartmentId;
        this.create_GA_event(categoryGA, action, label);
    }

    view_product_details(apartmentId) {
        const category = 'ilusia';
        const action = 'view_product_details';
        const label = 'Product - Details - Apartment ID: ' + apartmentId;
        this.create_GA_event(category, action, label);
    }
    
    select_delivery_slot(apartmentId) {
        const category = 'ilusia';
        const action = 'select_delivery_slot';
        const label = 'Delivery Slot - Select - Apartment ID: ' + apartmentId;
        this.create_GA_event(category, action, label);
    }
    
    user_zone_chat(apartmentId) {
        const category = 'ilusia';
        const action = 'user_zone_chat';
        const label = 'User Zone - Chat - Apartment ID: ' + apartmentId;
        this.create_GA_event(category, action, label);
    }
    
    user_zone_order_history(apartmentId) {
        const category = 'ilusia';
        const action = 'user_zone_order_history';
        const label = 'User Zone - Order history - Apartment ID: ' + apartmentId;
        this.create_GA_event(category, action, label);
    }
    
    voucher_valid(apartmentId, voucherCode) {
        const category = 'ilusia';
        const action = 'voucher_valid';
        const label = 'Voucher - Valid - Voucher: ' + voucherCode + ' - Apartment ID: ' + apartmentId;
        this.create_GA_event(category, action, label);
    }

    voucher_minimum_requirements(apartmentId, voucherCode) {
        const category = 'ilusia';
        const action = 'voucher_minimum_requirements';
        const label = 'Voucher - Minimum requirements - Voucher: ' + voucherCode + ' - Apartment ID: ' + apartmentId;
        this.create_GA_event(category, action, label);
    }

    voucher_expire(apartmentId, voucherCode) {
        const category = 'ilusia';
        const action = 'voucher_expire';
        const label = 'Voucher - Expire - Voucher: ' + voucherCode + ' - Apartment ID: ' + apartmentId;
        this.create_GA_event(category, action, label);
    }

    voucher_invalid(apartmentId, voucherCode) {
        const category = 'ilusia';
        const action = 'voucher_invalid';
        const label = 'Voucher - Invalid - Voucher: ' + voucherCode + ' - Apartment ID: ' + apartmentId;
        this.create_GA_event(category, action, label);
    }

    order_create(apartmentId) {
        const category = 'ilusia';
        const action = 'order_create';
        const label = 'Order - Create - Apartment ID: ' + apartmentId;
        this.create_GA_event(category, action, label);
    }

    order_complete(apartmentId) {
        const category = 'ilusia';
        const action = 'order_complete';
        const label = 'Order - Complete - Apartment ID: ' + apartmentId;
        this.create_GA_event(category, action, label);
    }

    order_error_payment(apartmentId) {
        const category = 'ilusia';
        const action = 'order_error_payment';
        const label = 'Order - Error payment - Apartment ID: ' + apartmentId;
        this.create_GA_event(category, action, label);
    }

    create_GA_event(category, action, label){
        ReactGA.event({
            category: category,
            action: action,
            label: label,
            nonInteraction: true
        });
    }

}

export default new TrackingApartments();
