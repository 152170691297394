
import React, { Component } from 'react'
import { browserHistory } from 'react-router'
import { AuthStore } from '../stores'
import { Icon, Button, LinkButton, SwitchButton } from '../components'


class SignUpPage extends Component {

  state = { terms: false }

  componentDidMount () {
    window.setTimeout(() => {
      this.refs.email.focus()
      window.clearTimeout(this)
    })
  }

   constructor(props) {
    super(props);
    this.processForm = this.processForm.bind(this);
  } 


  render () {
    return (
      <div className="section">
        <div className="signup-page">
          <div className="logotype">
            <div className="symbol">
              <Icon className="icon-symbol" type="symbol" width="60" height="60" fill="blue" viewport="0 0 200 200"></Icon>
            </div>
            <div className="logo">
              <Icon className="icon-logo" type="logo" width="120" height="40" fill="grey" viewport="0 0 200 70"></Icon>
            </div>
          </div>
          <h1 className="h1 light grey s32">Crear cuenta</h1>
          <form onSubmit={ this.processForm }>
            <input ref="email" className="input" id="email" type="text" placeholder="Correo electrónico" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" />
            <input className="input" id="password" type="password" placeholder="Contraseña" readOnly={ false } />
            <input className="input" id="name" type="text" placeholder="Nombre" readOnly={ false } />
            <input className="input" id="lastname" type="text" placeholder="Apellidos" readOnly={ false } />
            <button type="submit"></button>
            <Button onClick={ this.processForm } type="outline" placeholder="Registrarme" color="blue" />
          </form>
          <p className="p light grey s14">
            <span>¿Ya tienes cuenta? </span><LinkButton onClick="login" type="flat" placeholder="Inicia sesión" color="blue" />
          </p>
        </div>
        <div className="signup-page-footer">
          <p className="p light grey s12 footer">
            <span>Al registrarte aceptas los</span> <LinkButton type="flat" target= "blank" onClick="https://www.ilusia.com/legales/#aviso-legal" placeholder="Términos y condiciones de uso" color="grey" /><span> y la </span><LinkButton type="flat" target="blank" onClick="https://www.ilusia.com/privacidad/" placeholder="política de Privacidad." color="grey" />

          </p>
          <br />
          <SwitchButton name="terminos"
                            id="terminos"
                            isChecked={ this.state.terms }
                            onSwitchChange={ this.TerminosSwichButtonToogle }
                          />
        </div>
      </div>
    )
  }

TerminosSwichButtonToogle = (event) => this.setState({ terms: !this.state.terms })

  processForm (event) {

    event.preventDefault()
    const email = document.querySelector('form input#email').value
    const password = document.querySelector('form input#password').value
    const name = document.querySelector('form input#name').value
    const lastname = document.querySelector('form input#lastname').value
    const terms = this.state.terms
    AuthStore.singUp(email, password, name, lastname, terms, () => browserHistory.push('/'))
  }
}

export default SignUpPage
