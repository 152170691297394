
import React, { Component } from 'react'

class AuthPage extends Component {

  render () {
    return (
      <div className="section">
        Autenticando usuario
      </div>
    )
  }

}

export default AuthPage
