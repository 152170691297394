
import React from 'react'

const hand = () => {
  return (
    <g transform="scale(2.8)">
      <path d="M26.6,2c-3.103,0-5.62,2.716-5.62,6.076v38.542l-8.373-9.045c-2.198-2.369-5.757-2.363-7.963,0
        c-2.192,2.379-2.192,6.23,0,8.605l17.982,19.396c0.264,0.287,0.55,0.538,0.862,0.768c0.073,0.051,0.151,0.081,0.222,0.13
        c0.246,0.159,0.495,0.312,0.755,0.429c0.08,0.033,0.158,0.045,0.252,0.079c0.255,0.101,0.522,0.2,0.811,0.257
        c0.008,0.002,0.018,0.002,0.029,0.012c0.34,0.061,0.683,0.111,1.043,0.111c0.716,0,1.377-0.162,1.997-0.414
        c0.038-0.021,0.088-0.021,0.138-0.045c0.318-0.139,0.632-0.318,0.922-0.525c0.011-0.008,0.03-0.016,0.046-0.027
        c0.636-0.453,1.166-1.036,1.585-1.715c0.062-0.084,0.097-0.174,0.142-0.264c0.136-0.256,0.278-0.502,0.382-0.773
        c0.063-0.167,0.097-0.344,0.143-0.515c0.054-0.205,0.134-0.401,0.171-0.62c0.063-0.326,0.077-0.662,0.093-0.988
        c0-0.07,0.025-0.133,0.025-0.193V8.076C32.244,4.716,29.715,2,26.6,2z M44.554,27.764c-3.099,0-5.623,2.724-5.623,6.074v27.443
        c0,3.354,2.524,6.08,5.623,6.08c3.106,0,5.631-2.727,5.631-6.08V33.848C50.185,30.487,47.66,27.764,44.554,27.764z M62.794,36.996
        c-3.119-0.004-5.649,2.722-5.649,6.076v18.209c0,3.354,2.53,6.08,5.649,6.08c3.1,0,5.611-2.727,5.611-6.08V43.072
        C68.405,39.717,65.894,36.992,62.794,36.996z"/>
    </g>
  )
}

export default hand
