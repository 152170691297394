
import React from 'react'

const location = () => {
  return (
    <g>
      <path d="M100,1C64.758,1,36.182,29.572,36.182,64.818c0,7.137,0.244,14.93,2.406,21.338
       c6.332,30.729,58.828,113.68,61.412,113.68c3.055,0,55.188-83.025,61.436-113.723c2.146-6.385,2.383-14.174,2.383-21.295
       C163.818,29.572,135.25,1,100,1z M100,92.297c-14.59,0-26.412-11.83-26.412-26.41c0-14.59,11.822-26.416,26.412-26.416
       c14.586,0,26.412,11.826,26.412,26.416C126.412,80.467,114.586,92.297,100,92.297z"/>
    </g>
  )
}

export default location
