
import config from '../../config'
import checkApiResponse from './checkApiResponse'

/**
 * @func: exportCart
 * @return {[Object]} data
 *
 * @description:
 */

const exportCart = (id) => {
  const params = { id_order: id }
  return config.API.cartExportationWebPost({}, params, {})
  .then(result => result.data)
  .catch(error => checkApiResponse(error))
}


export default exportCart
