
import React from 'react'

const grid_view = () => {
  return (
  	<g>
     <path d="M37.356,54.878c-9.664,0-17.513-7.848-17.513-17.513c0-9.676,7.849-17.524,17.513-17.524
     	c9.683,0,17.519,7.849,17.519,17.524C54.875,47.03,47.039,54.878,37.356,54.878z M54.875,99.994c0-9.664-7.836-17.501-17.519-17.501
     	c-9.664,0-17.513,7.837-17.513,17.501c0,9.676,7.849,17.524,17.513,17.524C47.039,117.519,54.875,109.67,54.875,99.994z
     	 M54.875,162.646c0-9.676-7.836-17.513-17.519-17.513c-9.664,0-17.513,7.837-17.513,17.513c0,9.664,7.849,17.513,17.513,17.513
     	C47.039,180.159,54.875,172.311,54.875,162.646z M162.644,54.878c-9.677,0-17.519-7.848-17.519-17.513
     	c0-9.676,7.842-17.524,17.519-17.524c9.676,0,17.513,7.849,17.513,17.524C180.156,47.03,172.319,54.878,162.644,54.878z
     	 M180.156,99.994c0-9.664-7.837-17.501-17.513-17.501c-9.677,0-17.519,7.837-17.519,17.501c0,9.676,7.842,17.524,17.519,17.524
     	C172.319,117.519,180.156,109.67,180.156,99.994z M180.156,162.646c0-9.676-7.837-17.513-17.513-17.513
     	c-9.677,0-17.519,7.837-17.519,17.513c0,9.664,7.842,17.513,17.519,17.513C172.319,180.159,180.156,172.311,180.156,162.646z
     	 M100.003,54.878c-9.677,0-17.519-7.848-17.519-17.513c0-9.676,7.842-17.524,17.519-17.524s17.513,7.849,17.513,17.524
     	C117.516,47.03,109.68,54.878,100.003,54.878z M117.516,99.994c0-9.664-7.836-17.501-17.513-17.501S82.484,90.33,82.484,99.994
     	c0,9.676,7.842,17.524,17.519,17.524S117.516,109.67,117.516,99.994z M117.516,162.646c0-9.676-7.836-17.513-17.513-17.513
     	s-17.519,7.837-17.519,17.513c0,9.664,7.842,17.513,17.519,17.513S117.516,172.311,117.516,162.646z"/>
  	</g>
  )
}

export default grid_view
