import React, { Component } from 'react'
import { translate } from 'react-i18next'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { LinkIconButton, Icon, IconButton } from '../'
import { AppStore } from '../../stores'
import config from '../../config'
import { capitalize } from '../../utils'
import { Link } from 'react-router'

@translate(['common'], { wait: false })
class FiltersWelfares extends Component {

 
    state = {
      welfaresEnable: AppStore.welfaresEnable
    }


    render () {
      const icons = AppStore.welfares.map(welfares => {
          //const colour = allergens.colour
          var colour =  "rgba(110, 189, 88, 0.3)"
          var fill = "rgba(37,49,55, 0.3)"
          const id = welfares.id_welfare
          const name = welfares.name
          if (this.state.welfaresEnable.includes(id)) {
              //colour = welfares.colour
              colour = "rgba(154, 210, 75, 0.7)"
              fill = "rgba(255,255,255,0.8)"
          }
          return <div className="icon_position_up" key={id}>  
          	 <div className="icon_up">
	          	<IconButton type="fab large"
	                          style={ { backgroundColor: colour } }
	                          iconFill= { fill }
	                          id={ id }
	                          iconSize="82"
	                          viewport = '0 0 200 200'
	                          iconType={ "welfare_" + id }
	                          iconTitle={ name }
	                          onClick={ () => this.WelfaresEnabled(id)} />
                    </div>
                  <div className="icon_down"><p className="p s13 regular white" >{name}</p></div>
          </div>
      });

      return (
                <div className="parent_icon_position">
                  <div className="icon_top">
                    {icons}
                   </div>  
                </div>
      )

  }

    WelfaresEnabled(id) {
     	
        if (!AppStore.welfaresEnable.includes(id)){
              AppStore.welfaresEnable.push(id)
              this.setState({ welfaresEnable : AppStore.welfaresEnable} )
        } else {
              var index = AppStore.welfaresEnable.indexOf(id);
              if (index > -1) {
                  AppStore.welfaresEnable.splice(index, 1);
                  this.setState({ welfaresEnable : AppStore.welfaresEnable} )
              }
        }

    }

}

export default FiltersWelfares