
/**
 * @func: capitalize
 *
 * @param {String}
 * @param {String}
 */

const capitalize = (string) => {
  let tlc = string.toLowerCase()
  return tlc.charAt(0).toUpperCase() + tlc.slice(1)
}

export default capitalize
