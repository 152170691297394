
/*****************************************************************************
* @class: Toolbar
* @author: Edgar Bermejo
* @description:
*
*
* @usage:
*
*****************************************************************************/

import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Link } from 'react-router'
import { translate } from 'react-i18next'
import config from '../../config'
import { AppStore, UIStore, AuthStore, ShopStore } from '../../stores'
import { Button, Icon, Dropdown, Modal, LoginModal, SignupModal, LinkButton } from '../'
import CategoriesMenu from './components/CategoriesMenu/CategoriesMenu'
import ProviderOptions from './components/ProviderOptions'
import LoginSignup from '../Dropdowns/dropdowns/LoginSignup'
import UserLogged from '../Dropdowns/dropdowns/UserLogged'


@translate(['common'], { wait: false })
@observer
class Toolbar extends Component {

  static propTypes = {
  }

  static defaultProps = {
  }


  render () {
    const { t } = this.props
    const type = UIStore.toolbarType
    const color = (AppStore.provider) ? AppStore.provider.last_color : null
    const iconColor = (type === 'simple' || color === null) ? config.COLORS.BLUE : 'white'
    const iconClass = (type === 'simple' || color === null) ? 'no-blend-mode' : ''
    const styler = { background: (type === 'custom' && color) ? color : 'white' }

    return (
      <div className={ `toolbar ${ type }` } style={ styler }>
        <div className="logo-wrapper">
          <Link to="/" className="logotype">
            <Icon className={ `logo ${ iconClass }` }
                  type="logo_symbol"
                  width="114"
                  height="38"
                  fill={ iconColor }
                  viewport="0 0 114 38"></Icon>
          </Link>
        </div>

        <div className="provider-options-wrapper">
          { (AppStore.provider) ? <ProviderOptions /> : null }
        </div>

      </div>
    )
  }


  loginHandler = (event) => {
    UIStore.toggleModal({ content: () => <LoginModal onSubmit={ AuthStore.logIn } /> })
  }

  signupHandler = (event) => {
    UIStore.toggleModal({ content: () => <SignupModal onSubmit={ AuthStore.singUp } /> })
  }
}


export default Toolbar
