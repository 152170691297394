import React, { Component } from 'react'
import { FiltersAllergens, FiltersWelfares, FilterSwitch } from '../components/FiltersButton'
import { Link } from 'react-router'
import { AppStore } from '../stores'
import { Button, Icon, Dropdown, Modal, LoginModal, SignupModal, LinkButton } from '../components/Icons'
import { SwitchButton } from '../components/'


class Filters extends Component {


  constructor() {
        super();
        this.state = { showMe: true }
    };

  

  render () {

        var filter
        var className
        if (this.state.showMe) { 
            filter =  <FiltersAllergens />;
            className = "section filters_allergens"
        }
        else {
            filter = <FiltersWelfares />;
            className = "section filters_welfares"
        }
        
        return ( 

               <div className={className}>
                    <div className="container_back">
                        <div className="switch_position">
                             <FilterSwitch name="switch" isChecked={ this.props.active } color={ 'blue' } texts={ this.props.texts } onSwitchChange={ () => this.FiltersSwichButton() } />
                        </div>
                        { filter }
                    </div>
                </div>
      )        
  }

FiltersSwichButton() {
    if (AppStore.allergenActive === 1) {
        AppStore.allergenActive = 0
        this.setState({ showMe : true} )
    } else {
        AppStore.allergenActive = 1 
        this.setState({ showMe : false} )
    }
  
  }

}

export default Filters
