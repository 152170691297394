import React, { Component } from 'react';
import { UIStore} from '../../../../stores';
import { Icon, Button } from '../../../';
import { SwitchButton } from '../../../Buttons';
import { setCookie, deleteCookie } from "../../../../utils";

class ConfigCookies extends Component {
  
  constructor(){
    super()
    this.state = {
      selectedSection:0,
      saveCookiesAnalytics: true,
    };
    this.sectionContent = [
      {
        name: "Su privacidad", 
        id:0, 
        description:"Al visitar nuestra web, ésta puede almacenar o recuperar información en su navegador en forma de cookies. Esta información puede ser sobre usted, sus preferencias o su dispositivo y se utiliza principalmente para lograr que el sitio funcione como se espera. La información generalmente no lo identifica en forma directa, pero puede brindarle una experiencia web más personalizada. Para proteger su privacidad, puede optar por aceptarlas o por excluir algunos tipos de cookies cambiando nuestra configuración predeterminada. Sin embargo, si bloquea algunos tipos de cookies, su experiencia de uso en la web se puede ver afectada y también los servicios que podemos ofrecerle. Si quiere más información pulse aquí"},
      {
        name: "Cookies técnicas", 
        id:1, 
        RigthComponent:()=><Button onClick={ ()=>{} } type="flat" placeholder="Activado" color="blue" />,
        description:"Estas cookies permiten al usuario la navegación a través de nuestra web y la utilización de las diferentes opciones o servicios que existen en ella. Para garantizar el correcto funcionamiento de la web, mantendremos activas las cookies con finalidad técnica"},
      {
        name: "Cookies analíticas", 
        id:2,
        RigthComponent:()=><SwitchButton 
          id="cookies-analytics"
          name="cookies-analytics"
          isChecked={this.state.saveCookiesAnalytics}
          onSwitchChange={() => this.setState({ saveCookiesAnalytics: !this.state.saveCookiesAnalytics})} />, 
        description:"Estas cookies permiten al usuario la navegación a través de nuestra web y la utilización de las diferentes opciones o servicios que existen en ella. Para garantizar el correcto funcionamiento de la web, mantendremos activas las cookies con finalidad técnica"},
    ];
  }
  render() {
    const {sectionContent}=this
    const {name: title, description, RigthComponent} = sectionContent[sectionContent.findIndex(({id})=>id === this.state.selectedSection)];
    return (
      <div className="cookies-modal">
        <div className="cookies-modal--header">
          <div className="logotype">
            <div className="symbol">
              <Icon className="icon-symbol" type="symbol" width="45" height="45" fill="blue" viewport="0 0 200 200"></Icon>
            </div>
            <div className="logo">
              <Icon className="icon-logo" type="logo" width="90" height="36" fill="grey" viewport="0 0 200 70"></Icon>
            </div>
          </div>
          <h2 className="">Acerca de tu privacidad</h2>
        </div>
        <div className="cookies-modal--content">
          <section className="cookies-modal--content__left">
            <ul>
              {sectionContent.map((section, index) =>{
                return <Option 
                  onClick={()=>this.setState({selectedSection: section.id})} 
                  id={section.id} 
                  isSelected={section.id === this.state.selectedSection}
                  name={section.name}/>
              })}
            </ul>
          </section>
          <section className="cookies-modal--content__rigth">
              <div>
                <h3>{title}</h3>
                {RigthComponent && RigthComponent()}
              </div>
              <p>{description}</p>
          </section>
        </div>
        <div className="cookies-modal--footer">
          <Button onClick={ this.savePreferences } /* type="outline"  */placeholder="Confirmar preferencias" color="blue"  />
          <div >
            <Button onClick={ this.deleteAllCookies }  placeholder="Rechazarlas todas" color="blue"  />
            <Button onClick={ this.saveAllCookies } placeholder="Permitirlas todas" color="blue"  />
          </div>
        </div>
      </div>
    )
  }

  savePreferences = () => {
    if(this.state.saveCookiesAnalytics){
      this.saveCookiesAnalytics();
    }else{
      this.dontSaveCookiesAnalytics();
    }
    this.saveCookiesTecnical();
    UIStore.hideCookiesDialog();
    UIStore.hideModal();
  }

  deleteAllCookies = () => {
    this.saveCookiesTecnical();
    this.dontSaveCookiesAnalytics();
    UIStore.hideCookiesDialog();
    UIStore.hideModal();
  }

  saveAllCookies = () => {
    this.saveCookiesAnalytics();
    this.saveCookiesTecnical();
    UIStore.hideCookiesDialog();
    UIStore.hideModal();
  }

  saveCookiesTecnical = () => {
    setCookie("cookie-dialog", encodeURIComponent("yes"), null);
  }

  saveCookiesAnalytics = () => {
    setCookie('cookie-analytic', encodeURIComponent("yes"), null);
  }

  dontSaveCookiesAnalytics = () => {
    setCookie('cookie-analytic', encodeURIComponent("no"), null);
    deleteCookie("_gat", "/", document.domain);
    deleteCookie("_gid", "/", document.domain);
    deleteCookie("_ga", "/", document.domain);
  }

}

const Option = ({name, id=0, isSelected=false, onClick=()=>{}}) => {
  return(
    <li key={id} >
      <button onClick={onClick} className={`${isSelected && "selected"}`} >
      <div className={`option_section`}  >
        {isSelected && (<div className="option_section_complemet"></div>)}
        <p>{name}</p>
      </div>
      </button>
    </li>
  )
}

export default ConfigCookies;