
import React from 'react'
import { Icon } from '../Icons'
import { isValidHEXColor, isValidRGBColor } from '../../utils'

/**
 * @func: IconButton
 * @param: {[Object]} props
 *
 * @description:
 *
 * @props:
 * - type: define el tipo de botón que se renderizará.
 * - color: define el color para el background cuando 'type = fab'.
 * - size: define el tamaño del icono sobre las medidas estandar. No se aplica cuando 'type = fab'.
 * - iconType: define el icono que se renderizará.
 * - iconTitle: define el texto para los tags 'aria-labelledby' y 'title' del elemento SVG.
 * - onClickEvent: {[Event]}
 *
 * @usage:
 * <IconButton type="fab"
 *             color="transparent-grey"
 *             size="small"
 *             iconType="start_user"
 *             iconTitle="usuario"
 *             onClickEvent={ (event) => console.log('Click en el jodido IconButton!') }/>
 */

const IconButton = (props) => <button className={ `btn icon ${ props.type || '' } ${ props.size || '' } ${ props.color || '' } ${ props.className || '' }` }
                                      id={ props.id || null }
                                      style={ props.style || null }
                                      onClick={ props.onClick || null }>
  <Icon type={ props.iconType }
        title={ props.iconTitle }
        className={ props.iconColor || '' }
        size={ props.iconSize }
        fill={ props.iconFill || '' }
        viewport = { props.viewport || '' }/>
</button>

export default IconButton
