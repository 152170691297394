import config from '../../config'

/**
 * @func: putAddress
 * @return {[Object]} data
 *
 * @description: Crear dirreccion del usuario logueado
 */

const putAddress = (address) => {
    return config.API.addressesPut({}, address, {})
        .then(result => result)
        .catch(error => error)
}


export default putAddress