
import React from 'react'

const twitter = () => {
  return (
    <g>
      <path d="M200.17,38.46c-7.338,3.242-15.252,5.462-23.55,6.442c8.468-5.076,14.964-13.098,18.036-22.698
    		c-7.934,4.714-16.724,8.128-26.056,9.962c-7.488-7.956-18.154-12.948-29.95-12.948c-22.666,0-41.044,18.366-41.044,41.022
    		c0,3.22,0.374,6.358,1.068,9.344c-34.1-1.708-64.348-18.026-84.572-42.856c-3.53,6.036-5.558,13.098-5.558,20.628
    		c0,14.23,7.242,26.794,18.25,34.154c-6.73-0.212-13.044-2.048-18.59-5.142c0,0.192,0,0.362,0,0.534
    		c0,19.86,14.164,36.436,32.914,40.232c-3.434,0.918-7.062,1.43-10.804,1.43c-2.656,0-5.216-0.256-7.724-0.726
    		c5.216,16.276,20.384,28.136,38.324,28.478c-14.036,11.03-31.722,17.556-50.952,17.556c-3.318,0-6.57-0.192-9.792-0.554
    		c18.164,11.648,39.72,18.432,62.898,18.432c75.484,0,116.75-62.524,116.75-116.75c0-1.77-0.032-3.54-0.116-5.29
    		C187.723,53.926,194.689,46.694,200.17,38.46z"/>
  	</g>
  )
}

export default twitter
