
import React, { Component } from 'react'

/**
 * IMPORTANT: este botón es provisional!
 * se reemplazará por el botón que corresponda.
 *
 * @func: SwitchButton
 * @param: {[Object]} props
 *
 * @description: Botón del componete ListDropdown.
 *
 * props.placeholder {[String]} @required
 * props.toogleEvent {[Function]}: @required.
 */

class SwitchButton extends Component {

  static propTypes = {
    id: React.PropTypes.string.isRequired,
    onSwitchChange: React.PropTypes.func,
    isChecked: React.PropTypes.bool,
    name: React.PropTypes.string.isRequired
  }

  static defaultProps = {
    isChecked: false
  }

  constructor (...args) {
    super(...args)

    this.state = {
      isChecked: this.props.active
    }
  }


  componentWillMount () {
    this.setState({ isChecked: this.props.active })
  }


  render () {
    const { id, onSwitchChange, isChecked, name, disabled } = this.props
    return(
      <div className={ `btn switch ${ (disabled) ? 'disabled' : '' }`}>
        <label htmlFor={ id }>
          <input onChange={ onSwitchChange }
                 defaultChecked={ isChecked }
                 disabled={ disabled || false }
                 id={ id }
                 name={ name }
                 className="switch"
                 type="checkbox"
                 value={ (this.state.isChecked) ? 1 : 0 } />
            <div className="switch-inner">
              <div ref="switchButton"></div>
            </div>
        </label>
      </div>
    )
  }
}


export default SwitchButton
