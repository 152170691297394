
import React from 'react'

const reminder = () => {
  return (
    <g>
      <path d="M169.04,81.133L169.04,81.133v1.564l0,0V81.133z M99.999,16.883c-45.582,0-82.533,36.953-82.533,82.535
       c0,45.588,36.951,82.535,82.533,82.535c45.584,0,82.535-36.947,82.535-82.535C182.534,53.836,145.583,16.883,99.999,16.883
        M99.999,0c54.822,0,99.42,44.6,99.42,99.418c0,54.82-44.598,99.416-99.42,99.416c-54.82,0-99.418-44.596-99.418-99.416
       C0.581,44.6,45.179,0,99.999,0L99.999,0z M157.503,134.006c2.39-4.006,1.076-9.184-2.931-11.574l-46.13-27.506V39.381
       c0-4.662-3.783-8.443-8.443-8.443c-4.658,0-8.441,3.781-8.441,8.443v65.135l54.375,32.422c1.354,0.809,2.842,1.189,4.316,1.189
       C153.124,138.127,155.923,136.656,157.503,134.006z"/>
    </g>
  )
}

export default reminder
