
/**
 * Ordena un Array según los parametros dados
 *
 * USO:
 * Ordena por precio de mayor a menor:
 * Array.sort(sortBy('price', true, parseInt))
 *
 * Ordena por orden alfabétido (A-Z), case insensitive
 * Array.sort(sortBy('city', false, function(a){return a.toUpperCase()}))
 */

const sortBy = (field, back, primer, idcategory) => {
  let key

  (field === 'categoryEntryIndex')
  ? key = primer ? (x) => primer(x[field][idcategory]) : (x) => x[field][idcategory]
  : key = primer ? (x) => primer(x[field]) : (x) => x[field]

  back = !back ? 1 : -1

  return (a, b) => {
    return a = key(a), b = key(b), back * ((a > b) - (b > a))
  }
}

// Sort Object
// const sortBy = (o) => Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {})

export default sortBy
