
import React from 'react'
import { translate } from 'react-i18next'
import { AppStore } from '../../stores'
import { Icon, LinkButton } from '../'

/**
 * HoC para notificaciones de error de autenticación
 * @param {[Object]} props
 * @param {[Object]} t
 */
const OrderInProgress = ({ props, t }) => <div className="container order-in-progress">
  <div className="box-md-7 box-offset-md-2">
    <Icon type="add_to_cart" fill="light-grey" className="test" size="180" />
    <h2 className="h2 regular black s36">Tu pedido está en curso</h2>
    <p className="p regular grey s16">Tu pedido se está enviando al supermercado, recibirás una notificación cuando el proceso haya finalizado y podrás finalizar la compra .</p>
    <LinkButton placeholder="Volver a la tienda"
                color="blue"
                onClick={ `/` } />
    <p className="p regular grey s16">¿Tienes dudas? Habla con <span className="p regular blue s16"><a href="https://v2.zopim.com/widget/popout.html?key=4BPg8VUFClto9gFT1tuZqBlJ4MxyLgqM" target="_blank">Soporte técnico</a></span>.</p>
  </div>
</div>

export default translate(['common'], { wait: false })(OrderInProgress)
