
/**
 * @func: urliFy
 * @param: {[String]} color
 * @return: {[Boolean]}
 *
 * @description: normaliza un string para pintarlo en la URL
 */

const urliFy = (string) => {
  const normalized = string.replace(/\//g, '-').replace(/\%/g, '').replace(/\&/g, '').replace(/\€/g, '').replace(/\ /g, '-').replace(/\./g, '-').replace(/\,/g, '-')
  return normalized.toLowerCase()
}
export default urliFy
